<template>
  <div class="mb-4 beneficiary-offer-detail">
    <a class="btn text-themed go-back" @click="backToList()">{{ $t("flexben.general.go_back") }}</a>
    <div class="row">
      <div class="col-12 flexben-page-title text-capitalize">
        <h1>{{ offer.name[locale] }}</h1>
      </div>
    </div>
    <div class="container-bg-white">
      <div class="row p-3">
        <div class="col-12 mb-3">
          <h3 class="mt-0 pt-1 pb-0 pl-0 mb-1 text-capitalize">
            {{ $t('flexben.offers.beneficiary.available_between',getAvailableObj()) }}
          </h3>
        </div>
        <div class="col-12 col-xl-4 mx-auto mx-xl-0 text-center">
          <img :src="offer.image_url" :alt="offer.name[locale]" class="offer-image">
        </div>
        <div class="col-12 col-xl-8 mt-3 mt-lg-0 mt-3">
          <p><span class="flx-type-badge">{{ $t("flexben.offers.types."+offer.type) }}</span></p>
          <p class="links-container" v-html="offer.description[locale]"></p>
          <p v-if="offer.county_availability==='specific'">{{ $t("flexben.offers.beneficiary.offer_available_in") }}: {{
            offer.counties.join(', ') }}.
          </p>
          <p v-else-if="offer.county_availability==='all'">
            {{ $t("flexben.offers.beneficiary.offer_available_in_all") }}
          </p>
          <p>
            <button v-if="offer.code_type==='text' && !showCode"
                    class="btn btn-themed ml-auto"
                    @click="function(){
                      showCode=true
                      trackData();
                    }">{{$t('flexben.offers.btn.get_offer')}}
            </button>

            <a v-if="offer.code_type==='file'"
               target="_blank"
               :href="offer.code_value_url"
               class="btn btn-themed ml-auto"
               @click="trackData()">{{$t('flexben.offers.btn.get_offer')}}</a>
          </p>
          <p v-if="offer.code_type==='text' && showCode">
            {{ $t('flexben.offers.beneficiary.code_is')}}<span
            class="text-20 text-bold ml-1">{{ offer.code_value }}</span>
          </p>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mt-3 text-center">
          <div class="d-inline-flex text-left mx-auto container-partner">
            <img :src="offer.partner_image_url" :alt="offer.partner_name" class="offer-partner-image mr-2">
            <h6 class="my-auto p-0 pt-1">{{ offer.partner_name}}</h6>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-8 mt-3">
          <h3 class="mt-0 pt-1 pl-0 text-capitalize ">{{ $t('flexben.offers.fields.usage_instructions')}}</h3>
          <div class="links-container" v-html="offer.usage_instructions[locale]"></div>
        </div>
      </div>
    </div>
    <div class="container-bg-white mt-3"
         v-if="offer.contact.site || offer.contact.phone || offer.contact.address || (offer.partner_description[locale].length>0)">
      <div class="row p-3">
        <div class="col-12" v-if="offer.contact.site || offer.contact.phone || offer.contact.address">
          <h3 class="mt-0 pt-1 px-0">{{ $t('flexben.offers.fields.contact')}}</h3>
        </div>
        <div class="col-12 col-md-4 mt-1 mt-md-0 d-inline-flex" v-if="offer.contact.address">
          <font-awesome-icon icon="map-marker-alt" class="mr-1 contact-ico"/>
          <span class="links-container" v-html="offer.contact.address"></span>
        </div>
        <div class="col-12 col-md-4 mt-1 mt-md-0 d-inline-flex" v-if="offer.contact.phone">
          <font-awesome-icon icon="phone" class="mr-1 text-20"/>
          <a :href="'tel:+'+offer.contact.phone" target="_blank" class="text-themed">{{offer.contact.phone}}</a></div>
        <div class="col-12 col-md-4 mt-1 mt-md-0 d-inline-flex" v-if="offer.contact.site">
          <font-awesome-icon icon="link" class="mr-1 text-20"/>
          <a
            :href="offer.contact.site" target="_blank" class="text-themed">{{offer.contact.site}}</a>
        </div>
        <div class="col-12 mt-3" v-if="offer.partner_description[locale].length>0">
          <h3 class="mt-0 pt-1 px-0 pb-0">{{ $t('flexben.offers.fields.partner_description')}}</h3>
          <div class="links-container" v-html="offer.partner_description[locale]">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "OfferDetails",
  props: {
    offer: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    },
    locale: {
      type: String,
      default: 'ro',
      required: true
    }
  },
  data: function () {
    return {
      showCode: false
    };
  },
  methods: {
    backToList() {
      this.$emit('backToList')
    },
    moment(date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    getAvailableObj() {
      return {
        start_date: this.moment(this.offer.start_use_date),
        end_date: this.moment(this.offer.end_use_date),
      }
    },
    trackData() {
      this.flxTrackData({
        c: 'offer',
        cid: this.$store.getters.flx_activeEmployment.company_id,
        tid: this.offer.id,
        a: 'order',
        l: 'details',
      });
    }
  },
}
</script>

<style scoped lang="scss">
.beneficiary-offer-detail {
  .type-badge {
    color: #ffffff;
    border-radius: 5px;
    padding: 3px 10px;
    font-weight: bold;
    background-color: #F59100;
  }

  .offer-image {
    border-radius: 5px;
    margin: 0 auto;
    max-width: 300px;
  }

  .offer-partner-image {
    border-radius: 5px;
    margin: 0;
    max-width: 100px;
  }

  .container-partner {
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
  }
}
</style>
