import httpFlexbenService from "@/http/http-flexben.js";
import i18n from "@/plugins/language/i18n";
import moment from "moment";
import ConfirmCancelRequestModal from "../modals/ConfirmCancelRequestModal";
import RequestsTable from "../partials/RequestsTable";
import Datepicker from "vuejs-datepicker";

export default {
  name: "BeneficiaryRequests",
  components: {
    "confirm-cancel-request-modal": ConfirmCancelRequestModal,
    'vue-datepicker': Datepicker,
    'flx-request-table': RequestsTable,
  },
  data() {
    return {
      initialLoading: true,
      loading: true,
      loaderError: false,
      httpService: httpFlexbenService.beneficiary,
      filters: {
        types: [],
        statuses: [],
      },
      companyOptions: {
        cam_budget_taxation: false,
        gross_budget_taxation: false,
      },
      requests: {
        data: [],
        current_page: 1,
        from: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
        total_unfiltered: 0,
        sortable_columns: [],
      },
      filters_values: {
        status: null,
        type: null,
        start_date: null,
        end_date: null,
        per_page: null,
        page: null,
      },
      filterData: {},
      startDate: null,
      endDate: null,
      dateFormat: 'dd/MM/yyyy',
      sortField: '',
      sortDirection: 'asc',
      reverseSort: false,
      cancelRequestObj: {id: null, benefit_sub_type: null},
      cancelRequestIndex: null,
    };
  },

  watch: {
    beneficiaryId: {
      handler: function (val) {
        this.filterData = {};
        this._getRequests();
      },
      immediate: true
    },
    startDate: {
      handler: function (val) {
        this.filterData.start_date = (val !== null) ? moment(val).format('yyyy-MM-DD') : '';
        this._getRequests();
      }
    },
    endDate: {
      handler: function (val) {
        this.filterData.end_date = (val !== null) ? moment(val).format('yyyy-MM-DD') : '';
        this._getRequests();
      }
    },
  },
  computed: {
    beneficiaryId: function () {
      this.loading = false;
      return this.$store.getters.flx_beneficiaryActiveEmploymentId;
    },
    locale: function () {
      return i18n.locale;
    },
  },
  methods: {
    _getRequests() {
      if (this.beneficiaryId !== null) {
        this.loading = true;
        this.httpService.requests(this.beneficiaryId, this.filterData)
          .then((response) => {
            if (this.initialLoading) {
              this.filters = response.data.filters;
            }
            this.requests = response.data.items;
            this.companyOptions = response.data.company_options;
            this.initialLoading = false;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = true;
            this.loaderError = true;
          })
      }
    },
    changePerPage(perPage) {
      this.filters_values.page = 1;
      this.filters_values.per_page = perPage;
      this.filterValues();
    },
    sortBy(fieldName) {
      if (this.sortField !== fieldName) {
        this.reverseSort = false;
      }
      if (typeof this.filterData.page !== 'undefined') {
        delete this.filterData.page;
      }
      this.filterData.sort_by = fieldName;
      this.filterData.sort_direction = !this.reverseSort ? 'asc' : 'desc';
      this._getRequests(this.filterData)
      this.sortField = fieldName;
      this.reverseSort = !this.reverseSort;
    },
    pageChanged(pageNb) {
      this.requests.current_page = pageNb;
      this.filterData.page = pageNb;
      this._getRequests();
    },
    confirmCancel(index) {
      this.cancelRequestObj = this.requests.data[index];
      this.cancelRequestIndex = index;
      this.$refs['cancel-request-modal'].openModal();
    },
    _resetCancelObj() {
      this.cancelRequestObj = {id: null, benefit_sub_type: null};
      this.cancelRequestIndex = null;
    },
    cancelRequest(toggleDisableAutoTopUp = null) {
      if (this.cancelRequestObj !== null) {
        this.loading = true;
        let params = {};
        if (toggleDisableAutoTopUp) {
          params.toggleDisableAutoTopUp = toggleDisableAutoTopUp;
        }
        this.httpService.orderCancel(this.beneficiaryId, this.cancelRequestObj.id, params)
          .then(function (response) {
            this.requests.data[this.cancelRequestIndex].status = 'canceled';
            this._resetCancelObj();
            this.loading = false;
          }.bind(this))
          .catch(function (error) {
            this.loaderError = true;
          }.bind(this))
      }
    },
    moment(date, format) {
      return moment(date).format(format);
    },
    filterValues() {
      if (!this.initialLoading) {
        let paramsBag = {};
        let filterValues = this.filters_values;

        if (filterValues.status !== null) {
          paramsBag.status = filterValues.status;
        }
        if (filterValues.type !== null) {
          paramsBag.type = filterValues.type;
        }
        if (filterValues.start_date !== null) {
          paramsBag.start_date = filterValues.start_date;
        }
        if (filterValues.end_date !== null) {
          paramsBag.end_date = filterValues.end_date;
        }
        if (filterValues.per_page !== null) {
          paramsBag.per_page = filterValues.per_page;
        }
        if (filterValues.page !== null) {
          paramsBag.page = filterValues.page;
        }


        this.searchString = '';
        this.sortField = '';
        this.sortDirection = 'asc';
        this.reverseSort = false;
        this.filterData = paramsBag;
        this._getRequests();
      }
    }
  }
}
