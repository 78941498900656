<template>
  <div :class="{show: openModal }"
       class="modal fade">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header text-center no-border pb-0">
          <h3 v-if="modalAction === 'form_submit'" :key="'confirm_modal_title'" class="mx-auto">
            <template v-if="options.saveAs==='draft'">{{$t("flexben.offers.modal.save_draft")}}</template>
            <template v-if="options.saveAs==='publish'">{{$t("flexben.offers.modal.publish")}}</template>
            <template v-if="options.saveAs==='save'">{{$t("flexben.offers.modal.confirm_save")}}</template>
            <template v-if="options.saveAs==='active'">{{$t("flexben.offers.modal.confirm_activate")}}</template>
            <template v-if="options.saveAs==='rejected'">{{$t("flexben.offers.modal.confirm_rejected")}}</template>
          </h3>
          <h3 v-else-if="modalAction === 'delete'" :key="'confirm_modal_title'" class="mx-auto">
            {{$t("flexben.offers.modal.confirm_delete")}}
          </h3>
          <h3 v-else-if="modalAction === 'go_back'" :key="'confirm_modal_title'" class="mx-auto">
            {{$t("flexben.offers.modal.confirm_leave")}}
          </h3>
          <h3 v-else-if="modalAction === 'end' && formSuccess && isAffiliatePartner" :key="'confirm_modal_title'" class="mx-auto">
            {{ $t('flexben.offers.modal.success_message') }}
          </h3>
          <h3 v-else-if="modalAction === 'end'" :key="'confirm_modal_title'" class="mx-auto">
            {{ $t('flexben.general.status_type.'+(formSuccess ? 'success' : 'failed')) }}
          </h3>
        </div>
        <div class="modal-body text-center bg-white" :class="{'text-danger':!formSuccess}"
             v-if="modalAction==='end'&& endMessage.length>0">
          {{endMessage}}
        </div>
        <div v-if="options.saveAs==='rejected'" class="modal-body text-center bg-white" :class="{'text-danger':!formSuccess}">
          <label for="reason" class="col-form-label">{{ $t("flexben.offers.modal.reject_reason") }}</label>
          <textarea name="reason" v-model="reason">
          </textarea>
        </div>
        <div class="modal-footer mb-2  no-border">
          <button v-if="modalAction === 'end'" type="button"
                  @click="$emit('confirmAction', 'end')"
                  class="btn save-btn float-right m-auto">
            {{$t("flexben.general.btn.continue")}}
          </button>
          <button v-if="modalAction !== 'end'" type="button"
                  @click="$emit('closeModal')"
                  class="btn mr-auto text-themed">
            {{$t("flexben.general.btn.cancel")}}
          </button>
          <button v-if="modalAction === 'go_back'" type="button"
                  @click="$emit('confirmAction', 'go_back')"
                  class="btn save-btn">
            {{$t("flexben.general.btn.confirm")}}
          </button>

          <button v-if="options.saveAs === 'rejected'" type="button"
                  @click="sendReason"
                  class="btn save-btn">
            {{$t("flexben.general.btn.send")}}

          </button>
          <button v-else-if="modalAction === 'form_submit' || modalAction === 'delete'" type="button"
                  @click="$emit('confirmAction', modalAction)"
                  class="btn save-btn">
            {{$t("flexben.general.btn.continue")}}

          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientConfirmModal",
  props: {
    openModal: {
      type: Boolean,
      default: false,
      required: true
    },
    formSuccess: {
      type: Boolean,
      default: false,
      required: true
    },
    modalAction: {
      type: String,
      default: '',
      required: true
    },
    endMessage: {
      type: String,
      default: '',
    },
    isAffiliatePartner: {
      type: Boolean,
      default: false,
      required:false
    },
    options: {
      type: Object,
      default: function () {
        return {
          saveAs: '',
          statusChange: '',
        };
      },
    },
  },
  data: () => {
    return {
      reason: null,
    }
  },
  methods: {
    sendReason() {
      if (this.reason) {
        this.$emit('confirmAction', 'rejected', this.reason);
      }
    }
  }
}
</script>
