export default {
  title: 'Partners',
  description: 'Administrate benefit partners',
  new: 'New partner',
  edit: 'Modify partner',
  assigned_benefits: 'Assigned benefits',
  assigned_offers: 'Assigned offers',
  no_assigned_benefits: 'Not assigned to any benefits',
  modal: {
    confirm_save: 'Are you sure you want to save the changes?',
    confirm_leave: 'The information has been changed, are you sure you want to leave?',
    confirm_delete: 'Are you sure you want to delete the partner?',
    extra_delete: 'Current partner assigned to {count_benefits} benefits.'
  },
  fields: {
    name: 'Name',
    cif: 'CUI',
    iban: 'Back iban',
    address: 'Address',
    phone: 'Phone number',
    site: 'Website',
    scope_availability: 'Availability',
    description_placeholder: 'Partner description text. Minimum 50 characters. Maximum 3000 characters',
    affiliate: {
      channel_url: 'Affiliation channel url (partner register)',
      partner_url: 'Affiliated partner url (partner offers)',
      name: 'Affiliation channel name',
      commercial_identifier: 'Commercial identifier',
      social_platform: 'Social platform',
      registered_date: 'Registered date',
    }
  },
  tooltips: {
    description: '(min 50, max 1000)'
  },
  scope_availability: {
    benefit: 'Benefits',
    offer: 'Offers',
  },
  filters: {
    title_type: 'Type',
    intern: 'Intern',
    affiliated: 'Affiliate',
    benefit: 'Benefits',
    offer: 'Offers',
  }
}
