<template>
  <div class="container-bg-white mt-3">
    <div class="row p-3">
      <div class="col-12 d-flex">
        <img :src="category.image_url" :alt="category.name[locale]"
             class="box-60-60 ml-2 rounded-1">
        <h3 class="mx-0 my-auto">{{category.name[locale]}}</h3>
      </div>
      <div class="col-12" v-if="offers.length>0">
        <div class="offer-list-container" :class="{'mt-3':(category !==null)}">
          <div class="offer-card d-flex flex-column ml-0 mx-auto mx-lg-2 mb-3"
               v-for="(offer, offerIndex) in offers"
               :key="'of_'+offer.id">
            <img class="offer-card-img cursor-pointer" :src="offer.image_url" :alt="offer.name[locale]"
                 @click="showOfferDetails(offer.id)">
            <div class="offer-card-body d-flex flex-column">
              <div class="offer-card-name mt-1 text-capitalize">{{offer.name[locale]}}</div>
              <div class="offer-card-description mt-1 mb-auto">{{ $t("flexben.offers.types."+offer.type) }}
                <div v-html="offer.description[locale]" class="links-container"></div>
              </div>
              <div class="offer-card-actions d-inline-flex mt-2">
                <button class="btn btn-themed" @click="showOfferDetails(offer.id)">{{ $t('flexben.general.details')
                  }}
                </button>
                <button v-if="offer.code_type==='text'"
                        class="btn btn-themed-blue ml-auto" @click="showTextCode(offerIndex)">{{
                  $t('flexben.offers.btn.get_offer')}}
                </button>
                <a v-if="offer.code_type==='file'"
                   target="_blank" :href="offer.code_value_url" class="btn btn-themed-blue ml-auto">{{
                  $t('flexben.offers.btn.get_offer')}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 text-center text-20 text-bold" v-else :key="'list_flexible_benefits'">
        {{ $t('flexben.offers.beneficiary.no_offers')}}
      </div>
      <div class="col-12 text-center mt-2">
        <button class="btn btn-themed" @click="backToCategories()">{{ $t('flexben.offers.btn.back_to_categories') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OffersList",
  props: {
    category: {
      type: Object,
      required: true,
      default: function () {
        return {
          image: null,
          image_url: null,
          name: null
        }
      }
    },
    offers: {
      type: Array,
      required: true,
      default: function () {
        return [];
      }
    },
    locale: {
      type: String,
      default: 'ro'
    }
  },
  methods: {
    showOfferDetails(id) {
      this.$emit('showOfferDetails', id);
    },
    showTextCode(index) {
      this.$emit('showTextCode', index);
    },
    backToCategories() {
      this.$emit('backToCategories');
    }
  }
}
</script>
