import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
    language: localStorage.language ? localStorage.language : "ro" ,
    beneficiaryLink: "",
    accessToken: "",
    flx_accessToken: null,
    flx_beneficiaryActiveEmploymentId: null,
    flx_beneficiaryActiveEmployment: null,
    flx_activeEmployment: null,
    flx_beneficiaryActiveEmployments: [],
    flx_beneficiaryActiveEmploymentsReceived: false,
    flx_fastTopUpBenefitId: null,
    flx_forceRefreshComponent: false
  },
  getters: {
    flx_beneficiaryActiveEmploymentId(state) {
      let returnVal = state.flx_beneficiaryActiveEmploymentId;
      if (returnVal == null && sessionStorage.getItem('flx_active_employment') != null) {
        let val = JSON.parse(sessionStorage.getItem('flx_active_employment'));
        returnVal = val.employment_id;
      }
      return returnVal;
    },
    flx_activeEmployment(state) {
      let returnVal = state.flx_activeEmployment;
      if (returnVal == null && sessionStorage.getItem('flx_active_employment') != null) {
        returnVal = JSON.parse(sessionStorage.getItem('flx_active_employment'));
      }
      return JSON.parse(sessionStorage.getItem('flx_active_employment'));
    }
  },
  mutations: {
    logout(state) {
      state.flx_beneficiaryActiveEmploymentId = null;
      state.flx_beneficiaryActiveEmployment = null;
      state.flx_activeEmployment = null;
      state.flx_beneficiaryActiveEmployments = [];
      state.flx_beneficiaryActiveEmploymentsReceived = false;
      state.flx_fastTopUpBenefitId = null;
      state.flx_accessToken = null;
    },
    setActiveEmployment(state, val) {
      state.flx_beneficiaryActiveEmployment = val;
      state.flx_activeEmployment = val;
      state.flx_beneficiaryActiveEmploymentId = val.employment_id;
    },
    activateForceRefreshComponent(state, val) {
      state.flx_forceRefreshComponent = val;
    },
    onAuthSuccess(state, val) {
      state.flx_accessToken = val.data.access_token;
    }
  }
});
