<template>
  <div class="category-list-container mt-3">
    <div
      v-for="(country,index) in countries"
      :key="index"
      class="category-card d-flex flex-column ml-0 mx-auto mx-lg-2 mb-3"
    >
      <div class="category-card-body d-flex flex-column">
        <div class="category-card-name mt-1">
          <span v-if="country.code!=='int'" :class="'fi fi-' + country.code" />
          <span v-if="country.code==='int'">&#127758;</span>
          {{ country.translations[locale] }}
        </div>
        <div class="category-card-actions mt-1">
          <button class="btn btn-themed" @click="setFilter(country.id)">
            {{ $t('flexben.general.details') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "/node_modules/flag-icons/css/flag-icons.min.css";
export default {
  name: "FlexibleCountriesListing",
  props: {
    param_name: null,
    countries: [],
    locale: null,
  },
  methods: {
    setFilter(id) {
      this.$emit('setFilter', this.param_name, id);
    }
  },
}
</script>
