import axios from "axios";

let companyUserSvc = process.env.VUE_APP_COMPANY_API_URL;
let companyRoot = process.env.VUE_APP_COMPANY_USER_ROOT;

axios.defaults.headers.common["api_key"] =
	process.env.VUE_APP_CARD_USER_API_KEY;

const companyUserAxiosInstance = axios.create({
	baseURL: companyUserSvc,
});

export default {
	userService: {
		getUserData(url, token) {
			return companyUserAxiosInstance.get(url, {
				headers: {
					Authorization: "Bearer " + btoa(token),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			});
		},
		getCompanyData(customerId, token, role) {
			return companyUserAxiosInstance.get(
				companyRoot + "/v1/user_companies/" + customerId,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/x-www-form-urlencoded",
						"X-Up-CRole": role,
					},
				}
			);
		},
		confirmUserData(url, userData, token) {
			return companyUserAxiosInstance.put(url, userData, {
				headers: {
					Authorization: "Bearer " + btoa(token),
					"Content-Type": "application/json",
				},
			});
		},
		getCompanyInvoices(companyId, companySearchParams, token) {
			return companyUserAxiosInstance.get(
				companyRoot +
				"/v1/user_companies/" +
				companyId +
				"/invoices/search" +
				companySearchParams,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		deleteBeneficary(url, token) {
			return companyUserAxiosInstance.delete(url, {
				headers: {
					Authorization: "Bearer " + btoa(token),
					"Content-Type": "application/json",
				},
			});
		},
		resetUserPassword(passwordData, token) {
			return companyUserAxiosInstance.put(
				companyRoot + "/v1/app_passwords/" + passwordData.password_id,
				passwordData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getMessages(formData, token) {
			return companyUserAxiosInstance.get(
				companyRoot + "/v2/app_messages" + formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		markMessageAsRead(message, token) {
			return companyUserAxiosInstance.put(
				companyRoot + "/v2/app_messages/" + message.id,
				message,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getNotification(userId, notificationId, formData, token) {
			return companyUserAxiosInstance.get(
				companyRoot + "/v1/user_companies/" + userId + "/notifications/" + notificationId + formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		updateNotification(userId, notification, token) {
			return companyUserAxiosInstance.put(
				companyRoot + "/v1/user_companies/" + userId + "/notifications/" + notification.id,
				notification,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getAddressPoints(companyId, params, token) {
			return companyUserAxiosInstance.get(
				companyRoot +
				"/v1/user_companies/" +
				companyId +
				"/delivery_points/search" +
				params,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		addNewUserForManageCompany(companyId, params, token) {
			return companyUserAxiosInstance.post(
				companyRoot +
				"/v1/user_companies/" +
				companyId +
				"/flows",
				params,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getFlowStatusCompany(companyId, token) {
			return companyUserAxiosInstance.get(
				companyRoot + "/v1/user_companies/" + companyId + "/flow",
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		confirmNewUserForManageCompany(flowId, params, token) {
			return companyUserAxiosInstance.put(
				companyRoot +
				"/v1/app_flows/" +
				flowId,
				params,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getUserForCompany(params, token) {
			return companyUserAxiosInstance.get(
				companyRoot +
				"/v1/app_flows/search" +
				params,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getUsersForManageCompany(companyId, token) {
			return companyUserAxiosInstance.get(
				companyRoot +
				"/v1/user_companies/" +
				companyId +
				"/users",
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		modifyUserForManageCompany(companyId, userId, params, token) {
			return companyUserAxiosInstance.put(
				companyRoot +
				"/v1/user_companies/" +
				companyId +
				"/users/" +
				userId,
				params,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		deleteUsersForManageCompany(companyId, userId, token) {
			return companyUserAxiosInstance.delete(
				companyRoot +
				"/v1/user_companies/" +
				companyId +
				"/users/" +
				userId,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		deleteCompanyFromUser(companyId, token) {
			return companyUserAxiosInstance.delete(
				companyRoot +
				"/v1/user_companies/" +
				companyId,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		
	},
};
