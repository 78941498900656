<template>
  <div class="middle-placed-content">
    <div class="cards-container">
      <div class="cards-listing">
        <h3>{{ $t("cardsPageTitle") }}</h3>
        <div>
          <a
            v-for="(entry, ind) in accounts"
            :key="ind"
            class="nav-item border-left carditem"
            :class="[
              'border-left-color-' + entry.product,
              entry.id === currentAccount.id
                ? 'card-active'
                : 'card-inactive'
            ]"
            v-if="entry.product !== 30"
            @click="selectAccount(ind)"
          >
            <div class="logo-card">
              <img
                :src="
                  require('../../assets/images/logo-up-' +
                  (entry.product > 1500 ? '15' : entry.product)+
                    '.png')
                "
              /> 
            </div>
            <div class="sum">{{ utils.formatAmount(entry.balance) }} lei</div>
            <br />
            <br />
            <div class="validity logo-card">
              {{ $t("cardValidLabel") }} {{ entry.card.expire }}
            </div>
            <div v-if="entry.card.virtual" class="sum">
              {{ "Virtual" }} 
            </div>
          </a>
        </div>
      </div>
      <div class="cards-details view">
        <div :class="[!pinSuccess && !cardSuccess && !plasticSuccess ? 'show' : 'hide']">
          <card-template
            :amountLeft="utils.formatAmount(this.currentAccount.balance)"
            :cardPan="this.getFormattedPan(this.currentAccount.card.pan)"
            :embossName="this.currentAccount.card.name"
            :expireLabel="this.currentAccount.card.expire"
            :product="this.currentAccount.product"
            :virtual="this.currentAccount.card.virtual"
          ></card-template>
          <table cellpadding="10" cellspacing="0" align="center">
            <tbody>
              <tr>
                <td
                  align="center"
                  valignn="top"
                  v-if="this.currentAccount.card.state === 'created'"
                >
                  <a
                    href="#"
                    @click="
                      showModalPopup('modal_popup_confirmActivateCard', true)
                    "
                  >
                    <img src="../../assets/images/activate-card.svg" />
                    <span v-html="$t('activeCardText')"></span>
                  </a>
                </td>
                <td
                  align="center"
                  valignn="top"
                  v-if="this.currentAccount.card.state === 'active'"
                >
                  <a
                    href="#"
                    @click="
                      showModalPopup('modal_popup_confirmBlockCard', true)
                    "
                  >
                    <img src="../../assets/images/card-locked.svg" />
                    <span v-html="$t('blockCardText')"></span>
                  </a>
                </td>
                <td
                  align="center"
                  valignn="top"
                  v-if="this.currentAccount.card.state === 'blocked'"
                >
                  <a
                    href="#"
                    @click="
                      showModalPopup('modal_popup_confirmUnblockCard', true)
                    "
                  >
                    <img src="../../assets/images/activate-card.svg" />
                    <span v-html="$t('unblockCardText')"></span>
                  </a>
                </td>
                <td
                  align="center"
                  valign="top"
                  v-if="
                    (this.currentAccount.card.state === 'created' ||
                      this.currentAccount.card.state === 'active') &&
                      !this.currentAccount.card.virtual
                  "
                >
                  <a
                    href="#"
                    @click="
                      showModalPopup('modal_popup_confirmPinRequest', true)
                    "
                  >
                    <img src="../../assets/images/request-pin.svg" />
                    <span v-html="$t('requestPinText')"></span>
                  </a>
                </td>
                <td
                  align="center"
                  valign="top"
                  v-if="
                    (this.currentAccount.card.state === 'created' ||
                      this.currentAccount.card.state === 'active') &&
                      this.currentAccount.card.virtual
                  "
                >
                  <a
                    href="#"
                    @click="
                      showModalPopup('modal_popup_confirmPlasticRequest', true)
                    "
                  >
                    <img src="../../assets/images/request-pin.svg" />
                    <span v-html="$t('requestPlasticText')"></span>
                  </a>
                </td>
                <td
                  align="center"
                  valign="top"
                  v-if="
                    this.currentAccount.card.state === 'cardblocked' ||
                      this.currentAccount.card.state === 'blocked' ||
                      this.currentAccount.card.state === 'deactivated' ||
                      this.currentAccount.card.state === 'pinblocked'
                  "
                >
                  <a
                    href="#"
                    @click="
                      showModalPopup('modal_popup_confirmCardRequest', true)
                    "
                  >
                    <img src="../../assets/images/request-pin.svg" />
                    <span v-html="$t('requestCardText')"></span>
                  </a>
                </td>
                <td align="center" valign="top">
                  <a @click="pushRoute('beneficiary_faq')">
                    <img src="../../assets/images/faq-big.svg" />
                    <span v-html="$t('faqText')"></span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <p style="padding: 0 10px; width: 70%; margin: 0 auto;">
            <a href="beneficiary/services?download=1">{{
              $t("showPinText")
            }}</a>
          </p>
        </div>

        <div :class="[pinSuccess || cardSuccess || plasticSuccess ? 'show' : 'hide']">
          <img src="../../assets/images/succes-pin.svg" />
          <p align="center">
            <small>
              <span v-if="pinSuccess">
                {{ $t("successMsg", { PIN: "PIN" }) }}
              </span>
              <span v-if="plasticSuccess">
                {{ $t("successMsg", { PIN: "CARD" }) }}
              </span>
              <span v-if="cardSuccess">
                {{ $t("successMsg", { PIN: "CARD" }) }}
              </span>
            </small>
          </p>
        </div>
      </div>
    </div>

    <div id="modal_popup_pinRequest" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_pinRequest', false)"
          class="close-button"
        >
          <img src="../../assets/images/close-button.svg" />
        </a>
        <p v-html="$t('pinReqMsg')" v-if="this.pinRequestMessage">
        </p>
        <p v-html="$t('cardReqMsg')" v-if="this.plasticRequestMessage">
        </p>
        <p v-html="$t('plasticReqMsg')" v-if="this.cardRequestMessage">
        </p>
      </div>
    </div>

    <div id="modal_popup_pinOrCardRequestError" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_pinOrCardRequestError', false)"
          class="close-button"
        >
          <img src="../../assets/images/close-button.svg" />
        </a>
        <p v-html="$t('errorPinOrCardReqMsg')">
        </p>
      </div>
    </div>

    <div id="modal_popup_confirmActivateCard" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_confirmActivateCard', false)"
          class="close-button"
        >
          <img src="../../assets/images/close-button.svg" />
        </a>
        <p v-html="$t('confirmActvatedCardReqMsg')">
        </p>
        <div class="buttons">
          <a href="#" class="yesButton" @click="activateOrBlockCard(true)"
            >{{$t('notificationsChooseOptionYes')}}</a
          >
          <a
            href="#"
            class="noButton"
            @click="showModalPopup('modal_popup_confirmActivateCard', false)"
            >{{$t('notificationsChooseOptionNo')}}</a
          >
        </div>
      </div>
    </div>

    <div id="modal_popup_confirmBlockCard" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_confirmBlockCard', false)"
          class="close-button"
        >
          <img src="../../assets/images/close-button.svg" />
        </a>
        <p v-html="$t('confirmBlockCardReqMsg')">
        </p>
        <div class="buttons">
          <a href="#" class="yesButton" @click="activateOrBlockCard(false)"
            >{{$t('notificationsChooseOptionYes')}}</a
          >
          <a
            href="#"
            class="noButton"
            @click="showModalPopup('modal_popup_confirmBlockCard', false)"
            >{{$t('notificationsChooseOptionNo')}}</a
          >
        </div>
      </div>
    </div>

    <div id="modal_popup_confirmUnblockCard" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_confirmUnblockCard', false)"
          class="close-button"
        >
          <img src="../../assets/images/close-button.svg" />
        </a>
        <p v-html="$t('confirmUnblockCardReqMsg')">
        </p>
        <div class="buttons">
          <a href="#" class="yesButton" @click="activateOrBlockCard(true)"
            >{{$t('notificationsChooseOptionYes')}}</a
          >
          <a
            href="#"
            class="noButton"
            @click="showModalPopup('modal_popup_confirmUnblockCard', false)"
            >{{$t('notificationsChooseOptionNo')}}</a
          >
        </div>
      </div>
    </div>

    <div id="modal_popup_confirmPinRequest" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_confirmPinRequest', false)"
          class="close-button"
        >
          <img src="../../assets/images/close-button.svg" />
        </a>
        <p v-html="$t('confirmPinReqMsg')"></p>
        <div class="buttons">
          <a href="#" class="yesButton" @click="reissueRequest('RP')">{{$t('notificationsChooseOptionYes')}}</a>
          <a
            href="#"
            class="noButton"
            @click="showModalPopup('modal_popup_confirmPinRequest', false)"
            >{{$t('notificationsChooseOptionNo')}}</a
          >
        </div>
      </div>
    </div>

    <div id="modal_popup_confirmPlasticRequest" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_confirmPlasticRequest', false)"
          class="close-button"
        >
          <img src="../../assets/images/close-button.svg" />
        </a>
        
        <p v-html="$t('confirmPlasticReqMsg')"></p>
        <br />
        <p v-html="$t('confirmPlasticReqCampaignMsg')"></p>
        <div class="buttons">
          <a href="#" class="yesButton" @click="reissueRequest('RM')">{{$t('notificationsChooseOptionYes')}}</a>
          <a
            href="#"
            class="noButton"
            @click="showModalPopup('modal_popup_confirmPlasticRequest', false)"
            >{{$t('notificationsChooseOptionNo')}}</a
          >
        </div>
      </div>
    </div>


    <div id="modal_popup_confirmCardRequest" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_confirmCardRequest', false)"
          class="close-button"
        >
          <img src="../../assets/images/close-button.svg" />
        </a>
        <p v-html="$t('confirmCardReqMsg')"></p>
        <br />
        <p v-html="$t('confirmPlasticReqCampaignMsg')"></p>
        <div class="buttons">
          <a href="#" class="yesButton" @click="confirmReissueRequest('RV')">{{$t('cardVirtualButtonLabel')}}</a>
          <a href="#" class="noButton" @click="confirmReissueRequest('RC')">{{$t('cardPhysicalButtonLabel')}}</a>
        </div>
      </div>
    </div>

    <div id="modal_popup_virtual" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_virtual', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p v-html="reissueCardType === 'RV' ? $t('confirmCardReqVirtualMsg') : $t('confirmCardReqPhysicalMsg')"></p>
        <a class="button-orange" @click="reissueRequest(reissueCardType)">{{
          $t("nextStepButtonLabel")
        }}</a>
        <a class="button-orange" @click="cancelReissueRequest()">{{
          $t("cancelModifyLabel")
        }}</a>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "../../plugins/utils.js";
import CardTemplate from "../../assets/components/CardTemplate.vue";
import httpServiceBeneficiary from "../../http/http-beneficiary.js";

export default {
  components: {
    CardTemplate
  },
  props: {
    accounts: Array,
    surname: String,
    accessToken: String,
    msg: String
  },
  data() {
    return {
      utils,
      currentAccount: this.accounts[0],
      transactions: null,
      dashboardError: null,
      transactionsCount: 0,
      selectedCardIndex: 0,
      pinSuccess: false,
      cardSuccess: false,
      plasticSuccess: false,
      pinRequestMessage: false,
      cardRequestMessage: false,
      plasticRequestMessage: false,
      reissueCardType: ""
    };
  },
  methods: {
    getProductNameMethod(productId) {
      return utils.getProductName(productId);
    },
    getFormattedPan(pan) {
      return utils.formatPAN(pan);
    },
    selectAccount(index) {
      this.currentAccount = this.accounts[index];
      this.pinSuccess = false;
      this.cardSuccess = false;
      this.plasticSuccess = false;
    },
    previousCard() {
      var noOfCards = this.accounts.length;
      this.selectedCardIndex--;
      if (this.selectedCardIndex < 0) {
        this.selectedCardIndex = noOfCards - 1;
      }
      this.currentAccount = this.accounts[this.selectedCardIndex];
    },
    nextCard() {
      var noOfCards = this.accounts.length;
      this.selectedCardIndex++;
      if (this.selectedCardIndex === noOfCards) {
        this.selectedCardIndex = 0;
      }
      this.currentAccount = this.accounts[this.selectedCardIndex];
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    activateOrBlockCard(cardStatus) {
      let formData = {
        id: this.currentAccount.card.id,
        active: cardStatus
      };
      httpServiceBeneficiary.beneficiaryService
        .setStateOrReasonCard(formData, this.accessToken)
        .then(function(response) {
          return response;
        })
        .then(response => {
          if (response.status === 200) {
            this.currentAccount.card.active = cardStatus;
            this.currentAccount.card.state = response.data.state;
            this.showModalPopup("modal_popup_confirmActivateCard", false);
            this.showModalPopup("modal_popup_confirmBlockCard", false);
            this.showModalPopup("modal_popup_confirmUnblockCard", false);
          }
        })
        .catch(function(error) {});
    },
    cancelReissueRequest(){
      this.reissueCardType = "";
      this.showModalPopup("modal_popup_virtual", false);
    },
    confirmReissueRequest(requestType){
      this.reissueCardType = requestType;
      this.showModalPopup("modal_popup_virtual", true);
    },
    reissueRequest(requestType) {
      let formData = {
        id: this.currentAccount.card.id,
        reason: requestType 
        //"RP" reemitere PIN
        //"RC" reemitere CARD
        //"RV" reemitere virtual
        //"RM" request materializare

      };
      this.showModalPopup('modal_popup_loading', true);

      httpServiceBeneficiary.beneficiaryService
        .setStateOrReasonCard(formData, this.accessToken)
        .then(response => {
          if (response.status === 200) {
            this.showModalPopup("modal_popup_confirmPinRequest", false);
            this.showModalPopup("modal_popup_confirmCardRequest", false);
            this.showModalPopup("modal_popup_confirmPlasticRequest", false);
            this.showModalPopup("modal_popup_virtual", false);
            this.showModalPopup('modal_popup_loading', false);
            this.pinSuccess = requestType === "RP";
            this.pinSuccess = requestType === "RM";
            this.pinSuccess = requestType === "RC" || requestType === "RV";
          }
        })
        .catch(error => {
          if (
            error.response.status === 400 &&
            error.response.data.description === "Has other reissue request"
          ) {
              this.showModalPopup("modal_popup_confirmPlasticRequest", false);
              this.showModalPopup("modal_popup_confirmCardRequest", false);
              this.showModalPopup("modal_popup_virtual", false);
              this.showModalPopup("modal_popup_pinRequest", false);
              this.showModalPopup('modal_popup_loading', false);
              this.pinRequestMessage = false;
              this.cardRequestMessage = false;
              this.plasticRequestMessage = false;
              switch (requestType){
                case "RP":
                  this.pinRequestMessage = true;
                  break;
                case "RM":
                  this.plasticRequestMessage = true;
                  break;
                case "RV":
                case "RC":
                  this.cardRequestMessage = true;
                break;
              }
              this.showModalPopup("modal_popup_pinRequest", true);

          } else if (
            error.response.status === 409 &&
            error.response.data.error_code === "CRBS"
          ) {
            this.showModalPopup("modal_popup_confirmPinRequest", false);
            this.showModalPopup("modal_popup_confirmCardRequest", false);
            this.showModalPopup("modal_popup_virtual", false);
            this.showModalPopup("modal_popup_pinOrCardRequestError", true);
            this.showModalPopup('modal_popup_loading', false);

          }
        });
    }
  },
  mounted() {
    this.pinSuccess = false;
    this.cardSuccess = false;
    this.plasticSuccess = false;
  },
  computed: {
    getProductName(productId) {
      return utils.getProductName(productId);
    },
    getTransactionImage(transaction) {
      if (transaction.transaction_status === "REJECTED") {
        return "../../assets/images/card-tranzactie.svg";
      } else if (transaction.transaction_status === "ACCEPTED") {
        if (transaction.transaction_type === "DEBIT") {
          return "../../assets/images/card-reincarare.svg";
        } else {
          return "../../assets/images/card-tranzactie.svg";
        }
      } else if (transaction.transaction_status === "UNLOADED") {
        // unloaded
        return "../../assets/images/card-tranzactie-procram.svg";
      } else {
        return "../../assets/images/card-tranzactie.svg";
      }
    },
    getTransactionAmountClass(transaction) {
      if (transaction.transaction_status === "REJECTED") {
        return "red";
      } else if (transaction.transaction_status === "ACCEPTED") {
        if (transaction.transaction_type === "DEBIT") {
          return "green";
        } else {
          return "red";
        }
      } else if (transaction.transaction_status === "UNLOADED") {
        // unloaded
        return "";
      } else {
        return "";
      }
    },
    getTabClass(accountId) {
      return accountId === this.currentAccount.id && "active";
    }
  },
  created() {
    this.currentAccount = this.accounts[0];
    // this.currentAccount.card.state = 'B';
    // this.currentAccount.card.state = 'pinblocked';
    // this.currentAccount.card.virtual = true;
  }
};
</script>
<style scoped lang="scss">
.nav-tabs {
  width: 60%;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  align-items: center;
  height: 53px;
  grid-column-gap: 5px;
}

.tabs a {
  padding-top: 15px;
  height: 50px;
  display: block;
  background: #ddd;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.tabs a:hover {
  background: #fff;
}

.tabs a.active {
  background: #fff !important;
}
</style>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
.middle-placed-content {
  position: relative;
  right: 0px;
  height: calc(100% - 68px);
  padding: 40px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
}

.cards-container {
  width: 90% !important;
  display: grid;
  min-height: 40px;
  background: #fff;
  margin: 0px auto;
  grid-template-columns: 40% 60%;
  border-radius: 5px;
  border-bottom: 2px solid #ddd;
  font-size: 16px;
  line-height: normal;
}

.cards-details {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  text-align: center;
  padding: 20px 0;
}
.cards-details a {
  text-decoration: none;
  font-weight: 700;
  color: #b1b1b1;
}

.carditem {
  width: 90%;
  height: 72px;
  border-radius: 4px;
  border-bottom: 2px solid #ddd;
  display: block;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.validity {
  clear: both;
  color: #b1b1b1;
  position: absolute;
}

@media (max-width: 1003px) {
  .cards-container {
    display: flex;
    flex-direction: column;
  }
}
</style>
