<template>
  <div class="container-fluid">
    <div class="row container-gray py-3">
      <div :class="{
        'col-12 col-md-6':true,
        'col-xl-2':companyOptions.cam_budget_taxation,
      }">
        <span class="flx-type-badge mr-1">{{ $t("flexben.benefit.sub_types."+ceBenefit.benefit.sub_type) }}</span>
      </div>
      <div
           :class="{
            'col-12 col-md-6':true,
            'col-xl-5':companyOptions.cam_budget_taxation,
            }">
        <div :class="companyOptions.cam_budget_taxation ? '' :'float-right'">
          <span class="mr-1" v-html="(ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale] === null) ?
            $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
            : ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale]">
          </span>
          <span id="tooltip_up_dejun_deductibility" class="d-inline-block flexben-tooltip-icon mr-2">i</span>

          <b-tooltip :target="'tooltip_up_dejun_deductibility'" variant="flexben" placement="topleft">
            <div class="text-left">
              {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
            </div>
          </b-tooltip>
          <span class="text-danger"><strong>{{ salaryDeductibility }} Lei</strong></span>
        </div>
      </div>

      <cam-value-info
        v-if="companyOptions.cam_budget_taxation"
        :valueCam="valueCam"></cam-value-info>


      <div class="col-12 mt-2 text-danger text-bold text-center"
           v-if="(dynamicFields.billing_value>remainingBudget)">
        {{ $t('flexben.beneficiary_pages.benefits.insufficient_budget') }}
      </div>
      <div class="col-12 mt-3">
        <div class="row">
          <div class="col-12 col-md-6 col-xl-4 form-group mb-1">
            <label class="col-form-label form-fields-required pt-0 mr-1">
              {{ $t('flexben.flexbenFormFields.value') }} (min: {{ minOrderValue }}Lei)</label>
            <input type="text" class="form-control"
                   v-model="value"
                   :min="minOrderValue"
                   :class="{'border-danger' : typeof requiredFields.value !='undefined'}">
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group mb-1">
            <label class="col-form-label form-fields-required pt-0 mr-1">{{ $t('flexben.flexbenFormFields.points_pago')
              }}</label>
            <input type="text" class="form-control"
                   v-model="formData.points_pago"
                   disabled
                   :class="{'border-danger' : typeof requiredFields.points_pago !='undefined'}">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 col-md-6 col-xl-4 form-group mb-1">
            <label class="col-form-label form-fields-required pt-0 mr-1">{{ $t('flexben.flexbenFormFields.email')
              }}</label>
            <input type="text" class="form-control" v-model="formData.email"
                   :class="{'border-danger' : typeof requiredFields.email !='undefined'}">
          </div>

          <div class="col-12 col-md-6 col-xl-4 form-group mb-1">
            <label class="col-form-label form-fields-required pt-0 mr-1">{{ $t('flexben.flexbenFormFields.first_name')
              }}</label>
            <input type="text" class="form-control" v-model="formData.first_name"
                   :class="{'border-danger' : typeof requiredFields.first_name !='undefined'}">
          </div>

          <div class="col-12 col-md-6 col-xl-4 form-group mb-1">
            <label class="col-form-label form-fields-required pt-0 mr-1">{{ $t('flexben.flexbenFormFields.last_name')
              }}</label>
            <input type="text" class="form-control" v-model="formData.last_name"
                   :class="{'border-danger' : typeof requiredFields.last_name !='undefined'}">
          </div>

          <div class="col-12 form-group mb-0 mt-1">
            <label class="col-form-label pt-0 mr-1">
              {{
              $t('flexben.benefit.points_pago_notices.processing_tax',{tax:ceBenefit.benefit.data.values.platform_tax.value})
              }}
            </label>
            <div class="d-flex mt-1 align-items-center">
              <span class="mr-2">{{ $t('flexben.general.confirmation') }}</span>
              <span>{{ $t('flexben.general.no') }}</span>
              <b-form-checkbox class="ml-2 mr-3" switch
                               v-model="acceptTax">
              </b-form-checkbox>
              <span>{{ $t('flexben.general.yes') }}</span>
            </div>
          </div>

          <div class="col-12 text-danger text-bold text-center mt-2"
               v-html="$t('flexben.benefit.points_pago_notices.personal_info')">
          </div>
          <total-value-pay
            v-if="(companyOptions.gross_budget_taxation || companyOptions.cam_budget_taxation)"
            :totalPayValue="totalPayValue"></total-value-pay>
        </div>
      </div>
    </div>
    <V1FormBuilder v-if="checkNotEmptyObj(dynamicFormFields)"
                   :dynamic-fields="dynamicFormFields"
                   :dynamicFormModel="dynamicFormModel"
                   :requiredDynamicFields="requiredDynamicFields"
                   :ref="'v1-form-builder'"
                   :locale="locale"
                   @setDynamicFormModel="setDynamicFormModel"/>
    <div class="row mt-3">
      <div class="col-12 px-0 pr-3 text-center text-md-left">
        <button class="btn btn-themed-green px-2 mr-2 px-md-4" @click="orderNow()"
                :disabled="!canOrder">
          {{ $t('flexben.beneficiary_pages.order_now') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import flexbenUtils from "@/plugins/flexben_utils";
import V1FormBuilder from "@/components/flexben/beneficiary_pages/benefits/partials/V1FormBuilder";
import TotalValuePay from "./TotalValuePay";
import CamValueInfo from "./CamValueInfo";
import {BFormCheckbox, BTooltip} from "bootstrap-vue";

export default {
  name: "PointsPago",
  components: {
    'b-tooltip': BTooltip,
    'b-form-checkbox': BFormCheckbox,
    V1FormBuilder,
    'total-value-pay': TotalValuePay,
    'cam-value-info': CamValueInfo
  },
  props: {
    ceBenefit: {
      type: Object,
      required: true
    },
    budgets: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },
    payload: {
      type: Object,
      required: true,
      default: function () {
        return {
          value: 0,
          benefit_id: this.benefitId,
          child_benefit_id: null,
          event_id: null,
          auto_top_up: false,
          ticket_count: 0,
          ticket_value: 0,
          extra: {},
        };
      }
    },
    errorsBag: {
      type: [Object, Array],
      default: function () {
        return {};
      }
    },
    companyOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          cam_budget_taxation: false,
          gross_budget_taxation: false,
          legislated_gross_calculation: false,
          last_monthly_order_day: null,
          budget_telework_transfer_to_general: false,
          cash_out_last_order_day: null,
          non_legislated_net_taxes_calculate: false,
          banner_image_url: null,
        };
      }
    },
    remainingBudget: {
      type: Number,
      default: 0,
    }
  },
  data: function () {
    return {
      formData: {
        first_name: null,
        last_name: null,
        email: null,
        value: null,
        points_pago: null
      },
      acceptTax: false,
      dynamicFormFields: {},
      dynamicFormModel: {},
      requiredDynamicFields: {},
      requiredFields: {},
      isValidForm: true,
      value: 0,
      salaryDeductibility: 0,
      totalPayValue: 0,
      obligatoryFields: [
        'first_name',
        'last_name',
        'email',
      ]
    };
  },
  computed: {
    dynamicFields: function () {
      return this.ceBenefit.benefit.dynamic_form.fields;
    },
    minOrderValue: function () {
      return this.ceBenefit.benefit.data.values.min_order_value.value + this.ceBenefit.benefit.data.values.platform_tax.value;
    },
    canOrder: function () {
      let returnValue = true;
      for (let field of this.obligatoryFields) {
        if (this.formData[field] == null || this.formData[field].length == 0) {
          returnValue = false;
        }
      }

      if (!this._testEmail(this.formData.email)) {
        returnValue = false;
      }
      if (this.formData.value < this.minOrderValue) {
        returnValue = false;
      } else if (this.formData.value > this.budgets.available) {
        returnValue = false;
      }

      if (this.totalPayValue > this.remainingBudget) {
        returnValue = false;
      }
      if (!this.acceptTax) {
        return false;
      }
      return returnValue;
    }
  },
  watch: {
    value: {
      handler: function (value) {
        this.formData.value = value;
        this.formData.points_pago = (value - this.ceBenefit.benefit.data.values.platform_tax.value) * this.ceBenefit.benefit.data.values.budget_to_point_ratio.value;
        this._setSalaryDeductibility(value);
      }
    }
  },
  created() {
    if (typeof this.dynamicFields.dynamic_fields !== "string") {
      this.dynamicFormFields = this.dynamicFields.dynamic_fields;
    }
    this.formData.first_name = this.ceBenefit.employee_data.first_name;
    this.formData.last_name = this.ceBenefit.employee_data.last_name;
    this.formData.email = this.ceBenefit.employee_data.email;
    this.value = this.ceBenefit.benefit.data.values.min_order_value.value + this.ceBenefit.benefit.data.values.platform_tax.value;
  },
  methods: {
    orderNow() {
      this._validateDynamicFields();
      if (this.isValidForm) {
        this._setPayload();
        this.$emit('orderNow');
      }
    },
    setDynamicFormModel(modelObj) {
      this.dynamicFormModel = modelObj;
    },
    _validateDynamicFields() {
      this.isValidForm = true;
      let requiredDynamicFields = {}, requiredFields = {}, index, fieldObj;

      for (let field of this.obligatoryFields) {
        if (this.formData[field] == null || this.formData[field].length == 0) {
          requiredFields[field] = 'required';
        }
      }

      if (!this._testEmail(this.formData.email)) {
        requiredFields.email = 'invalid';
      }

      if (this.formData.value < this.minOrderValue) {
        requiredFields.value = 'invalid';
      } else if (this.formData.value > this.budgets.available) {
        requiredFields.value = 'invalid';
      }

      if (this.checkNotEmptyObj(requiredFields)) {
        this.isValidForm = false;
        this.requiredFields = requiredFields;
      }

      for (index in this.dynamicFormFields) {
        fieldObj = {...this.dynamicFormFields[index]};
        if (typeof fieldObj.required !== 'undefined' && fieldObj.required) {
          if (
            ((fieldObj.type === 'checklist' || fieldObj.type === 'vueMultiSelect') && this.dynamicFormModel[fieldObj.model].length === 0) ||
            this.dynamicFormModel[fieldObj.model] === null || this.dynamicFormModel[fieldObj.model].length === 0
          ) {
            requiredDynamicFields[fieldObj.model] = 'required';
          }
        }
      }
      if (this.checkNotEmptyObj(requiredDynamicFields)) {
        this.isValidForm = false;
        this.requiredDynamicFields = requiredDynamicFields;

        // wait for arguments to be passed to child
        setTimeout(function () {
          this._reRenderFormBuilder();
          this._toggleFieldsToComplete(true);
        }.bind(this), 100);
      }
    },
    _toggleFieldsToComplete(on) {
      this.$emit('setErrorInAlertModal', [this.$t('flexben.beneficiary_pages.benefits.fields_to_complete')]);
      this.scrollToTop();
    },
    _reRenderFormBuilder() {
      this.$refs['v1-form-builder'].createDynamicModel();
    },
    _setSalaryDeductibility(value) {
      let gross = flexbenUtils.calculateGross(value, this.ceBenefit.benefit.global_variables, this.companyOptions.non_legislated_net_taxes_calculate);

      this.salaryDeductibility = (gross - value).toFixed(2);
      this.valueCam = gross * this.ceBenefit.benefit.global_variables['cam'] / 100;

      this._calculateTotalByCompanyOptions(value);
    },

    _setPayload() {
      this.payload.value = this.formData.value;
      this.payload.ticket_count = 1;
      this.payload.ticket_value = this.formData.value;
      this.payload.benefit_id = this.ceBenefit.benefit_id;
      this.payload.extra = {
        first_name: this.formData.first_name,
        last_name: this.formData.last_name,
        email: this.formData.email,
        value: this.formData.value,
        platform_tax: this.ceBenefit.benefit.data.values.platform_tax.value,
        budget_consumption_type: this.ceBenefit.benefit.budget_consumption_type
      };

    },
    _calculateTotalByCompanyOptions(rawValue) {
      let value = parseFloat(rawValue);
      if (this.companyOptions.gross_budget_taxation && value > 0) {
        value += parseFloat(this.salaryDeductibility);
      }
      if (this.companyOptions.cam_budget_taxation && value > 0) {
        value += parseFloat(this.valueCam);
      }
      this.totalPayValue = value;
    },
    _testEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
  }
}
</script>
