export default {
  title: 'Parteneri',
  description: 'Administreaza partenerii beneficiilor',
  new: 'Partener nou',
  edit: 'Modifica partener',
  assigned_benefits: 'Beneficii asociate',
  assigned_offers: 'Oferte asociate',
  no_assigned_benefits: 'Nu este asociat unui beneficiu',
  modal: {
    confirm_save: 'Esti sigur ca vrei sa salvezi modificarile?',
    confirm_leave: 'Exista informatii modificate care nu au fost salvate. Confirmi parasirea paginii?',
    confirm_delete: 'Esti sigur ca vrei sa stergi partenerul?',
    extra_delete: 'Partenerul curent are {count_benefits} beneficii.'
  },
  fields: {
    name: 'Nume',
    cif: 'CUI',
    iban: 'Iban banca',
    address: 'Adresa',
    phone: 'Numar telefon',
    site: 'Website',
    scope_availability: 'Disponibilitate',
    description_placeholder: 'Text descriere partener. Minim 50 de caractere. Maxim 3000 de caractere',
    affiliate: {
      channel_url: 'Url canal afiliere (inregistrare partener)',
      partner_url: 'Url partener afiliat (oferte partener)',
      name: 'Nume canal afiliere',
      commercial_identifier: 'Identificator comercial',
      social_platform: 'Platforma sociala',
      registered_date: 'Data inregistrare',
    }
  },
  tooltips: {
    description: '(min 50, max 1000)'
  },
  scope_availability: {
    benefit: 'Beneficii',
    offer: 'Oferte',
  },
  filters: {
    title_type: 'Tip',
    intern: 'Intern',
    affiliated: 'Afiliat'
  }
}
