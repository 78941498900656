export default {
  title: "Configuration",
  description: "Manager general settings",
  fixed_value: "Fixed value",
  no_future_values: "There are no future values",
  current_value: "Current value",
  new_value: "New value",
  parameter_name: "Parameter name",
  percent: "percent",
  table: {
    new_config: "Add new configuration",
    edit_config: "Change configuration",
    current_value: "Current value",
    future_value: "Future value",
  }
}
