<template>
  <div>
    <div class="notification-header-container">
        <div class="notification-header">
          <a
            :class="marketingMessage && 'active'"
            @click="() => marketingMessage = true"
          >
            {{ $t("messageMarketing") }}
          </a>
          <a
            :class="!marketingMessage && 'active'"
            @click="() => marketingMessage = false"
          >
            {{ $t("messageComercial") }}
          </a>
        </div>
      </div>
      <div class="notification-wrapper">
        <div
          class="notification-white-bar"
          :class="marketingMessage ? 'left-0' : 'right-0'"
        ></div>
        <div
          v-for="message in this.notifications"
          :key="message.id"
          class="main-notification unread"
          @click="isReadNotification(message)"
          :class="{ read: message.read }"
        >
          <div class="notification-title">
            <img v-if="message.read" src="@/assets/images/happy.png" alt />
            <img v-else src="@/assets/images/sceptic.png" alt />
            <h6>&#8226; {{ message.dateIssued | fromNow }}</h6>
          </div>
          <div class="notification-content">
            <h6>{{ message.title }}</h6>
            <p>
              <read-more
                class="offer-description"
                :moreStr="$t('readMore')"
                :text="message.message"
                link="#"
                :lessStr="$t('readLess')"
                :max-chars="100"
              ></read-more>
            </p>
          </div>
          <div class="notification-footer">
            <p>
              <a class="status-background notification-delete-button ">{{ $t("deleteNotification") }}</a>
            </p>
          </div>
        </div>
      </div>
  </div>
</template>


<script>
import moment from "moment";
import httpServiceBeneficiary from "@/http/http-beneficiary.js";
import Cookies from "js-cookie";

export default {
  name: "NotificationBox",
  props: {
    notifications: Array
  },
  data() {
    return {
      marketingMessage: true
    };
  },
  filters: {
    fromNow(date) {
			return moment(date).fromNow();
		}
  },
  methods: {
    isReadNotification(message) {
      message.read = true;
      httpServiceBeneficiary.beneficiaryService
        .markMessageAsRead(message, Cookies.get("token"))
        .catch(function(error) {
          // handle error
        });
    },
  },
  mounted() {}
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
