<template>
  <div class="row budget-totals m-0">
    <div :class="{
      'col-12': true,
      'col-md-6': companyOptions.gross_budget_taxation || budgetConsumptionType==='telework',
      'col-md-4': (!companyOptions.gross_budget_taxation && budgetConsumptionType==='general')
    }">
      <div class="text-center budget-box-inverse">
        <div class="budget-box-title">{{ Math.floor(remainingBudget) }}<span
          class="text-sup">{{flexbenUtils.getSupDecimals(remainingBudget)}}</span>
        </div>
        <div class="budget-box-body px-1 pb-1"> {{
          $t('flexben.beneficiary_pages.benefits.budget.available') }} {{ getBudgetExpirationDate() }}
        </div>
      </div>
    </div>
    <div :class="{
    'col-12 mt-2 mt-md-0': true,
    'col-md-6': companyOptions.gross_budget_taxation || budgetConsumptionType==='telework',
    'col-md-4': (!companyOptions.gross_budget_taxation && budgetConsumptionType==='general')
    }">
      <div class="text-center budget-box">
        <div class="budget-box-title text-themed">{{ Math.floor(budgets.allocated) }}<span
          class="text-sup text-themed-dark">{{flexbenUtils.getSupDecimals(budgets.allocated)}}</span>
        </div>
        <div class="budget-box-body px-1 pb-1">
          {{ $t('flexben.beneficiary_pages.benefits.budget.allocated') }}
          {{ $t('flexben.general.months.' + budgets.allocation_month_year.month) }}
          {{ budgets.allocation_month_year.year }}
        </div>
      </div>
    </div>
    <div v-if="!companyOptions.gross_budget_taxation && budgetConsumptionType==='general'" class="col-12 col-md-4 mt-2 mt-md-0">
      <div class="text-center budget-box">
        <div class="budget-box-title text-themed">
          {{ Math.floor(budgets.allocated_taxes) }}<span
          class="text-sup text-themed-dark">{{flexbenUtils.getSupDecimals(budgets.allocated_taxes)}}</span>
        </div>
        <div class="budget-box-body px-1 pb-1"
             v-html="$t('flexben.beneficiary_pages.benefits.budget.taxes',{
                      date: ($t('flexben.general.months.'+budgets.allocation_month_year.month) + ' ' +budgets.allocation_month_year.year)
                      })">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flexbenUtils from "@/plugins/flexben_utils";
import moment from "moment";

export default {
  name: "BudgetTotalsCards",
  props: {
    budgets: {
      require: true,
      type: Object,
      default: function () {
        return {
          available: 0,
          budget_expiration_date: null,
          allocated: 0,
          allocated_taxes: 0,
          allocation_month_year: {
            month: "12",
            year: "2020"
          },
        };
      }
    },
    reduceSize: {
      require: true,
      type: Boolean,
      default: false
    },
    companyOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          cam_budget_taxation: false,
          gross_budget_taxation: false,
          legislated_gross_calculation: false,
        };
      }
    },
    budgetConsumptionType: {
      type: String,
      required: true,
      default: function () {
        return 'general';
      }
    }
  },
  computed: {
    remainingBudget: function () {
      return parseFloat(this.budgets.available);
    }
  },
  data: function () {
    return {
      flexbenUtils: flexbenUtils,
    }
  },
  methods: {
    getBudgetExpirationDate() {
      if (this.budgets.budget_expiration_date === null) {
        return '-';
      }
      let date = moment(this.budgets.budget_expiration_date);
      return date.format('DD') + ' ' + this.$t('flexben.general.months.' + date.format('M')) + ' ' + date.year();
    },
  }
}
</script>
