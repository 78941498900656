<template>
  <div class="container-fluid flexben-container mb-5">
    <div v-if="!initialLoading">
      <div class="row">
        <div class="col-12 flexben-page-title">
          <h1 class="mb-0">{{ $t("flexben.beneficiary_pages.requests.title_voucher_history") }}</h1>
        </div>
      </div>

      <div class="container-bg-white mt-3">
        <div class="row p-3">
          <div class="col-12">
            <div class="table-responsive scaled-table hover-table">
              <table class="table table-striped">
                <thead>
                <tr>
                  <td>{{ $t("flexben.beneficiary_pages.requests.table.request_number") }}</td>
                  <td>{{ $t("flexben.beneficiary_pages.requests.table.partner") }}</td>
                  <td>{{ $t("flexben.beneficiary_pages.requests.table.benefit") }}</td>
                  <td>{{ $t("flexben.beneficiary_pages.requests.table.amount") }}</td>
                  <td>{{ $t("flexben.beneficiary_pages.requests.table.taxes") }}</td>
                  <td>{{ $t("flexben.beneficiary_pages.requests.table.placed_date") }}</td>
                  <td></td>
                </tr>
                </thead>
                <tbody v-if="!loading && items.data.length > 0" :key="'list_table'">
                <tr v-for="(item, index) of items.data"
                    :key="index"
                    class="trhover parent">
                  <td>{{ item.id }}</td>
                  <td>{{ (item.benefit_id && item.benefit.partner_id) ? item.benefit.partner.name:'-' }}</td>
                  <td>{{ (item.benefit_id) ? item.benefit.name:'-' }}</td>
                  <td>{{ item.total_value }} Lei</td>
                  <td>{{ ((item.total_value_cas + item.total_value_cass + item.total_value_tax+
                    item.value_cam).toFixed(2)+' Lei') }}
                  </td>
                  <td>{{ moment(item.placed_at, 'DD/MM/YYYY HH:mm') }}</td>
                  <td>
                    <a v-if="item.value_type==='file'"
                       :href="item.download_url + authorizationTokenString"
                       target="_blank"
                       class="btn btn-themed-o p-1">
                      {{ $t("flexben.general.btn.obtain") }}</a>
                    <button v-else
                            @click="showCode(item)"
                            class="btn btn-themed-o p-1">
                      {{ $t("flexben.general.btn.obtain") }}
                    </button>
                  </td>
                </tr>
                </tbody>
                <tbody v-else :key="'list_table'">
                <tr class="gri-cell text-center trhover">
                  <td colspan="100" class="text-center">{{ $t("flexben.general.no_results_found") }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 p-0">
            <listPagination
              :currentPage="items.current_page"
              :totalItems="items.total"
              :startItem="items.from"
              :endItem="items.to"
              :itemsPerPage="items.perPage"
              @pageChanged="pageChanged"
              @changePerPage="changePerPage"></listPagination>
          </div>
        </div>
      </div>
    </div>
    <scroll-to-top-btn></scroll-to-top-btn>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
    <ViewVoucherCodeModal ref="voucher_modal"/>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import Cookies from "js-cookie";
import ViewVoucherCodeModal from "@/components/flexben/beneficiary_pages/requests/modals/ViewVoucherCodeModal";

export default {
  name: "BeneficiaryRequestsVoucherHistory",
  components: {
    ViewVoucherCodeModal
  },
  data: function () {
    return {
      initialLoading: true,
      loading: true,
      loaderError: false,
      httpService: httpFlexbenService.beneficiary,
      filters_values: {
        per_page: null,
        page: null,
      },
      items: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      },
    };
  },
  computed: {
    authorizationTokenString: function () {
      return '?authorization_token=' + btoa(Cookies.get('token'))
    }
  },
  created() {
    this.beneficiaryId = this.$store.getters.flx_beneficiaryActiveEmploymentId;
    this.__loadList();
  },
  mounted() {
    this.beneficiaryId = this.$store.getters.flx_beneficiaryActiveEmploymentId;
  },
  methods: {
    changePerPage(perPage) {
      this.filters_values.page = 1;
      this.filters_values.per_page = parseInt(perPage);
      this.__loadList();
    },
    pageChanged(pageNb) {
      this.items.current_page = parseInt(pageNb);
      this.filters_values.page = parseInt(pageNb);
      this.__loadList();
    },
    showCode(details) {
      this.$refs['voucher_modal'].openModal(details);
    },
    __loadList() {
      this.loading = true;
      this.httpService.requestsVoucherHistory(this.beneficiaryId, this.filters_values)
        .then((response) => {
          this.items = response.data.items;
          this.loading = false;
          this.initialLoading = false;
        })
        .catch((error) => {
          this.loading = true;
          this.loaderError = true;
        })

    }
  }

}
</script>
