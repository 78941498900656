<template>
  <b-modal id="modal-1"
           ref="cancel-request-modal"
           :hide-header-close="true"
           headerClass="border-0 text-center"
           bodyClass="modal-body no-border background-white text-center"
           footerClass="modal-footer border-top-0"
           centered>
    <template v-slot:modal-header>
      <slot name="header">
        <h2 class="mb-0 mx-auto text-capitalize">{{ $t('flexben.general.confirmation') }}</h2>
      </slot>
    </template>
    <template v-slot:default>
      <slot name="body"></slot>
    </template>
    <template v-slot:modal-footer>
      <div class="row w-100">
        <div class="col-12 text-center">
          <button class="btn btn-themed mx-auto" @click="confirmAction()">{{ $t('flexben.general.btn.confirm') }}</button>
        </div>
        <div class="col-12 text-center mt-2">
          <button class="btn" @click="closeModal()">{{ $t('flexben.general.btn.cancel') }}</button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";

export default {
  name: "AutoTopUpNotLegislatedModal",
  components: {
    'b-modal': BModal
  },
  props: {
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },
  },
  methods: {
    openModal() {
      this.$refs['cancel-request-modal'].show();
    },
    closeModal() {
      this.$refs['cancel-request-modal'].hide();
    },
    confirmAction() {
      this.$emit('confirmAction');
      this.$refs['cancel-request-modal'].hide();
    }
  },
}
</script>
