<template>
  <b-modal id="modal-1"
           ref="alert-modal"
           :hide-header-close="true"
           headerClass="border-0 text-center"
           bodyClass="modal-body no-border background-white text-center "
           footerClass="modal-footer border-top-0"
           centered>
    <template v-slot:modal-header v-if="failed">
      <h2 class="mb-0 mx-auto text-capitalize">{{ $t('flexben.general.alert') }}</h2>
    </template>
    <template v-slot:default>
      <div class="text-18" :class="textClass" v-html="message"></div>
    </template>
    <template v-slot:modal-footer>
      <button class="btn text-themed mx-auto" @click="closeModal()">{{ $t('flexben.general.btn.close') }}</button>
    </template>
  </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";

export default {
  name: "AlertModal",
  components: {
    'b-modal': BModal
  },
  props: {
    message: {
      default: 'Success!'
    },
    textClass: {
      default: 'text-success'
    },
    failed: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },
  methods: {
    openModal() {
      this.$refs['alert-modal'].show();
    },
    closeModal() {
      this.$emit('clearActionSuccess');
      this.$refs['alert-modal'].hide();
    }
  },
}
</script>
