<template>
  <div class="container-fluid">
    <div class="row" v-if="isActivePersonalContributionType">
      <button
        class="btn btn-themed-subselect mx-md-1 mb-2 col-12 col-md d-inline-flex align-items-center justify-content-center border-themed"
        v-for="(contributionType, index) in PPP3FormsService.getContributionTypeList()"
        :class="{ active: contributionType.value === contributionTypeSelected }"
        :disabled="PPP3FormsService.getActiveRecurringBenefit() !== null"
        @click="changeContributionTypeSelected(contributionType.value)"
        :key="index">
        {{$t("flexben.beneficiaries.fields.contribution_type")}}:  {{ $t(contributionType.translationKey) }}
      </button>
    </div>
    <div v-for="item in ceBenefitsData" class="container-fluid" :key="item.id">
      <div v-if="item.isShow">
        <div class="row container-gray py-3 text-left">
          <div class="col-12">
            <div  class="d-flex justify-content-between">
              <span class="flx-type-badge mr-1 h-25">{{ $t("flexben.benefit.sub_types.ppp3") }}</span>
              <div :class="companyOptions.cam_budget_taxation ? 'text-right text-xl-left' : 'text-right'"
                   class="w-100"
                   v-if="item.contributionType === 'mixt'">
                <div class="d-block text-center" >
                  <div class="row mb-3">
                    <div v-if="item.ceBenefit" class="col-sm-10 p-0" style="text-align: end">
                       <span class="mr-1 " v-html="(item.ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale] === null) ?
                        $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
                        : item.ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale]">
                        </span>
                    </div>
                    <div v-else class="col-sm-10 p-0" style="text-align: end">
                       <span class="mr-1 " v-html="
                        $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
                        ">
                        </span>
                    </div>
                    <div class="col-sm-2 p-0">
                      <span id="tooltip_up_dejun_deductibility" class="d-inline-block flexben-tooltip-icon ">i</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-10 p-0" style="text-align: end">
                      <span>{{$t('flexben.beneficiary_pages.benefits.tooltips.employee')}}</span>
                    </div>
                    <div class="col-sm-2 p-0">
                      <div v-if="!PPP3FormsService.isSetMixtContributionType && contributionTypeSelected !== 'mixt' && PPP3FormsService.getActiveRecurringBenefit() !== null && taxesLoader" class="load d-flex"></div>
                      <b-tooltip :target="'tooltip_up_dejun_deductibility'" variant="flexben" placement="topleft">
                        <div class="text-left">
                          {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
                        </div>
                      </b-tooltip>
                      <span v-if="PPP3FormsService.isSetPersonalEmployerType" class="text-danger"><strong>0 Lei</strong></span>
                      <span v-else-if="!taxesLoader && PPP3FormsService.getActiveRecurringBenefit() === null" class="text-danger"><strong>{{ salaryDeductibilityTypeCompany }} Lei</strong></span>
                      <span v-else-if="PPP3FormsService.getActiveRecurringBenefit() !== null" class="text-danger"><strong>0 Lei</strong></span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-10 p-0" style="text-align: end">
                      <span >{{$t('flexben.beneficiary_pages.benefits.tooltips.personal')}}</span>
                    </div>
                    <div class="col-sm-2 p-0">
                      <div v-if="!PPP3FormsService.isSetMixtContributionType && contributionTypeSelected !== 'mixt' && PPP3FormsService.getActiveRecurringBenefit() !== null && taxesLoader" class="load d-flex"></div>
                      <b-tooltip :target="'tooltip_up_dejun_deductibility'" variant="flexben" placement="topleft">
                        <div class="text-left">
                          {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
                        </div>
                      </b-tooltip>
                      <span v-if="PPP3FormsService.isSetPersonalEmployerType" class="text-danger"><strong>0 Lei</strong></span>
                      <span v-else-if="!taxesLoader && PPP3FormsService.getActiveRecurringBenefit() === null" class="text-danger"><strong>{{ salaryDeductibilityTypePersonal }} Lei</strong></span>
                      <span v-else-if="PPP3FormsService.getActiveRecurringBenefit() !== null" class="text-danger"><strong>0 Lei</strong></span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="item.contributionType === 'personal'" class="d-flex">
                <div v-if="item.cebenefit && item.ceBenefit.benefit.translations !== undefined">
                     <span class="mr-1" v-html="(item.ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale] === null) ?
                        $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
                        : item.ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale]">
                      </span>
                </div>
                <div v-else>
                     <span class="mr-1" v-html="
                        $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
                        ">
                      </span>
                </div>
                <span id="tooltip_up_dejun_deductibility" class="d-inline-block flexben-tooltip-icon mr-2">i</span>
                <div v-if="!PPP3FormsService.isSetMixtContributionType && contributionTypeSelected !== 'mixt' && PPP3FormsService.getActiveRecurringBenefit() !== null && taxesLoader" class="load d-flex"></div>
                <b-tooltip :target="'tooltip_up_dejun_deductibility'" variant="flexben" placement="topleft">
                  <div class="text-left">
                    {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
                  </div>
                </b-tooltip>
                <span v-if="PPP3FormsService.isSetMixtContributionType && contributionTypeSelected !== 'mixt'" class="text-danger"><strong>0 Lei</strong></span>
                <span v-else-if="!taxesLoader && PPP3FormsService.getActiveRecurringBenefit() === null" class="text-danger"><strong>{{ salaryDeductibilityTypePersonal }} Lei</strong></span>
                <span v-else-if="PPP3FormsService.getActiveRecurringBenefit() !== null" class="text-danger"><strong>0 Lei</strong></span>
              </div>
              <div v-else-if="item.contributionType === 'employer'" class="d-flex">
                <div v-if="item.ceBenefit && item.ceBenefit.benefit.translations !== undefined">
                     <span class="mr-1" v-html="(item.ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale] === null) ?
                        $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
                        : item.ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale]">
                      </span>
                </div>
                <div v-else>
                     <span class="mr-1" v-html="
                        $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
                        ">
                      </span>
                </div>
                <span id="tooltip_up_dejun_deductibility" class="d-inline-block flexben-tooltip-icon mr-2">i</span>
                <div v-if="!PPP3FormsService.isSetMixtContributionType && contributionTypeSelected !== 'mixt' && PPP3FormsService.getActiveRecurringBenefit() !== null && taxesLoader" class="load d-flex"></div>
                <b-tooltip :target="'tooltip_up_dejun_deductibility'" variant="flexben" placement="topleft">
                  <div class="text-left">
                    {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
                  </div>
                </b-tooltip>
                <span v-if="PPP3FormsService.isSetMixtContributionType && contributionTypeSelected !== 'mixt'" class="text-danger"><strong>0 Lei</strong></span>
                <span v-else-if="!taxesLoader && PPP3FormsService.getActiveRecurringBenefit() === null" class="text-danger"><strong>{{ salaryDeductibilityTypeCompany }} Lei</strong></span>
                <span v-else-if="PPP3FormsService.getActiveRecurringBenefit() !== null" class="text-danger"><strong>0 Lei</strong></span>
              </div>
            </div>
          </div>
          <div v-if="contributionTypeSelected === 'mixt' && PPP3FormsService.isSetPersonalEmployerType"
               class="col-12 text-danger text-bold text-center">
            {{ $t('flexben.beneficiary_pages.benefits.is_set_personal_employeer_contribution_type')}}
          </div>
          <div v-else-if="PPP3FormsService.getActiveRecurringBenefit() !== null"
               class="col-12 text-danger text-bold text-center">
            {{ $t('flexben.beneficiary_pages.benefits.non_legislated_already_month_use',{
            type: $t("flexben.benefit.sub_types.ppp3"),
            benefit: PPP3FormsService.getActiveRecurringBenefit().name
            }) }}.
          </div>
          <div v-else-if="PPP3FormsService.getActiveRecurringBenefit() === null &&  item.ceBenefit && item.ceBenefit.benefit.recurring_has_ordered"
               class="col-12 text-danger text-bold text-center"
               v-html="$t('flexben.beneficiary_pages.benefits.non_legislated_recurring_ordered')">
          </div>
          <div v-else-if="PPP3FormsService.isSetMixtContributionType && contributionTypeSelected !== 'mixt'" class="col-12 text-danger text-bold text-center">
            {{ $t('flexben.beneficiary_pages.benefits.is_set_mixt_contribution_type')}}
          </div>

          <!--  Contract number field   -->
          <div class="col-12 col-md-6 col-xl-6 form-group">
            <label for="contract_nr" class="col-form-label ">{{
              $t('flexben.beneficiary_pages.benefits.fields.contract_number') }}
              <span class="text-danger">*</span></label>
            <input type="number" step="0.01" id="contract_nr"
                   v-if="contributionTypeSelected === 'mixt' && PPP3FormsService.isSetPersonalEmployerType"
                   :class="{'border-danger':invalidFields.recurring_company_value}"
                   class="form-control"
                   disabled>
            <input type="number" step="0.01" id="contract_nr"
                   v-else-if="PPP3FormsService.isSetMixtContributionType && contributionTypeSelected !== 'mixt'"
                   :class="{'border-danger':invalidFields.recurring_company_value}"
                   class="form-control"
                   disabled>
            <input type="text" id="contract_nr"
                   v-else-if="PPP3FormsService.getActiveRecurringBenefit() === null"
                   :class="{'border-danger':invalidFields.contractNr}"
                   class="form-control"
                   :value="item.contractNr"
                    @input="changeContractNumber(item.contributionType)">
            <input type="number" step="0.01" id="contract_nr"
                   v-else-if="PPP3FormsService.getActiveRecurringBenefit() !== null"
                   :class="{'border-danger':invalidFields.recurring_company_value}"
                   class="form-control"
                   disabled>
          </div>
          <!--  Personal contribution field   -->
          <div
            v-if="item.contributionType === 'mixt' || item.contributionType === 'personal'"
            class="col-12 col-md-6 col-xl-6 form-group">
            <label for="recurring_value" class="col-form-label">
              {{ $t('flexben.beneficiary_pages.benefits.fields.top_up_personal_recurring_value') }}
              <span class="text-danger">*</span>
            </label>
            <input type="number" step="0.01" id="contract_nr"
                   v-if="contributionTypeSelected === 'mixt' && PPP3FormsService.isSetPersonalEmployerType"
                   :class="{'border-danger':invalidFields.recurring_company_value}"
                   class="form-control"
                   disabled>
            <input type="number" step="0.01" id="contract_nr"
                   v-else-if="PPP3FormsService.isSetMixtContributionType && contributionTypeSelected !== 'mixt'"
                   :class="{'border-danger':invalidFields.recurring_company_value}"
                   class="form-control"
                   disabled>
            <input id="recurring_value"
                   v-else-if=" PPP3FormsService.getActiveRecurringBenefit() === null"
                   type="number"
                   class="form-control"
                   :class="{'border-danger':invalidFields.recurring_personal_value}"
                   :value="item.recurringPersonalValue"
                   @input="changePersonalValue(item.contributionType)">
            <input type="number" step="0.01" id="contract_nr"
                   v-if=" PPP3FormsService.getActiveRecurringBenefit() !== null"
                   :class="{'border-danger':invalidFields.recurring_company_value}"
                   class="form-control"
                   disabled
                  >
            <div :class="{ 'show' : errorRecurringPersonalValue}" class="invalid-feedback">
              {{$t('flexben.beneficiary_pages.benefits.fields.error_input_val') }}
            </div>
          </div>
          <!--  Company contribution field   -->
          <div
            v-if="item.contributionType === 'mixt' || item.contributionType === 'employer'"
            class="col-12 col-md-6 col-xl-6 form-group">
            <label for="contract_nr" class="col-form-label">{{
              $t('flexben.beneficiary_pages.benefits.fields.top_up_employer_recurring_value') }}
              <span class="text-danger">*</span></label>
            <input type="number" step="0.01" id="contract_nr"
                   v-if="contributionTypeSelected === 'mixt' && PPP3FormsService.isSetPersonalEmployerType"
                   :class="{'border-danger':invalidFields.recurring_company_value}"
                   class="form-control"
                   disabled>
            <input type="number" step="0.01" id="contract_nr"
                   v-else-if="PPP3FormsService.isSetMixtContributionType && contributionTypeSelected !== 'mixt'"
                   :class="{'border-danger':invalidFields.recurring_company_value}"
                   class="form-control"
                   disabled>
            <input type="number" step="0.01" id="contract_nr"
                   v-else-if="PPP3FormsService.getActiveRecurringBenefit() === null"
                   :class="{'border-danger':invalidFields.recurring_company_value}"
                   class="form-control"
                   :value="item.recurringCompanyValue"
                   @input="changeCompanyValue(item.contributionType)">
            <input type="number" step="0.01" id="contract_nr"
                   disabled
                   v-if=" PPP3FormsService.getActiveRecurringBenefit() !== null"
                   :class="{'border-danger':invalidFields.recurring_company_value}"
                   class="form-control"
                   >
            <div :class="{'show' : errorRecurringCompanyValue}" class="invalid-feedback">
              {{ $t('flexben.beneficiary_pages.benefits.fields.error_input_val') }}
            </div>
          </div>
          <div class="col-12 text-bold width-100">
            <span v-html="$t('flexben.beneficiary_pages.benefits.non_legislated_recurring_not_taxed',{
                value:item.monthlyNotTaxedAttribution, remainingYearMonths:item.remainingYearMonths
            })"></span>
            {{
            item.ceBenefit && item.ceBenefit.benefit.recurring_has_ordered ?
            '' : $t('flexben.beneficiary_pages.benefits.non_legislated_recurring_not_taxed_extra')
            }}.
          </div>
          <div class="position-relative w-100">
            <div v-if="!PPP3FormsService.isSetMixtContributionType && contributionTypeSelected !== 'mixt' && PPP3FormsService.getActiveRecurringBenefit() !== null && taxesLoader" class="load load-total position-absolute"></div>
            <total-value-pay
              v-if="contributionTypeSelected === 'mixt' && PPP3FormsService.isSetPersonalEmployerType"
              :totalPayValue="0"
              :isLoading="false"
            ></total-value-pay>
            <total-value-pay
              v-else-if="PPP3FormsService.isSetMixtContributionType && contributionTypeSelected !== 'mixt'"
              :totalPayValue="0"
              :isLoading="false"
            ></total-value-pay>
            <total-value-pay
              v-else-if="(companyOptions.gross_budget_taxation || companyOptions.cam_budget_taxation) && PPP3FormsService.getActiveRecurringBenefit() === null"
              :totalPayValue="totalPayValue"
              :isLoading="taxesLoader"
            ></total-value-pay>
            <total-value-pay
              v-if="PPP3FormsService.getActiveRecurringBenefit() !== null"
              :totalPayValue="0"
              :isLoading="false"
            ></total-value-pay>
          </div>
        </div>
      </div>
      <div class="row mt-3" v-if="item.isShow">
        <div class="col-12 px-0 text-md-left">
          <div v-if="PPP3FormsService.isSetMixtContributionType && contributionTypeSelected !== 'mixt'">
            <button class="btn px-2 px-md-4 mb-2 mr-md-2 btn-themed-blue"
                    @click="changeContributionTypeSelected('mixt')"
            >
              {{ $t('flexben.beneficiary_pages.benefits.go_to_mixt_contribution_type')}}
            </button>
          </div>
          <div v-if="contributionTypeSelected === 'mixt' && PPP3FormsService.isSetPersonalEmployerType"></div>
          <div v-else-if="!PPP3FormsService.isSetMixtContributionType || contributionTypeSelected === 'mixt'">
            <button class="btn px-2 px-md-4 mb-2 mr-md-2" v-if="item.ceBenefit && PPP3FormsService.getActiveRecurringBenefit() === null"
                    :disabled="!canActivateAutoTopUp()"
                    :class="{
                              'btn-themed-green':!enableAutoTopup,
                              'btn-themed-red':enableAutoTopup,
                            }"
                    @click="confirmToggleAutoTopUp('toggle')"
            >
              {{ toogleAutoTopUpButtonText(item.recurringHasOrdered) }}
            </button>
            <button class="btn px-2 px-md-4 mb-2 mr-md-2" v-else-if="PPP3FormsService.getActiveRecurringBenefit() === null"
                    :disabled="!canActivateAutoTopUp()"
                    :class="{
                              'btn-themed-green':!enableAutoTopup,
                              'btn-themed-red':enableAutoTopup,
                            }"
                    @click="confirmToggleAutoTopUp('toggle')"
            >
              {{ toogleAutoTopUpButtonText(item.recurringHasOrdered) }}
            </button>
            <button v-if="enableAutoTopup && PPP3FormsService.getActiveRecurringBenefit() === null"
                    class="btn btn-themed btn px-2 px-md-4 mb-2"
                    :disabled="!canActivateAutoTopUp()"
                    @click="confirmToggleAutoTopUp('update')">{{
              $t('flexben.beneficiary_pages.benefits.btn.update_auto_top_up') }}
            </button>
            <a v-if="item.ceBenefit && item.ceBenefit.benefit.product_link!==null && item.ceBenefit && item.ceBenefit.benefit.product_link.length>0"
               :href="item.ceBenefit && item.ceBenefit.benefit.product_link" target="_blank"
               class="btn btn-themed-blue float-md-right px-2 px-md-4 mb-2"><span
              class="d-flex">
              <font-awesome-icon icon="external-link-alt" class="mr-1 contact-ico text-14"/>
              <span class="text-14">{{ $t('flexben.beneficiary_pages.benefits.btn.sign_contract') }}</span>
            </span></a>
          </div>
        </div>
      </div>
    </div>
    <auto-top-up-not-legislated-modal ref="auto_top_up_not_legislated_modal"
                                      :locale="locale"
                                      @confirmAction="toggleAutoTopUp">
      <template v-slot:body>
        <div class="mb-0 mx-auto">
            <template v-if="action==='update'">
              {{$t('flexben.beneficiary_pages.benefits.modal.auto_topup.confirm_update') }} <br><br>
              <div class="text-danger">{{ $t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_enable_pp3')
                }}
              </div>
            </template>
            <template v-if="action==='toggle'">
              {{ enableAutoTopup ? $t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_disable') :
              $t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_enable') }}
            </template>
        </div>
      </template>
    </auto-top-up-not-legislated-modal>
  </div>
</template>
<script>
  import {BTooltip} from "bootstrap-vue";
  import AutoTopUpNotLegislatedModal
    from "@/components/flexben/beneficiary_pages/benefits/modals/AutoTopUpNotLegislatedModal";
  import TotalValuePay from "@/components/flexben/beneficiary_pages/benefits/partials/TotalValuePay";
  import PPP3FormService from "./../PPP3FormService";
  import ApiTaxes from "@/multiben-module/src/services/api/apiTaxes";

  export default {
    name: 'NewPPP3Form',
    components: {
      'b-tooltip': BTooltip,
      'auto-top-up-not-legislated-modal': AutoTopUpNotLegislatedModal,
      'total-value-pay': TotalValuePay,
    },
    props: {
      ceBenefit: {
        required: true
      },
      isActivePersonalContributionType: {
        type: Boolean,
        required: true
      },
      companyOptions: {
        type: Object,
        required: true,
        default: function () {
          return {
            cam_budget_taxation: false,
            gross_budget_taxation: false,
            legislated_gross_calculation: false,
            last_monthly_order_day: null,
            budget_telework_transfer_to_general: false,
            cash_out_last_order_day: null,
            non_legislated_net_taxes_calculate: false,
            banner_image_url: null,
          };
        }
      },
      locale: {
        type: String,
        required: true,
        default: 'ro'
      },
    },
    data: function () {
      return {
        PPP3FormsService: PPP3FormService,
        ceBenefitsData: null,
        contributionTypeSelected:null,
        taxesLoader: false,
        salaryDeductibilityTypePersonal: 0,
        salaryDeductibilityTypeCompany: 0,
        invalidFields: {},
        recurringPersonalValue: 0,
        recurringCompanyValue: 0,
        errorRecurringPersonalValue: false,
        errorRecurringCompanyValue: false,
        inputContractNumber: 0,
        benefitId: null,
        companyEmployeeID: null,
        totalPayValue: 0,
        action: 'toggle',
      }
    },
    computed: {
      initServiceWithNewData() {
        return this.PPP3FormsService.init(this.getCeBenefitsData).bind();
      },
      getCeBenefitsData() {
        return (!this.ceBenefit) ? null : this.ceBenefit;
      },

      enableAutoTopup() {
        return this.ceBenefitsData[this.contributionTypeSelected].enableAutoTopup;
      },
    },
    created() {
      this.populateFormsData();
    },
    watch: {
      ceBenefit: {
        handler: function () {
          this.populateFormsData();
        }
      },
    },
    methods: {
      changeContractNumber(type) {
        const value = event.target.value;

        if (!this.ceBenefitsData[type]) {
          console.error('No type')
          return;
        }
        this.ceBenefitsData[type].contractNr = value;
      },

      changePersonalValue(type) {
        let value = event.target.value;

        if (!this.ceBenefitsData[type]) {
          console.error('No type')
          return;
        }
        this.ceBenefitsData[type].recurringPersonalValue = value;

        value = parseFloat(value);
        if (!value) {
          this.errorRecurringPersonalValue = true;
          return;
        }
        if (value === 0) {
          this.errorRecurringPersonalValue = true;
          return;
        }

        this.errorRecurringPersonalValue = false;

        this._calculateTotalByCompanyOptions(this.ceBenefitsData[type].recurringPersonalValue, this.ceBenefitsData[type].recurringCompanyValue, type);

      },

      canActivateAutoTopUp() {
        const contractNumber = this.ceBenefitsData[this.contributionTypeSelected].contractNr;
        let hasContractNumber = (typeof contractNumber === 'string') && contractNumber.length > 0;
        let hasPersonalContributionSet = true;
        let hasCompanyContributionSet = true;

        let personalContributionSet = parseFloat(this.ceBenefitsData[this.contributionTypeSelected].recurringPersonalValue);
        let companyContributionSet = parseFloat(this.ceBenefitsData[this.contributionTypeSelected].recurringCompanyValue);

        if (this.contributionTypeSelected === 'personal' || this.contributionTypeSelected === 'mixt') {
          hasPersonalContributionSet = (typeof personalContributionSet === 'number') && personalContributionSet > 0;
        }
        if (this.contributionTypeSelected === 'employer' || this.contributionTypeSelected === 'mixt') {
          hasCompanyContributionSet = (typeof companyContributionSet === 'number') && companyContributionSet > 0;
        }
        return (hasContractNumber && hasPersonalContributionSet && hasCompanyContributionSet);
      },

      changeCompanyValue(type) {
        let value = event.target.value;

        if (!this.ceBenefitsData[type]) {
          console.error('No type')
          return;
        }
        this.ceBenefitsData[type].recurringCompanyValue = value;

        value = parseFloat(value);

        if (!value) {
          this.errorRecurringCompanyValue = true;
          return;
        }
        if (value === 0) {
          this.errorRecurringCompanyValue = true;
          return;
        }
        this._calculateTotalByCompanyOptions(this.ceBenefitsData[type].recurringPersonalValue, this.ceBenefitsData[type].recurringCompanyValue, type);
      },

      populateFormsData() {
        this.PPP3FormsService.init(this.getCeBenefitsData, this.isActivePersonalContributionType).bind();
        this.ceBenefitsData = this.PPP3FormsService.getMappedData();
        this.contributionTypeSelected = this.PPP3FormsService.getContributionTypeSelected();
        this.companyEmployeeID = this.PPP3FormsService.getCompanyEmployeeId();
        this.benefitId = this.PPP3FormsService.getBenefitId();
        this._calculateTotalByCompanyOptions(this.ceBenefitsData[this.contributionTypeSelected].recurringPersonalValue, this.ceBenefitsData[this.contributionTypeSelected].recurringCompanyValue, this.contributionTypeSelected);
      },

      changeContributionTypeSelected(value) {

        this.ceBenefitsData.personal.isShow = false;
        this.ceBenefitsData.employer.isShow = false;
        this.ceBenefitsData.mixt.isShow = false;

        if (!this.ceBenefitsData[value]) {
          return;
        }

        switch (value) {
          case 'mixt':
            this.ceBenefitsData.mixt.isShow = true;
            break
          case 'personal':
            this.ceBenefitsData.personal.isShow = true;
            break
          case 'employer':
            this.ceBenefitsData.employer.isShow = true;
        }

        this.contributionTypeSelected = value;


        this._calculateTotalByCompanyOptions(this.ceBenefitsData[value].recurringPersonalValue, this.ceBenefitsData[value].recurringCompanyValue, value);
      },

      _calculateTotalByCompanyOptions(personalValue, companyValue, type) {

        if (!personalValue) {
          personalValue = 0;
        }

        if (!companyValue) {
          companyValue = 0;
        }

        let params = {
          'company_employee_id' : this.companyEmployeeID,
          'benefit_id' : this.benefitId,
          'recurring_company_value' : companyValue,
          'recurring_personal_value' : personalValue,
          'contribution_type' : type,
        };

        this.taxesLoader = true;

        ApiTaxes.getPPP3Taxes(params).then(response => {
          switch (type) {
            case 'personal':
              this.salaryDeductibilityTypePersonal = this.financial(response.data.displayInfo.personalSalaryDeductible);
            break
            case 'employer':
              this.salaryDeductibilityTypeCompany = this.financial(response.data.displayInfo.companySalaryDeductible);
            break
            default:
              this.salaryDeductibilityTypePersonal = this.financial(response.data.displayInfo.personalSalaryDeductible);
              this.salaryDeductibilityTypeCompany = this.financial(response.data.displayInfo.companySalaryDeductible);
          }

          let total = response.data.displayInfo.totalPayment;
          switch (this.contributionTypeSelected) {
            case 'personal':
              total = total - response.data.displayInfo.companySalaryDeductible - companyValue;
              break
            case 'employer':
              total = total - response.data.displayInfo.personalSalaryDeductible - personalValue;
              break
          }

          this.totalPayValue = total;

          this.taxesLoader = false;
        }).catch((error) => {
          console.log(error)
          this.taxesLoader = false;
        });

      },

      financial(x) {
        return Number.parseFloat(x).toFixed(2);
      },
      confirmToggleAutoTopUp(action = 'toggle') {
        this.action = action;
        this.$refs.auto_top_up_not_legislated_modal.openModal();
      },
      toogleAutoTopUpButtonText() {
        return this.enableAutoTopup ?
          this.$t('flexben.beneficiary_pages.benefits.btn.disable_auto_top_up') :
          this.$t('flexben.beneficiary_pages.benefits.btn.activate_auto_top_up')
      },

      toggleAutoTopUp() {
        const payload = {
          enable_auto_topup: this.action === 'toggle' ? !this.enableAutoTopup :this.enableAutoTopup,
          recurring_company_value: this.ceBenefitsData[this.contributionTypeSelected].recurringCompanyValue,
          recurring_personal_value: this.ceBenefitsData[this.contributionTypeSelected].recurringPersonalValue,
          contract_number: this.ceBenefitsData[this.contributionTypeSelected].contractNr,
          benefit_id: this.benefitId,
          contribution_type: this.contributionTypeSelected,
          isPPP3: true
        };

        if (payload.enable_auto_topup && this._validatePayload(payload)) {
          return;
        }

        this.$emit('toggleAutoTopUpPPP3', payload);
      },

      _validatePayload(payload) {
        this.invalidFields = {};
        let hasError = false;
        var regEx = /^[0-9a-zA-Z]+$/;
        if (payload.contract_number == null || payload.contract_number.length === 0) {
          this.invalidFields.contractNr = 'required';
          hasError = true;
        } else if (!payload.contract_number.match(regEx)) {
          this.invalidFields.contractNr = 'non_alpha_num';
          hasError = true;
        } else if (payload.contract_number.length > 100) {
          this.invalidFields.contractNr = 'invalid';
          hasError = true;
        }

        return hasError;
      },
    }
  }
</script>
