import Beneficiary from "./views/beneficiary/Beneficiary";
import Company from "./views/company/Company";
import CreateAffiliatePartner from "@/components/flexben/affiliate_partners/create/CreateAffiliatePartner.vue";
import FlexbenAffiliatePartner from "@/components/layout/FlexbenAffiliatePartner.vue";

export default {
  router: [
    // Common flexben
    {
      path: "/multibeneficii/dashboard",
      name: "flexben",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "flexben"}
    },
    // BENEFICIARY
    {
      path: "/beneficiary/multibeneficii",
      name: "beneficiary_flexben_dashboard",
      component: Beneficiary,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "beneficiary_flexben_dashboard"}
    },
    {
      path: "/beneficiary/multibeneficii/benefits",
      name: "beneficiary_flexben_benefits",
      component: Beneficiary,
      meta: {
        isFlxRoute: true,
        module_permission_code: 'flexible_benefits'
      },
      params: {menu: "beneficiary_flexben_benefits"},
    },
    {
      path: "/beneficiary/multibeneficii/requests",
      name: "beneficiary_flexben_requests",
      component: Beneficiary,
      meta: {
        isFlxRoute: true,
        module_permission_code: ['telework', 'flexible_benefits']
      },
      params: {menu: "beneficiary_flexben_requests"},
    },
    {
      path: "/beneficiary/multibeneficii/request-voucher-history",
      name: "beneficiary_flexben_request_voucher_history",
      component: Beneficiary,
      meta: {
        isFlxRoute: true,
        module_permission_code: ['telework', 'flexible_benefits']
      },
      params: {menu: "beneficiary_flexben_request_voucher_history"},
    },
    {
      path: "/beneficiary/multibeneficii/fixed-benefits",
      name: "beneficiary_flexben_fixed_benefits",
      component: Beneficiary,
      meta: {
        isFlxRoute: true,
        module_permission_code: 'fixed_benefits'
      },
      params: {menu: "beneficiary_flexben_fixed_benefits"},
    },
    {
      path: "/beneficiary/multibeneficii/telework-benefits",
      name: "beneficiary_flexben_telework_benefits",
      component: Beneficiary,
      meta: {
        isFlxRoute: true,
        module_permission_code: 'telework'
      },
      params: {menu: "beneficiary_flexben_telework_benefits"},
    },
    {
      path: "/beneficiary/multibeneficii/offers",
      name: "beneficiary_flexben_offers",
      component: Beneficiary,
      meta: {
        isFlxRoute: true,
        module_permission_code: 'special_offers'
      },
      params: {menu: "beneficiary_flexben_offers"},
    },
    {
      path: "/beneficiary/multibeneficii/auto-topups",
      name: "beneficiary_flexben_auto_topups",
      component: Beneficiary,
      meta: {
        isFlxRoute: true,
        module_permission_code: ['telework', 'flexible_benefits']
      },
      params: {menu: "beneficiary_flexben_auto_topups"},
    },
    {
      path: "/beneficiary/multibeneficii/terms",
      name: "beneficiary_flexben_terms",
      component: Beneficiary,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "beneficiary_flexben_terms"}
    },
    {
      path: "/beneficiary/multibeneficii/faq",
      name: "beneficiary_flexben_faq",
      component: Beneficiary,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "beneficiary_flexben_faq"}
    },
    {
      path: "/beneficiary/multibeneficii/gdpr",
      name: "beneficiary_flexben_gdpr",
      component: Beneficiary,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "beneficiary_flexben_gdpr"}
    },

    // ADMIN || CLIENT
    {
      path: "/multibeneficii/configuration",
      name: "flexben_configuration",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "configuration"}
    },
    {
      path: "/multibeneficii/channels",
      name: "flexben_channels_list",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "flexben_channels_list"}
    },
    {
      path: "/multibeneficii/categories",
      name: "flexben_categories",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "categories"}
    },
    {
      path: "/multibeneficii/edit-category",
      name: "flexben_edit_category",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "categories"}
    },
    {
      path: "/multibeneficii/clients",
      name: "flexben_clients",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "flexben_clients"}
    },
    {
      path: "/multibeneficii/client/:id?",
      name: "flexben_edit_client",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "flexben_edit_client"}
    },
    {
      path: "/multibeneficii/benefit-groups",
      name: "flexben_benefit_group_list",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "flexben_benefit_group_list"}
    },
    {
      path: "/multibeneficii/fixed-benefits",
      name: "flexben_fixed_benefits_list",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "flexben_fixed_benefits_list"}
    },
    {
      path: "/multibeneficii/benefits",
      name: "flexben_benefits",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "benefits"}
    },
    {
      path: "/multibeneficii/beneficiaries",
      name: "flexben_beneficiaries",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "beneficiaries"}
    },
    {
      path: "/multibeneficii/beneficiaries/new",
      name: "flexben_beneficiary_new",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "beneficiaries"}
    },
    {
      path: "/multibeneficii/beneficiaries/:beneficiary_id/edit",
      name: "flexben_beneficiary_edit",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "beneficiaries"}
    },
    {
      path: "/multibeneficii/requests",
      name: "flexben_requests",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "requests"}
    },
    {
      path: "/multibeneficii/requests-history",
      name: "flexben_requests_history_list",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "requests-history-list"}
    },
    {
      path: "/multibeneficii/orders",
      name: "flexben_orders",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "orders"}
    },
    {
      path: "/multibeneficii/import-history",
      name: "flexben_import_history",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "import-history"}
    },
    {
      path: "/multibeneficii/import-new",
      name: "flexben_import_new",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "import-new"}
    },
    {
      path: "/multibeneficii/offer-categories",
      name: "flexben_offer_category_list",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "flexben_offer_category_list"}
    },
    {
      path: "/multibeneficii/offers",
      name: "flexben_offer_list",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "flexben_offer_list"}
    },
    {
      path: "/multibeneficii/partners",
      name: "flexben_partner_list",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "flexben_partner_list"}
    },
    {
      path: "/multibeneficii/brands",
      name: "flexben_brand_list",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "flexben_brand_list"}
    },
    {
      path: "/multibeneficii/reports",
      name: "flexben_report_list",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "flexben_report_list"}
    },
    {
      path: "/multibeneficii/terms",
      name: "flexben_terms",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "flexben_terms"}
    },
    {
      path: "/multibeneficii/faq",
      name: "flexben_faq",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "flexben_faq"}
    },
    {
      path: "/multibeneficii/benefit-vouchers",
      name: "flexben_benefit_voucher_list",
      component: Company,
      meta: {
        isFlxRoute: true,
      },
      params: {menu: "flexben_benefit_voucher_list"}
    },

    /*
     * partner affiliates
     */
    {
      path: "/multibeneficii/partner-affiliation/:af_id",
      name: "flexben_create_affiliate_partner",
      component: CreateAffiliatePartner,
      params: {menu: "flexben_create_affiliate_partner"},
      meta: {
        hide_chat_bot: true
      },
    },
    {
      path: "/multibeneficii/registration/success/:url_id",
      name: "flexben_registration_success_affiliate_partner",
      component: CreateAffiliatePartner,
      params: {menu: "flexben_registration_success_affiliate_partner"},
      meta: {
        hide_chat_bot: true
      },
    },
    {
      path: "/multibeneficii/partner-offers/:af_id",
      name: "flexben_partner_offers",
      component: FlexbenAffiliatePartner,
      meta: {
        hide_chat_bot: true
      },
      params: {menu: "flexben_partner_offers"}
    },
  ]
}
