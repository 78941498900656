<template>
  <div>
    <a class="btn text-themed go-back" @click="goBack()">{{ $t("flexben.general.go_back") }}</a>
    <div class="row">
      <div class="col-12 col-md-6 flexben-page-title">
        <h1>{{ (formAction === 'create') ? $t('flexben.brand.new') : $t('flexben.brand.edit') }}</h1>
      </div>
      <div class="col-12 col-md-6 text-right">
        <button class="btn text-danger px-4" v-if="(formAction === 'update')" @click="modalAct('delete')">
          <font-awesome-icon icon="trash-alt"/>
          {{$t("flexben.general.btn.delete")}}
        </button>
        <button class="btn btn-themed px-4" @click="modalAct('form_submit')">
          {{ $t('flexben.general.btn.save') }}
        </button>
      </div>
    </div>
    <div class="row my-1" v-if="checkNotEmptyObj(errorsBag)">
      <div class="col-12">
        <div class="alert alert-danger alert-dismissible mb-0">
          <button type="button" class="close" data-dismiss="alert">&times;</button>
          <form-error-list :errorBag="errorsBag" />
        </div>
      </div>
    </div>
    <div class="container-bg-white mt-3">
      <div class="row px-3">
        <div class="col-12">
          <h3 class="m-0 pl-0">{{ $t('flexben.general.details') }}</h3>
        </div>
        <div class="col-12 col-xl-4 d-flex align-items-start justify-content-center mb-1">
          <div class="image-container cursor-pointer d-flex align-items-center" @click="$refs.image_input.click()">
            <div class="image-changed" v-if="imageChanged">{{$t("flexben.benefit.image_changed")}}</div>
            <img
              v-if="formData.image || formData.image_url"
              class="img-fluid upload-image"
              :alt="formData.name"
              :src="(formData.image !== null) ? imagePreview : formData.image_url"
              :class="{'img-border-danger':(Object.keys(errorsBag).includes('image'))}"
            />
            <div class="upload-image-middle-container">
              <div class="upload-image-text">{{$t("flexben.benefit.upload_image")}}</div>
            </div>
            <input type="file" class="hide" ref="image_input" accept=".png,.jpeg,.jpg,.svg,.jfif" @change="setImageFile($event)" />
          </div>
        </div>
        <div class="col-12 col-xl-8">
          <div class="row">
            <div class="col-12 col-md-6 form-group">
              <label for="name" class="form-fields-required col-form-label">
                {{ $t('flexben.brand.fields.name') }}
              </label>
              <input type="text" class="form-control" id="name" v-model="formData.name"
                :class="{'border-danger':(Object.keys(errorsBag).includes('name'))}"
              />
            </div>
            <div class="col-12 col-md-6 form-group">
              <label for="site" class="col-form-label">{{ $t('flexben.brand.fields.website') }}</label>
              <input
                type="text"
                class="form-control"
                id="site"
                v-model="formData.website"
                :class="{'border-danger':(Object.keys(errorsBag).includes('website'))}"
              >
            </div>

            <div class="col-12 pb-2" v-for="(langCode, index) in options.available_languages" :key="index">
              <label :for="'description_'+index" class="col-form-label">
                {{ $t("flexben.general.description") }} - {{ langCode }}
              </label>
              <span
                :id="'description_tooltip_'+index"
                class="d-inline-block flexben-tooltip-icon ml-2 text-bold"
              >
                i
              </span>
              <b-tooltip :target="'description_tooltip_'+index" variant="flexben" placement="topleft">
                <div class="text-left">{{ $t("flexben.brand.tooltips.description") }}</div>
              </b-tooltip>
              <p v-if="Object.keys(errorsBag).includes('description.'+langCode)" class="text-danger">
                {{errorsBag['description.'+langCode][0]}}
              </p>
              <v-editor
                :id="'description_'+index" rows="5"
                class="form-control-textarea my-0"
                :placeholder="$t('flexben.brand.fields.description_placeholder')"
                v-model="formData.description[langCode]"
                :editorToolbar="editorToolbar"
                :class="{'border-danger':(Object.keys(errorsBag).includes('description|'+langCode))}"
              />
            </div>

            <div class="col-12 form-group">
              <label for="address" class="col-form-label">
                {{ $t('flexben.brand.fields.scope_availability') }}
              </label>
              <div v-for="(item, key) of options.scope_availability" :key="key" class="d-flex">
                <input
                  type="checkbox"
                  :value="item"
                  :id="'scope_availability_'+item"
                  :checked="formData.scope_availability.includes(item)"
                  @change="toggleScopeAvailability(item)"
                  class="mr-2 cursor-pointer"
                />
                <label :for="'scope_availability_'+item" class="text-14 mb-0 cursor-pointer">
                  {{ $t('flexben.brand.scope_availability.'+item) }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-modal
      :action="modal.action"
       :formSuccess="modal.formSuccess"
       :message="modal.message"
       :canDelete="modal.canDelete"
       ref="confirm-modal"
       @confirmAction="confirmAction"
    >
    </confirm-modal>
    <content-loader :loader-visible="this.loading" :loader-error="loaderError" />
  </div>
</template>

<script>
import FormErrorList from "@/components/fragments/FormErrorList";
import httpFlexbenService from "@/http/http-flexben";
import ConfirmModal from "@/components/flexben/brands/modals/ConfirmModal";
import {BTooltip} from "bootstrap-vue";
import {VueEditor} from "vue2-editor";
import vueEditorSettings from "@/constants/vue_editor_settings";

export default {
  name: "PartnerForm",
  components: {
    "form-error-list": FormErrorList,
    "confirm-modal": ConfirmModal,
    'b-tooltip': BTooltip,
    'v-editor': VueEditor,
  },
  props: {
    itemId: {
      required: false,
      type: Number,
    },
    formAction: {
      type: String,
      required: true,
    },
    options: {
      required: true,
      type: Object,
    },
  },
  data: () => {
    return {
      httpService: httpFlexbenService.brandService,
      editorToolbar: vueEditorSettings.editorToolbar,
      loading: false,
      loaderError: false,
      imageChanged: false,
      benefitsCount: 0,
      isFormModified: false,
      formData: {
        id: null,
        name: null,
        website: null,
        image: null,
        image_url: null,
        description: {},
        scope_availability: [],
      },
      modal: {
        action: '',
        formSuccess: true,
        message: '',
        canDelete: true,
      },
      errorsBag: {},
    };
  },
  watch: {
    formData: {
      deep: true,
      handler: function () {
        this.isFormModified = true;
      }
    }
  },
  created() {
    if (!this.itemId) {
      return;
    }
    this.httpService.details(this.itemId).then(function (response) {
      this.benefitsCount = response.data.benefitsCount;
      if (this.benefitsCount) {
        this.modal.canDelete = false;
      }
      this.formData.name = response.data.brand.name;
      this.formData.website = response.data.brand.website;
      this.formData.image_url = response.data.brand.image_url;
      for (let index in this.options.available_languages) {
        let langCode = this.options.available_languages[index];
        this.formData.description[langCode] = response.data.brand.description
          ? response.data.brand.description[langCode]
          : ''
        ;
      }
      this.formData.scope_availability = response.data.brand.scope_availability ? response.data.brand.scope_availability : [];
    }.bind(this)).then(function () {
      this.isFormModified = false;
    }.bind(this));
  },
  methods: {
    goBack() {
      if (this.isFormModified) {
        this.modalAct('go_back')
      } else {
        this.$emit('clearForm');
      }
    },
    confirmAction(action) {
      if (action === 'go_back') {
        this.isFormModified = false;
        this.goBack();
      }
      this.closeModal();
      if (action === 'form_submit') {
        this.save();
      }
      if (action === 'delete') {
        this.delete();
      }
      if (action === 'end') {
        this.goBack();
      }
    },
    modalAct(action) {
      this.modal.message = '';
      if (action === 'delete' && this.benefitsCount) {
        this.modal.message = this.$t('flexben.brand.modal.extra_delete', {count_benefits: this.benefitsCount});
      }
      this.modal.action = action;
      this.openModal();
    },
    openModal() {
      this.$refs['confirm-modal'].openModal();
    },
    closeModal() {
      this.$refs['confirm-modal'].closeModal();
    },
    _prepareFormData() {
      let formData = new FormData();
      if (this.formData.name) {
        formData.append('name', this.formData.name);
      }
      if (this.formData.website) {
        formData.append('website', this.formData.website);
      }
      if (this.formData.image) {
        formData.append('image', this.formData.image);
      }
      formData.append('description', JSON.stringify(this.formData.description));
      formData.append('scope_availability', JSON.stringify(this.formData.scope_availability));

      return formData;
    },
    save() {
      this.loading = true;
      this.httpService.save(this.itemId, this._prepareFormData())
        .then(function () {
          this.modal.formSuccess = true;
          this.modal.action = 'end';
          this.loading = false;
          this.isFormModified = false;
          this.openModal();
        }.bind(this))
        .catch(function (error) {
          if (error.response.status === 400) {
            this.errorsBag = error.response.data.error_fields;
            this.modal.formSuccess = false;
            this.modal.message = this.$t('flexben.general.validation_errors');
            this.modal.action = 'end';
            this.loading = false;
            this.openModal();
          } else {
            this.loading = false;
            this.loaderError = true;
          }
        }.bind(this));
    },
    delete() {
      this.loading = true;
      this.httpService.delete(this.itemId)
        .then(function (response) {
          this.modal.formSuccess = true;
          this.modal.action = 'end';
          this.modal.message = '';
          this.loading = false;
          this.openModal();
        }.bind(this))
        .catch(function (error) {
          if (error.response.status === 400) {
            this.loading = false;
            this.loaderError = true;
          }
        }.bind(this));
    },
    setImageFile() {
      this.errorsBag = {};
      if (this.$refs.image_input.accept.indexOf(this.$refs.image_input.files[0].type.split('/')[1]) > 0) {
        let reader = new FileReader();
        reader.readAsDataURL(this.$refs.image_input.files[0]);
        reader.onload = evt => {
          let img = new Image();
          img.onload = () => {
            if (img.width !== img.height) {
              this.errorsBag = {image: 'image_square'};
            } else if (img.width !== 500 || img.height !== 500) {
              this.errorsBag = {image: 'image_500_500'};
            } else {
              this.formData.image = this.$refs.image_input.files[0];
              this.imageChanged = true;
              if (typeof this.formData.image !== "undefined") {
                this.imagePreview = URL.createObjectURL(this.formData.image);
              }
            }
          }
          img.src = evt.target.result;
        }
      } else {
        this.errorsBag = {image: 'image_type_supported'};
      }
    },
    toggleScopeAvailability(item) {
      if (this.formData.scope_availability.includes(item)) {
        this.formData.scope_availability.splice(this.formData.scope_availability.indexOf(item), 1);
      } else {
        this.formData.scope_availability.push(item);
      }
    },
  },
}
</script>
