<template>
  <div class="row container-gray pt-2 mt-3">
    <div class="col-12 overflow-hidden">
      <vue-form-generator :schema="schema"
                          :model="dynamicFormModel"
                          :options="formOptions"
                          :key="vueFormGenbKey"
                          tag="div"
                          :class="'container-fluid p-0'"></vue-form-generator>
    </div>
  </div>
</template>

<script>
import VueFormGenerator from 'vue-form-generator';
import moment from 'moment';
import i18n from "@/plugins/language/i18n";

export default {
  name: "V1FormBuilder",
  components: {
    "vue-form-generator": VueFormGenerator.component
  },

  props: {
    dynamicFields: {
      type: [Array, Object],
      required: true,
      default: function () {
        return [];
      }
    },
    dynamicFormModel: {
      type: Object,
      required: true,
      default: function () {
        return {};
      }
    },
    requiredDynamicFields: {
      type: Object,
      required: true,
      default: function () {
        return {};
      }
    },
    locale: {
      type: String,
      required: true,
      default: 'ro',
    }
  },
  data: function () {
    return {
      isInt: false,
      model: {},
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true
      },
      schema: {groups: []},
      vueFormGenbKey: 0,
      currentLang: 'ro',
      // locale: i18n.locale,
    }
  },
  watch: {
    locale: {
      handler: function (val) {
        if (this.isInt) {
          this.currentLang = val;
        }
      }
    },
    currentLang: {
      handler: function (val) {
        this.createDynamicModel(val);

      }
    }
  },
  created() {
    this.currentLang = this.locale;
    this.createDynamicModel(this.currentLang);
    this.isInt = true;
  },
  methods: {
    createDynamicModel(currentLang) {
      let model = {}, obj = {}, availableLang = ['en', 'ro'], locale;
      let fields = {...this.dynamicFields};
      for (let index in fields) {
        obj = {...fields[index]}; // clone obj and set it later with specific events on it
        if (typeof obj.model === "undefined") {
          continue;
        }
        model[obj.model] = null;
        switch (obj.type) {
          case 'input':
            if (obj.inputType === 'text' || obj.inputType === 'email') {
              model[obj.model] = '';
            } else if (obj.inputType === 'number') {
              model[obj.model] = (typeof obj.min != "undefined") ? obj.min : 0;
            } else if (obj.inputType === 'date') {
              obj.set = function (model, val) {
                model[this.model] = moment(val, 'DD.MM.YYYY').format('YYYY-MM-DD');
              };
            } else if (obj.inputType === 'datetime') {
              // obj.set = function (model, val) {
              //   // model[this.model] = moment(val, 'YYYY.MM.DDTHH:mm').format('YYYY-MM-DD hh:mm:ss');
              // };
            } else if (obj.inputType === 'file' || obj.inputType === 'image') {
              obj.styleClasses = ['col-12', 'col-xl-6', 'overflow-hidden', 'flx-form-group-file'];
            }
            break;
          case 'checklist':
            if (Array.isArray(obj.styleClasses)) {
              obj.styleClasses.push('flx-form-group-label');
            } else {
              obj.styleClasses = obj.styleClasses.split(' ').push('flx-form-group-label');
            }
            model[obj.model] = [];
            break;
          case 'BsSwitch':
            model[obj.model] = (typeof obj.default != "undefined") ? obj.default : false;
            break;
          case 'select':
            if (typeof obj.selectOptions === "undefined") {
              obj.selectOptions = {
                noneSelectedText: '-',
                hideNoneSelectedText: true
              }
            }
            if (typeof obj.multiSelect != "undefined" && obj.multiSelect) {
              model[obj.model] = [];
            }
            break;
          case'vueMultiSelect':
            if (Array.isArray(obj.styleClasses)) {
              obj.styleClasses.push('form-group-multiselect');
            } else {
              obj.styleClasses = obj.styleClasses.split(' ').push('form-group-multiselect');
            }
            model[obj.model] = [];
            break;
          case'textArea':
            model[obj.model] = '';
            break;
          default:
            model[obj.model] = '';
            break;
        }
        if (typeof obj.label != 'undefined') {
          // treat multilang
          if (typeof obj.label === 'object') {
            locale = currentLang;
            if (typeof obj.label[locale] !== 'undefined') {
              obj.label = obj.label[locale];
            } else {
              locale = 'ro';
              obj.label = (typeof obj.label[locale] === "undefined") ? obj.name : obj.label[locale];
            }
          }
          if (Array.isArray(obj.styleClasses)) {
            obj.styleClasses.push('flx-form-group-label');
          } else {
            obj.styleClasses = obj.styleClasses.split(' ').push('flx-form-group-label');
          }
        } else {
          obj.styleClasses = obj.styleClasses.split(' ').push('flx-form-group-label');
        }

        if (this.checkNotEmptyObj(this.requiredDynamicFields) && typeof this.requiredDynamicFields[obj.model] !== 'undefined') {
          if (Array.isArray(obj.styleClasses)) {
            obj.styleClasses.push('flx-form-group-required')
          } else {
            obj.styleClasses = obj.styleClasses.split(' ').push('flx-form-group-required');
          }
        } else if (Array.isArray(obj.styleClasses) && obj.styleClasses.includes('flx-form-group-required')) {
          let indexOfArr = obj.styleClasses.indexOf('flx-form-group-required');
          obj.styleClasses.splice(indexOfArr, 1);
        }
        fields[index] = obj;
      }
      if (!this.checkNotEmptyObj(this.dynamicFormModel)) {
        this._setParentModel(model);
      } else {
        this.vueFormGenbKey = this.vueFormGenbKey++;
      }
      this.schema.groups = [
        {
          styleClasses: 'row',
          fields: fields
        }
      ];
      this.vueFormGenbKey++;
    },
    _setParentModel(modelObj) {
      this.$emit('setDynamicFormModel', modelObj)
    }
  }
}
</script>

