export default {
  app_name: 'Multibeneficii',
  affiliation_channels: 'Canale afiliati',
  admin_dashboard: 'Tablou de control',
  client_dashboard: 'Compania mea',
  beneficiary_dashboard: 'Sumar',
  benefit_groups: 'Grupuri de beneficii',

  overview: 'Sumar',
  configuration: 'Configurare',
  categories: 'Categorii beneficii',
  benefits: 'Beneficii',
  flexible_benefits: 'Beneficii flexibile',
  fixed_benefits: 'Beneficii fixe',
  clients: 'Clienti',
  beneficiaries: 'Beneficiari',
  requests: 'Solicitari',
  new_requests: 'Solicitari noi',
  requests_history: 'Istoric solicitari',
  orders: 'Istoric comenzi',
  import: 'Import',
  import_history: 'Istoric import',
  offer_category: 'Categorii oferte',
  offers_administration: 'Adm - Oferte speciale',
  offers: 'Oferte speciale',
  auto_top_ups: 'Auto alimentari beneficii',
  partners: 'Parteneri',
  brands: 'Brand',
  reports: 'Rapoarte',
  terms: 'Termenii si conditiile',
  flexible_voucher_stocks: 'Stoc vouchere',
  gdpr: 'Politica privind protectia datelor cu caracter personal',
  telework: 'Beneficii telemunca',
  company_settings: 'Setari companie',
  benefit_administration: 'Adm - Beneficii',
  request_voucher_history: 'Istoric vouchere',
}
