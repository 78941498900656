export default {
  title: 'Brand',
  description: 'Administreaza brand',
  new: 'Brand nou',
  edit: 'Modifica',
  fields: {
    name: 'Nume',
    description_placeholder: 'Text descriere brand. Minim 50 de caractere. Maxim 1000 de caractere',
    website: 'Website',
    scope_availability: 'Disponibilitate',
  },
  modal: {
    confirm_save: 'Esti sigur ca vrei sa salvezi modificarile?',
    confirm_leave: 'Exista informatii modificate care nu au fost salvate. Confirmi parasirea paginii?',
    confirm_delete: 'Esti sigur ca vrei sa stergi brandul?',
    extra_delete: 'Brandul curent are {count_benefits} beneficii.'
  },
  tooltips: {
    description: '(min 50, max 1000)'
  },
  scope_availability: {
    benefit: 'Beneficii',
    offer: 'Oferte',
  },
}
