<template>
  <div class="container-bg-white row pt-3 pb-2 mt-3 mx-0">
    <div class="col-12">
      <h2 class="m-0 mb-2">{{ $t(titleKey)}}</h2>
    </div>
    <div class="col-12 position-relative">
      <div v-show="showButtons"
           class="arrow-container-start position-absolute ">
                    <span class="flx-arrow my-auto d-flex align-content-center"
                          @click="benefitsScroll(-image.width)">
                    <font-awesome-icon icon="angle-left" class="text-28"/>
                    </span>
      </div>
      <div class="flexben-benefits" :ref="'flx_benefits_container'">
        <div class="flexben-benefit-box-container m-2 d-flex flex-column"
             v-for="(benefit, index) in benefits"
             :key="index">
          <div class="flexben-benefit-box p-3">
            <div class="bg-img" :style="{
                backgroundImage: (benefit.image !== null ) ? 'url('+benefit.image_url+')' : null,
                backgroundColor: benefit.color                 }">

            </div>

          </div>
          <button
            class="btn btn-themed-o benefit-box-try-btn benefit-box-active text-uppercase"
            @click="actionClick(benefit.id)"
            :key="'unavail_btn_action'">
            {{ $t('flexben.beneficiary_pages.order_now')}}
          </button>
          <div class="mr-2 pt-2" v-if="benefit.crm_code==='up_dejun' && withToggleAuto">
            <div class="d-inline-flex align-items-center w-100">
                                  <span
                                    class="text-bold mr-1">{{$t('flexben.beneficiary_pages.benefits.auto_toggle')}}</span>
              <span id="tooltip_auto_topup"
                    class="d-inline-block flexben-tooltip-icon mr-2">i</span>

              <b-tooltip :target="'tooltip_auto_topup'" variant="flexben" placement="topleft">
                <div class="text-left"
                     v-html="$t('flexben.beneficiary_pages.benefits.tooltips.auto_topup',{benefit:benefit.name})"></div>
              </b-tooltip>

              <b-form-checkbox id="benefitDejunEnable" class="d-inline-block ml-auto"
                               switch
                               @change="openAutoTopUpModal()"
                               v-model="autoTopUp.toggle"></b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div v-show="showButtons"
           class="arrow-container-end position-absolute ">
                    <span class="flx-arrow my-auto d-flex align-content-center"
                          @click="benefitsScroll(image.width)">
                    <font-awesome-icon icon="angle-right" class="text-28"/>
                    </span>
      </div>
    </div>
    <div class="col-12 text-center">
      <a href="/beneficiary/multibeneficii/benefits"  class="btn btn-themed all-flex-benefit-btn">
        {{ $t('flexben.beneficiary_pages.benefits.all_benefits')}} </a>
    </div>
  </div>
</template>

<script>
import {BFormCheckbox, BTooltip} from "bootstrap-vue";

export default {
  name: "BenefitsHorizontalList",
  components: {
    'b-tooltip': BTooltip,
    "b-form-checkbox": BFormCheckbox,
  },
  props: {
    benefits: {
      type: Array,
      default: function () {
        return []
      },
      required: true
    },
    titleKey: {
      type: String,
      default: '',
      required: true
    },
    withToggleAuto: {
      type: Boolean,
      default: false
    },
    autoTopUp: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data: function () {
    return {
      showButtons: false,
      isMobile: (document.body.clientWidth < 1004),
      image: {
        width: 300
      }
    }
  },
  mounted() {
    this.showButtons = (this.benefits.length * this.image.width) > this.$refs['flx_benefits_container'].clientWidth;
  },
  methods: {
    benefitsScroll(pxCount) {
      let target = this.$refs['flx_benefits_container'];
      let containerWidth = target.clientWidth;
      let menuSize =  this.benefits.length * pxCount;
      let menuInvisibleSize = menuSize - containerWidth;
      let menuEndOffset = menuInvisibleSize - 50;
      let menuPosition = target.scrollLeft;

      let scrollWidth = this.isMobile ? 314 : containerWidth;

        if (pxCount < 0) {
          if (menuPosition === 0) {
            target.scrollBy(-menuEndOffset, 0);
          }else {
            target.scrollBy(-scrollWidth, 0);
          }
        } else {
          if (menuPosition >= menuEndOffset) {
            target.scrollLeft = 0;
          }else {
            target.scrollBy(scrollWidth, 0);
          }
        }
    },
    openAutoTopUpModal() {
      if (this.withToggleAuto) {
        this.$emit('openAutoTopUpModal');
      }
    },
    actionClick(benefitId) {
      this.$emit('actionClick', benefitId);
    }
  }
}
</script>
