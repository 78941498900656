import axios from "axios";

let beneficiarySvc = process.env.VUE_APP_CARD_USER_API_URL;
let beneficiaryRoot = process.env.VUE_APP_CARD_USER_ROOT;

axios.defaults.headers.common["api_key"] =
	process.env.VUE_APP_CARD_USER_API_KEY;

const beneficiaryAxiosInstance = axios.create({
	baseURL: beneficiarySvc,
});

export default {
	beneficiaryService: {
		getBeneficiaryData(url, token) {
			return beneficiaryAxiosInstance.get(url, {
				headers: {
					Authorization: "Bearer " + btoa(token),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			});
		},
		confirmBeneficaryData(url, beneficiaryData, token) {
			return beneficiaryAxiosInstance.put(url, beneficiaryData, {
				headers: {
					Authorization: "Bearer " + btoa(token),
					"Content-Type": "application/json",
				},
			});
		},
		deleteBeneficary(url, token) {
			return beneficiaryAxiosInstance.delete(url, {
				headers: {
					Authorization: "Bearer " + btoa(token),
					"Content-Type": "application/json",
				},
			});
		},
		resetBeneficiaryPassword(passwordData, token) {
			return beneficiaryAxiosInstance.put(
				beneficiaryRoot + "/v1/app_passwords/" + passwordData.password_id,
				passwordData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getTransactionsForAccount(accountId, token) {
			return beneficiaryAxiosInstance.get(
				beneficiaryRoot + "/v1/beneficiary_transactions/" + accountId,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		searchTransactionsForAccount(formData, token) {
			return beneficiaryAxiosInstance.get(
				beneficiaryRoot + "/v1/beneficiary_transactions/search" + formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		setStateOrReasonCard(formData, token) {
			return beneficiaryAxiosInstance.put(
				beneficiaryRoot + "/v1/cards/" + formData.id,
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		searchOffers(formData, token) {
			return beneficiaryAxiosInstance.get(
				beneficiaryRoot + "/v1/offers/search" + formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getMessages(formData, token) {
			return beneficiaryAxiosInstance.get(
				beneficiaryRoot + "/v2/app_messages" + formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		markMessageAsRead(message, token) {
			return beneficiaryAxiosInstance.put(
				beneficiaryRoot + "/v2/app_messages/" + message.id,
				message,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getCardsForBenficiariesOAUTH(benefId, token) {
			return beneficiaryAxiosInstance.get(
				beneficiaryRoot +
				"/v2/beneficiaries/" + benefId + "/acceptances",
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		putCardsForBenficiariesOAUTH(benefId, formData, token) {
			return beneficiaryAxiosInstance.put(
				beneficiaryRoot +
				"/v2/beneficiaries/" + benefId + "/acceptances",
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		getNotification(beneficiaryId, notificationId, formData, token) {
			return beneficiaryAxiosInstance.get(
				beneficiaryRoot + "/v2/beneficiaries/" + beneficiaryId + "/notifications/" + notificationId + formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		updateNotification(beneficiaryId, notification, token) {
			return beneficiaryAxiosInstance.put(
				beneficiaryRoot + "/v2/beneficiaries/" + beneficiaryId + "/notifications/" + notification.id,
				notification,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
	},
};
