<template>
  <ul class="text-danger list-unstyled mb-1">
    <li v-for="( errorField, index) in error_bag" :key="index">
      {{errorField}}
    </li>
  </ul>
</template>

<script>
export default {
  name: "FormErrorList",
  props: {
    errorBag: {
      type: Object,
      default: function () {
        return {};
      },
      required: true
    },
  },
  computed: {
    error_bag: function () {
      let errorBag = {...this.errorBag};
      let errorList = [];
      let indexKey, errorField, fullKey, errorFieldExtra = '';
      for (errorField in errorBag) {
        errorFieldExtra = '';
        if (typeof errorField === 'number' || errorField === 'no__field') {
          let message = this.$t('flexben.formErrorReasons.' + errorBag[errorField]).toLowerCase();
          let messageUCF = message[0].toUpperCase();
          let restOfMessage = message.slice(1);
          errorList.push(messageUCF + restOfMessage);
        } else if (typeof errorBag[errorField] === 'string') {
          if (errorBag[errorField].includes('|')) {
            const message = errorBag[errorField].split('|');
            if (errorField.includes('|')) {
              const fieldExplode = errorField.split('|');
              errorField = fieldExplode[0];
              errorFieldExtra = ' ' + fieldExplode[1];
            }
            errorList.push(this.$t('flexben.formVarFieldError', {
              field: this.$t('flexben.flexbenFormFields.' + errorField) + errorFieldExtra,
              reason: this.$t('flexben.formErrorReasons.' + message[1]),
              var: message[0]
            }))
          } else {
            if (errorField === 'miss_match_last_top_up_day') {
              errorList.push(this.$t('flexben.import.invalid_reasons.miss_match_last_top_up_day', {date: errorBag[errorField]}));
            } else {
              fullKey = errorField;
              if (errorField.includes('|')) {
                const fieldExplode = errorField.split('|');
                errorField = fieldExplode[0];
                errorFieldExtra = ' ' + fieldExplode[1];
              }
              errorList.push(this.$t('flexben.formFieldError', {
                field: this.$t('flexben.flexbenFormFields.' + errorField) + errorFieldExtra,
                reason: this.$t('flexben.formErrorReasons.' + errorBag[fullKey])
              }))
            }
          }
        } else {
          for (indexKey in errorBag[errorField]) {
            errorFieldExtra = '';
            fullKey = errorField;
            if (errorField.includes('|')) {
              const fieldExplode = errorField.split('|');
              errorField = fieldExplode[0];
              errorFieldExtra = ' ' + fieldExplode[1];
            }
            errorList.push(this.$t('flexben.formFieldError', {
              field: this.$t('flexben.flexbenFormFields.' + errorField) + errorFieldExtra,
              reason: this.$t('flexben.formErrorReasons.' + errorBag[fullKey][indexKey]).toLocaleLowerCase()
            }))
          }
        }
      }
      return errorList;
    }
  },
  methods: {
    getErrorField(string) {
      return string
    }
  }
}
</script>
