<template>
  <div class="col-12 col-md-6 col-xl-4 ml-auto text-right">
    <span class="mr-1">{{$t('flexben.beneficiary_pages.benefits.cam_tax')}}</span>
    <span id="tooltip_cam" class="d-inline-block flexben-tooltip-icon mr-2">i</span>
    <b-tooltip :target="'tooltip_cam'" variant="flexben" placement="topleft">
      <div class="text-left">
        {{$t('flexben.beneficiary_pages.benefits.tooltips.cam_tax')}}
      </div>
    </b-tooltip>
    <span class="text-danger"><strong>{{ valueCam.toFixed(2) }} Lei</strong></span>
  </div>
</template>

<script>
import {BTooltip} from "bootstrap-vue";

export default {
  name: "CamValueInfo",
  components: {
    'b-tooltip': BTooltip
  },
  props: {
    valueCam: {
      type: Number,
      required: true,
      default: 0
    },
  }
}
</script>

