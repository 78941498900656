<template>
  <div id="modal_popup_nps" class="modal-popup">
    <div class="modal-popup-content">
      <a @click="closeNps" class="close-button">
        <img src="@/assets/images/close-button.svg" />
      </a>
      <LinkFrame :documentContent="this.notification.content" />
      <!-- <div class="" v-if="this.notification" v-html ="this.notification.content"> </div> -->
      <!-- <a class="button-orange" @click="acceptTerms()">{{
        $t("acceptTerms")
      }}</a> -->
    </div>
  </div>
</template>

<script>
import LinkFrame from "@/components/beneficiary/LinkFrame.vue";
import Cookies from "js-cookie";
import httpServiceBeneficiary from "@/http/http-beneficiary.js";
import httpServiceUser from "@/http/http-user.js";

export default {
  name: "ModalPopupNPS",
  props: {
    id: {
      default: "modal_popup_nps",
      type: String,
    },
    notification: Object,
    type: String,
    url: String,
    userId: Number
  },
  methods: {
    closeNps() {
      if (this.type == 'nps_beneficiary') {
        httpServiceBeneficiary.beneficiaryService
          .updateNotification(this.userId, {id: this.notification.id, "action": "dismiss"}, Cookies.get("token"))
          .then(function (response) {
            // handle success
            return response.data;
          })
          .then(data => {
            // nu facem nimic
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        httpServiceUser.userService
          .updateNotification(this.userId, {id: this.notification.id, "action": "dismiss"}, Cookies.get("token"))
          .then(function (response) {
            // handle success
            return response.data;
          })
          .then(data => {
            // nu facem nimic
          })
          .catch(function (error) {
            console.log(error);
          });
      }
        this.showModalPopup('modal_popup_nps', false)
      // }
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
  },
  components: {
    LinkFrame,
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped >
.modal-popup-content {
  width: 550px !important;
  padding: 0px !important;
  max-width: 550px !important;
}
</style>
