<template>
  <div class="container-fluid">
    <div class="row container-gray py-3">
      <div :class="{
      'col-12 col-md-6':true,
      'col-xl-4':companyOptions.cam_budget_taxation,
      }">
        <span class="flx-type-badge mr-1 threshold-gym-info">{{ $t("flexben.benefit.sub_types."+ceBenefit.benefit.sub_type) }}</span>
        <span v-if="thresholds.gym_membership.status && thresholds.gym_membership.value>0" id="threshold-gym-info" class="d-inline-block flexben-tooltip-icon mr-2">i</span>
        <b-tooltip v-if="thresholds.gym_membership.status&& thresholds.gym_membership.value>0" :target="'threshold-gym-info'" variant="flexben" placement="topright">
          <div class="text-left">
            {{ $t('flexben.beneficiary_pages.benefits.tooltips.thresholds.' + thresholds.gym_membership.code, {value: thresholds.gym_membership.value}) }}
          </div>
        </b-tooltip>
      </div>
      <div :class="{
      'col-12 col-md-6':true,
      'col-xl-4':companyOptions.cam_budget_taxation,
      }">
        <div :class="companyOptions.cam_budget_taxation ? 'text-right text-xl-left' : 'text-right'">
         <span class="mr-1" v-html="(ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale] === null) ?
            $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
            : ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale]">
          </span>
          <span id="tooltip_up_dejun_deductibility" class="d-inline-block flexben-tooltip-icon mr-2">i</span>

          <b-tooltip :target="'tooltip_up_dejun_deductibility'" variant="flexben" placement="topleft">
            <div class="text-left">
              {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
            </div>
          </b-tooltip>
          <span class="text-danger"><strong>{{ salaryDeductibility }} Lei</strong></span>
        </div>
      </div>

      <cam-value-info v-if="companyOptions.cam_budget_taxation" :valueCam="valueCam"></cam-value-info>

      <div class="col-12 mt-2 text-danger text-bold text-center"
           v-if="(selectedPeriodIndex !== null && valueSubscription>remainingBudget)">
        {{ $t('flexben.beneficiary_pages.benefits.insufficient_budget') }}
      </div>
      <div class="col-12 text-danger text-center text-bold" v-if="lastDayToOrderExceeded">
        {{ $t('flexben.formErrorReasons.exceeded_last_topup_date_client') }}
      </div>

      <div class="col-12 mt-3">
        <div class="row">
          <div class="col-12 col-md-6 col-xl-4 form-group mb-md-0">
            <label for="periods" class="form-fields-required col-form-label pt-0">{{
              $t('flexben.beneficiary_pages.benefits.fields.subscription_period') }}</label>
            <select name="periods" id="periods" class="form-control mt-0"
                    :class="{'border-danger':(Object.keys(errorsBag).includes('selected_period_code'))}"
                    v-model="selectedPeriodIndex">
              <option :value="null" disabled>{{
                $t('flexben.beneficiary_pages.benefits.fields.subscription_chose_period') }}
              </option>
              <option v-for="(option, index) in  ceBenefit.benefit.dynamic_form.fields.added_period_fields"
                      :key="index" :value="index">
                {{ $t('flexben.benefit.subscription_periods.'+option.code) }}
              </option>
            </select>
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label for="periods" class="form-fields-required col-form-label pt-0">{{
              $t('flexben.beneficiary_pages.benefits.fields.subscription_value') }}</label>
            <input class="form-control"
                   type="number"
                   disabled
                   :value="valueSubscription"/>
          </div>

          <total-value-pay v-if="companyOptions.gross_budget_taxation || companyOptions.cam_budget_taxation"
                           :totalPayValue="totalPayValue"></total-value-pay>
        </div>
      </div>
    </div>
    <V1FormBuilder v-if="checkNotEmptyObj(dynamicFormFields)"
                   :dynamic-fields="dynamicFormFields"
                   :dynamicFormModel="dynamicFormModel"
                   :requiredDynamicFields="requiredDynamicFields"
                   :ref="'v1-form-builder'"
                   :locale="locale"
                   @setDynamicFormModel="setDynamicFormModel"/>
    <div class="row" v-if="thresholds.gym_membership.status">
      <div class="col-6 col-md-4">
        <label for="personal_yes" class="form-fields-required col-form-label">
          <input type="radio"
                 name="personal"
                 class="radio-custom cursor-pointer"
                 id="personal_yes"
                 :value="true"
                 v-model="personalUse"/>
          {{$t("flexben.beneficiary_pages.benefits.personal_use_yes")}}</label>
      </div>
      <div class="col-6 col-md-4">
        <label for="personal_no" class="form-fields-required col-form-label">
          <input type="radio"
                 name="personal"
                 class="radio-custom cursor-pointer"
                 id="personal_no"
                 :value="false"
                 v-model="personalUse"/>
          {{$t("flexben.beneficiary_pages.benefits.personal_use_no")}}</label>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 px-0 text-md-left">
        <button class="btn btn-themed-green px-2 mr-2 px-md-4 mb-2" @click="orderNow()"
                :disabled="(thresholds.gym_membership.status && personalUse == null) || (lastDayToOrderExceeded || selectedPeriodIndex === null || valueSubscription>remainingBudget ||
                ((companyOptions.gross_budget_taxation || companyOptions.cam_budget_taxation) && totalPayValue>remainingBudget) || (!valueSubscription))">
          {{ $t('flexben.beneficiary_pages.order_now') }}
        </button>
        <a v-if="ceBenefit.benefit.product_link!==null && ceBenefit.benefit.product_link.length>0"
           :href="ceBenefit.benefit.product_link" target="_blank"
           class="btn btn-themed-blue float-md-right px-2 px-md-4 mb-2"><span
          class="d-flex">
          <font-awesome-icon icon="external-link-alt" class="mr-1 contact-ico text-14"/>
          <span class="text-14">{{ $t('flexben.beneficiary_pages.benefits.btn.sign_contract') }}</span>
        </span></a>
      </div>
      <div v-if="ceBenefit.benefit.product_link!==null && ceBenefit.benefit.product_link.length>0"
           class="col-12 px-0 text-right">
        <span class="text-bold ">{{ $t('flexben.beneficiary_pages.benefits.sign_contract_info') }}</span>
        <span id="sign_contract" class="d-inline-block flexben-tooltip-icon ml-2">i</span>

        <b-tooltip :target="'sign_contract'" variant="flexben" placement="topleft">
          <div class="text-left">
            {{ $t('flexben.beneficiary_pages.benefits.tooltips.sign_contract') }}
          </div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import {BTooltip} from "bootstrap-vue";
import V1FormBuilder from "@/components/flexben/beneficiary_pages/benefits/partials/V1FormBuilder";
import TotalValuePay from "@/components/flexben/beneficiary_pages/benefits/partials/TotalValuePay";
import CamValueInfo from "@/components/flexben/beneficiary_pages/benefits/partials/CamValueInfo";
import moment from "moment";
import flexbenUtils from "@/plugins/flexben_utils";

export default {
  name: "SubscriptionForm",
  components: {
    'b-tooltip': BTooltip,
    V1FormBuilder,
    'total-value-pay': TotalValuePay,
    'cam-value-info': CamValueInfo
  },
  props: {
    ceBenefit: {
      type: Object,
      required: true
    },
    budgets: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },
    payload: {
      type: Object,
      required: true,
      default: function () {
        return {
          value: 0,
          benefit_id: null,
          child_benefit_id: null,
          event_id: null,
          auto_top_up: false,
          ticket_count: 0,
          ticket_value: 0,
          extra: {},
        };
      }
    },
    errorsBag: {
      type: [Object, Array],
      default: function () {
        return {};
      }
    },
    companyOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          cam_budget_taxation: false,
          gross_budget_taxation: false,
          legislated_gross_calculation: false,
          last_monthly_order_day: null,
          budget_telework_transfer_to_general: false,
          cash_out_last_order_day: null,
          non_legislated_net_taxes_calculate: false,
          banner_image_url: null,
        };
      }
    },
    remainingBudget: {
      type: Number,
      default: 0,
    }
  },
  data: function () {
    return {
      selectedPeriodIndex: null,
      periodObject: {value: 0, code: null},
      salaryDeductibility: 0,
      personalUse: null,
      totalPayValue: 0,
      valueCam: 0,
      dynamicFormFields: {},
      dynamicFormModel: {},
      requiredDynamicFields: {},
      isValidForm: true,
      valueSubscription: 0,
      lastDayToOrderExceeded: false,
      thresholds:{gym_membership: {status:false, value: 0, code: ''}}
    };
  },
  watch: {
    selectedPeriodIndex: {
      handler: function (index) {
        this.periodObject = this.ceBenefit.benefit.dynamic_form.fields.added_period_fields[index];
        this.valueSubscription = this.periodObject.value;
        this._setSalaryDeductibility();
      }
    },
    valueSubscription: {
      handler: function (index) {
        if (this.valueSubscription > this.remainingBudget) this.valueSubscription = this.remainingBudget;
        this._setSalaryDeductibility();
      }
    },
    personalUse: {
      handler: function (val) {
        this._setSalaryDeductibility();
      }
    }
  },

  created() {
    this.setThresholds();
    if (typeof this.ceBenefit.benefit.dynamic_form.fields.dynamic_fields !== "string") {
      this.dynamicFormFields = this.ceBenefit.benefit.dynamic_form.fields.dynamic_fields;
    }
    this.lastDayToOrderExceeded = this.checkIfLastDayToOrderExceeded();
  },
  methods: {
    orderNow() {
      if (this.selectedPeriodIndex !== null) {
        this._validateDynamicFields();
        if (this.isValidForm) {
          this._setPayload();
          this.$emit('orderNow');
        }
      }
    },
    setDynamicFormModel(modelObj) {
      this.dynamicFormModel = modelObj;
    },
    checkIfLastDayToOrderExceeded() {
      return this.companyOptions.last_monthly_order_day !== null && this.companyOptions.last_monthly_order_day < parseInt(moment().format('D'));
    },
    setThresholds(){
      if (this.ceBenefit.benefit.thresholds && Object.values(this.ceBenefit.benefit.thresholds).length) {
        if (this.ceBenefit.benefit.thresholds.gym_membership) {
          this.thresholds.gym_membership.value = this.ceBenefit.benefit.remaining_legal_limit;
          this.thresholds.gym_membership.status = true;
          this.thresholds.gym_membership.code = this.ceBenefit.benefit.thresholds.gym_membership.code;
        }
      }
    },
    _validateDynamicFields() {
      this.isValidForm = true;
      this.requiredDynamicFields = {};
      let requiredFields = {}, index, fieldObj;
      for (index in this.dynamicFormFields) {
        fieldObj = {...this.dynamicFormFields[index]};
        if (typeof fieldObj.required !== 'undefined' && fieldObj.required) {
          if (
            ((fieldObj.type === 'checklist' || fieldObj.type === 'vueMultiSelect') && this.dynamicFormModel[fieldObj.model].length === 0) ||
            this.dynamicFormModel[fieldObj.model] === null || this.dynamicFormModel[fieldObj.model].length === 0
          ) {
            requiredFields[fieldObj.model] = 'required';
          }
        }
      }

      if (this.checkNotEmptyObj(requiredFields)) {
        this.isValidForm = false;
        this.requiredDynamicFields = requiredFields;


        // wait for arguments to be passed to child
        this._toggleFieldsToComplete(true);
        setTimeout(function () {
          this._reRenderFormBuilder();
        }.bind(this), 100);
      } else {
        this.isValidForm = true;
      }
    },
    _toggleFieldsToComplete(on) {
      this.$emit('setErrorInAlertModal', [this.$t('flexben.beneficiary_pages.benefits.fields_to_complete')]);
      this.scrollToTop();
    },
    _reRenderFormBuilder() {
      this.$refs['v1-form-builder'].createDynamicModel();
    },
    _setPayload() {
      this.payload.value = this.valueSubscription;
      this.payload.ticket_count = 1;
      this.payload.ticket_value = this.valueSubscription;
      this.payload.benefit_id = this.ceBenefit.benefit_id;
      this.payload.extra = {
        selected_period: this.periodObject,
        dynamic_form: this.dynamicFormModel,
        personal_use: this.personalUse
      };
    },
    _setSalaryDeductibility() {
      if (this.selectedPeriodIndex !== null) {
        let value = parseFloat(this.valueSubscription);

        if (this.thresholds.gym_membership.status) {
          if (this.personalUse && this._calculateValueWithThreshold(value)) {
            return;
          }
        }

        let valueToTax = flexbenUtils.calculateGross(value, this.ceBenefit.benefit.global_variables, this.companyOptions.non_legislated_net_taxes_calculate);
        this.salaryDeductibility = (valueToTax - value).toFixed(2);

        if (this.companyOptions.non_legislated_net_taxes_calculate) {
          this.valueCam = value * this.ceBenefit.benefit.global_variables['cam'] / 100;
        } else {
          this.valueCam = valueToTax * this.ceBenefit.benefit.global_variables['cam'] / 100;
        }

        this._calculateTotalByCompanyOptions(value);
      } else {
        this.totalPayValue = 0;
        this.salaryDeductibility = 0;
      }
    },
    _calculateValueWithThreshold(value) {
      let thresholdRemainingValue = 0;
      let benefit = this.ceBenefit.benefit;

      if (Object.values(benefit.thresholds).length) {
        if (benefit.remaining_legal_limit > 0 && benefit.remaining_legal_limit < value) {
          thresholdRemainingValue = value - benefit.remaining_legal_limit;

          let valueToTax = flexbenUtils.calculateGross(thresholdRemainingValue, benefit.global_variables, this.companyOptions.non_legislated_net_taxes_calculate);
          this.salaryDeductibility = (valueToTax - thresholdRemainingValue).toFixed(2);
          this.valueCam = valueToTax * benefit.global_variables['cam'] / 100;

          this._calculateTotalByCompanyOptions(value);
          return true;

        } else if (benefit.remaining_legal_limit >= value) {
          this.salaryDeductibility = 0;
          this.valueCam = 0;
          this._calculateTotalByCompanyOptions(value);
          return true;
        }
      }
      return false;
    },
    _calculateTotalByCompanyOptions(value) {
      if (this.companyOptions.gross_budget_taxation) {
        value += parseFloat(this.salaryDeductibility);
      }
      if (this.companyOptions.cam_budget_taxation) {
        value += parseFloat(this.valueCam);
      }
      this.totalPayValue = value;
    },
    validateSubscription(event) {
      if (this.valueSubscription < 1) {
        this.valueSubscription = 1;
      } else if (this.valueSubscription == '') {
        this.totalValue = 1;
      }
    }
  }
}
</script>
