export default {
  you_have_new_top_up_request: "You have no requests | You have {count} new top-up requests pending your approval. | You have {count} new top-up requests pending your approval.",
  go_to_requests_page_to_validate_or_reject_them: "Go to requests page to validate or reject them.",
  manage_request: "Manage requests",
  what_would_you_like_to_do: "What would you like to do",
  configure_benefits: "CONFIGURE BENEFITS",
  manage_beneficiaries: "MANAGE BENEFICIARIES",
  import_files: "IMPORT FILES",
  manage_orders: "MANAGE ORDERS",
  active_beneficiaries: '<strong>Beneficiaries</strong> <br> with active accounts',
  total_budgets: '<strong>Total Budget</strong><br>allocated to beneficiaries this year',
  unspent_budgets: '<strong>Unspent Budget</strong> <br>on beneficiaries accounts',
  require_help: 'Do you need help?',
}
