<template>
  <div class="container">
    <div v-show="!initialLoading">
      <div class="row mb-5">
        <div class="col-12 col-xl-6 flexben-page-title">
          <h1 class="m-0">{{ $t('flexben.partner.edit') }}</h1>
        </div>

        <div class="col-12 col-xl-6 text-right">
          <button class="btn btn-themed px-4 "
                  @click="modalAct('save')">
            {{ $t('flexben.general.btn.save') }}
          </button>
        </div>
      </div>
      <div class="card ">
        <div class="card-body container">
          <div class="row mb-5">
            <div class="col-12 col-xl-4 d-flex justify-content-center align-items-start mb-3 mb-xl-0 mx-auto">
              <div class="image-container cursor-pointer d-flex align-items-center mx-auto"
                   @click="$refs.partner_image.click()">
                <div class="image-changed" v-if="imageChanged.image">{{$t("flexben.general.image_changed")}}</div>
                <img class="img-fluid cursor-pointer upload-image"
                     :class="{'img-border-danger':(Object.keys(errorsBag).includes('image'))}"
                     :src="(formData.image!==null) ? image_preview.image : formData.image_url"/>
                <div class="upload-image-middle-container my-auto">
                  <div class="upload-image-text">{{$t("flexben.general.upload_image")}}</div>
                </div>
                <input type="file" class="hide" ref="partner_image" accept=".png,.jpeg,.jpg,.svg,.jfif"
                       @change="setImageFile('partner_image','image', 'image')">
              </div>
            </div>

          </div>
          <div class="row">
            <div v-for="(obj, index) in formData.description" :key="'de_'+index"
                 class="col-8 mb-5 align-items-center mx-auto">
              <label :for="'description_'+index" class="col-form-label pt-0">{{
                $t("flexben.offers.fields.description")
                }} - {{obj.lang}}</label>
              <span :id="'description_tooltip_'+index"
                    class="d-inline-block flexben-tooltip-icon ml-2 text-bold">i</span>
              <b-tooltip :target="'description_tooltip_'+index" variant="flexben" placement="topleft">
                <div class="text-left">{{$t('flexben.partner.tooltips.description')}}</div>
              </b-tooltip>
              <p v-if="Object.keys(errorsBag).includes('description.'+obj.lang)" class="text-danger">
                {{errorsBag['description.'+obj.lang][0]}} </p>
              <v-editor :id="'description_'+index" rows="5"
                        class="form-control-textarea my-0 w-100"
                        :placeholder="$t('flexben.partner.fields.description_placeholder')"
                        v-model="obj.value"
                        :editorToolbar="editorToolbar"
                        :class="{'border-danger':(Object.keys(errorsBag).includes('description.'+obj.lang))}">
              </v-editor>
              <p> {{obj.value.length}} / 3000</p>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button class="btn btn-themed px-4 "
                @click="modalAct('save')">
          {{ $t('flexben.general.btn.save') }}
        </button>
      </div>
      <confirm-modal :openModal="modal.open"
                     :modalAction="modal.action"
                     :formSuccess="modal.formSuccess"
                     :endMessage="modal.endMessage"
                     :options="modal.options"
                     @closeModal="closeModal"
                     @confirmAction="confirmAction"/>
    </div>
  </div>
</template>

<script>
import ConfirmModal from "@/components/flexben/offers/modals/ConfirmModal";
import {VueEditor} from "vue2-editor";
import vueEditorSettings from "@/constants/vue_editor_settings";
import httpFlexben from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";
import {BTooltip} from "bootstrap-vue";

export default {
  name: "UpdateAffiliatePartner",
  components: {
    'confirm-modal': ConfirmModal,
    'v-editor': VueEditor,
    'b-tooltip': BTooltip
  },
  props: {
    affiliatePartnerId: {
      type: String,
      default: null,
      required: false
    },
  },
  data: () => {
    return {
      loading: false,
      initialLoading: true,
      loaderError: false,
      locale: i18n.locale,
      imageUrl: httpFlexben.globalVariablesService.baseImagePreview,
      errorsBag: {},
      editorToolbar: vueEditorSettings.editorToolbar,
      httpServicePartner: httpFlexben.affiliationChannels.partner,
      imageChanged: {
        image: false,
      },
      image_preview: {
        image: null,
      },
      formData: {
        image: null,
        image_url: httpFlexben.globalVariablesService.baseImagePreview,
        description: [],
      },
      modal: {
        action: '',
        subOperation: '',
        open: false,
        formSuccess: false,
        endMessage: '',
        options: {
          saveAs: '',
          statusChange: '',
        }
      },
    }
  },
  created() {
    let userRole = this.flxUserLevel(this.affiliatePartnerId).userLevel;
    if (userRole !== 'admin' && userRole !== 'affiliate_partner') {
      window.location.href = '/';
    } else {
      this._get();
    }
  },
  methods: {
    _get() {
      this.loading = true;

      this.httpServicePartner.get(this.affiliatePartnerId)
        .then((response) => {

          if (response.data.partner.image_url) {
            this.formData.image_url = response.data.partner.image_url;
          }

          let lang;
          for (let index in response.data.available_languages) {
            lang = response.data.available_languages[index];
            this.formData.description.push({
              lang: lang,
              value: response.data.partner.description[lang]
            });
          }


          if (this.initialLoading) {
            this.initialLoading = false;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    update() {
      this.loading = true;
      let payload = this._prepareFormData();

      this.httpServicePartner.update(this.affiliatePartnerId, payload).then((response) => {
        if (response.data) {
          this.loading = false;
          this.initialLoading = false;
          this.registered = true;
          this.errorsBag = {};
          this.modal.formSuccess = true;
          this.modal.action = 'end';
          this.modal.open = true;
        }
      }).catch((error) => {
        if (error.isAxiosError && error.response.status == 400) {
          this.errorsBag = error.response.data.error_fields;
          this.loading = false;
        } else {
          this.loading = true;
          this.loaderError = true;
        }
      });
    },
    setImageFile(refTarget, fileType, formKey) {
      this.errorsBag = {};
      if (fileType === 'image') {
        if (this.$refs[refTarget].accept.indexOf(this.$refs[refTarget].files[0].type.split('/')[1]) > 0) {
          let reader = new FileReader();
          reader.readAsDataURL(this.$refs[refTarget].files[0]);
          reader.onload = evt => {
            let img = new Image();
            img.onload = () => {
              if (img.width !== img.height) {
                this.errorsBag = {image: 'image_square'};
                this.scrollToTop();
              } else if (img.width !== 500 || img.height !== 500) {
                this.errorsBag = {image: 'image_500_500'};
                this.scrollToTop();
              } else {
                this.formData[formKey] = this.$refs[refTarget].files[0];
                this.imageChanged[formKey] = true;
                if (typeof this.formData[formKey] !== "undefined") {
                  this.image_preview[formKey] = URL.createObjectURL(this.formData[formKey]);
                }
              }
            }
            img.src = evt.target.result;
          }
        } else {
          this.errorsBag = {image: 'image_type_supported'}
        }
      } else if (fileType === 'file') {
        if (typeof this.$refs[refTarget].files[0] !== 'undefined') {
          delete this.errorsBag.code_value;

          if (this.$refs[refTarget].files[0].size < 10000000) {
            this.formData[formKey] = this.$refs[refTarget].files[0];
          } else {
            this.errorsBag[formKey] = 'exceeds_10_mb';
            this.scrollToTop();
          }
        }
      }
    },
    _prepareFormData() {
      let formData = new FormData();

      let description = {}, index = 0;
      index = 0;
      for (index in this.formData.description) {
        description[this.formData.description[index].lang] = this.formData.description[index].value;
      }
      formData.append('description', JSON.stringify(description));

      if (this.formData.image) {
        formData.append('image', this.formData.image);
      }

      return formData;
    },
    confirmAction(action) {
      if (action === 'form_submit') {
        this.loading = true;
        this.errorBag = {};
        this.modal.open = false;
        if (this.modal.action === 'form_submit') {
          if (this.modal.options.saveAs === 'save') {
            this.update();
          }
        }
      }

      if (action === 'end') {
        this.closeModal();
      }
    },
    closeModal() {
      this.modal.action = '';
      this.modal.options.statusChange = '';
      this.modal.options.saveAs = '';
      this.modal.open = false;
    },
    modalAct(action) {
      this.modal.action = 'form_submit';
      this.modal.subOperation = 'form_submit';
      this.modal.options.saveAs = action;

      this.modal.open = true;
    }
  }
}
</script>
