<template>
  <b-overlay :show="loading" rounded="sm">
    <b-container :aria-hidden="loading ? 'true' : null">
      <div class="row">
        <button
          class="btn btn-themed-subselect mx-md-1 col-12 col-md text-uppercase d-inline-flex align-items-center justify-content-center"
          :class="(subOptionSelected ==='monthly') && 'active'"
          @click="changeSubOption('monthly')"
          v-if="ceBenefit.benefit.company_config.configuration.attribution_permission.monthly">
          <img src="@/assets/images/flexben/benefit_tabs/icon-monthly-attribution.svg" alt="Top up"
               class="tabs-icon mr-1"/>
          {{ $t('flexben.beneficiary_pages.benefits.attribution_month') }}
        </button>
        <button
          class="btn btn-themed-subselect mx-md-1 mt-2 mt-md-0 col-12 col-md text-uppercase d-inline-flex align-items-center justify-content-center"
          :class="(subOptionSelected ==='occasionally') && 'active'"
          @click="changeSubOption('occasionally')"
          v-if="ceBenefit.benefit.company_config.configuration.attribution_permission.occasionally">
          <img src="@/assets/images/flexben/benefit_tabs/icon-company.svg" alt="Top up" class="tabs-icon mr-1"/>
          {{ $t('flexben.beneficiary_pages.benefits.attribution_occasion') }}
        </button>
      </div>
      <div class="row container-gray py-3 mt-3" v-show="(subOptionSelected === 'monthly')">
        <div class="col-12 text-bold">
          <span class="mr-1">{{ $t('flexben.beneficiary_pages.benefits.top_up_info') }}</span>
          <span id="top_up_information_culture_month"
                class="d-inline-block flexben-tooltip-icon mr-2">i</span>
          <b-tooltip :target="'top_up_information_culture_month'" variant="flexben" placement="topleft">
            <div class="text-left mb-1">
              {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_multiple_year') }}
            </div>
            <div class="text-left">
              {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_info_last_anual',{
              benefit: ceBenefit.benefit.name,
              date: ceBenefit.benefit.end_use_day_month.day + ' '+
              $t('flexben.general.months.'+ceBenefit.benefit.end_use_day_month.month)
              }) }}
            </div>
          </b-tooltip>
        </div>
        <div class="col-12 text-danger text-center text-bold" v-if="lastDayToOrderExceeded">
          {{ $t('flexben.formErrorReasons.exceeded_last_topup_date_client') }}
        </div>
        <div class="col-12 text-center text-bold text-danger mt-2"
             v-if="this.subOptionSelected === 'monthly' && this.ceBenefit.benefit.has_month_attributed"
             v-html="$t('flexben.beneficiary_pages.benefits.already_used',{benefit: ceBenefit.benefit.name})">
        </div>

        <div class="col-12 mt-2">
          {{ $t('flexben.beneficiary_pages.benefits.monthly_legislated') }}
          <strong> {{ ceBenefit.benefit.data.values.maximum_legal_value_per_month.value }} Lei</strong>
        </div>
      </div>

      <div class="row container-gray py-3 mt-3" v-show="(subOptionSelected === 'occasionally')">
        <div class="col-12 text-bold">
              <span class="mr-1">
                {{ $t('flexben.beneficiary_pages.benefits.top_up_info') }}
              </span>
          <span id="top_up_information_culture_occasion"
                class="d-inline-block flexben-tooltip-icon mr-2">i</span>
          <b-tooltip :target="'top_up_information_culture_occasion'" variant="flexben" placement="topleft">
            <div class="text-left mb-1">
              {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_unlimited_occasion') }}
            </div>
            <div class="text-left">
              {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_info_last_anual',{
              benefit: ceBenefit.benefit.name,
              date: ceBenefit.benefit.end_use_day_month.day + ' '+
              $t('flexben.general.months.'+ceBenefit.benefit.end_use_day_month.month)
              }) }}
            </div>
          </b-tooltip>
        </div>
        <div class="col-12 mt-2 d-inline-flex" v-if="ceBenefit.benefit.events.length > 0"
             :key="'company_occasions'">
          <div class="form-check mr-2 align-baseline d" v-for="(event, index) in ceBenefit.benefit.events"
               :key="index">
            <input type="radio" name="up_culture_events"
                   :id="'up_culture_event_'+index"
                   :value="index"
                   v-model="selectedEvent"
                   :disabled="(event.has_ordered || !event.is_available)"
                   class="form-check-input"
                   :class="{'cursor-pointer':(!event.has_ordered && event.is_available)}"
                   :key="'radio_'+index">
            <label :for="'up_culture_event_'+index"
                   class="form-fields-required form-check-label text-capitalize"
                   :class="{'cursor-pointer':(!event.has_ordered && event.is_available)}"
                   :key="'label_'+index">{{ event.name }}</label>
          </div>
        </div>
        <div class="col-12 mt-2 text-danger text-center text-bold" v-else :key="'company_occasions'">
          {{ $t('flexben.beneficiary_pages.benefits.no_occasions') }}
        </div>
      </div>

      <div class="row container-gray mt-3 py-3">
        <div class="col-12">
          <div class="bold float-left">
            <span class="mr-1">{{ $t('flexben.beneficiary_pages.benefits.top_up_value') }}</span>
            <span id="tooltip_up_culture_occasion"
                  class="d-inline-block flexben-tooltip-icon mr-2">i</span>

            <b-tooltip :target="'tooltip_up_culture_occasion'" variant="flexben" placement="topleft">
              <div class="text-left mb-1">
                {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_unlimited')}}
              </div>
              <div class="text-left">
                {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_taxation',{
                threshold: getTooltipMaxDeduct(),
                tax: ceBenefit.benefit.global_variables['impozit-venit'],
                cas: ceBenefit.benefit.global_variables['cas'],
                cass: ceBenefit.benefit.global_variables['cass']
                }) }}
              </div>
            </b-tooltip>
          </div>
          <div class="float-right" v-if="values.taxes.allTaxes>0">
            <span class="mr-1" v-html="(ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale] === null) ?
              $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
              : ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale]">
            </span>
            <span id="tooltip_up_culture_deductibility"
                  class="d-inline-block flexben-tooltip-icon mr-2">i</span>

            <b-tooltip :target="'tooltip_up_culture_deductibility'" variant="flexben" placement="topleft">
              <div class="text-left">
                {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
              </div>
            </b-tooltip>
            <span class="text-danger text-bold" v-html="`${values.taxes.allTaxes.toFixed(2)} lei`"></span>
          </div>
        </div>
        <div
          v-if="(!ceBenefit.benefit.company_config.configuration.attribution_permission.monthly && !ceBenefit.benefit.company_config.configuration.attribution_permission.occasionally)"
          class="col-12 text-danger text-bold text-center mt-2">
          {{$t('flexben.beneficiary_pages.benefits.client_no_access')}}
        </div>

        <div class="col-12 ">
          <div class="flexben-vue-slider-container">
            <vue-slider :class="'px-2'"
                        v-bind="sliderOptions"
                        v-model="sliderValue"
                        :disabled="(subOptionSelected===null ||
                          (subOptionSelected === 'monthly' && ceBenefit.benefit.has_month_attributed) ||
                          (subOptionSelected === 'occasionally'&& selectedEvent===null))"
                        :key="sliderKey"
                        :lazy="true">
            </vue-slider>
          </div>
        </div>
      </div>
      <total-value-pay v-if="companyOptions.gross_budget_taxation"
                       :totalPayValue="values.total"></total-value-pay>
      <div class="row mt-3">
        <div class="col-12 px-0 pr-3 text-center text-md-left">
          <div class="d-flex flex-column flex-lg-row align-items-center align-items-lg-start align-content-center">
            <button class="btn btn-themed-green px-2 mr-2 px-lg-4" @click="orderNow()"
                    :disabled="(lastDayToOrderExceeded || sliderValue === 0 ||
                        (subOptionSelected === 'monthly' && ceBenefit.benefit.has_month_attributed)||
                        !ceBenefit.benefit.company_config.is_active ||
                        !values.hasBudget ||
                        (companyOptions.gross_budget_taxation && totalPayValue>remainingBudget))">
              {{ $t('flexben.beneficiary_pages.order_now') }}
            </button>
            <a v-if="ceBenefit.benefit.product_link.length>0"
               class="text-themed px-2 mr-lg-2 mt-2 px-lg-4 text-uppercase text-15 "
               :href="ceBenefit.benefit.product_link" target="_blank">
              {{ $t('flexben.beneficiary_pages.benefits.btn.where_to_use_vouchers') }}
            </a>
          </div>
        </div>
      </div>
    </b-container>
  </b-overlay>
</template>

<script>
import {BContainer, BOverlay, BTooltip} from "bootstrap-vue";
import VueSlider from "vue-slider-component";
import TotalValuePay from "@/components/flexben/beneficiary_pages/benefits/partials/TotalValuePay";
import flexbenUtils from "@/plugins/flexben_utils";
import moment from "moment";

export default {
  name: "UpCultureForm",
  components: {
    'vue-slider': VueSlider,
    "b-tooltip": BTooltip,
    'total-value-pay': TotalValuePay,
    'b-overlay': BOverlay,
    'b-container': BContainer,
  },
  props: {
    ceBenefit: {
      type: Object,
      required: true
    },
    budgets: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      required: true,
      default() {
        return {
          taxes: {
            allTaxes: 0,
          },
          total: 0,
          hasBudget: true,
        };
      }
    },
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },
    sliderGeneralOptions: {
      type: Object,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
      default: function () {
        return {
          value: 0,
          benefit_id: this.benefitId,
          child_benefit_id: null,
          event_id: null,
          auto_top_up: false,
          ticket_count: 0,
          ticket_value: 0,
          extra: {},
        };
      }
    },
    companyOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          cam_budget_taxation: false,
          gross_budget_taxation: false,
          legislated_gross_calculation: false,
          last_monthly_order_day: null,
          budget_telework_transfer_to_general: false,
          cash_out_last_order_day: null,
          non_legislated_net_taxes_calculate: false,
          banner_image_url: null,
        };
      }
    },
    globalVariables: {
      type: Object,
      required: true,
      default: function () {
        return {
          cas: null,
          cass: null,
          cam_tax: null,
          'impozit-venit': null,
        };
      }
    },
    remainingBudget: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      sliderValue: 0,
      toggleAutoTopup: false,
      totalPayValue: 0,
      salaryDeductibility: 0,
      sliderOptions: {},
      subOptionSelected: null,
      formatTooltip: val => {
        return val + ' Lei'
      },
      selectedEvent: null,
      sliderKey: 0,
      maxLegalValue: 0,
      lastDayToOrderExceeded: false
    };
  },
  watch: {
    sliderValue: {
      handler: function (val) {
        const payload = {
          value: this.sliderValue,
          benefit_id: this.ceBenefit.benefit.company_config.benefit_id,
          child_benefit_id: null,
          event_id: null,
          auto_top_up: false,
          ticket_count: (this.sliderValue /
            this.ceBenefit.benefit.ticket_value).toFixed(0),
          ticket_value: this.ceBenefit.benefit.ticket_value,
          extra: {}
        };
        this.$emit("getTaxes", payload.benefit_id, payload);
      }
    },
    values: {
      handler() {
        if (!this.values.hasBudget) {
          this.$emit('makeToast', this.$t('rejectReason_balancenotvalid'));
        }
      }
    },
    selectedEvent: {
      handler: function (val) {
        this._setSliderOptions();
      }
    },
  },
  created() {
    if (this.ceBenefit.benefit.company_config.configuration.attribution_permission.monthly) {
      this.subOptionSelected = 'monthly';
    } else if (!this.ceBenefit.benefit.company_config.configuration.attribution_permission.monthly &&
      this.ceBenefit.benefit.company_config.configuration.attribution_permission.occasionally) {
      this.subOptionSelected = 'occasionally';
    }
    this.lastDayToOrderExceeded = this.checkIfLastDayToOrderExceeded();
  },
  mounted() {
    this._setSliderOptions();
  },
  methods: {
    orderNow() {
      if (this.subOptionSelected === 'occasionally' && this.event_id === null) {
        return;
      }
      this._setPayload();
      this.$emit('orderNow');
    },
    changeSubOption(value) {
      this.selectedEvent = null;
      this.subOptionSelected = value;
      this.salaryDeductibility = 0;
      this._setSliderOptions();
      this.sliderKey++;
    },
    getTooltipMaxDeduct() {
      if (this.subOptionSelected === 'monthly') {
        return this.ceBenefit.benefit.data.values.maximum_legal_value_per_month.value;
      } else if (this.subOptionSelected === 'occasionally') {
        return this.ceBenefit.benefit.data.values.maximum_legal_value_per_event.value;
      } else {
        return 0;
      }
    },
    checkIfLastDayToOrderExceeded() {
      return this.companyOptions.last_monthly_order_day !== null && this.companyOptions.last_monthly_order_day < parseInt(moment().format('D'));
    },
    _setSliderOptions() {
      let sliderGeneralOptions = {...this.sliderGeneralOptions};
      let marks = {};
      let minVal = 0;
      let maxVal = 0;
      let maxLegalVal = 0;

      if (this.subOptionSelected === 'monthly' && !this.ceBenefit.benefit.has_month_attributed) {
        maxVal = (this.budgets.available < this.ceBenefit.benefit.remainingOrderAmount) ? this.budgets.available : this.ceBenefit.benefit.remainingOrderAmount ;
        if (maxVal > this.ceBenefit.benefit.data.values.maximum_value_per_order_month.value) {
          maxVal = this.ceBenefit.benefit.data.values.maximum_value_per_order_month.value;
        }

        maxLegalVal = this.ceBenefit.benefit.data.values.maximum_legal_value_per_month.value;
      } else if (this.subOptionSelected === 'occasionally' && this.selectedEvent !== null) {
        maxVal = this.ceBenefit.benefit.events[this.selectedEvent].maximum_topup_limit;
        maxLegalVal = this.ceBenefit.benefit.data.values.maximum_legal_value_per_event.value;
        if (maxVal > this.budgets.available) {
          maxVal = this.budgets.available;
        }
      }
      this.maxLegalValue = maxLegalVal;
      if (maxLegalVal > 0 && minVal < maxLegalVal && maxLegalVal < maxVal) {
        marks[maxLegalVal] = {
          label: maxLegalVal + ' Lei',
          labelStyle: {
            top: -18 + 'px',
            color: '#C50E1F',
            background: '#fff',
            padding: '2px 12px',
            'border-radius': '12px'
          },
        }
        let markPoint = ((maxLegalVal - minVal) * 100 / (maxVal - minVal)).toFixed(1);

        sliderGeneralOptions.process = () => [
          [0, markPoint, {backgroundColor: '#1FC591'}],
          [markPoint, 100, {backgroundColor: '#C50E1F'}]
        ]
      }
      this.sliderValue = minVal;
      marks[minVal] = {
        label: minVal + ' Lei'
      };
      if (maxVal % this.ceBenefit.benefit.ticket_value > 0) {
        maxVal = maxVal - (maxVal % this.ceBenefit.benefit.ticket_value);
      }
      marks[maxVal] = {
        label: maxVal + ' Lei'
      };

      if ((this.subOptionSelected === 'occasionally' && this.selectedEvent === null) ||
        this.budgets.available === 0 ||
        this.subOptionSelected === 'monthly' && this.ceBenefit.benefit.has_month_attributed) {
        sliderGeneralOptions.disabled = true;
        sliderGeneralOptions.maxVal = 0;
        marks[maxVal] = {
          label: 0 + ' Lei'
        };
        if (minVal < maxLegalVal && maxLegalVal < maxVal && maxLegalVal && this.selectedEvent === null) {
          marks[this.ceBenefit.benefit.data.values.maximum_legal_value_per_month.value] = {
            label: this.ceBenefit.benefit.data.values.maximum_legal_value_per_month.value + ' Lei'
          };
        }
      } else {
        sliderGeneralOptions.disabled = false;
      }

      sliderGeneralOptions.stepStyle = {
        backgroundColor: 'transparent'
      }

      sliderGeneralOptions.marks = marks;
      sliderGeneralOptions.min = (this.budgets.available > 0) ? minVal : 0;
      sliderGeneralOptions.max = maxVal;
      sliderGeneralOptions.tooltipFormatter = this.formatTooltip;
      sliderGeneralOptions.interval = this.ceBenefit.benefit.ticket_value;
      this.sliderOptions = sliderGeneralOptions;
    },
    _setSalaryDeductibility() {
      let value = this.sliderValue;
      let withoutTax, tax;
      let grossVal = 0;
      if (this.companyOptions.legislated_gross_calculation) {
        grossVal = flexbenUtils.calculateGross(value, this.globalVariables);
      }

      if (value <= this.maxLegalValue) {
        this.sliderOptions.tooltipStyle.color = '#1FC591';
      } else {
        this.sliderOptions.tooltipStyle.color = '#C50E1F';
      }


      if (value > 0) {
        let maxToExceed = 0;
        if (this.subOptionSelected === 'monthly') {
          maxToExceed = this.ceBenefit.benefit.data.values.maximum_legal_value_per_month.value;
        } else if (this.subOptionSelected === 'occasionally' && this.selectedEvent !== null) {
          maxToExceed = this.ceBenefit.benefit.data.values.maximum_legal_value_per_event.value;
        }

        if (value <= maxToExceed) {
          let taxPercent = this.ceBenefit.benefit.global_variables['impozit-venit'] / 100;
          let totalDeductPercent = (this.ceBenefit.benefit.global_variables['cas'] + this.ceBenefit.benefit.global_variables['cass']) / 100;
          if (this.companyOptions.legislated_gross_calculation) {
            withoutTax = grossVal * totalDeductPercent; // CAS + CASS
            tax = (grossVal - withoutTax) * taxPercent; // Imp Ven
          } else {
            withoutTax = value * totalDeductPercent; // CAS + CASS
            tax = (value - withoutTax) * taxPercent; // Imp Ven
          }
          this.salaryDeductibility = (withoutTax + tax).toFixed(2);
        } else {
          // under max legal taxation
          let baseValue = maxToExceed;

          let taxPercent = this.ceBenefit.benefit.global_variables['impozit-venit'] / 100;
          let totalDeductPercent = (this.ceBenefit.benefit.global_variables['cas'] + this.ceBenefit.benefit.global_variables['cass']) / 100;
          if (this.companyOptions.legislated_gross_calculation) {
            withoutTax = baseValue * totalDeductPercent;
            tax = (baseValue - withoutTax) * taxPercent;
          } else {
            withoutTax = baseValue * totalDeductPercent;
            tax = (baseValue - withoutTax) * taxPercent;
          }

          //exceeding taxation
          let exceededValue = value - baseValue;
          let grossExceed = flexbenUtils.calculateGross(exceededValue, this.globalVariables);
          this.salaryDeductibility = (withoutTax + tax + grossExceed - exceededValue).toFixed(2);
        }
        this._calculateTotalByCompanyOptions(value);
      } else {
        this._calculateTotalByCompanyOptions(0);
        this.salaryDeductibility = 0;
      }
    },
    _setPayload() {
      let ticketCount = this.sliderValue / this.ceBenefit.benefit.ticket_value;

      this.payload.value = this.sliderValue;
      this.payload.benefit_id = this.ceBenefit.benefit_id;
      this.payload.event_id = (this.selectedEvent !== null) ? this.ceBenefit.benefit.events[this.selectedEvent].id : null;
      this.payload.ticket_count = ticketCount.toFixed(0);
      this.payload.ticket_value = this.ceBenefit.benefit.ticket_value;
      this.payload.extra = {};
    },
    _calculateTotalByCompanyOptions(sliderValue) {
      let value = parseFloat(sliderValue);
      if (this.companyOptions.gross_budget_taxation && value > 0) {
        value += parseFloat(this.salaryDeductibility);
      }
      this.totalPayValue = value;
    }
  }
}
</script>
