import axios from "axios";

let beneficiarySvc = process.env.VUE_APP_CARD_USER_API_URL;
let localSvc = process.env.VUE_APP_FLEXBEN_BASE;
let messagesSvc = process.env.VUE_APP_MESSAGES_API_URL;
let companySvc = process.env.VUE_APP_COMPANY_API_URL;
let companyRoot = process.env.VUE_APP_COMPANY_USER_ROOT;
let beneficiaryRoot = process.env.VUE_APP_CARD_USER_ROOT;

let companyLoginEndpoint = companyRoot + "/v1/tokens";
let beneficiaryLoginEndpoint = beneficiaryRoot + "/v1/tokens";

const beneficiaryAxiosInstance = axios.create({
	baseURL: beneficiarySvc,
});

const messagesAxiosInstance = axios.create({
	baseURL: messagesSvc,
});

const configAxiosInstance = axios.create({
	baseURL: "https://app.uponline.ro/cnf/",
});

const companyAxiosInstance = axios.create({
	baseURL: companySvc,
});

const loginAxiosInstance = axios.create({
  baseURL: localSvc,
});

const querystring = require("querystring");

export default {
	commonsService: {
		async getConfigFile(language, mode) {
			if (!sessionStorage.getItem(mode + "_jsonFile")) {
				await configAxiosInstance
					.get(language + "/" + mode + "_config.json", {
						mode: "cors",
						headers: {
							"Content-Type": "text/plain",
							Accept: "text/plain",
						},
					})
					.then((response) => {
						sessionStorage.setItem(
							mode + "_jsonFile",
							JSON.stringify(response.data)
						);
					})

					.catch((error) => {
						return error;
					});
			}
			axios.defaults.headers.common["api_key"] =
				process.env.VUE_APP_CARD_USER_API_KEY;
		},
		sendMail(formData) {
			return messagesAxiosInstance.post(
				"/message_services/v1/mail_messages",
				formData,
				{
					headers: {
						Authorization: "Basic " + btoa("acioc:acioc"),
						"Content-Type": "application/json;charset=UTF-8",
						api_key: "dOQpzn113eMDdBr36ngKVlbz",
					},
				}
			);
		},
		sendForm(formData) {
			return messagesAxiosInstance.post(
				"/message_services/v1/form_messages",
				formData,
				{
					headers: {
						"Content-Type": "application/json;charset=UTF-8",
						api_key: "dOQpzn113eMDdBr36ngKVlbz",
					},
				}
			);
		},
		oAuthLogin(formData, uuid) {
			let dataForm = {
				scope: formData.scope,
				grant_type: "password",
				username: formData.username,
				password: formData.password,
			};
			let headersReg = {
				Authorization: "Basic " + btoa(process.env.VUE_APP_AUTHENTICATION_BASIC),
				"Content-Type": "application/x-www-form-urlencoded",
				"X-Up-OAuth": formData.userOAuth,
				"X-Up-Device": uuid
			};
			if (process.env.VUE_APP_ENV !== 'development' ){
				return beneficiaryAxiosInstance.post(
					beneficiaryLoginEndpoint,
					querystring.stringify(dataForm),
					{
						headers: headersReg,
					}
				);
			} else {
				return loginAxiosInstance.post(
				beneficiaryLoginEndpoint,
				querystring.stringify(dataForm),
				{
					headers: headersReg,
				}
			);
		}
		},
	},
	beneficiaryService: {
		userLogin(formData, recaptcha, uuid) {
			let dataForm = {
				scope: "web",
				grant_type: "password",
				username: formData.username,
				password: formData.password,
			};
			let config = {};
			if (recaptcha !== null) {
				config = {
					headers: {
						Authorization:
							"Basic " + btoa(process.env.VUE_APP_AUTHENTICATION_BASIC),
						"Content-Type": "application/x-www-form-urlencoded",
						"X-Up-Captcha": recaptcha,
						"X-Up-Device": uuid
					},
				};
			} else {
				config = {
					headers: {
						Authorization:
							"Basic " + btoa(process.env.VUE_APP_AUTHENTICATION_BASIC),
						"Content-Type": "application/x-www-form-urlencoded",
					},
				};
				}
				if (process.env.VUE_APP_ENV !== 'development' ){
					return beneficiaryAxiosInstance.post(
						beneficiaryLoginEndpoint,
						querystring.stringify(dataForm),
						config
					);
				} else {
					return loginAxiosInstance.post(
						beneficiaryLoginEndpoint,
						querystring.stringify(dataForm),
						config
					);
				}
		},

		sendLoginOtp(otpForm, token){
			return beneficiaryAxiosInstance.post(
				beneficiaryRoot + "/v1/checks/otp",
				otpForm,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},

		requestTokenForRegistration(myScope) {
			let dataForm = {
				scope: myScope,
				grant_type: "client_credentials",
			};
			let headersReg = {
				Authorization:
					"Basic " + btoa(process.env.VUE_APP_AUTHENTICATION_BASIC),
				"Content-Type": "application/x-www-form-urlencoded",
			};
			if (process.env.VUE_APP_ENV !== 'development' ){
				return beneficiaryAxiosInstance.post(
					beneficiaryLoginEndpoint,
					querystring.stringify(dataForm),
					{
						headers: headersReg
					}
				);
			} else {
				return loginAxiosInstance.post(
					beneficiaryLoginEndpoint,
					querystring.stringify(dataForm),
					{
						headers: headersReg
					}
				);
			}
		},
		userCheckForRegistration(formData, token, recaptcha) {
			let dataForm = {
				pan: formData.cardNumber,
				code: formData.code,
				lang: localStorage.language,
			};
			return beneficiaryAxiosInstance.post(
				beneficiaryRoot + "/v2/app_logins",
				dataForm,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
						"X-Up-Captcha": recaptcha,
					},
				}
			);
		},
		userGetInvitationInfos(params, token) {
			return beneficiaryAxiosInstance.get(
				beneficiaryRoot + "/v1/app_invitations/search?" + params,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},

		userGetActivationInfos(params, token) {
			return beneficiaryAxiosInstance.get(
				beneficiaryRoot + "/v1/app_logins/search?" + params,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},

		confirmBeneficaryData(beneficiaryData, token) {
			return beneficiaryAxiosInstance.put(
				beneficiaryRoot + "/v1/app_logins/" + beneficiaryData.id,
				beneficiaryData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},

		confirmBeneficaryInvite(beneficiaryData, token) {
			return beneficiaryAxiosInstance.put(
				beneficiaryRoot + "/v1/app_invitations/" + beneficiaryData.id,
				beneficiaryData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},

		confirmBeneficaryDataV2(beneficiaryData, token) {
			return beneficiaryAxiosInstance.put(
				beneficiaryRoot + "/v2/app_logins/" + beneficiaryData.id,
				beneficiaryData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		userCheckForPasswordReset(formData, token) {
			return beneficiaryAxiosInstance.post(
				beneficiaryRoot + "/v2/app_passwords",
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		resetBeneficiaryPassword(passwordData, token) {
			return beneficiaryAxiosInstance.put(
				beneficiaryRoot + "/v3/app_passwords/",
				passwordData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		beneficiaryCheckForPasswordReset(formData, token) {
			return beneficiaryAxiosInstance.post(
				beneficiaryRoot + "/v3/app_passwords",
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
    validateOtp(otpData, token) {
      return beneficiaryAxiosInstance.put(
				beneficiaryRoot + "/v3/app_passwords/" ,
				otpData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		sendOtp(otpData, headers) {
			return beneficiaryAxiosInstance.post(
				beneficiaryRoot + "/v1/checks/otp" ,
				otpData,
				{
					headers: headers
				}
			);
    }
	},
	companyService: {
		userLogin(formData, recaptcha) {
			let dataForm = {
				scope: "webclient",
				grant_type: "password",
				username: formData.username,
				password: formData.password,
			};
			let config = {};
			if (recaptcha !== null) {
				config = {
					headers: {
						Authorization:
							"Basic " + btoa(process.env.VUE_APP_AUTHENTICATION_BASIC),
						"Content-Type": "application/x-www-form-urlencoded",
						"X-Up-Captcha": recaptcha,
					},
				};
			} else {
				config = {
					headers: {
						Authorization:
							"Basic " + btoa(process.env.VUE_APP_AUTHENTICATION_BASIC),
						"Content-Type": "application/x-www-form-urlencoded",
					},
				};
				}
				if (process.env.VUE_APP_ENV !== 'development' ){
					return companyAxiosInstance.post(
						companyLoginEndpoint + "?cid=" + dataForm.username,
						querystring.stringify(dataForm),
						config
					);
				} else {
					return loginAxiosInstance.post(
						companyLoginEndpoint + "?cid=" + dataForm.username,
						querystring.stringify(dataForm),
						config
					);
				}
		},
		sendLoginOtp(otpForm, token){
			console.log(otpForm);
			return beneficiaryAxiosInstance.post(
				companyRoot + "/v1/checks/otp",
				otpForm,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		requestTokenForRegistration(myScope) {
			let dataForm = {
				scope: myScope,
				grant_type: "client_credentials",
			};
			let headersReg = {
				Authorization:
					"Basic " + btoa(process.env.VUE_APP_AUTHENTICATION_BASIC),
				"Content-Type": "application/x-www-form-urlencoded",
			};
			if (process.env.VUE_APP_ENV !== 'development' ){
				return companyAxiosInstance.post(
					companyLoginEndpoint,
					querystring.stringify(dataForm),
					{
						headers: headersReg
					}
				);
			} else {
				return loginAxiosInstance.post(
					companyLoginEndpoint,
					querystring.stringify(dataForm),
					{
						headers: headersReg
					}
				);
			}
		},
		userCheckForPasswordReset(formData, token) {
			return companyAxiosInstance.post(
				companyRoot + "/v1/app_passwords",
				formData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		userResetCodeCheck(params, token) {
			return companyAxiosInstance.get(
				companyRoot + "/v1/app_passwords/search?" + params,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
		companyPasswordReset(passwordData, token) {
			return companyAxiosInstance.put(
				companyRoot + "/v1/app_passwords/" + passwordData.password_id,
				passwordData,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
		},
    getNomenclature(params, token) {
      return companyAxiosInstance.get(
				companyRoot + "/v1/items/search?" + params,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
    },
		getCompanyIBAN(companyId, type, token) {
      return companyAxiosInstance.get(
				companyRoot + "/v1/user_companies/" + companyId + "/ibans/search" + type,
				{
					headers: {
						Authorization: "Bearer " + btoa(token),
						"Content-Type": "application/json",
					},
				}
			);
    },
	},
};
