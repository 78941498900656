export default {
  title: "Import from files",
  select: "Select {type} import file",
select_update_beneficiaries: "Choose the import file for updating the beneficiaries.",
  descriptive_text: "Import beneficiaries, budgets and timesheets.",
  greetings_question: "What would you like to do?",
  no_file_uploaded: "Upload a file to continue.",
  go_back_action: "Back to actions",
  go_back_file_upload: "back to file upload",
  entries: "Entries",
  complete: {
    thank_you: 'Thank you!',
    fail: 'Failed!',
    fail_text: 'Import completion has failed! Please try again.',
    beneficiary: {
      text_1: 'Your file is now imported. <strong>{total}</strong> beneficiaries were added and <strong>{updated}</strong> updated.',
      text_1_no_update: 'Your file is now imported. <strong>{total}</strong> beneficiaries were added.',
    },
    budget: {
      text_1: 'Your file is now imported. <strong>{total}</strong> budget entries and <strong>{updated}</strong> updated',
      text_1_no_update: 'Your file is now imported. <strong>{total}</strong> budget entries.',
      text_2: 'Total budget amount <strong>{sum} Lei.</strong>',
    },
    time_sheet: {
      text_1: 'Your file is now imported. <strong>{total}</strong> timesheet entries were added and <strong>{updated}</strong> updated.',
      text_1_no_update: 'Your file is now imported. <strong>{total}</strong> timesheet entries were added.',
    },
    review_text_2: 'You can find the imported {type} entries for your company in the Beneficiaries section.',
    review_text_3: 'Also, to see all past imports, you can access the Import history section.',
  },
  types: {
    beneficiaries: "Beneficiary",
    beneficiary: "Beneficiary",
    time_sheets: "Timesheet",
    time_sheet: 'Timesheet',
    budgets: 'Budgets',
    budget: 'Budgets',
    telework_budgets: 'work from home budgets',
    ppp3: 'Private pension',
    ppp3_budgets: 'PPP3 budgets',
  },
  statuses: {
    fail: 'Failed',
    complete: 'Complete',
    pending: 'Pending',
    ready: 'Ready',
  },
  item_statuses: {
    new: "New",
    valid: 'Valid',
    invalid: "Invalid",
    duplicate: "Duplicate",
    update: "Update",
  },
  summary: {
    total_items: "Total import items:",
    total_valid: "Valid items:",
    total_invalid: "Invalid items:",
    total_selected: "Selected count:",
    total_new: "Number of new beneficiaries:",
    total_duplicate: "Number of duplicate entries:",
    total_update: "Number of update beneficiaries:",
    total_amount: "Total amount:",
    time_sheet_date: "Timesheet uploaded for:",
    notice_new_beneficiary: "Newly added beneficiaries will receive an invitation email to join the platform.",
    filter_entries: "Filter entries",
  },
  invalid_reasons: {
    empty_import: "Your import is empty, try a different file.",
    malformed_import_file: "The import file does not respect the template file or fields not well formatted.",
    invalid_year: "Invalid import year.",
    invalid_month: "Invalid import month.",
    miss_match_last_top_up_day: "Cannot load timesheet for selected month. Beneficiaries can still place requests until {date} for the current month",
    import_completion_failed: "Import could not be finished.",

  },
  table_headings: {
    first_name: "First name",
    last_name: "Last name",
    name_mix: "Last name+First name",
    name: "Beneficiary",
    cnp: "Personal identification number(CNP)",
    ppp3_budget: "PPP3 Budget",
    ppp3_expiration_date: "Data expirare PPP3",
    action: "Action",
    contribution_type: "Contribution type",
    personal_contribution_value: "Personal contribution value",
    company_contribution_value: "Company contribution value",
    contract_number: "Numar contract",
    benefit_name:"Nume beneficiu",
    insurance_policy_value:"Insurance policy value",
    invitation_email: "Invitation email",
    invitation_channel: "Invitation in platform using",
    email: "Email",
    benefit_group: "Benefit group",
    phone_number: "Phone number",
    employee_number: "Employee number",
    department: "Department",
    job_title: "Job title",
    cost_center: "Cost center",
    working_point: "Subsidiary",
    underage_children_nr: "Number of underage children",
    total_budget: "Available budget",
    budget: "Budget",
    expiration_date: "Expiration date",
    updated_at: "Last update",
    working_days: "Worked days",
    recorded_for: "Recorded for",
    status_entry: "Entry status",
    year: "Year",
    month: "Month",
    last_employment_date: "Deleted on",
    group: "Group",
    benefit_category_code: "Benefit category code",
    up_ro_address_id: 'Card delivery address code',
    address: 'Address',
    up_dejun_enable_auto_topup: 'Auto topup Up Dejun',
    up_dejun_ticket_value: 'Auto topup ticket value (lei) Up Dejun',
    up_dejun_ticket_count: 'Auto topup no of tickets Up Dejun',
    up_dejun_ticket_value_no_tips: 'Auto topup ticket value Up Dejun',
    income_tax_percent: 'Income tax percent',
    cas_percent: 'CAS percent',
    cass_percent: 'CASS percent',
    fixed_benefit_group: 'Fixed benefits group',
    fixed_benefit_group_code: 'Fixed benefits group',
    ppp3_deduction_limit: 'PPP3 Deduction Budget',
    ppp3_personal_deduction_limit: 'PPP3 Personal Deduction Budget',
    medical_insurance_deduction_limit: 'Medical Insurance Deduction Budget',
    thresholds: {
      gym_membership: 'Gym Membership Deduction Budget',
    }
  },
  buttons: {
    delete_button: "Delete {count} selected record(s)",
    chose_beneficiaries: "Import beneficiaries",
    chose_update_beneficiaries: "Update beneficiaries",
    chose_budgets: "Import budgets",
    chose_time_sheets: "Import timesheets",
    chose_telework_budgets: "Import telework budgets",
    chose_ppp3: "Import ppp3",
    upload_file: "Upload file",
    download_template: "Download template",
    next_step: "Next step",
    go_back_action: "Pas anterior",
    go_back_file_upload: "Back to import file",
    confirm_complete: "Complete confirm",
    go_to_beneficiary: "Go to beneficiaries",
    go_to_import_history: "Go to import history",
    new_import: "New import",
  },
}
