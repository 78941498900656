<template>
  <div class="row p-3 ben-benefits-filters">
    <div class="col-12 col-md-3 col-xl-2 d-flex align-items-center align-items-md-start">
      <img src="@/assets/images/filter.svg"/>
      <span class="ml-3 my-auto my-md-0">{{ $t("flexben.general.filter") }}</span>
      <button class="btn text-themed-dark ml-auto d-md-none border-themed-dark"
              @click="toggleFilters()">
        <font-awesome-icon icon="chevron-down" :class="collapsed?'btn-collapsed-off':'btn-collapsed-on'"/>
      </button>
    </div>
    <div class="col-12 col-md-9 col-xl-10 filters-items mt-2 pb-1" :class="{'filters-items-collapsed':collapsed}">
      <div class="row">
        <div class="col-12 col-md-3 form-group mb-1 mb-md-0">
          <label class="col-form-label pt-0 pb-1" for="partner">{{ $t("flexben.benefit.fields.partner") }}</label>
          <select id="partner" class="form-control w-100"
                  @change="changeFilterValues(true)"
                  v-model="filterValues.partner">
            <option :value="null">{{ $t('flexben.general.all') }}</option>
            <option v-for="(item, index) in filterOptions.partners"
                    :key="index"
                    :value="item.id">{{ item.name }}
            </option>
          </select>
        </div>
        <div v-if="filterOptions.legislated_types!==null"
             class="col-12 col-md-3 form-group mb-1 mb-md-0">
          <label class="col-form-label pt-0 pb-1" for="legislated_type">{{ $t("flexben.benefit.legislated_type")
            }}</label>
          <select id="legislated_type" class="form-control w-100"
                  @change="changeFilterValues(true)"
                  v-model="filterValues.legislated_type">
            <option :value="null">{{ $t('flexben.general.all') }}</option>
            <option v-for="(item, value) in filterOptions.legislated_types"
                    :key="value"
                    :value="value">{{$t("flexben.benefit.legislated_types." + item)}}
            </option>
          </select>
        </div>
        <div v-if="filterOptions.fiscal_treatments!==null"
             class="col-12 col-md-3 form-group mb-1 mb-md-0">
          <label class="col-form-label pt-0 pb-1" for="fiscal_treatment">{{ $t("flexben.benefit.fiscal_treatment")
            }}</label>
          <select id="fiscal_treatment" class="form-control w-100"
                  @change="changeFilterValues(false)"
                  v-model="filterValues.fiscal_treatment">
            <option :value="null">{{ $t('flexben.general.all') }}</option>
            <option v-for="(item, value) in filterOptions.fiscal_treatments"
                    :key="value"
                    :value="item"> {{ $t("flexben.benefit.deductibility." + item ) }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-3 form-group mb-1 mb-md-0">
          <label class="col-form-label pt-0 pb-1" for="select_location_availability">{{
            $t("flexben.benefit.available_locations") }}</label>
          <select id="select_location_availability" class="form-control w-100"
                  @change="changeFilterValues(false)"
                  v-model="filterValues.location_availability">
            <option v-for="(location) in filterOptions.location_availability" :key="location" :value="location">

              {{$t("flexben.benefit.location_availability." + location)}}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-3 form-group mb-0">
          <label class="col-form-label pt-0 pb-1" for="select_county">
            {{ $t("flexben.benefit.fields.county") }}</label>

          <select id="fiscal_treatment" class="form-control w-100"
                  @change="changeFilterValues(false)"
                  v-model="filterValues.county">
            <option :value="null">{{ $t('flexben.general.all') }}</option>
            <option v-for="(item, index) in filterOptions.counties"
                    :key="index"
                    :value="item.id"> {{ item.name }}
            </option>
          </select>
        </div>
        <div v-if="filterValues.category!=null && filterOptions.categories.values.length>0"
             class="col-12 col-md-3 form-group mb-1 mb-md-0">
          <label class="col-form-label pt-0 pb-1" for="category">{{ $t("flexben.general.category") }}</label>
          <select id="category" class="form-control w-100"
                  @change="changeFilterValues(true)"
                  v-model="filterValues.category">
            <option :value="null">{{ $t('flexben.general.all') }}</option>
            <option v-for="(item, index) in filterOptions.categories"
                    :key="index"
                    :value="item.id">{{ item.name[locale] }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlexibleBenefitsFilters",
  props: {
    filterValues: {
      type: Object,
      required: true,
      default: function () {
        return {
          category: null,
          search: null,
          partner: null,
          legislated_type: null,
          fiscal_treatment: null,
          sub_type: null,
          county: null,
          counties: null,
          location_availability: 0,
        };
      }
    },
    filterOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          categories: [],
          partners: [],
          legislated_types: [],
          fiscal_treatments: [],
          sub_types: [],
          counties: []
        };
      }
    },
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },
    budgetConsumptionType: {
      type: String,
      required: true,
      default: function () {
        return 'general';
      }
    }
  },
  data: function () {
    return {
      collapsed: false,
      location_availability: 0
    };
  },
  created() {
    this.collapsed = !(screen.availWidth > 767);
  },
  methods: {
    changeFilterValues(onlyCategory = false) {
      this.$emit('changeFilterValues', onlyCategory)
    },
    toggleFilters() {
      this.collapsed = !this.collapsed;
      if (window.pageYOffset < 500) {
        this.scrollToTop(500);
      }
    }
  }
}
</script>
<style lang="scss">
.ben-benefits-filters {
  .filters-items {
    -webkit-transition: max-height 500ms ease;
    -moz-transition: max-height 500ms ease;
    -ms-transition: max-height 500ms ease;
    -o-transition: max-height 500ms ease;
    transition: max-height 500ms ease;
    overflow: hidden;
    // "height: 0" not work with css transitions
    max-height: 400px;
  }

  .filters-items-collapsed {
    max-height: 0;
  }
}
</style>
