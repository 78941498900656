<template>
  <b-overlay :show="loading" rounded="sm">
    <b-container :aria-hidden="loading ? 'true' : null">
    <div class="row">
      <button
        class="btn btn-themed-subselect mx-md-1 mb-2 col-12 col-md text-uppercase d-inline-flex align-items-center justify-content-center"
        :class="{ active: benefitChildSelected === index }"
        v-for="(childBenefit, index) in ceBenefit.benefit.children"
        @click="changeChildSelected(index)"
        :key="index">
        <img src="@/assets/images/flexben/benefit_tabs/icon-legislated-events.svg"
             alt=""
             class="tabs-icon mr-1"
             v-if="childBenefit.crm_code === 'up_gift_legislated'"/>
        <img src="@/assets/images/flexben/benefit_tabs/icon-company.svg"
             alt=""
             class="tabs-icon mr-1"
             v-if="childBenefit.crm_code === 'up_gift_specific_events'"/>
        <img src="@/assets/images/flexben/benefit_tabs/icon-stim.svg" alt=""
             class="tabs-icon mr-1"
             v-if="childBenefit.crm_code === 'up_gift_s_t_i_m'"/>
        {{ childBenefit.crm_code === "up_gift_s_t_i_m" ? getStimCompanyName() : $t("flexben.benefit.crm_codes." +
        childBenefit.crm_code) }}
      </button>
    </div>
    <div class="row container-gray py-3 mb-3"
         v-for="(childBenefit, index) in ceBenefit.benefit.children"
         :key="index"
         v-show="benefitChildSelected === index">
      <div class="col-12 text-bold">
        <span class="mr-1">{{
          $t("flexben.beneficiary_pages.benefits.top_up_info")
        }}</span>
        <span :id="'top_up_information_gift_' + index"
              class="d-inline-block flexben-tooltip-icon mr-2">i</span>

        <b-tooltip :target="'top_up_information_gift_' + index"
                   variant="flexben"
                   placement="topleft">
          <div class="text-left mb-1"
               v-if="activeChildBenefit.crm_code !== 'up_gift_s_t_i_m'">
            {{ $t("flexben.beneficiary_pages.benefits.tooltips.top_up_one_event") }}
          </div>
        </b-tooltip>
      </div>
      <div class="col-12 text-danger text-center text-bold" v-if="lastDayToOrderExceeded">
        {{ $t('flexben.formErrorReasons.exceeded_last_topup_date_client') }}
      </div>
      <div class="col-12 text-center bold text-danger mt-2"
           v-if="activeChildConfig.is_active &&
            activeChildConfig.type === 'stim' &&
            !activeChildBenefit.is_available"
           v-html="
          $t('flexben.beneficiary_pages.benefits.already_used_month', {
            benefit: ceBenefit.benefit.name + ' ' + getStimCompanyName()
          })"></div>

      <div class="col-12 mt-2"
           v-if="activeChildConfig.is_active && activeChildConfig.type !== 'stim'"
           :key="'sub_tab_key'">
        <div v-if="childBenefit.events.length > 0" :key="'company_events'">
          <div class="form-check mr-2 align-baseline d-inline-block "
               v-for="(event, index) in childBenefit.events"
               :key="index">
            <input type="radio"
                   :id="'up_gift_event_' + event.id"
                   :value="index"
                   :disabled="!event.is_available || event.over_range || event.has_ordered"
                   v-model="selectedEvent"
                   class="form-check-input"
                   :class="{
                'cursor-pointer': !event.has_ordered && event.is_available
              }"
                   :key="'checkbox_gift_' + index"/>
            <label :for="'up_gift_event_' + event.id"
                   class="text-bold form-check-label text-capitalize"
                   :class="{
                'cursor-pointer': !event.has_ordered && event.is_available
              }"
                   :key="'label_gift_' + index">
              {{ activeChildConfig.type === "law_events" ? $t("flexben.general.legal_events." + event.name) : event.name
              }}</label>
          </div>
        </div>
        <div v-else :key="'company_events'">
          {{ $t("flexben.beneficiary_pages.benefits.no_events") }}
        </div>
      </div>
      <div class="col-12 mt-3"
           v-else-if="activeChildConfig.is_active && activeChildConfig.type === 'stim'"
           :key="'sub_tab_key'">
        {{ $t("flexben.beneficiary_pages.benefits.max_stim", { name: getStimCompanyName() }) }}
        <strong>{{ activeChildConfig.max_topup_value }} Lei</strong>
      </div>
      <div v-else class="col-12 mt-3 bold text-center" :key="'sub_tab_key'">
        {{ $t("flexben.beneficiary_pages.benefits.not_available") }}
      </div>
    </div>
    <div class="row container-gray py-3">
      <div class="col-12">
        <div class="bold float-left">
          <span class="mr-1">{{
            $t("flexben.beneficiary_pages.benefits.top_up_value")
          }}</span>
          <span id="tooltip_up_gift_top_up"
                class="d-inline-block flexben-tooltip-icon mr-2">i</span>
          <b-tooltip :target="'tooltip_up_gift_top_up'"
                     variant="flexben"
                     placement="topleft">
            <div class="text-left"
                 v-if="activeChildBenefit.crm_code === 'up_gift_legislated'"
                 v-html="$t('flexben.beneficiary_pages.benefits.tooltips.up_gift.legislated',
                 {max:activeChildBenefit.data.values.maximum_legal_value_per_event.value})"></div>
            <div class="text-left"
                 v-if="activeChildBenefit.crm_code === 'up_gift_specific_events'">
              {{ $t( "flexben.beneficiary_pages.benefits.tooltips.up_gift.specific",
              { max: activeChildBenefit.data.values.maximum_legal_value_per_event.value } ) }}
            </div>
            <div class="text-left mb-1"
                 v-if="activeChildBenefit.crm_code === 'up_gift_s_t_i_m'">
              {{ $t( "flexben.beneficiary_pages.benefits.tooltips.top_up_unlimited" ) }}
            </div>
            <div class="text-left"
                 v-if="activeChildBenefit.crm_code === 'up_gift_s_t_i_m'">
              {{ $t( "flexben.beneficiary_pages.benefits.tooltips.top_up_taxation_taxes", { tax:
              globalVariables["impozit-venit"],
              cas: globalVariables["cas"],
              cass: globalVariables["cass"]
              }) }}
            </div>
          </b-tooltip>
        </div>
        <div class="float-right">
          <span class="mr-1" v-html="(ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale] === null) ?
            $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
            : ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale]">
          </span>
          <span id="tooltip_up_gift_deductibility"
                class="d-inline-block flexben-tooltip-icon mr-2">i</span>

          <b-tooltip
            :target="'tooltip_up_gift_deductibility'"
            variant="flexben"
            placement="topleft">
            <div class="text-left">
              {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
            </div>
          </b-tooltip>
          <span class="text-danger text-bold" v-html="`${values.taxes.allTaxes.toFixed(2)} lei`"></span>
        </div>
      </div>
      <div v-if="ceBenefit.benefit.children.length===0"
           class="col-12 text-danger text-bold text-center mt-2">
        {{$t('flexben.beneficiary_pages.benefits.client_no_access')}}
      </div>
      <div class="col-12 ">
        <div class="flexben-vue-slider-container">
          <vue-slider
            v-if="configuredSlider"
            :class="'px-2'"
            v-bind="sliderOptions"
            v-model="sliderValue"
            :lazy="true"
            :key="sliderKey">
          </vue-slider>
        </div>
      </div>
      <total-value-pay v-if="companyOptions.gross_budget_taxation"
                       :totalPayValue="values.total"></total-value-pay>
    </div>
    <div class="row mt-3">
      <div class="col-12 px-0 text-center text-md-left">
        <div class="d-flex flex-column flex-lg-row align-items-center align-items-lg-start align-content-center">
          <button class="btn btn-themed-green px-2 mr-2 px-lg-4"
                  @click="orderNow()"
                  :disabled="lastDayToOrderExceeded || !values.hasBudget || loading || sliderValue === 0 || !activeChildConfig.is_active ||(companyOptions.gross_budget_taxation && totalPayValue>remainingBudget)">
            {{ $t("flexben.beneficiary_pages.order_now") }}
          </button>
          <a v-if="ceBenefit.benefit.product_link.length>0"
             class="text-themed px-2 mr-lg-2 mt-2 px-lg-4 text-uppercase text-15 "
             :href="ceBenefit.benefit.product_link" target="_blank">
            {{ $t('flexben.beneficiary_pages.benefits.btn.where_to_use_vouchers') }}
          </a>
        </div>
      </div>

    </div>
  </b-container>
  </b-overlay>
</template>
<script>
import {BContainer, BOverlay, BTooltip} from "bootstrap-vue";
import VueSlider from "vue-slider-component";
import TotalValuePay from "@/components/flexben/beneficiary_pages/benefits/partials/TotalValuePay";
import flexbenUtils from "@/plugins/flexben_utils";
import moment from "moment";

export default {
  name: "UpGiftForm",
  components: {
    "vue-slider": VueSlider,
    "b-tooltip": BTooltip,
    'total-value-pay': TotalValuePay,
    'b-container': BContainer,
    'b-overlay': BOverlay,
  },
  props: {
    ceBenefit: {
      type: Object,
      required: true
    },
    budgets: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      required: true,
      default() {
        return {
          taxes: {
            allTaxes: 0,
          },
          total: 0,
          hasBudget: true,
        };
      }
    },
    locale: {
      type: String,
      required: true,
      default: "ro"
    },
    sliderGeneralOptions: {
      type: Object,
      required: true
    },
    payload: {
      type: Object,
      required: true,
      default: function () {
        return {
          value: 0,
          benefit_id: this.benefitId,
          child_benefit_id: null,
          event_id: null,
          auto_top_up: false,
          ticket_count: 0,
          ticket_value: 0,
          extra: {}
        };
      }
    },
    companyOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          cam_budget_taxation: false,
          gross_budget_taxation: false,
          legislated_gross_calculation: false,
          last_monthly_order_day: null,
          budget_telework_transfer_to_general: false,
          cash_out_last_order_day: null,
          non_legislated_net_taxes_calculate: false,
          banner_image_url: null,
        };
      }
    },
    globalVariables: {
      type: Object,
      required: true,
      default: function () {
        return {
          cas: null,
          cass: null,
          cam_tax: null,
          'impozit-venit': null,
        };
      }
    },
    remainingBudget: {
      type: Number,
      default: 0,
    }
  },
  data: function () {
    return {
      sliderValue: 0,
      totalPayValue: 0,
      sliderOptions: {},
      configuredSlider: false,
      salaryDeductibility: 0.0,
      camValue: 0.0,
      benefitChildSelected: 0,
      formatTooltip: val => {
        return val + " Lei";
      },
      childrenConfigurations: {},
      activeChildBenefit: {crm_code: null, is_active: false},
      activeChildConfig: {
        is_active: false
      },
      selectedEvent: null,
      sliderKey: 0,
      lastDayToOrderExceeded: false,
    };
  },
  watch: {
    ceBenefit: {
      immediate: true,
      handler() {
        console.log(this);
      }
    },
    values: {
      handler() {
        if (!this.values.hasBudget) {
          this.$emit('makeToast', this.$t('rejectReason_balancenotvalid'));
        }
      }
    },
    sliderValue: {
      handler: function (val) {
        const payload = this._getPayload();

        this.$emit("getTaxes", payload.benefit_id, payload);
      }
    },
    benefitChildSelected: {
      handler: function (val) {
        this.salaryDeductibility = 0;
        this.selectedEvent = null;
        this.setActiveChildBenefit(val);
        this.sliderValue = 0;
      }
    },
    selectedEvent: {
      handler: function (val) {
        this.salaryDeductibility = 0;
        this._setSliderOptions();
        this.sliderValue = 0;
      }
    }
  },
  created() {
    this.lastDayToOrderExceeded = this.checkIfLastDayToOrderExceeded();
  },
  mounted() {
    this._setChildrenConfigs();
    this.setActiveChildBenefit(this.benefitChildSelected);
    this._setSliderOptions();
  },
  methods: {
    setActiveChildBenefit(index) {
      if (this.ceBenefit.benefit.children.length > 0) {
        this.activeChildBenefit = this.ceBenefit.benefit.children[index];
      }
      if (this.activeChildBenefit.crm_code === "up_gift_legislated") {
        this.activeChildConfig = this.childrenConfigurations.law_events;
      } else if (
        this.activeChildBenefit.crm_code === "up_gift_specific_events"
      ) {
        this.activeChildConfig = this.childrenConfigurations.company_events;
      } else if (this.activeChildBenefit.crm_code === "up_gift_s_t_i_m") {
        this.activeChildConfig = this.childrenConfigurations.stim;
      } else {
        this.activeChildConfig = {is_active: false};
      }

      this._setSliderOptions();
    },
    setActiveTab() {
      this.$emit("setActiveTab", this.ceBenefit.benefit.company_config.id);
    },
    orderNow() {
      this._setPayload();
      this.sliderValue = 0;
      this.salaryDeductibility = 0;
      this.$emit("orderNow");
    },
    changeChildSelected(index) {
      this.benefitChildSelected = index;
      this.sliderValue = 0;
      this.selectedEvent = null;
      this.sliderKey++;
    },
    getStimCompanyName() {
      let index,
        result = this.$t("flexben.benefit.crm_codes.up_gift_s_t_i_m");
      for (index in this.ceBenefit.benefit.company_config.configuration
        .children) {
        if (
          this.ceBenefit.benefit.company_config.configuration.children[
            index
            ].type.toLocaleLowerCase() === "stim"
        ) {
          let internal_benefit_name = this.ceBenefit.benefit.company_config
            .configuration.children[index].internal_benefit_name;
          if (
            internal_benefit_name !== null &&
            internal_benefit_name.length > 0
          ) {
            result = internal_benefit_name;
            break;
          }
        }
      }
      return result;
    },
    checkIfLastDayToOrderExceeded() {
      return this.companyOptions.last_monthly_order_day !== null && this.companyOptions.last_monthly_order_day < parseInt(moment().format('D'));
    },
    _setChildrenConfigs() {
      let index;
      let children = this.ceBenefit.benefit.company_config.configuration
        .children;
      for (index in children) {
        this.childrenConfigurations[children[index].type] = children[index];
      }
    },
    _setSliderOptions() {
      this.configuredSlider = false;
      this.salaryDeductibility = 0;
      this.sliderValue = 0;
      let sliderGeneralOptions = {...this.sliderGeneralOptions};
      let marks = {};
      let minVal = 0;
      let maxVal = 0;

      if (this.activeChildConfig.is_active) {
        if (this.activeChildConfig.type === "stim") {
          maxVal = this.activeChildConfig.max_topup_value;
        } else if (
          this.selectedEvent !== null &&
          typeof this.activeChildBenefit.events[this.selectedEvent] !==
          "undefined" &&
          this.activeChildBenefit.events[this.selectedEvent].is_available
        ) {
          maxVal = this.activeChildBenefit.events[this.selectedEvent]
            .maximum_topup_limit;
        } else {
          sliderGeneralOptions.disabled = true;
        }

        if (!sliderGeneralOptions.disabled && maxVal > this.budgets.available) {
          maxVal = this.budgets.available;
        }

        if (
          this.activeChildBenefit.global_variables["cas"] > 0 &&
          this.activeChildBenefit.global_variables["cass"] > 0 &&
          this.activeChildBenefit.global_variables["impozit-venit"] > 0
        ) {
          sliderGeneralOptions.processStyle = {
            backgroundColor: "#C50E1F"
          };
          sliderGeneralOptions.tooltipStyle.color = "#C50E1F";
        } else {
          sliderGeneralOptions.processStyle = {
            backgroundColor: "#1FC591"
          };
          sliderGeneralOptions.tooltipStyle.color = "#1FC591";
        }
      }

      if (maxVal % this.activeChildBenefit.ticket_value > 0) {
        maxVal = maxVal - (maxVal % this.activeChildBenefit.ticket_value);
      }

      marks[minVal] = {
        label: minVal + " Lei"
      };
      marks[maxVal] = {
        label: maxVal + " Lei"
      };
      sliderGeneralOptions.marks = marks;
      sliderGeneralOptions.min = parseInt(minVal);
      sliderGeneralOptions.max = parseInt(maxVal);
      sliderGeneralOptions.tooltipFormatter = this.formatTooltip;
      sliderGeneralOptions.interval = this.activeChildBenefit.ticket_value;
      if (
        (this.activeChildConfig.is_active &&
          this.activeChildConfig.type === "stim" &&
          !this.activeChildBenefit.is_available) ||
        maxVal === 0 ||
        this.budgets.available === 0 ||
        !this.activeChildConfig.is_active
      ) {
        sliderGeneralOptions.disabled = true;
      } else {
        sliderGeneralOptions.disabled = false;
      }
      this.sliderOptions = sliderGeneralOptions;
      this.configuredSlider = true;
    },
    _getPayload() {
      let ticketCount =
        this.sliderValue /
        this.ceBenefit.benefit.ticket_value;
      return {
        value: this.sliderValue,
        benefit_id: this.ceBenefit.benefit.company_config.benefit_id,
        child_benefit_id: this.activeChildBenefit.id,
        event_id:
          this.selectedEvent !== null
            ? this.activeChildBenefit.events[this.selectedEvent].id
            : null,
        auto_top_up: false,
        ticket_count: ticketCount.toFixed(0),
        ticket_value: this.ceBenefit.benefit.ticket_value,
        extra: {}
      };
    },
    _setPayload() {
      let ticketCount = this.sliderValue / this.ceBenefit.benefit.ticket_value;

      this.payload.value = this.sliderValue;
      this.payload.event_id =
        this.selectedEvent !== null
          ? this.activeChildBenefit.events[this.selectedEvent].id
          : null;
      this.payload.benefit_id = this.ceBenefit.benefit_id;
      this.payload.child_benefit_id = this.activeChildBenefit.id;
      this.payload.ticket_count = ticketCount.toFixed(0);
      this.payload.ticket_value = this.ceBenefit.benefit.ticket_value;
      this.payload.extra = {};
    },
    _calculateTotalByCompanyOptions(sliderValue) {
      let value = parseFloat(sliderValue);
      if (this.companyOptions.gross_budget_taxation && value > 0) {
        value += parseFloat(this.salaryDeductibility);
      }
      this.totalPayValue = value;
    }
  }
};
</script>
