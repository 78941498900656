import reports from './flexben/en/reports.js';
import offers from './flexben/en/offers';
import affiliate_channels from './flexben/en/affiliate_channels';
import categories from './flexben/en/categories';
import benefit from './flexben/en/benefit';
import clients from './flexben/en/clients';
import imports from './flexben/en/import';
import beneficiaries from './flexben/en/beneficiaries';
import order from './flexben/en/order';
import requests from './flexben/en/requests';
import beneficiary_pages from './flexben/en/beneficiary_pages';
import benefit_groups from './flexben/en/benefit_groups';
import partner from './flexben/en/partner';
import brand from './flexben/en/brand';
import voucher_stocks from './flexben/en/voucher_stocks';
import general from './flexben/en/general';
import form_fields from './flexben/en/form_fields';
import form_error from './flexben/en/form_error';
import menu from './flexben/en/menu';
import dashboard from './flexben/en/dashboard';
import import_history from './flexben/en/import_history';
import offer_category from './flexben/en/offer_category';
import fixed_benefits from './flexben/en/fixed_benefits';
import configuration from './flexben/en/configuration';
import up_ro_validation_messages from './flexben/en/up_ro_validation_messages';


export default {
  about: {
    text_1: 'Up Multibeneficii offers you the freedom to choose the type of benefits you want and for how long, based on the budget allocated by your employer.',
    text_2: 'You can choose from a wide range of benefits such as meal cards, gift cards and vouchers, cultural cards, holiday vouchers, medical packages, private pensions or gym memberships.',
  },
  admin_client_header: {
    id: 'ID',
    cui: 'CUI',
    iban: 'IBAN',
    address: 'Adresa'
  },
  affiliate_channels: affiliate_channels,
  benefit: benefit,
  beneficiaries: beneficiaries,
  beneficiary_pages: beneficiary_pages,
  benefit_groups: benefit_groups,
  configuration: configuration,
  categories: categories,
  clients: clients,
  dashboard: dashboard,
  formFieldError: "{field} - {reason}.",
  formVarFieldError: "{field} - {var} {reason}.",
  formErrorReasons: form_error,
  flexbenFormFields: form_fields,
  fixed_benefits: fixed_benefits,
  general: general,
  import: imports,
  import_history: import_history,
  managed: {
    multiple_companies: {
      title: "Manage companies",
      description: "Manage Multibeneficii settings for different companies",
    },
  },
  menu: menu,
  order: order,
  offer_category: offer_category,
  offers: offers,
  partner: partner,
  brand: brand,
  pagination: {
    title: "Showing items from {from} to {to} of {of}",
    first: "First",
    last: "Last",
  },
  progress_bar: {
    chose_import: "Chose import",
    chose_file: "Chose file",
    review: "Review",
    confirmation: "Confirmation",
    order_type: 'Order Type',
    benefit: 'Benefit',
    requests: 'Requests',
    order_placed: 'Order placed',
  },
  reports: reports,
  requests: requests,
  voucher_stocks: voucher_stocks,
  up_ro_validation_messages: up_ro_validation_messages
};
