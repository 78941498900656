export default {
  title: "| Orders history | Orders for {requestType}",
  description: '{count} pending orders',
  order_history: 'Order history',
  place_orders: 'New Orders',
  count: 'pending orders',
  filter_orders: 'Filter orders',
  client: 'Client',
  order_type: 'Order type',
  order_amount: 'Order amount',
  benefit: 'Benefit',
  date_between: 'Start date',
  and: 'End date',
  order_fragment: 'Order',
  place_on: 'placed on',
  orders: 'Orders',
  order_number: 'Order number',
  type: 'Type',
  types: {
    top_up: 'Card top-up',
    top_up_live: 'Card top-up - warranty',
    card_request: 'Card request',
    not_legislated: 'Partners',
  },
  benefits: 'Benefits',
  summary: 'Order summary',
  placed_at: 'Placed at',
  amount: 'Amount',
  total_requests_value: 'Total ordered benefits value',
  request_amount: 'Benefit value',
  status: 'Status',
  steps: {
    add_new_card_or_top_up_order: "Add new card or top-up order",
    greetings_question: 'What kind of order would you like to place?',
    add_new_top_up_order: "Add new top-up order",
    add_new_card_order: "Add new card order",
    please_select_the_requests_you_want_to_include_in_the_order: "Please select the requests you want to include in the order",
    please_review_the_selected_requests: 'Please review the selected requests',
    please_select_the_benefit_you_want_to_order: "Please select the benefit you want to order",
    department: "Department",
    job_title: "Job title",
    cost_center: 'Cost center',
    working_point: 'Subsidiaries',
    group: 'Group',
    beneficiary_status: 'Beneficiary status',
    beneficiary: 'Beneficiary',
    request_number: 'Request number',
    cnp: 'CNP',
    no_items_found: 'No item found',
    actions: 'Actions',
    number_of_selected_requests: 'Number of selected requests:',
    valid_entries: 'Valid entries:',
    invalid_entries: 'Invalid entries:',
    delivery_address: 'Delivery address',
    new_requests: "New requests",
    your_card_request_order_is_now_placed: 'Your order is now placed. <strong>{countPlaceOrders}</strong> new cards will be issued.<br /><br /> You can find the placed orders for your company in the Orders section',
    your_top_up_order_is_now_placed: 'Your order is now placed. <strong>{countPlaceOrders}</strong> new top-up requests will be processed.<br /><br />Total top-up amount: <strong>{totalAmount} Lei</strong><br /><br />You can find the placed orders for your company in the Orders section.',
    for_selected: 'For {count} selected record(s)',
    buttons: {
      card_request: 'Issue new cards',
      top_up: 'Top-up cards',
      reject_selected_order_request: 'Reject {countOrderRequest} selected request(s)',
      set_delivery_address_to: 'Set delivery address to',
      remove_selected_request_from_the_order: 'Remove {countOrderRequest} selected request(s) from the order',
      retry: 'Retry',
      place_order: 'Place order',
      go_to_requests: 'Go to Request',
      go_to_orders: 'Go to orders',
    }
  },
  modal: {
    header_title: 'Order {orderNumber} placed on {placedAt}',
    next_step: 'Next step',
    select_client_company_to_add_new_order: 'Select client company to add new order',
  },
  error: {
    beneficiary_does_not_have_card: 'Beneficiary does not have an active card for the selected benefit.',
    card_error_empty_list: "Order sent does not contain any ticket number greater than 0.",
    card_error_customer: "The code in excel is different from the customer code being ordered.",
    card_error_mandatory: "Field {field} for employee {name} is required.",
    card_error_already: "The card for {name} has already been issued.",
    card_error_already_deleted: "Employee {name} has been deleted, but has an active card. If you wish to reactivate, please contact UpRomania.",
    card_error_length: "The {field} field for employee {name} has too many characters.",
    card_error_duplicate: "The {field} field for employee {name} is duplicated.",
    card_error_invalid: "The field {field} for employee {name} has an invalid value.",
    card_error_load_month: "Employee voucher number {name} is invalid.",
    card_error_load_value: "Field {field} for employee {name} is required.",
    card_error_load_value_invalid: "The {field} face value for the {name} employee is not valid for the selected product.",
    card_error_load_value_min: "The nominal value for employee {name} cannot be less than {field} lei.",
    card_error_load_value_max: "The face value for employee {name} cannot be higher than {field} lei.",
    card_error_address_not_found: "Employee address code {name} is invalid.",
    card_error_not_validrenew: "The card for {name} is not eligible for renewal, so there is no need to cancel the automatic renewal.",
    card_error_not_ivr_mobile: "Beneficiary {name} does not have a valid phone number entered and therefore cannot be ordered.",
    card_error_not_validstatus: "The card status for {name} is not valid for the selected action.",
    card_error_not_valid_reissue: "The card for {name} is not valid for reissue. A new card order is required.",
    card_error_not_found: "There is no card for this CNP.",
    card_error_not_active: "The card for {name} is not yet active.",
    card_error_not_validtoday: "The card status for {name} is not valid for the selected action.",
    card_error_renew_invalid: "Card {name} is not valid for a renewed.",
    card_error_renew_notfound: "There is no card for this CNP.",
    card_error_already_repl: "There is still a request made today for {name}.",
    this_order_status_has_been_changed: 'Order status changed.',
  }
}
