import axios from "axios";
import i18n from "@/plugins/language/i18n";
import Cookies from "js-cookie";

const apiBase = process.env.VUE_APP_FLEXBEN_BASE;
const axiosInstance = axios.create(
  {
    baseURL: apiBase,
    headers: {
      "Content-Type": 'application/json;charset=utf-8',
      Lang: i18n.locale,
    }
  });


function getToken() {
  return "Bearer " + btoa(Cookies.get('token'));
}

export default {
  /**
   *
   * @param params = {
   *     c: null, // campaign
   *     cid: null, // company_id
   *     tid: null, // track_id
   *     a: null, // action
   *     l: null, // location
   *   }
   */
  trackData(params) {
    if (process.env.VUE_APP_ENABLE_FLX_TRACKING) {
      const token = btoa(Cookies.get('token'));
      // console.log("Bearer " + btoa(Cookies.get('token')));
      params.authorization_token = token;
      axiosInstance.post('/tracking', params, {
        headers: {Authorization: "Bearer " + token},
      });
    }
  }
}
