<template>
  <div class="mt-2 flexben-beneficiary-benefit-details"
       :class="{'text-center pt-5':typeof this.benefit.id=='undefined'}">
    <a class="btn text-themed go-back" @click="backToList()">{{ $t("flexben.beneficiary_pages.benefits.go_back") }}</a>
    <div class="" v-if="!initialLoading">
      <div class="container-bg-white mt-2" v-if="this.benefitConfig.hasChildren && this.benefitConfig.isPPP3">
        <div class="row w-100">
          <div class="col-md-6">
            <h4 class="m-3 text-center">{{benefitConfig.parentName}}</h4>
          </div>
          <div class="col-md-6">
            <select class="form-control m-3 border border-warning" name="ppp3_child" id="ppp3_child"
                    v-model="childSelect">
              <option :value="child_sub_type.id" v-for="child_sub_type in this.benefitConfig.children"
                      :key="child_sub_type.name">{{$t(child_sub_type.name)}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <BeneficiaryBenefitDetails
      v-if="isFinishToSetup"
      :hasChildren="this.benefitConfig.hasChildren"
      :beneficiaryId="beneficiaryId"
      :benefit="(!this.benefitConfig.hasChildren) ? benefit : this.benefitConfig.childrenSelected"
      :companyOptions="companyOptions"
      :isPPP3BenefitDetails="isPPP3BenefitDetails"
      :budgets="budgets"
      :locale="this.locale"
      :globalVariables="this.globalVariables"
      :similar_benefits="similar_benefits"
      :transport_products="transport_products"
      :active_transport_subscriptions="active_transport_subscriptions"
      :isActivePersonalContributionType="isActivePersonalContributionType"
      @backToList="backToList"
      @setNewBenefit="_setNewBenefit"
      @setBudgetsByChild="_setBudgetsByChild"
      @refreshChildSelected="_refreshChildSelected"/>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>

</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import BeneficiaryBenefitDetails from "@/components/flexben/beneficiary_pages/benefits/BeneficiaryBenefitDetails";
import ApiBenefit from "@/multiben-module/src/services/api/apiBenefit";


export default {
  name: "BeneficiaryBenefit",
  components: {
    BeneficiaryBenefitDetails
  },
  props: {
    beneficiaryId: {
      type: Number,
      required: true
    },
    benefitId: {
      type: Number,
      required: true
    },
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },
    budgets: {
      type: Object,
      required: true,
    },
    companyOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          cam_budget_taxation: false,
          gross_budget_taxation: false,
          legislated_gross_calculation: false,
          last_monthly_order_day: null,
          budget_telework_transfer_to_general: false,
          cash_out_last_order_day: null,
          non_legislated_net_taxes_calculate: false,
          banner_image_url: null,
        };
      }
    }
  },
  data: function () {
    return {
      benefit: {},
      benefitConfig: {
        hasChildren: false,
        children: {},
        childrenSelected: {},
        parentName: '',
        isPPP3: false,
        fastAccess: false
      },
      initialLoading: true,
      loading: true,
      loaderError: false,
      httpService: httpFlexbenService.beneficiary,
      errorsBag: {},
      alreadyTranslatedErrors: false,
      childSelect: null,
      similar_benefits: [],
      transport_products: [],
      active_transport_subscriptions: [],
      globalVariables: {
        cas: null,
        cass: null,
        cam_tax: null,
        'impozit-venit': null,
      },
      isFinishToSetup: false,
      isPPP3BenefitDetails: false,
      isActivePersonalContributionType: false,
    }
  },

  created() {
    this.loading = true;
    ApiBenefit.getBenefitDetails(this.beneficiaryId, this.benefitId)
      .then((response) => {
        this.setupDataResponse(response.data);
        this.isFinishToSetup = true;
      })
      .catch((error) => {
        this.isFinishToSetup = true;
        this.loading = true;
        if (error.response.status !== 401) {
          this.loaderError = true;
        }
      })
  },
  computed: {
    checkItemSelected() {
      if (!this.childSelect) {
        this.benefitConfig.childrenSelected = {benefit: null};
        return;
      }

      if (sessionStorage.getItem('flexbenFastTopUpChildBenefitId') !== null && !this.benefitConfig.fastAccess) {
        this.childSelect = sessionStorage.getItem('flexbenFastTopUpChildBenefitId');
        this.benefitConfig.fastAccess = true;
      }

      this.loading = true;

      this._refreshChildSelected();

      return true;
    }
  },

  watch: {
    checkItemSelected() {}
  },
  methods: {

    _refreshChildSelected() {
      this.loading = true;

      ApiBenefit.getBenefitDetailsPPP3(this.beneficiaryId, Number(this.childSelect))
        .then((response) => {

          if (response.data.similar_benefits) {
            this.similar_benefits = response.data.similar_benefits;
          }

          this.benefitConfig.childrenSelected = {benefit: response.data.details};
          this.isActivePersonalContributionType = response.data.is_active_personal_contribution_type;
          this.isPPP3BenefitDetails = true;
          sessionStorage.removeItem('flexbenFastTopUpChildBenefitId');
          this.loading = false;
        })
        .catch((error) => {
          this.loading = true;
          if (error.response.status !== 401) {
            this.loaderError = true;
          }
        })
    },

    _setNewBenefit(benefitId) {
      this.$emit('setNewBenefit', benefitId);
    },

    _setBudgetsByChild(budgets) {
      this.$emit('setBudgetsByChild', budgets)
    },

    backToList() {
      this.$emit('backToList');
    },

    setupDataResponse(data) {
      this.benefit = data.details;

      if (data.details.benefit.sub_type === 'ppp3') {
        this.benefitConfig.children = data.details.benefit.children;
        this.isPPP3BenefitDetails = true;
        this.benefitConfig.hasChildren = true;
        this.benefitConfig.isPPP3 = true;
        this.benefitConfig.parentName = data.details.benefit.name;
        this.childSelect = this.benefitConfig.children[0].id;
      }

      if ((data.details.benefit.children !== undefined) && !data.details.benefit.children.length) {
        this.isPPP3BenefitDetails = false;
        this.benefitConfig.children = {};
        this.benefitConfig.hasChildren = false;
      }

      if (data.transport_products) {
        this.transport_products = data.transport_products;
      }
      if (data.active_transport_subscriptions) {
        this.active_transport_subscriptions = data.active_transport_subscriptions;

      }
      if (data.similar_benefits) {
        this.similar_benefits = data.similar_benefits;
      }

      if (data.global_variables) {
        this.globalVariables = data.global_variables;
      }

      this.initialLoading = false;
      this.formContainerKey += 1;
      this.loading = false;
      this.loaderError = false;
    },

  }
}
</script>

<style lang="scss">
.flexben-beneficiary-benefit-details {
  .benefit-image {
    border-radius: 5px;
    margin: 0 auto;
    max-width: 300px;
    width: 100%;
  }

  .color-left-bar {
    border-left: 2px solid #F59100;
  }
}
</style>
<style lang="scss" src="@/assets/styles/flexben_beneficiary.scss"></style>
