import httpFlexbenService from "@/http/http-flexben";
import SubscriptionForm from "./partials/SubscriptionForm";
import VoucherForm from "./partials/VoucherForm";
import UpDejunForm from "./partials/UpDejunForm";
import UpVacationForm from "./partials/UpVacationForm";
import UpCultureForm from "./partials/UpCultureForm";
import UpGiftForm from "./partials/UpGiftForm";
import BenefitPartner from "./partials/BenefitPartner";
import FormErrorList from "@/components/fragments/FormErrorList";
import AlertModal from "@/components/flexben/beneficiary_pages/partials/AlertModal";
import ConfirmModal from "@/components/flexben/beneficiary_pages/partials/ConfirmModal";
import BenefitsHorizontalList from "../partials/BenefitsHorizontalList";
import RecurringNotLegislatedForm from "./partials/RecurringNotLegislatedForm";
import PPP3Form from "./partials/PPP3Forms";
import WorldClassForm from "@/components/flexben/beneficiary_pages/benefits/partials/WorldClassForm";
import PointsPagoForm from "@/components/flexben/beneficiary_pages/benefits/partials/PointsPagoForm";
import CashOutForm from "./partials/CashOutForm";
import TransportForm from "@/components/flexben/beneficiary_pages/benefits/partials/TransportForm";
import PPP3BenefitDetails from "./ppp3BenefitsDetails";
import PPP3FormNew from "./partials/PPP3FormsNew";

export default {
  name: "BeneficiaryBenefitDetails",
  components: {
    BenefitPartner,
    UpGiftForm,
    UpCultureForm,
    UpVacationForm,
    UpDejunForm,
    VoucherForm,
    CashOutForm,
    SubscriptionForm,
    WorldClassForm,
    TransportForm,
    PointsPagoForm,
    AlertModal,
    ConfirmModal,
    "form-error-list": FormErrorList,
    BenefitsHorizontalList,
    RecurringNotLegislatedForm,
    PPP3Form,
    PPP3FormNew
  },
  props: {
    isPPP3BenefitDetails: {
      type: Boolean,
      required: true
    },

    globalVariables: {
      type: Object,
      required: true
    },
    hasChildren: {
      type: Boolean
    },
    benefit: {
      type: Object,
      required: true
    },

    beneficiaryId: {
      type: Number,
      required: true
    },
    similar_benefits: {
      type: Array,
      required: true
    },
    transport_products: {
      type: Array,
      required: true
    },
    active_transport_subscriptions: {
      type: Array,
      required: true
    },
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },
    budgets: {
      type: Object,
      required: true,
    },
    isActivePersonalContributionType: {
      type: Boolean
    },
    companyOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          cam_budget_taxation: false,
          gross_budget_taxation: false,
          legislated_gross_calculation: false,
          last_monthly_order_day: null,
          budget_telework_transfer_to_general: false,
          cash_out_last_order_day: null,
          non_legislated_net_taxes_calculate: false,
          banner_image_url: null,
        };
      }
    }
  },
  data: function () {
    return {
      initialLoading: true,
      loading: false,
      loaderError: false,
      httpService: httpFlexbenService.beneficiary,
      errorsBag: {},
      alreadyTranslatedErrors: false,
      values: {
        taxes: {
          allTaxes: 0,
          cam: 0,
          cas: 0,
          cass: 0,
          exceededCas: 0,
          exceededCass: 0,
          exceededTax: 0,
          exceededValue: 0,
          tax: 0,
          ticketValue: 0,
          totalCas: 0,
          totalCass: 0,
          totalTax: 0,
          totalValue: 0,
          value: 0,
        },
        total: 0,
        hasBudget: true,
      },
      ceBenefit: {
        benefit: {},
      },
      ppp3CeBenefits: {
        ceBenefits: {},
        hasIds: false,
        partner: {
          name: null,
          id: null,
          data: null
        },
        locale: null,
        benefit: {
          image: null,
          name: null,
          color: null,
          description: null,
          advantages: null,
          rationale: null,
        }
      },

      ceBenefitAdditionalData: {
        hasChildren: false,
        children: {}
      },

      idToBind : null,
      isChildren : false,
      payload: {
        value: 0,
        benefit_id: this.benefitId,
        child_benefit_id: null,
        event_id: null,
        ticket_count: 0,
        ticket_value: 0,
        extra: {},
      },
      sliderGeneralOptions: {
        tooltip: 'always',
        tooltipStyle: {
          color: '#F59100',
          backgroundColor: 'transparent',
          after: 'none'
        },
        adsorb: true,
        interval: 1,
        height: '7px',
        dotSize: 25,
        min: 0,
        max: 0,
        dotStyle: {
          backgroundColor: '#F59100',
          width: '25px',
          height: '25px',
        },
        stepStyle: {
          backgroundColor: '#F59100',
        },
        processStyle: {
          backgroundColor: '#1FC591',
        },
      },
      formContainerKey: 0,
      actionSuccess: null,
      alertModal: {
        class: null,
        message: null,
        failed: false
      },
      fieldsToComplete: false
    };

  },

  computed: {
    benefitTypeComponent: function () {
      if (!this.isPPP3BenefitDetails && this.ceBenefit.benefit.is_legislated) {
        return {
          up_dejun: 'UpDejunForm',
          up_vacation: 'UpVacationForm',
          up_culture: 'UpCultureForm',
          up_gift: 'UpGiftForm',
        }[this.ceBenefit.benefit.crm_code];
      } else {
        if (!this.hasChildren) {
          return {
            voucher: 'VoucherForm',
            subscription_24_pay: 'TransportForm',
            subscription: 'SubscriptionForm',
            subscription_world_class: 'WorldClassForm',
            points_pago: 'PointsPagoForm',
            medical_insurance: 'RecurringNotLegislatedForm',
            cash_out: 'CashOutForm',
          }[this.ceBenefit.benefit.sub_type];
        } else {
          return {
            ppp3: 'PPP3FormNew',
          }['ppp3'];
        }
      }
    },
    setBenefit() {
      if (!this.hasChildren) {
        this.ceBenefit = this.benefit
      } else {
        this.ceBenefit = this.benefit.benefit
      }
    }
  },
  watch : {
    ceBenefit: {
      handler: function (val) {
        if (!val) {
          return;
        }

        if (!this.ceBenefit && !this.isPPP3BenefitDetails) {
          return;
        }

        console.log(this.isPPP3BenefitDetails)

        if (this.isPPP3BenefitDetails) {
          const ppp3BenefitsService = PPP3BenefitDetails.refreshAttributes();
          ppp3BenefitsService.setCeBenefits(this.ceBenefit);
          this.ppp3CeBenefits.hasIds = ppp3BenefitsService.hasId();
          this.ppp3CeBenefits.benefit.image = ppp3BenefitsService.getImage();
          this.ppp3CeBenefits.benefit.name = ppp3BenefitsService.getName();
          this.ppp3CeBenefits.benefit.color = ppp3BenefitsService.getColor();
          this.ppp3CeBenefits.benefit.description = ppp3BenefitsService.getDescription();
          this.ppp3CeBenefits.benefit.rationale = ppp3BenefitsService.getRationale();
          this.ppp3CeBenefits.partner = ppp3BenefitsService.getPartner();
        }
      }
    },

    setBenefit() {

    }
  },
  methods: {
    confirmOrderNow() {
      this.$refs['confirm-modal'].openModal();
    },
    orderNow() {
      this.loading = true;
      this.actionSuccess = false;
      this.alreadyTranslatedErrors = false;
      this.httpService.orderDirectly(this.beneficiaryId, this._prepareFormData())
        .then((response) => {
          this.ceBenefit = response.data.details;
          this.formContainerKey += 1;
          this.initialLoading = true;
          this.actionSuccess = 'order_now';
          this.loading = false;
          this._setBudgetsByChild(response.data.budgets);
          this.formContainerKey += 1;
          this.scrollToTop();
          this.showAlertModal();
        })
        .catch((error) => {
          this._handleOrderError(error);
        });
    },
    makeToast(message = '', variant = 'danger') {
      this.$bvToast.toast(message, {
        autoHideDelay: 3000,
        appendToast: false,
        toaster: 'b-toaster-bottom-center',
        noCloseButton: true,
        variant: variant,
        solid: true,
      })
    },
    getTaxes(benefitId, payload) {
      this.loading = true;
      this.httpService.benefitDetailsTaxes(benefitId, this.beneficiaryId, payload)
        .then((response) => {
          this.values = response.data;
        })
        .catch((error) => {
          this._handleOrderError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validate24PayOrder() {
      //TODO: use this on next mobile release
      this.loading = true;
      this.actionSuccess = false;
      this.alreadyTranslatedErrors = false;

      let validationPayload = {
        extra : {
          start_date: this.payload.extra.start_date,
          product_code: this.payload.extra.product_code,
          phone_number: this.payload.extra.phone_number
        }
      };

      this.httpService.validate24PayOrder(validationPayload)
        .then((response) => {
          this.loading = false;
          this.scrollToTop();
          this.showAlertModal();
          this.orderNow(false);
        })
        .catch((error) => {
          this._handleOrderError(error);
        });
    },
    goToSimilar(benefitId) {
      this.$emit('setNewBenefit', benefitId);
      this._resetPayload();
    },
    findOutMore(link) {
      if (link === null) {
        link = 'https://upromania.ro/'
      }
      window.open(link, '_blank');
    },

    clearActionSuccess() {
      this.actionSuccess = null;
      this.alertModal = {
        class: null,
        message: null,
        failed: false,
      };
    },
    toggleFieldsToComplete(on) {
      this.fieldsToComplete = on;
      this.scrollToTop();
    },
    _handleOrderError(error) {
      if (error.response.status >= 500 && error.response.status < 600) {
        this.loaderError = true;
      } else if (error.response.status === 400) {
        if (typeof (error.response.data.error_fields) !== 'undefined') {
          this.errorsBag = error.response.data.error_fields;
        } else {
          this.alertModal.message = error.response.data.error_message_description;
          this.alertModal.class = 'text-danger';
          this.alertModal.failed = true;

          this.loading = false;
          this.scrollToTop();
          this.$refs['alert-modal'].openModal();
          return;
        }

        this.loading = false;
        this.scrollToTop();
        this.showAlertModal(false);
      } else {
        this.loaderError = true;
      }
    },
    _resetPayload() {
      this.payload = {
        value: 0,
        benefit_id: this.benefitId,
        child_benefit_id: null,
        event_id: null,
        auto_top_up: false,
        ticket_count: 0,
        ticket_value: 0,
        extra: {},
      };
    },
    _prepareFormData() {
      let formData = new FormData();
      formData.append('value', this.payload.value);
      formData.append('benefit_id', this.payload.benefit_id);
      formData.append('child_benefit_id', this.payload.child_benefit_id);
      formData.append('event_id', this.payload.event_id);
      formData.append('ticket_count', this.payload.ticket_count);
      formData.append('ticket_value', this.payload.ticket_value);
      formData.append('sub_type', this.payload.sub_type);
      formData.append('lang', this.locale);

      // todo add special form preparation in case files need to be sent
      formData.append('extra', JSON.stringify(this.payload.extra));
      return formData;
    },
    _setBudgetsByChild(budgets) {
      this.$emit('setBudgetsByChild', budgets)
    },
    toggleAutoTopUp(ticketValue, ticketCount) {
      this.loading = true;
      let payload = {
        daily_ticket_value: ticketValue,
        auto_number_of_tickets: ticketCount,
        status: this.ceBenefit.enable_auto_topup
      };
      this.httpService.toggleAutoTopup(this.beneficiaryId, payload)
        .then(function (response) {
          this.requestStatus = 'success';
          this.requestMessage = this.$t('flexben.beneficiary_pages.benefits.success_toggle_auto_topup');
          this.ceBenefit.enable_auto_topup = response.data.enable_auto_topup;
          this.ceBenefit.ticket_value = ticketValue;
          this.loading = false;
        }.bind(this))
        .catch(function (error) {
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
            this._setAutoDejunTopUpData(this.benefits);
          } else {
            if (error.response.status === 400 && typeof error.response.data.error_fields !== 'undefined') {
              this.errorBag = error.response.data.error_fields;
              this._setAutoDejunTopUpData(this.benefits);
            }
            this.initialLoading = false;
            this.loading = false;
          }
        }.bind(this));
    },
    showAlertModal(isSuccess = true) {
      if (isSuccess) {
        this.alertModal.class = 'text-success';
        this.alertModal.message = this.$t('flexben.beneficiary_pages.benefits.action_message.' + this.actionSuccess);
        this.alertModal.failed = false;
      } else {
        this.alertModal.class = 'text-danger';
        this.alertModal.message = this._getErrorBag(this.errorsBag).join('<br>');
        this.alertModal.failed = true;
      }
      this.$refs['alert-modal'].openModal();
    },
    setErrorInAlertModal(errors) {
      this.errorsBag = errors;
      this.alreadyTranslatedErrors = true;
      this.showAlertModal(false);
    },
    _getErrorBag: function (errorBag) {
      if (this.alreadyTranslatedErrors) {
        return errorBag;
      }
      let errorList = [];
      let indexKey, errorField, fullKey, errorFieldExtra = '';
      for (errorField in errorBag) {
        errorFieldExtra = '';
        if (typeof errorField === 'number' || errorField === 'no__field') {
          let message = this.$t('flexben.formErrorReasons.' + errorBag[errorField]).toLowerCase();
          let messageUCF = message[0].toUpperCase();
          let restOfMessage = message.slice(1);
          errorList.push(messageUCF + restOfMessage);
        } else if (typeof errorBag[errorField] === 'string') {
          if (errorBag[errorField].includes('|')) {
            const message = errorBag[errorField].split('|');
            if (errorField.includes('|')) {
              const fieldExplode = errorField.split('|');
              errorField = fieldExplode[0];
              errorFieldExtra = ' ' + fieldExplode[1];
            }
            errorList.push(this.$t('flexben.formVarFieldError', {
              field: this.$t('flexben.flexbenFormFields.' + errorField) + errorFieldExtra,
              reason: this.$t('flexben.formErrorReasons.' + message[1]),
              var: message[0]
            }))
          } else {
            if (errorField === 'miss_match_last_top_up_day') {
              errorList.push(this.$t('flexben.import.invalid_reasons.miss_match_last_top_up_day', {date: errorBag[errorField]}));
            } else {
              fullKey = errorField;
              if (errorField.includes('|')) {
                const fieldExplode = errorField.split('|');
                errorField = fieldExplode[0];
                errorFieldExtra = ' ' + fieldExplode[1];
              }
              errorList.push(this.$t('flexben.formFieldError', {
                field: this.$t('flexben.flexbenFormFields.' + errorField) + errorFieldExtra,
                reason: this.$t('flexben.formErrorReasons.' + errorBag[fullKey])
              }))
            }
          }
        } else {
          for (indexKey in errorBag[errorField]) {
            errorFieldExtra = '';
            fullKey = errorField;
            if (errorField.includes('|')) {
              const fieldExplode = errorField.split('|');
              errorField = fieldExplode[0];
              errorFieldExtra = ' ' + fieldExplode[1];
            }
            errorList.push(this.$t('flexben.formFieldError', {
              field: this.$t('flexben.flexbenFormFields.' + errorField) + errorFieldExtra,
              reason: this.$t('flexben.formErrorReasons.' + errorBag[fullKey][indexKey]).toLocaleLowerCase()
            }))
          }
        }
      }
      return errorList;
    },
    calculateGrossValue(netVal) {
      let casPercent = parseFloat(this.globalVariables['cas']) / 100;
      let cassPercent = parseFloat(this.globalVariables['cass']) / 100;
      let impPercent = parseFloat(this.globalVariables['impozit-venit']) / 100;

      return netVal / ((1 - casPercent - cassPercent) - (1 - casPercent - cassPercent) * impPercent);
    },
    benefitsScroll(pxCount) {
      let target = this.$refs['flx_benefits_container'];
      let containerWidth = target.clientWidth;
      if (pxCount < 0) {
        if (this.sliderScroll > 0) {
          target.scrollBy(pxCount, 0);
        } else {
          target.scrollBy(containerWidth, 0);
          this.sliderScroll = containerWidth;
        }
      } else {
        if (containerWidth >= this.sliderScroll) {
          if (containerWidth - this.sliderScroll + pxCount < 50) {
            pxCount = containerWidth - this.sliderScroll + pxCount;
          }
          target.scrollBy(pxCount, 0);
        } else {
          target.scrollBy(containerWidth * -1, 0);
          this.sliderScroll = 0;
        }
      }
      this.sliderScroll += pxCount;
    },
    toggleAutoTopUpNotLegislated(payload) {
      this.loading = true;
      this.httpService.toggleAutoTopupNonLegislatedV1(this.beneficiaryId, payload)
        .then((response) => {
          this.ceBenefit.enable_auto_topup = response.data.enable_auto_topup;
          this.getBenefitDetails(payload.benefit_id)
        })
        .catch((error) => {
          if (error.response.status === 400) {
            if (typeof error.response.data.error_fields !== 'undefined') {
              this.errorsBag = error.response.data.error_fields;
              this.loading = false;
              this.scrollToTop();
              this.showAlertModal(false);
            }
            console.log(error.response.data)
            if (typeof error.response.data.failed_validation !== 'undefined') {
              this.errorsBag = error.response.data.failed_validation;
              this.loading = false;
              this.scrollToTop();
              this.showAlertModal(false);
            }

          } else {
            this.loaderError = true;
          }
        });
    },
    toggleAutoTopUpPPP3(payload) {
      this.loading = true;
      this.httpService.toggleAutoTopupNonLegislatedV2(this.beneficiaryId, payload)
        .then((response) => {
          this.$emit('refreshChildSelected', response.data.data.benefit_id);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            if (typeof error.response.data.error_fields !== 'undefined') {
              this.errorsBag = error.response.data.error_fields;
              this.loading = false;
              this.scrollToTop();
              this.showAlertModal(false);
            }
            console.log(error.response.data)
            if (typeof error.response.data.failed_validation !== 'undefined') {
              this.errorsBag = error.response.data.failed_validation;
              this.loading = false;
              this.scrollToTop();
              this.showAlertModal(false);
            }

          } else {
            this.loaderError = true;
          }
        });
    },
  }
}
