import httpFlexbenTracking from "@/http/http-flexben-tracking";

export default {
  getSupDecimals(value, withCurrency = true) {
    value = (value.toFixed(2) + "").split(".");
    let returnString = typeof value[1] !== 'undefined' ? ',' + value[1] : ',00';
    if (withCurrency) {
      returnString += ' Lei';
    }
    return returnString;
  },
  calculateGross(netVal, taxes, netTaxation = false) {
    netVal = parseFloat(netVal);
    if (netVal == 0) {
      return netVal;
    }
    let casPercent = parseFloat(taxes['cas']) / 100;
    let cassPercent = parseFloat(taxes['cass']) / 100;
    let impPercent = parseFloat(taxes['impozit-venit']) / 100;

    if (netTaxation) {
      return netVal + (netVal * (1 - ((1 - casPercent - cassPercent) - (1 - casPercent - cassPercent) * impPercent)));
    } else {
      return netVal / ((1 - casPercent - cassPercent) - (1 - casPercent - cassPercent) * impPercent);
    }
  },

  checkFlxModulePermission(modulePermissions, toCheck) {
    if (!toCheck) { // null || undefined
      return true;
    }

    if (typeof toCheck == 'string') {
      return modulePermissions.includes(toCheck);
    } else {
      // assuming array
      for (let code of toCheck) {
        if (modulePermissions.includes(code)) {
          return true;
        }
      }
      return false;
    }
  },
  trackData(data) {
    httpFlexbenTracking.trackData(data);
  }
}
