export default {
  you_have_new_top_up_request: "Nu ai solicitari noi | Ai {count} solicitari noi in asteptarea aprobarii. | Ai {count} solicitari noi in asteptarea aprobarii.",
  go_to_requests_page_to_validate_or_reject_them: "Acceseaza pagina de solicitari pentru a le valida sau respinge.",
  manage_request: "Gestioneaza solicitarile",
  what_would_you_like_to_do: "Ce ai vrea sa faci?",
  configure_benefits: "CONFIGUREAZA BENEFICII",
  manage_beneficiaries: "GESTIONEAZA BENEFICIARII",
  import_files: "IMPORT FISIERE",
  manage_orders: "GESTIONEAZA COMENZILE",
  active_beneficiaries: '<strong>Beneficiari</strong> <br> care au conturi active',
  total_budgets: '<strong>Buget total</strong><br>alocat beneficiarilor in anul curent',
  unspent_budgets: '<strong>Buget ramas necheltuit</strong> <br>in conturile beneficiarilor',
  require_help: 'Ai nevoie de ajutor?',
}
