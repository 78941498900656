import Vue from "vue";
import VueI18n from "vue-i18n";

import romanian from "@/plugins/language/ro";
import english from "@/plugins/language/en.js";

Vue.use(VueI18n);

const messages = {
	ro: romanian[0],
	en: english[0]
};

const i18n = new VueI18n({
	locale: "ro", // set locale
	fallbackLocale: "en", // set fallback locale
	messages // set locale messages
});

export default i18n;
