export default {
  title: "Fixed benefits",
  subtitle: "Manage fixed benefits",
  new: "New fixed benefit",
  edit: "Edit fixed benefit",
  list: "Fixed benefits list",
  modal: {
    confirm_save: 'Are you sure you want to save the changes?',
    confirm_leave: 'The information has been changed, are you sure you want to leave?',
    confirm_delete: 'Are you sure you want to delete the fixed benefit?',
  },
  table: {
    company: "Client",
    name: "Name",
    description: "Description",
  }
}
