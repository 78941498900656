export default {
  title: "Benefit groups",
  new: "New Group",
  edit: "Edit group",
  list: "Group list",
  legislated_benefits: "Legislated benefits",
  flexible_benefits: "Flexible benefits",
  benefits: "benefits",
  benefit_type_legislated: "legislated",
  benefit_type_flexible: "not legislated",
  no_benefits_available: "You have no {type} benefits allocated",
  detail: {
    ben_name: 'Benefit name',
    contribution: {
      client: 'Employer contribution',
      personal: 'Personal contribution'
    },
    partner_name: 'Partner',
    description: 'Description',
    allocation_control: 'Allocation control',
    allocated: 'Allocated',
    not_allocated: 'Not Allocated',
  },
  modal: {
    confirm_save: 'Are you sure you want to save the changes?',
    confirm_leave: 'The information has been changed, are you sure you want to leave?',
    confirm_delete: 'Are you sure you want to delete the group?',
    disclaimer_delete: 'Beneficiaries that have this group allocated will be allocated the default group. '
  },
  types: {
    all: 'All',
    default: 'Default',
    not_default: 'Custom',
  },
  benefit_types: {
    all: 'All',
    1: 'Flexible Benefits',
    2: 'Fixed Benefits',
  },
  fields: {
    title: "Benefit groups",
    company: "Client",
    group: "Group",
    code: "Group Code",
    benefits: 'Benefits',
    type: 'Group type',
    benefit_type: "Benefit types",
  },
  btn: {
    add_all: 'Select all',
    remove_all: 'Deselect all',
  }
}
