export default {
  new: 'New Category',
  title: "Benefit categories",
  description: "Group benefits into categories",
  no_category_message: "",
  add: "Add a new category",
  save: "Save",
  cancel_changes: "Your current changes will be lost. Are you sure you want to continue?",
  delete: "Delete category",
  edit: "Edit category",
  cat_name: "Category name",
  option_default: "Assign benefit",
  filters: {
    client_scoped: 'Specific client',
    general: 'General',
    company: 'Client',
    type: 'Category type'
  },
  benefits: "Benefits",
  table: {
    title: "Categories",
    new_category: "New category",
    description: "Description",
    benefits: "Benefits",
    option_default: "Chose a benefit to assign",
    assign: "Assign",
    benefit: "Benefit",
    date_created: "Date created",
    date_categories: "Categories",
    date_status: "Status",
  },
  modal: {
    confirmation: "Confirmation",
    this_will_delete_the_selected_item: "This will delete the selected item.",
    this_will_save_the_selected_item: "This will save the selected item.",
    please_confirm_this_action: "Please confirm this action.",
    continue: "Continue",
    cancel: "Cancel",
    success_message_create: "<div class=\"container\"><div class=\"col-12 mt-2 mb-5\"><div class=\"text-center\"><h3>Success</h3></div></div><div class=\"col-12 my-2 text-center\">Category saved successfully.</div></div>",
    success_message_edit: "<div class=\"container\"><div class=\"col-12 mt-2 mb-5\"><div class=\"text-center\"><h3>Success</h3></div></div><div class=\"col-12 my-2 text-center\">Category edit successfully.</div></div>",
  },
}
