import BudgetTotalsCards from "@/components/flexben/beneficiary_pages/partials/BudgetTotalsCards";
import FlexibleBenefitsFilters from "@/components/flexben/beneficiary_pages/benefits/partials/FlexibleBenefitsFilters";
import httpFlexbenService from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";
import FlexibleBenefitsListing from "./partials/FlexibleBenefitsListing";
import BeneficiaryBenefit from "@/components/flexben/beneficiary_pages/benefits/BeneficiaryBenefit";
import ScrollToTopBtn from "@/components/fragments/ScrollToTopBtn";
import FlexibleCategoriesListing from "@/components/flexben/beneficiary_pages/benefits/partials/FlexibleCategoriesListing";
import FlexibleCountriesListing from "@/components/flexben/beneficiary_pages/benefits/partials/FlexibleCountriesListing";
import FlexibleBrandsListing from "@/components/flexben/beneficiary_pages/benefits/partials/FlexibleBrandsListing";
import ApiBenefit from "@/multiben-module/src/services/api/apiBenefit";

export default {
  name: 'BeneficiaryBenefitList',
  components: {
    ScrollToTopBtn,
    BeneficiaryBenefit,
    FlexibleBenefitsListing,
    FlexibleCategoriesListing,
    FlexibleCountriesListing,
    FlexibleBrandsListing,
    BudgetTotalsCards,
    FlexibleBenefitsFilters
  },
  props: {
    budgetConsumptionType: {
      type: String,
      required: true,
      default: function () {
        return 'general';
      }
    }
  },
  data: function () {
    return {
      initialLoading: true,
      loading: true,
      loaderError: false,
      httpService: httpFlexbenService.beneficiary,

      searchString: '',
      params: {
        category: null,
        search: null,
        partner: null,
        legislated_type: null,
        fiscal_treatment: null,
        location_availability: 0,
        sub_type: null,
        page: 1,
        perPage: 20,
        county: null,
        country: null,
        main_country: null,
        brand: null,
      },
      filterOptions: {
        categories: [],
        partners: [],
        legislated_types: [],
        fiscal_treatments: [],
        sub_types: [],
        counties: [],
        countries: [],
        main_countries: [],
        brands: [],
      },
      appliedFilters: [],
      benefits: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      },
      selectedCategory: null,
      budgets: {
        available: 0,
        budget_expiration_date: null,
        allocated: 0,
        allocated_taxes: 0,
        allocation_month_year: {
          month: "12",
          year: "2020"
        },
      },
      budgetTotalCards: 0,
      details: {
        benefitId: null,
        show: false,
        fast_access_set: false,
      },
      benefitDetailsKey: 0,
      companyOptions: {
        cam_budget_taxation: false,
        gross_budget_taxation: false,
        legislated_gross_calculation: false,
        banner_image_url: null,
        last_monthly_order_day: null,
      },
      beneficiaryId: null
    }
  },
  computed: {
    locale: function () {
      return i18n.locale;
    }
  },
  created: function () {
    this.setBudgetConsumptionType();
  },
  mounted() {
    this.beneficiaryId = this.$store.getters.flx_beneficiaryActiveEmploymentId;
  },
  watch: {
    beneficiaryId: {
      handler: function (val) {
        this._loadOptions();
        if (sessionStorage.getItem('flexbenFastTopUpBenefitId') !== null && !this.details.fast_access_set) {
          this.showDetails(parseInt(sessionStorage.getItem('flexbenFastTopUpBenefitId')));
          sessionStorage.removeItem('flexbenFastTopUpBenefitId');
          this.details.fast_access_set = true;
        }
      },
      immediate: true
    }
  },
  methods: {
    setBudgetConsumptionType() {
      ApiBenefit.setBudgetConsumptionType(this.budgetConsumptionType);
      this.httpService.setBudgetConsumptionType(this.budgetConsumptionType);
    },
    changeFilterValues(onlyCategory) {
      this._loadFilters(true, onlyCategory)
      this._loadBenefits();
      if (this.params.category) {
        this.selectedCategory = this.filterOptions.categories.filter((item) => {
          return item.id === this.params.category;
        })[0];
      }
    },
    showDetails(benefitId) {
      this.details.benefitId = benefitId;
      this.details.show = true;
    },
    backToList() {
      this.details.show = false;
      this.details.benefitId = null;
    },
    changePerPage(perPage) {
      this.params.page = 1;
      this.params.perPage = perPage;
      this._loadBenefits();
    },
    pageChanged(pageNb) {
      this.params.page = pageNb;
      this._loadBenefits();
    },
    search() {
      this.params = {};
      if (this.searchString.length >= 3) {
        this.params.search = this.searchString;
        this._loadBenefits();
      } else if (this.searchString.length === 0) {
        this._loadBenefits();
      }
    },
    setNewBenefit(benefitId) {
      this.details.show = false;
      this.details.benefitId = benefitId;
      this.details.show = true;
      this.benefitDetailsKey++;
    },
    setBudgetsByChild(budgets) {
      this.budgets = budgets;
      this.budgetTotalCards = this.budgetTotalCards++;
    },
    _loadOptions() {
      if (this.beneficiaryId !== null) {
        this.loading = true;
        this.loaderError = false;
        this.httpService.employeeOptions(this.beneficiaryId, this.budgetConsumptionType)
          .then((response) => {
            this.initialLoading = false;
            this.companyOptions = response.data.company_options;
            this.budgets = response.data.budgets;
            this._loadFilters();
            this.loading = false
          })
          .catch((error) => {
            // this.loaderError = true;
            this.loading = true;
          });
      }
    },
    _loadFilters(cancelLoading = true, onlyCategory = false) {
      if (this.beneficiaryId !== null) {
        if (cancelLoading) {
          this.loading = true;
        }
        let params = {...this.params};
        if (onlyCategory) {
          params.only_category = onlyCategory ? 1 : 0;
        }
        this.httpService.filterOptions(this.beneficiaryId, params)
          .then((response) => {
            if (onlyCategory) {
              this.filterOptions.categories = response.data.categories;
            } else {
              this.filterOptions = {
                legislated_types: response.data.legislated_types,
                fiscal_treatments: response.data.web_fiscal_treatments,
                sub_types: response.data.sub_types,
                categories: response.data.categories,
                statuses: response.data.statuses,
                partners: response.data.partners,
                counties: response.data.counties,
                countries: response.data.countries,
                main_countries: response.data.main_countries,
                brands: response.data.brands,
                location_availability: response.data.location_availability
              };
            }
            if (cancelLoading) {
              this.loading = false
            }
          })
          .catch((error) => {
            if (500 <= error.response.status && error.response.status < 600) {
              this.loaderError = true;
            } else {
              this.loading = false;
            }
          });
      }
    },
    _loadBenefits() {
      if (this.beneficiaryId !== null) {
        this.loading = true;
        this.httpService.benefits(this.beneficiaryId, this.params)
          .then((response) => {
            this.benefits.data = response.data;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = true;
          });
      }
    },
    _resetBudgets() {
      this.loading = true;
      this.httpService.budgets(this.beneficiaryId)
        .then(function (response) {
          this.budgets = response.data;
          this.budgetTotalCards = this.budgetTotalCards++;

          this.loading = false;
        }.bind(this))
    },
    getBenefits() {
      for (let category of this.filterOptions.categories) {
        if (category.id === this.params.category) {
          this.selectedCategory = category;
          break;
        }
      }
      this._loadBenefits();
    },
    setFilter(filter, value) {
      this.params[filter] = value;
      this.appliedFilters.push(filter);
      this.changeFilterValues(false);
    },
    clearFilters() {
      this.selectedCategory = null;
      this.params.category = null;
      this.params.country = null;
      this.params.main_country = null;
      this.params.brand = null;
      this.changeFilterValues(false);
      this.scrollToTop(250);
    },
    back() {
      this.params[this.appliedFilters.pop()] = null;
      this.changeFilterValues(false);
      this.scrollToTop(250);
    },
  }
}
