<template>
  <div>
    <a
      v-for="entry in languages"
      :key="entry.title"
      class="language-button"
      :style="languageStyle(entry.language)"
      @click="changeLocale(entry.language)"
    >
      <flag
        :iso="entry.flag"
        v-bind:squared="false"
        :title="entry.title"
        v-if="entry.flag !== 'hu'"
      />
    </a>
  </div>
</template>
<script>
import i18n from "@/plugins/language/i18n";
import httpService from "@/http/http-common.js";

export default {
  data() {
    return {
      languages: [
        { flag: "ro", language: "ro", title: "Romana" },
        { flag: "gb", language: "en", title: "English" }
        // { flag: "hu", language: "hu", title: "Maghyar" }
      ],
      currentLanguage: localStorage.language ? localStorage.language : "ro"
    };
  },
  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
      this.currentLanguage = locale;
      localStorage.language = locale;
      this.$store.state.language = locale;
      sessionStorage.removeItem("mobile_jsonFile");
      sessionStorage.removeItem("client_jsonFile");
      sessionStorage.removeItem("affiliate_jsonFile");
      httpService.commonsService
        .getConfigFile(this.currentLanguage, "mobile")
        .then((response) => {
          this.$emit("languageChanged", x);
        });
      httpService.commonsService
        .getConfigFile(this.currentLanguage, "client")
        .then((response) => {
          this.$emit("languageChanged", x);
        });
      let x = httpService.commonsService
        .getConfigFile(this.currentLanguage, "affiliate")
        .then((response) => {
          this.$emit("languageChanged", x);
        });
      // window.location.reload();
    },
    languageStyle(language) {
      if (language === this.currentLanguage) {
        return "opacity: 1;";
      }
      return "";
    }
  },
  mounted() {
    i18n.locale = this.currentLanguage;
  }
};
</script>
<style lang="scss">
.language-button {
  padding: 5px;
  cursor: pointer;
  font-size: 22px;
  opacity: 0.5;
}

.language-div {
  text-align: right;
  padding-right: 10px;
  padding-top: 5px;
  height: 30px;
}
</style>
