<template>
  <b-overlay :show="loading" rounded="sm">
    <b-container :aria-hidden="loading ? 'true' : null">
      <div class="row container-gray py-3 mb-2">
        <div class="border-box col-12 text-bold">
          <span class="mr-1">{{ $t('flexben.beneficiary_pages.benefits.top_up_info') }}</span>
          <span id="top_up_information_vacation"
                class="d-inline-block flexben-tooltip-icon mr-2">i</span>

          <b-tooltip :target="'top_up_information_vacation'" variant="flexben" placement="topleft">
            <div class="text-left">
              {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_multiple_year') }}<br>
              {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_info_last_anual',{
              benefit: ceBenefit.benefit.name,
              date: ceBenefit.benefit.end_use_day_month.day + ' '+
              $t('flexben.general.months.'+ceBenefit.benefit.end_use_day_month.month)
              }) }}
            </div>
          </b-tooltip>
        </div>

        <div class="col-12 text-center text-bold text-danger mt-2"
             v-if="this.ceBenefit.benefit.order_pending"
             v-html="$t('flexben.beneficiary_pages.benefits.already_used',{benefit: ceBenefit.benefit.name})">
        </div>
        <div class="col-12 text-danger text-center text-bold" v-if="lastDayToOrderExceeded">
          {{ $t('flexben.formErrorReasons.exceeded_last_topup_date_client') }}
        </div>

        <div class="col-12 text-danger mt-2 text-center text-bold"
             v-if="ceBenefit.benefit.data.values.minimum_value_per_order.value > this.budgets.available && !this.ceBenefit.benefit.order_pending">
          {{ $t('flexben.beneficiary_pages.benefits.budget_under_min_limit',
          {min_val:ceBenefit.benefit.data.values.minimum_value_per_order.value}) }}
        </div>
        <div class="border-box col-12 col-md-6 mt-2">
          {{ $t('flexben.beneficiary_pages.benefits.yearly_legislated_limit') }}
          <span class="text-bold">{{ ceBenefit.benefit.data.values.maximum_legal_value_per_year.value }} Lei</span>
        </div>
        <div class="col-12 col-md-6 mt-2">
          {{ $t('flexben.beneficiary_pages.benefits.deductibility_remaining') }} <span
          class="text-bold text-danger">{{ ceBenefit.benefit.maximum_yearly_remaining }} Lei </span>
        </div>

      </div>
      <div class="row container-gray mt-3 py-3">
        <div class="border-box col-12">
          <div class="bold float-left">
            <span class="mr-1">{{ $t('flexben.beneficiary_pages.benefits.top_up_value') }}</span>
            <span id="tooltip_up_vacation_top_up"
                  class="d-inline-block flexben-tooltip-icon mr-2">i</span>

            <b-tooltip :target="'tooltip_up_vacation_top_up'" variant="flexben" placement="topleft">
              <div class="text-left mb-1">{{
                $t('flexben.beneficiary_pages.benefits.tooltips.top_up_unlimited') }}
              </div>
              <div class="text-left">
                {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_taxation',{
                threshold: ceBenefit.benefit.maximum_yearly_remaining,
                tax: globalVariables['impozit-venit'],
                cas: globalVariables['cas'],
                cass: globalVariables['cass']
                }) }}
              </div>
            </b-tooltip>
          </div>
          <div class="float-right">
            <span class="mr-1" v-html="(ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale] === null) ?
              $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
              : ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale]">
            </span>
            <span id="tooltip_up_vacation_deductibility"
                  class="d-inline-block flexben-tooltip-icon mr-2">i</span>

            <b-tooltip :target="'tooltip_up_vacation_deductibility'" variant="flexben" placement="topleft">
              <div class="text-left">
                {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
              </div>
            </b-tooltip>
            <span class="text-danger text-bold" v-html="`${values.taxes.allTaxes.toFixed(2)} lei`"></span>
          </div>
        </div>

        <div class="col-12 ">
          <div class="flexben-vue-slider-container">
            <vue-slider v-if="configuredSlider"
                        :class="'px-2'"
                        v-bind="sliderOptions"
                        :processStyle="processStyle"
                        v-model="sliderValue"
                        :lazy="true">
            </vue-slider>
          </div>
        </div>
        <total-value-pay v-if="companyOptions.gross_budget_taxation"
                         :totalPayValue="values.total"></total-value-pay>
        <div class="col-12" v-if="configuredSlider">
          <button class="btn text-themed px-0" :class="{'d-flex':!showManualInput, 'd-none':showManualInput}"
                  @click="showManualInput=true">
            <font-awesome-icon icon="caret-square-down" class="mr-1 contact-ico"/>
            {{ $t('flexben.beneficiary_pages.benefits.btn.set_manual') }}
          </button>
          <div class="form-group m-0" v-show="showManualInput">
            <label for="manual_input" class="form-fields-required col-form-label">
              {{ $t('flexben.beneficiary_pages.benefits.fields.manual_value',{min:sliderOptions.min,
              max:sliderOptions.max}) }}
            </label>
            <input type="number" class="form-control w-25" id="manual_input"
                   step="1"
                   onkeydown="if(event.key==='.'){event.preventDefault();}"
                   v-model.number="sliderValue"
                   :min="sliderOptions.min"
                   :max="sliderOptions.max"
                   @input="checkValue">
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="border-box col-12 px-0 pr-3 text-center text-md-left">
          <div class="d-flex flex-column flex-lg-row align-items-center align-items-lg-start align-content-center">
            <button class="btn btn-themed-green px-2 mr-2 px-lg-4" @click="orderNow()"
                    :disabled="(manualInputInvalid || !values.hasBudget || lastDayToOrderExceeded || sliderValue === 0 || !ceBenefit.benefit.company_config.is_active || (companyOptions.gross_budget_taxation && totalPayValue>remainingBudget))">
              {{ $t('flexben.beneficiary_pages.order_now') }}
            </button>
            <a v-if="ceBenefit.benefit.product_link.length>0"
               class="text-themed px-2 mr-lg-2 mt-2 px-lg-4 text-uppercase text-15 "
               :href="ceBenefit.benefit.product_link" target="_blank">
              {{ $t('flexben.beneficiary_pages.benefits.btn.where_to_use_vouchers') }}
            </a>
          </div>
        </div>
      </div>
    </b-container>
  </b-overlay>
</template>
<script>
import {BContainer, BOverlay, BTooltip} from "bootstrap-vue";
import VueSlider from "vue-slider-component";
import TotalValuePay from "@/components/flexben/beneficiary_pages/benefits/partials/TotalValuePay";
import flexbenUtils from "@/plugins/flexben_utils";
import moment from "moment";

export default {
  name: "UpVacationForm",
  components: {
    'vue-slider': VueSlider,
    "b-tooltip": BTooltip,
    'total-value-pay': TotalValuePay,
    'b-overlay': BOverlay,
    'b-container': BContainer,
  },
  props: {
    ceBenefit: {
      type: Object,
      required: true
    },
    budgets: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      required: true,
      default() {
        return {
          taxes: {
            allTaxes: 0,
          },
          total: 0,
          hasBudget: true,
        };
      }
    },
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },
    sliderGeneralOptions: {
      type: Object,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
      default: function () {
        return {
          value: 0,
          benefit_id: this.benefitId,
          child_benefit_id: null,
          event_id: null,
          auto_top_up: false,
          ticket_count: 0,
          ticket_value: 0,
          extra: {},
        };
      }
    },
    companyOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          cam_budget_taxation: false,
          gross_budget_taxation: false,
          legislated_gross_calculation: false,
          last_monthly_order_day: null,
        };
      }
    },
    globalVariables: {
      type: Object,
      required: true,
      default: function () {
        return {
          cas: null,
          cass: null,
          cam_tax: null,
          'impozit-venit': null,
        };
      }
    },
    remainingBudget: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      sliderValue: 0,
      manualInputInvalid: false,
      toggleAutoTopup: false,
      sliderOptions: {},
      configuredSlider: false,
      processStyle: {
        background: 'rgb(31, 197, 145) 100%',
      },
      salaryDeductibility: 0,
      totalPayValue: 0,
      exceededSalaryDeductibility: 0,
      formatTooltip: val => {
        return val + this.currency;
      },
      currency: ' Lei',
      showManualInput: false,
      lastDayToOrderExceeded: false,
    };
  },
  watch: {
    sliderValue: {
      handler: function (val) {
        const payload = this._getPayload();

        this.$emit("getTaxes", payload.benefit_id, payload);
      }
    },
    values: {
      handler() {
        if (!this.values.hasBudget) {
          this.$emit('makeToast', this.$t('rejectReason_balancenotvalid'));
        }
      }
    },
    ceBenefit: {
      handler: function (val) {
        this._setSliderOptions();
      }
    }
  },
  mounted() {
    this._setSliderOptions();
    this.lastDayToOrderExceeded = this.checkIfLastDayToOrderExceeded();
  },
  methods: {
    checkValue() {
      if (this.sliderValue === null || this.sliderValue === '')  {
        this.sliderValue = 0;
      }
    },
    _getPayload() {
      let ticketCount =
        this.sliderValue /
        this.ceBenefit.benefit.ticket_value;
      return {
        value: this.sliderValue,
        benefit_id: this.ceBenefit.benefit.company_config.benefit_id,
        child_benefit_id: null,
        event_id: null,
        auto_top_up: false,
        ticket_count: ticketCount.toFixed(0),
        ticket_value: this.ceBenefit.benefit.ticket_value,
        extra: {}
      };
    },
    orderNow() {
      if (this._validate()) {
        return;
      }
      this._setPayload();
      this.$emit('orderNow');
    },
    checkIfLastDayToOrderExceeded() {
      return this.companyOptions.last_monthly_order_day !== null && this.companyOptions.last_monthly_order_day < parseInt(moment().format('D'));
    },
    _validate() {
      if (this.sliderOptions.min > this.sliderValue || this.sliderValue > this.sliderOptions.max) {
        this.$emit('setErrorInAlertModal', [this.$t('flexben.beneficiary_pages.benefits.fields.manual_value', {
          min: this.sliderOptions.min,
          max: this.sliderOptions.max
        })])
        return true;
      }
      return false;
    },
    _setSliderOptions() {
      this.configuredSlider = false;
      let sliderGeneralOptions = {...this.sliderGeneralOptions},
        marks = {},
        minVal = this.ceBenefit.benefit.data.values.minimum_value_per_order.value;

      let maxVal = this.budgets.available;
      let maxLegalValPerYear = this.ceBenefit.benefit.maximum_yearly_remaining;
      if (minVal > maxVal || this.ceBenefit.benefit.order_pending) {
        maxVal = minVal = 0;
        sliderGeneralOptions.disabled = true;
        this.showManualInput = false;
      }

      if (0 < maxLegalValPerYear && maxLegalValPerYear < maxVal) {
        let markPoint = ((maxLegalValPerYear - minVal) * 100 / (maxVal - minVal)).toFixed(1);
        marks[maxLegalValPerYear] = {
          label: maxLegalValPerYear + this.currency,
          labelStyle: {
            top: '-18px',
            color: '#C50E1F',
            background: '#fff',
            padding: '2px 6px',
            'border-radius': '12px'
          },
        };
        sliderGeneralOptions.process = () => [
          [
            0.0,
            markPoint,
            {
              backgroundColor: '#1FC591',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }
          ],
          [
            markPoint,
            100,
            {
              backgroundColor: '#C50E1F',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }
          ],
        ];

        sliderGeneralOptions.stepStyle = {
          backgroundColor: 'transparent'
        }
      } else if (maxLegalValPerYear < maxVal) {
        this.processStyle.background = '#C50E1F';
      }

      if (maxVal % this.ceBenefit.benefit.ticket_value > 0) {
        maxVal = maxVal - (maxVal % this.ceBenefit.benefit.ticket_value);
      }

      marks[minVal] = {
        label: minVal + this.currency,
      };
      marks[maxVal] = {
        label: maxVal + this.currency
      };
      sliderGeneralOptions.marks = marks;
      sliderGeneralOptions.min = minVal;
      sliderGeneralOptions.max = maxVal;
      sliderGeneralOptions.tooltipFormatter = this.formatTooltip;
      sliderGeneralOptions.interval = this.ceBenefit.benefit.ticket_value;
      if (!this.ceBenefit.benefit.company_config.is_active || this.ceBenefit.benefit.order_pending) {
        sliderGeneralOptions.disabled = true;
        this.showManualInput = false;
        this.showDownArrow = false;
      }

      this.sliderValue = minVal;
      this.sliderOptions = sliderGeneralOptions;
      this.configuredSlider = true;
    },
    _setSalaryDeductibility() {
      let value = this.sliderValue;
      let grossVal = 0;
      let grossBaseVal = 0;
      let withoutTax, tax;
      if (this.companyOptions.legislated_gross_calculation) {
        grossVal = flexbenUtils.calculateGross(value, this.globalVariables);
      }

      if (value <= this.ceBenefit.benefit.maximum_yearly_remaining) {
        this.sliderOptions.tooltipStyle.color = '#1FC591';
      } else {
        this.sliderOptions.tooltipStyle.color = '#C50E1F';
      }

      if (value !== 0) {
        let deductPercentBase = 0;

        if (value <= this.ceBenefit.benefit.maximum_yearly_remaining) {
          this.salaryDeductibility = (value * deductPercentBase / 100).toFixed(2);
          let taxPercent = this.ceBenefit.benefit.global_variables['impozit-venit'] / 100;
          let totalDeductPercent = (this.ceBenefit.benefit.global_variables['cas'] + this.ceBenefit.benefit.global_variables['cass']) / 100;

          if (this.companyOptions.legislated_gross_calculation) {
            withoutTax = grossVal * totalDeductPercent;
            tax = (grossVal - withoutTax - (grossVal * (this.ceBenefit.benefit.global_variables.cass / 100))) * taxPercent;
          } else {
            withoutTax = value * totalDeductPercent;
            tax = (value - withoutTax) * taxPercent;
          }
          this.salaryDeductibility = (withoutTax + tax).toFixed(2);

        } else {
          // under max legal taxation
          let baseValue = this.ceBenefit.benefit.maximum_yearly_remaining;
          if (this.companyOptions.legislated_gross_calculation) {
            grossBaseVal = flexbenUtils.calculateGross(baseValue, this.globalVariables);
          }
          let taxPercent = this.ceBenefit.benefit.global_variables['impozit-venit'] / 100;
          let totalDeductPercent = (this.ceBenefit.benefit.global_variables['cas'] + this.ceBenefit.benefit.global_variables['cass']) / 100;
          if (this.companyOptions.legislated_gross_calculation) {
            withoutTax = grossBaseVal * totalDeductPercent;
            tax = (grossVal - withoutTax - (grossVal * (this.ceBenefit.benefit.global_variables.cass / 100))) * taxPercent;
          } else {
            withoutTax = baseValue * totalDeductPercent;
            tax = (baseValue - withoutTax) * taxPercent;
          }


          //exceeding taxation
          let exceededValue = value - baseValue;
          let grossExceed = flexbenUtils.calculateGross(exceededValue, this.globalVariables);

          this.salaryDeductibility = (withoutTax + tax + grossExceed - exceededValue).toFixed(2);
        }
        this._calculateTotalByCompanyOptions(value);
      } else {
        this._calculateTotalByCompanyOptions(0);
        this.salaryDeductibility = 0;
      }
    },
    _setPayload() {
      this.payload.value = this.sliderValue;
      this.payload.benefit_id = this.ceBenefit.benefit_id;
      this.payload.ticket_count = Math.floor(this.sliderValue / this.ceBenefit.benefit.ticket_value);
      this.payload.ticket_value = this.sliderValue / this.payload.ticket_count;
      this.payload.extra = {};
    },
    _calculateTotalByCompanyOptions(sliderValue) {
      let value = parseFloat(sliderValue);
      if (this.companyOptions.gross_budget_taxation && value > 0) {
        value += parseFloat(this.salaryDeductibility);
      }
      this.totalPayValue = value;
    }
  }
}
</script>

