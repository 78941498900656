export default {
  legislated_type: 'Legal treatment',
  fiscal_treatment: "Fiscal treatment",
  legislated_types: {
    0: 'Legislated',
    1: 'Non legislated',
  },
  btn: {
    export: 'Export benefits',
    clear_category: 'Back to categories',
    back_button: 'Back',
  },
  title: "Benefits",
  description: "Configure available benefits",
  description_placeholder: 'Benefit description text. Maximum 3000 characters',
  reason_placeholder: 'Benefit reason text. Maximum 3000 characters',
  valid_from_future: "Valid from (future)",
  future_values: "Future values",
  current_values: "Current values",
  save_draft: "Save as draft",
  set_inactive: "Set inactive",
  publish: "Publish",
  filter_title: "Filter benefits",
  add: "Add benefit",
  valid_from: "Valid from",
  no_items_found: "No benefits were found",
  name: "Benefit name",
  id: 'CRM ID',
  rationale: 'Description',
  details: 'Details',
  available_categories: 'Available categories',
  available_categories_clients: 'Customer specific categories available',
  available_locations: 'Available locations',
  available_client_systems: 'Available systems',
  daily_minimum_order_value: 'Daily minimum order value',
  daily_maximum_order_value: 'Daily maximum order value',
  up_gift_law_tab_table_gender_restrictions: 'Gender restrictions',
  up_gift_law_tab_table_for_birthdays: 'For birthdays',
  up_gift_law_tab_table_for_limit_child: "Configurable limit for minor",
  maximum_value_per_order: "Maximum value per order",
  maximum_value_per_event: "Maximum value per event",
  new_maximum_value_per_event: "New maximum value per event",
  new_beneficiary: "New beneficiary",
  delete: "Delete",
  suspended: "Suspended",
  reactivate: "Reactivate",
  resend_invitation: "Resend invitation",
  change_status_to_invited: "Change Status to Invited",
  change_status_to_reActivate: "Reactivate User",
  beneficiary_created_successfully: "Beneficiary created successfully",
  beneficiary_updated_successfully: "Beneficiary updated successfully",
  status_change_successfully: "Status change successfully",
  add_beneficiary: "Add beneficiary",
  status_changed_successfully: "Status has been changed successfully",
  action_has_been_executed_successfully: "Action has been executed successfully",
  crm_codes: {
    up_gift_legislated: 'Events stated by law',
    up_gift_specific_events: 'Specific events',
    up_gift_s_t_i_m: 'STIM',
  },
  image_changed: "The changed image was not saved",
  upload_image: "Upload image (500x500 px)",
  fields: {
    maximum_legal_value_per_month: "Maximum legal value per month",
    maximum_value_per_order_month: "Maximum order value per month",
    maximum_legal_value_per_year: "Maximum legal value per year",
    minimum_value_per_order: "Minimum value per order",
    new_minimum_value_per_order: "New minimum value per order",
    new_maximum_legal_value_per_year: "New maximum legal value per year",
    new_daily_ticket_min_value: "New daily minimum ticket value",
    daily_ticket_min_value: "Daily minimum ticket value",
    new_daily_ticket_max_value: "New daily maximum ticket value",
    new_maximum_legal_value_per_event: "New maximum legal value per event",
    daily_ticket_max_value: "Daily ticket maximum legal value",
    maximum_legal_value_per_event: "Maximum legal value per event",
    new_maximum_legal_value_per_month: "New maximum legal value per month",
    new_maximum_value_per_order_month: "New maximum order value per month",
    valid_form: "Valid from",
    valid_form_future: "Valid from (future)",
    latest_annual_for_placing_orders: "Latest annual for placing orders",
    daily_minimum_ticket_value: "Daily minimum ticket value",
    daily_maximum_ticket_value: "Daily maximum ticket value",
    new_daily_minimum_ticket_value: "New daily minimum ticket value",
    new_daily_maximum_ticket_value: "New daily maximum ticket value",
    sub_type: "Sub type",
    child_sub_type: "Choose provider",
    is_parent: "Is parent",
    subscription_periods: 'Subscription periods',
    product_link: 'Product link',
    name: 'Name',
    crm_code: 'ID CRM',
    description: 'Description',
    rationale: 'Rationale',
    delivery_type: 'Delivery type',
    extra: 'Dynamic fields',
    voucher_billing_value: 'Value Lei',
    voucher_value_code: 'Voucher code',
    voucher_value_file: 'Voucher file',
    status: 'Status',
    partner: 'Partner',
    county: 'County availability',
    previous_budget: 'Previous budget',
    new_budget: 'Current budget',
    min_order_value: 'Minimum order value',
    new_min_order_value: 'New minimum order value',
    info_contract_number: 'Beneficiary will have the "Nr. polita"(Nr. contract) field automatically generated.',
    available_mobile: "Mobile application availability",
    budget_consumption_types: "Budget consumption availability",
    budget_to_point_ratio: 'Budget to point ratio',
    new_budget_to_point_ratio: 'New Budget to point ratio',
    platform_tax: 'Processing fee',
    new_platform_tax: 'New processing fee',
    vat: 'VAT',
  },
  modal: {
    confirm_save: 'Are you sure you want to save the changes?',
    confirm_leave: 'The information has been changed, are you sure you want to leave?',
    confirm_delete: 'Are you sure you want to delete the benefit?',
    save_draft: 'Are you sure you want to save the benefit as draft?',
    publish: 'Are you sure you want to publish the benefit?',
    confirm_status: 'Are you sure you want to {action} the benefit?',
    irreversible_action: 'Action is irreversible!',
    status_actions: {
      active: 'activate',
      inactive: 'inactivate',
    }
  },
  edit: 'Edit',
  create: 'New benefit',
  dynamic_fields: 'Dynamic fields',
  partner_data: 'Partner information',
  sub_types: {
    none: 'Not specified',
    voucher: 'Voucher',
    voucher_fuel: 'Voucher fuel',
    subscription: 'Subscription',
    subscription_world_class: 'WorldClass Subscription',
    ppp3: 'Private pension pylon 3',
    medical_insurance: 'Medical insurance',
    points_pago: 'Pago points',
    cash_out: 'Cash out',
    subscription_24_pay: 'Subscription 24Pay',
  },
  types: {
    transport: 'Transport'
  },
  thresholds: {
    gym_membership: 'Threshold gym membership',
    tooltip: 'Threshold that applies for gym membership (also for vouchers)'
  },
  tooltips: {
    category: 'Selected categories can be part of one single group'
  },
  subscription_periods: {
    w_1: 'One week',
    w_2: 'Two weeks',
    m_1: '1 month',
    m_3: '3 months',
    m_6: '6 months',
    m_12: '12 months',
  },
  delivery_types: {
    digital_code: 'Digital - code',
    digital_file: 'Digital - file',
    physical: 'Physical',
    card: 'Card top up',
  },
  deductibility: {
    3: 'No deductibility',
    2: 'Partial deductibility',
    1: 'Integral',
    none: 'No deductibility',
    partial: 'Partial deductibility',
    complete: 'Integral',
  },
  no_partner: 'Partner not set',
  world_class_fields: {
    price: 'Price',
    prices: 'Prices',
    subscription_periods: 'Subscription duration',
    location: 'Location',
    facilities: 'Facilities',
    time_schedule: 'Time schedule',
    level: 'Level',
    city: 'City',
    club_name: 'Club level',
    club_code: 'Club code',
    is_local_price: 'Local price',
    address: 'Address',
    phone_number: 'Phone number',
    has_gym: 'Gym',
    has_aerobic_studio: 'Aerobic studio',
    has_cycling_studio: 'Cycling studio',
    has_pt: 'Personal Trainer',
    has_indoor_pool: 'Indoor pool',
    has_outdoor_terrace: 'Outdoor terrace',
    has_outdoor_pool: 'Outdoor pool',
    has_indoor_football: 'Indoor football',
    has_dry_sauna: 'Dry sauna',
    has_wet_sauna: 'Wet sauna',
    has_jacuzzi: 'Jacuzzi',
    has_pilates_studio: 'Pilates studio',
    has_yoga_studio: 'Yoga studio',
    has_kids_studio: 'Kids studio',
    has_squash: 'Squash',
    last_day_to_order_for_next_month: 'Last day to order to next month subscription',
    subscription_start_day: 'Subscription start date',
    chose_club: 'Chose club',
    subscription_type: 'Subscription type',
  },
  world_class_types: {
    network: 'Network',
    local: 'Local',
  },
  world_class_notices: {
    personal_info: 'Filled in data will be communicated to world class for subscription activation',
    local_price: 'Membership with access only to the selected club',
    network_price: 'Subscription with access to all clubs in the same category and in those in the lower category of the selected club',
    birth_date_tooltip: 'Your date of birth is requested by World Class to verify that you are of legal age.',
  },
  world_class_import: {
    types: {
      label: 'Choose type of import',
      prices: 'Prices network',
      locations: 'Locations',
      prices_locale: 'Prices locale'
    },
    upload: 'Upload File',
    import: 'Import',
    no_file_uploaded: 'No file uploaded'
  },
  transport_24_pay: {
    no_account_info: 'Please enter the phone number associated with your 24Pay account or create an account by downloading the 24Pay app to gain access to all available subscriptions',
    start_date_info: 'Start date may differ depending on your active subscriptions',
    expiration_date: 'Subscription expiration date',
    start_date: 'Subscription start date',
    phone_number: 'Phone number account 24Pay',
    subscription_name: 'Subscription name 24Pay',
    provider_name: 'Transport provider name',
    order_id_24_pay: 'Order id in 24Pay',
    period_name: 'Period availability',
    active_subscription: 'You have one active subscription that expires on: ',
    labels: {
      phone_number: 'Phone number',
      provider: 'Transport provider',
      start_date: 'Subscription start date',
      expiration_date: 'Subscription expiration date',
      subscription: 'Subscription',
    }
  },
  points_pago_notices: {
    personal_info: 'Points will be added matching the "Email" field to your Pago account. <br> If you dont have a Pago account you can use the Email address to register and redeem your points',
    processing_tax: 'Price includes {tax} Lei processing tax.'
  },
  county_availability: {
    0: 'All counties',
    1: 'Specific',
  },
  location_availability: {
    0: 'All',
    1: 'Online',
    2: 'Physical'
  },
  budget_consumption_types: {
    general: 'Flexible budget',
    telework: 'Telework budget',
    ppp3: 'PPP3 budget',
  },
  promoted_as_new: "Benefit promoted in what's new category",
  contribution_type: {
    personal: "Personal",
    employer: "Employer",
    mixt: "Mixt",
  }
}
