<template>
  <button class="btn rounded-circle btn-go-top text-22"
          v-show="scrollPosition>fromTop"
          @click="goTop()"><font-awesome-icon icon="chevron-down" class="rotate-180"></font-awesome-icon>
  </button>
</template>

<script>
export default {
  name: "ScrollToTopBtn",
  props: {
    fromTop: {
      type: Number,
      default: 100
    }
  },
  data: function () {
    return {
      scrollPosition: 0
    };
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  methods: {
    goTop() {
      this.scrollToTop()
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
    }
  }
}
</script>
<style scoped>
.btn-go-top {
  position: fixed;
  background: white;
  color: #f49400;
  border: 2px solid #f49400;
  z-index: 1000;
  top: calc(100vh - 3.7rem);
  right: 0.5rem;
}
.btn-go-top:hover {
  background: #f49400;
  color: white;
}
</style>
