var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row budget-totals m-0"},[_c('div',{class:{
    'col-12': true,
    'col-md-6': _vm.companyOptions.gross_budget_taxation || _vm.budgetConsumptionType==='telework',
    'col-md-4': (!_vm.companyOptions.gross_budget_taxation && _vm.budgetConsumptionType==='general')
  }},[_c('div',{staticClass:"text-center budget-box-inverse"},[_c('div',{staticClass:"budget-box-title"},[_vm._v(_vm._s(Math.floor(_vm.remainingBudget))),_c('span',{staticClass:"text-sup"},[_vm._v(_vm._s(_vm.flexbenUtils.getSupDecimals(_vm.remainingBudget)))])]),_c('div',{staticClass:"budget-box-body px-1 pb-1"},[_vm._v(" "+_vm._s(_vm.$t('flexben.beneficiary_pages.benefits.budget.available'))+" "+_vm._s(_vm.getBudgetExpirationDate())+" ")])])]),_c('div',{class:{
  'col-12 mt-2 mt-md-0': true,
  'col-md-6': _vm.companyOptions.gross_budget_taxation || _vm.budgetConsumptionType==='telework',
  'col-md-4': (!_vm.companyOptions.gross_budget_taxation && _vm.budgetConsumptionType==='general')
  }},[_c('div',{staticClass:"text-center budget-box"},[_c('div',{staticClass:"budget-box-title text-themed"},[_vm._v(_vm._s(Math.floor(_vm.budgets.allocated))),_c('span',{staticClass:"text-sup text-themed-dark"},[_vm._v(_vm._s(_vm.flexbenUtils.getSupDecimals(_vm.budgets.allocated)))])]),_c('div',{staticClass:"budget-box-body px-1 pb-1"},[_vm._v(" "+_vm._s(_vm.$t('flexben.beneficiary_pages.benefits.budget.allocated'))+" "+_vm._s(_vm.$t('flexben.general.months.' + _vm.budgets.allocation_month_year.month))+" "+_vm._s(_vm.budgets.allocation_month_year.year)+" ")])])]),(!_vm.companyOptions.gross_budget_taxation && _vm.budgetConsumptionType==='general')?_c('div',{staticClass:"col-12 col-md-4 mt-2 mt-md-0"},[_c('div',{staticClass:"text-center budget-box"},[_c('div',{staticClass:"budget-box-title text-themed"},[_vm._v(" "+_vm._s(Math.floor(_vm.budgets.allocated_taxes))),_c('span',{staticClass:"text-sup text-themed-dark"},[_vm._v(_vm._s(_vm.flexbenUtils.getSupDecimals(_vm.budgets.allocated_taxes)))])]),_c('div',{staticClass:"budget-box-body px-1 pb-1",domProps:{"innerHTML":_vm._s(_vm.$t('flexben.beneficiary_pages.benefits.budget.taxes',{
                    date: (_vm.$t('flexben.general.months.'+_vm.budgets.allocation_month_year.month) + ' ' +_vm.budgets.allocation_month_year.year)
                    }))}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }