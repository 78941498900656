<template>
  <b-modal ref="offer-preview"
           header-class="border-0"
           :body-class="{'no-border background-grey text-center':true,'p-0':true}"
           footer-class="modal-footer border-top-0"
           :no-close-on-esc="true"
           :no-close-on-backdrop="true"
           size="xl"
           centered>
    <template v-slot:default>

      <div class="container-fluid flexben-container">
        <div v-if="offer != null" class="mb-4 preview-offer-detail">
          <div class="row">
            <div class="col-12 flexben-page-title text-capitalize">
              <h1>{{ offerName }}</h1>
            </div>
          </div>
          <div class="container-bg-white">
            <div class="row p-3">
              <div class="col-12 mb-3">
                <h3 class="mt-0 pt-1 pb-0 pl-0 mb-1 text-capitalize">
                  {{ $t('flexben.offers.beneficiary.available_between',getAvailableObj()) }}
                </h3>
              </div>
              <div class="col-12 col-xl-4 mx-auto mx-xl-0">
                <img :src="imagePreview" :alt="offerName" class="offer-image">
              </div>
              <div class="col-12 col-xl-8 mt-3 mt-lg-0 mt-3">
                <p><span v-if="offer.type" class="flx-type-badge">{{ $t("flexben.offers.types."+offer.type)}}</span></p>
                <p class="links-container" v-html="descriptionName"></p>
                <p v-if="offer.county_availability==='specific'">{{ $t("flexben.offers.beneficiary.offer_available_in")}}:
                  {{getSelectedCounties()}}
                </p>
                <p v-else-if="offer.county_availability==='all'">
                  {{ $t("flexben.offers.beneficiary.offer_available_in_all") }}
                </p>
                <p>
                  <button v-if="offer.code_type==='text' && !showCode"
                          class="btn btn-themed ml-auto" @click="showCode=true">{{$t('flexben.offers.btn.get_offer')}}
                  </button>

                </p>
                <p v-if="offer.code_type==='text' && showCode">
                  {{ $t('flexben.offers.beneficiary.code_is')}}<span
                  class="text-20 text-bold ml-1">{{ offer.code_value }}</span>
                </p>
              </div>
              <div class="col-12 col-md-6 col-lg-4 mt-3 text-center">
                <div class="d-inline-flex mx-auto container-partner">
                  <img v-if="!offer.partner" :src="image_url" :alt="''" class="offer-partner-image mr-2">
                  <img v-else :src="offer.partner.image_url" :alt="offer.partner.name" class="offer-partner-image mr-2">
                  <h6 class="my-auto p-0 pt-1">{{ offer.partner ? offer.partner.name : ''}}</h6>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 mt-3">
                <h3 class="mt-0 pt-1 pl-0 text-capitalize ">{{ $t('flexben.offers.fields.usage_instructions')}}</h3>
                <div class="links-container" v-html="instructionName"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:modal-footer>
      <button type="button"
              @click="closeModal"
              class="btn save-btn mx-auto my-0">
        {{$t("flexben.general.btn.close")}}
      </button>
      <button type="button"
              @click="publish"
              class="btn save-btn mx-auto my-0">
        {{$t("flexben.general.btn.publish")}}
      </button>
    </template>
    <br/>
  </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";
import httpFlexbenService from "@/http/http-flexben";

export default {
  name: "OfferPreviewModal",
  components: {
    'b-modal': BModal
  },
  props: {
    locale: {
      type: String,
      default: 'ro',
      required: true
    }
  },
  data: () => {
    return {
      offer: null,
      imagePreview: null,
      showCode: false,
      image_url: httpFlexbenService.globalVariablesService.baseImagePreview,
    }
  },
  computed: {
    offerName: function () {
      if (this.offer == null) {
        return '';
      }
      return Object.values(this.offer.name).filter((item) => {
        return item['lang_code'] == this.locale
      })[0].value;
    },
    descriptionName: function () {
      if (this.offer == null) {
        return '';
      }
      return Object.values(this.offer.description).filter((item) => {
        return item['lang_code'] == this.locale
      })[0].value;
    },
    instructionName: function () {
      if (this.offer == null) {
        return '';
      }
      return Object.values(this.offer.usage_instructions).filter((item) => {
        return item['lang_code'] == this.locale
      })[0].value;
    },

  },
  methods: {
    getAvailableObj() {
      return {
        start_date: this.moment(this.offer.start_use_date, 'DD/MM/YYYY'),
        end_date: this.moment(this.offer.end_use_date,'DD/MM/YYYY'),
      }
    },
    getSelectedCounties() {
      let counties = [];
      this.offer.counties.forEach((item) => {
        if (this.offer.selected_counties.includes(item['value']))
          counties.push(item['name']);
      });

      return counties.join(', ');
    },
    closeModal() {
      this.offer = null;
      this.showCode = false;
      this.$refs['offer-preview'].hide();
    },
    openModal(offer, imagePreview) {
      this.offer = offer;
      this.imagePreview = imagePreview;
      this.$refs['offer-preview'].show();
    },
    publish() {
      this.closeModal();
      this.$emit('publish');
    }
  }
}
</script>

<style scoped lang="scss">

.preview-offer-detail {
  .type-badge {
    color: #ffffff;
    border-radius: 5px;
    padding: 3px 10px;
    font-weight: bold;
    background-color: #F59100;
  }

  .offer-image {
    border-radius: 5px;
    margin: 0 auto;
    max-width: 300px;
  }

  .offer-partner-image {
    border-radius: 5px;
    margin: 0;
    max-width: 100px;
  }

  .container-partner {
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
  }
}
</style>
