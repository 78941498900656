<template>
  <div class="container-fluid">
    <div class="row container-gray py-3">
      <div :class="{
        'col-12 col-md-6':true,
        'col-xl-2':companyOptions.cam_budget_taxation,
      }">
        <span class="flx-type-badge mr-1">{{ $t("flexben.benefit.sub_types."+ceBenefit.benefit.sub_type) }}</span>
        <span v-if="thresholds.gym_membership.status && thresholds.gym_membership.value>0" id="threshold-gym-info" class="d-inline-block flexben-tooltip-icon mr-2">i</span>
        <b-tooltip v-if="thresholds.gym_membership.status && thresholds.gym_membership.value>0" :target="'threshold-gym-info'" variant="flexben" placement="topright">
          <div class="text-left">
            {{ $t('flexben.beneficiary_pages.benefits.tooltips.thresholds.' + thresholds.gym_membership.code, {value: thresholds.gym_membership.value}) }}
          </div>
        </b-tooltip>
      </div>
      <div :class="{
            'col-12 col-md-6':true,
            'col-xl-5':companyOptions.cam_budget_taxation,
            }">
        <div :class="companyOptions.cam_budget_taxation ? '' :'float-right'">
          <span class="mr-1" v-html="(ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale] === null) ?
            $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
            : ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale]">
          </span>
          <span id="tooltip_up_dejun_deductibility" class="d-inline-block flexben-tooltip-icon mr-2">i</span>

          <b-tooltip :target="'tooltip_up_dejun_deductibility'" variant="flexben" placement="topleft">
            <div class="text-left">
              {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
            </div>
          </b-tooltip>
          <span class="text-danger"><strong>{{ salaryDeductibility }} Lei</strong></span>
        </div>
      </div>

      <cam-value-info v-if="companyOptions.cam_budget_taxation" :valueCam="valueCam"></cam-value-info>

      <div class="col-12 mt-2 text-danger text-bold text-center"
           v-if="(dynamicFields.billing_value>remainingBudget)">
        {{ $t('flexben.beneficiary_pages.benefits.insufficient_budget') }}
      </div>
      <div class="col-12 text-danger text-center text-bold" v-if="lastDayToOrderExceeded">
        {{ $t('flexben.formErrorReasons.exceeded_last_topup_date_client') }}
      </div>
      <div class="col-12 mt-3">
        <div class="row">
          <div class="col-12 col-md-6 col-xl-4" v-if="dynamicFields.delivery_type !=='card'">
            <div class="form-group mb-0">
              <label class="col-form-label pt-0 mr-1">{{ $t('flexben.beneficiary_pages.benefits.fields.value')
                }}</label>
              <div class="form-control">
                {{ dynamicFields.billing_value }} Lei
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 col-xl-8"
               v-if="dynamicFields.delivery_type ==='card'">
            <div class="row">
              <div class="col-12 col-xl-6 form-group mb-0">
                <label class="form-fields-required col-form-label pt-0" for="top_up_value">{{
                  $t('flexben.beneficiary_pages.benefits.fields.top_up_value_card') }}</label>
                <input type="number" id="top_up_value" class="form-control bg-white mb-0"
                       :class="{'border-danger':(Object.keys(errorsBag).includes('value'))}"
                       min="0"
                       v-model="cardData.top_up_value"/>
              </div>
              <div class="col-12 col-xl-6 d-flex">
                <button class="btn btn-themed-blue my-2 mt-xl-auto mb-xl-0 btn-input-h" @click="requestCardAct()">{{
                  requestCard ? $t('flexben.beneficiary_pages.benefits.btn.'+((ceBenefit.properties.card_series ===
                  null)?'add_series_card':'previous_card')) :
                  $t('flexben.beneficiary_pages.benefits.btn.request_card') }}
                </button>
              </div>
              <div class="col-12 col-xl-6 form-group mb-0" v-show="!requestCard">
                <label class="form-fields-required col-form-label" for="p_card_series">{{
                  $t('flexben.beneficiary_pages.benefits.fields.card_series') }}</label>
                <input type="text" id="p_card_series" class="form-control bg-white" v-model="cardData.card_series"/>
              </div>
            </div>
          </div>
          <total-value-pay
            v-if="(companyOptions.gross_budget_taxation || companyOptions.cam_budget_taxation)"
            :totalPayValue="totalPayValue"></total-value-pay>
        </div>
      </div>
    </div>
    <V1FormBuilder v-if="checkNotEmptyObj(dynamicFormFields)"
                   :dynamic-fields="dynamicFormFields"
                   :dynamicFormModel="dynamicFormModel"
                   :requiredDynamicFields="requiredDynamicFields"
                   :ref="'v1-form-builder'"
                   :locale="locale"
                   @setDynamicFormModel="setDynamicFormModel"/>
    <div class="row mt-3">
      <div class="row" v-if="thresholds.gym_membership.status">
        <div class="col-6 col-md-4">
          <label for="personal_yes" class="form-fields-required col-form-label">
            <input type="radio"
                   name="personal"
                   class="radio-custom cursor-pointer"
                   id="personal_yes"
                   :value="true"
                   v-model="personalUse"/>
            {{$t("flexben.beneficiary_pages.benefits.personal_use_yes")}}</label>
        </div>
        <div class="col-6 col-md-4">
          <label for="personal_no" class="form-fields-required col-form-label">
            <input type="radio"
                   name="personal"
                   class="radio-custom cursor-pointer"
                   id="personal_no"
                   :value="false"
                   v-model="personalUse"/>
            {{$t("flexben.beneficiary_pages.benefits.personal_use_no")}}</label>
        </div>
      </div>

      <div class="col-12 px-0 pr-3 text-center text-md-left">
        <button class="btn btn-themed-green px-2 mr-2 px-md-4" @click="orderNow()"
                :disabled="(thresholds.gym_membership.status && personalUse == null) || lastDayToOrderExceeded ||(dynamicFields.delivery_type !=='card' && dynamicFields.billing_value>remainingBudget)||
                (dynamicFields.delivery_type ==='card' && cardData.top_up_value === 0) ||
                ((companyOptions.gross_budget_taxation || companyOptions.cam_budget_taxation) && totalPayValue>remainingBudget)">
          {{ $t('flexben.beneficiary_pages.order_now') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {BTooltip} from "bootstrap-vue";
import V1FormBuilder from "@/components/flexben/beneficiary_pages/benefits/partials/V1FormBuilder";
import TotalValuePay from "@/components/flexben/beneficiary_pages/benefits/partials/TotalValuePay";
import CamValueInfo from "@/components/flexben/beneficiary_pages/benefits/partials/CamValueInfo";
import moment from "moment";
import flexbenUtils from "@/plugins/flexben_utils";

export default {
  name: "VoucherForm",
  components: {
    'b-tooltip': BTooltip,
    V1FormBuilder,
    'total-value-pay': TotalValuePay,
    'cam-value-info': CamValueInfo
  },
  props: {
    ceBenefit: {
      type: Object,
      required: true
    },
    budgets: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },
    payload: {
      type: Object,
      required: true,
      default: function () {
        return {
          value: 0,
          benefit_id: this.benefitId,
          child_benefit_id: null,
          event_id: null,
          auto_top_up: false,
          ticket_count: 0,
          ticket_value: 0,
          extra: {},
        };
      }
    },
    errorsBag: {
      type: [Object, Array],
      default: function () {
        return {};
      }
    },
    companyOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          cam_budget_taxation: false,
          gross_budget_taxation: false,
          legislated_gross_calculation: false,
          last_monthly_order_day: null,
          budget_telework_transfer_to_general: false,
          cash_out_last_order_day: null,
          non_legislated_net_taxes_calculate: false,
          banner_image_url: null,
        };
      }
    },
    remainingBudget: {
      type: Number,
      default: 0,
    }
  },
  data: function () {
    return {
      formData: {},
      cardData: {
        card_series: null,
        top_up_value: 0,
      },
      requestCard: true,
      personalUse: null,
      salaryDeductibility: 0,
      valueCam: 0,
      totalPayValue: 0,
      dynamicFormFields: {},
      dynamicFormModel: {},
      requiredDynamicFields: {},
      isValidForm: true,
      lastDayToOrderExceeded: false,
      thresholds:{gym_membership: {status:false, value: 0, code: ''}}
    }
  },
  computed: {
    dynamicFields: function () {
      return this.ceBenefit.benefit.dynamic_form.fields;
    },
  },
  watch: {
    cardData: {
      handler: function (obj) {
        this._setSalaryDeductibility(obj.top_up_value);
      },
      deep: true
    },
    personalUse: {
      handler: function (val) {
        this._setSalaryDeductibility(this.dynamicFields.billing_value);
      }
    }
  },
  created() {
    this.setThresholds();
    if (this.dynamicFields.delivery_type === 'card') {
      if (this.ceBenefit.properties.card_series !== null) {
        this.requestCard = false;
        this.cardData.card_series = this.ceBenefit.properties.card_series;
        this.cardData.top_up_value = this.ceBenefit.properties.previous_top_up;
      }
    } else {
      this._setSalaryDeductibility(this.dynamicFields.billing_value);
    }
    if (typeof this.dynamicFields.dynamic_fields !== "string") {
      this.dynamicFormFields = this.dynamicFields.dynamic_fields;
    }
    this.lastDayToOrderExceeded = this.checkIfLastDayToOrderExceeded();
  },
  methods: {
    orderNow() {
      this._validateDynamicFields();
      if (this.isValidForm) {
        this._setPayload();
        this.$emit('orderNow');
      }
    },
    requestCardAct() {
      this.requestCard = !this.requestCard;
      if (!this.requestCard && this.ceBenefit.properties.card_series !== null) {
        this.cardData.card_series = this.ceBenefit.properties.card_series;
      }
    },
    setDynamicFormModel(modelObj) {
      this.dynamicFormModel = modelObj;
    },
    setThresholds(){
      if (this.ceBenefit.benefit.thresholds && Object.values(this.ceBenefit.benefit.thresholds).length) {
        if (this.ceBenefit.benefit.thresholds.gym_membership) {
          this.thresholds.gym_membership.value = this.ceBenefit.benefit.remaining_legal_limit;
          this.thresholds.gym_membership.status = true;
          this.thresholds.gym_membership.code = this.ceBenefit.benefit.thresholds.gym_membership.code;
        }
      }
    },
    checkIfLastDayToOrderExceeded() {
      return this.companyOptions.last_monthly_order_day !== null && this.companyOptions.last_monthly_order_day < parseInt(moment().format('D'));
    },
    _validateDynamicFields() {
      this.isValidForm = true;
      let requiredFields = {}, index, fieldObj;
      for (index in this.dynamicFormFields) {
        fieldObj = {...this.dynamicFormFields[index]};
        if (typeof fieldObj.required !== 'undefined' && fieldObj.required) {
          if (
            ((fieldObj.type === 'checklist' || fieldObj.type === 'vueMultiSelect') && this.dynamicFormModel[fieldObj.model].length === 0) ||
            this.dynamicFormModel[fieldObj.model] === null || this.dynamicFormModel[fieldObj.model].length === 0
          ) {
            requiredFields[fieldObj.model] = 'required';
          }
        }
      }
      if (this.checkNotEmptyObj(requiredFields)) {
        this.isValidForm = false;
        this.requiredDynamicFields = requiredFields;

        // wait for arguments to be passed to child
        setTimeout(function () {
          this._reRenderFormBuilder();
          this._toggleFieldsToComplete(true);
        }.bind(this), 100);
      } else {
        this.isValidForm = true;
      }
    },
    _toggleFieldsToComplete(on) {
      this.$emit('setErrorInAlertModal', [this.$t('flexben.beneficiary_pages.benefits.fields_to_complete')]);
      this.scrollToTop();
    },
    _reRenderFormBuilder() {
      this.$refs['v1-form-builder'].createDynamicModel();
    },
    _setSalaryDeductibility(value) {
      if (this.thresholds.gym_membership.status) {
        if (this.personalUse && this._calculateValueWithThreshold(value)) {
          return;
        }
      }

      let valueToTax = flexbenUtils.calculateGross(value, this.ceBenefit.benefit.global_variables, this.companyOptions.non_legislated_net_taxes_calculate);
      this.salaryDeductibility = (valueToTax - value).toFixed(2);

      if (this.companyOptions.non_legislated_net_taxes_calculate) {
        this.valueCam = value * this.ceBenefit.benefit.global_variables['cam'] / 100;
      } else {
        this.valueCam = valueToTax * this.ceBenefit.benefit.global_variables['cam'] / 100;
      }

      this._calculateTotalByCompanyOptions(value);
    },
    _calculateValueWithThreshold(value) {
      let thresholdRemainingValue = 0;
      let benefit = this.ceBenefit.benefit;

      if (benefit.remaining_legal_limit > 0 && benefit.remaining_legal_limit < value) {
        thresholdRemainingValue = value - benefit.remaining_legal_limit;

        let valueToTax = flexbenUtils.calculateGross(thresholdRemainingValue, benefit.global_variables, this.companyOptions.non_legislated_net_taxes_calculate);
        this.salaryDeductibility = (valueToTax - thresholdRemainingValue).toFixed(2);
        this.valueCam = valueToTax * benefit.global_variables['cam'] / 100;

        this._calculateTotalByCompanyOptions(value);

        return true;

      } else if (benefit.remaining_legal_limit >= value) {
        this.salaryDeductibility = 0;
        this.valueCam = 0;
        this._calculateTotalByCompanyOptions(value);

        return true;
      }

      return false;
    },
    _setPayload() {
      if (this.ceBenefit.benefit.sub_type === 'voucher' && this.dynamicFields.delivery_type === 'card') {
        this.payload.value = this.cardData.top_up_value;
        this.payload.ticket_count = 1;
        this.payload.ticket_value = this.cardData.top_up_value;
        this.payload.benefit_id = this.ceBenefit.benefit_id;
        this.payload.extra = {
          card_data: this.cardData,
          request_card: this.requestCard,
          dynamic_form: this.dynamicFormModel,
          personal_use: this.personalUse
        };
      } else {
        this.payload.value = this.dynamicFields.billing_value;
        this.payload.ticket_count = 1;
        this.payload.ticket_value = this.dynamicFields.billing_value;
        this.payload.benefit_id = this.ceBenefit.benefit_id;
        this.payload.extra = {
          selected_period: this.periodObject,
          dynamic_form: this.dynamicFormModel,
          personal_use: this.personalUse
        };
      }
    },
    _calculateTotalByCompanyOptions(sliderValue) {
      let value = parseFloat(sliderValue);
      if (this.companyOptions.gross_budget_taxation && value > 0) {
        value += parseFloat(this.salaryDeductibility);
      }
      if (this.companyOptions.cam_budget_taxation && value > 0) {
        value += parseFloat(this.valueCam);
      }
      this.totalPayValue = value;
    }
  }
}
</script>
