export default {
  title: "Beneficii fixe",
  subtitle: "Gestioneaza beneficiile fixe",
  new: "Beneficiu fix nou",
  edit: "Modifica beneficiu fix",
  list: "Lista de beneficii fixe",
  modal: {
    confirm_save: 'Esti sigur ca vrei sa salvezi modificarile?',
    confirm_leave: 'Exista informatii modificate care nu au fost salvate. Confirmi parasirea paginii?',
    confirm_delete: 'Esti sigur ca vrei sa stergi beneficiul fix?',
  },
  table: {
    company: "Client",
    name: "Nume",
    description: "Descriere",
  }
}
