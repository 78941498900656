
class PPP3BenefitDetails {
    ceBenefits = null;
    ppp3CeBenefits = {
      ceBenefits: {},
      hasIds: false,
      partner: {
        name: null,
        id: null,
        data: null
      },
      locale: null,
      benefit: {
        image: null,
        name: null,
        color: null,
        description: null,
        advantages: null,
        rationale: null,
        id: null,
      }
    };

    refreshAttributes() {
      this.ppp3CeBenefits = {
        ceBenefits: {},
        hasIds: false,
        partner: {
          name: null,
          id: null,
          data: null
        },
        locale: null,
        benefit: {
          image: null,
          name: null,
          color: null,
          description: null,
          advantages: null,
          rationale: null,
          id: null,
        }
      };
      return this;
    }

    setCeBenefits(ceBenefits) {
      this.ceBenefits = ceBenefits;
      this.setAttributes();
    }

    setAttributes() {
      if (!this.ceBenefits) {
        console.error('No ce benefits set, please use setCeBenefits(), method first')
        return false;
      }


      if (this.ceBenefits.benefit === null) {
        return false;
      }

      const setId = (value) => {
        if (!this.ppp3CeBenefits.benefit.id) {
          this.ppp3CeBenefits.benefit.id = value;
          return;
        }
        this.ppp3CeBenefits.hasIds = !(this.ppp3CeBenefits.benefit.id && this.ppp3CeBenefits.benefit.id !== value);
      }

      const setImage = (value) => {
        if (!this.ppp3CeBenefits.benefit.image) {
          this.ppp3CeBenefits.benefit.image = value;
          return;
        }

        if (this.ppp3CeBenefits.benefit.image !== value) {
          this.ppp3CeBenefits.benefit.image = false;
          console.error('No match benefits -- (image)');
        }
      }

      const setName = (value) => {
        if (!this.ppp3CeBenefits.benefit.name) {
          this.ppp3CeBenefits.benefit.name = value;
          return;
        }

        if (this.ppp3CeBenefits.benefit.name !== value) {
          console.error('No match benefits -- (name)');
          this.ppp3CeBenefits.benefit.name = false;
        }
      }

      const setColor = (value) => {
        if (!this.ppp3CeBenefits.benefit.color) {
          this.ppp3CeBenefits.benefit.color = value;
        }
        if (this.ppp3CeBenefits.benefit.color !== value) {
          console.error('No match benefits -- (color)');
          this.ppp3CeBenefits.benefit.color = false;
        }
      }

      const setDescription = (value) => {
        if (!this.ppp3CeBenefits.benefit.description) {
          this.ppp3CeBenefits.benefit.description = value;
          return;
        }

        if (typeof this.ppp3CeBenefits.benefit.description !== typeof value) {
          console.error('No match benefits -- (description)');
          this.ppp3CeBenefits.benefit.description = false;
        }
      }

      const setRationale = (value) => {
        if (!this.ppp3CeBenefits.benefit.rationale) {
          this.ppp3CeBenefits.benefit.rationale = value;
          return;
        }
        if (typeof this.ppp3CeBenefits.benefit.rationale !== typeof value) {
          console.error('No match benefits -- (rationale)');
          this.ppp3CeBenefits.benefit.rationale = false;
        }
      }

      const setPartner = (value) => {

        if (typeof value !== 'object') {
          return;
        }

        if (!this.ppp3CeBenefits.partner.id) {
          this.ppp3CeBenefits.partner.id = value.id
        }

        if (!this.ppp3CeBenefits.partner.name) {
          this.ppp3CeBenefits.partner.name = value.name
        }

        if (!this.ppp3CeBenefits.partner.data) {
          this.ppp3CeBenefits.partner.data = value
          return;
        }

        if (this.ppp3CeBenefits.partner.id !== value.id) {
          console.error('No match benefits -- (partner.id)');
          this.ppp3CeBenefits.partner.id = null;
        }

        if (this.ppp3CeBenefits.partner.name !== value.name) {
          console.error('No match benefits -- (partner.name)');
          this.ppp3CeBenefits.partner.name = null;
        }
      }

      for ( let ceBenefit in this.ceBenefits) {
          if (this.ceBenefits[ceBenefit].benefit !== undefined) {
            setId(this.ceBenefits[ceBenefit].benefit.id);
            setImage(this.ceBenefits[ceBenefit].benefit.image_url);
            setName(this.ceBenefits[ceBenefit].benefit.name);
            setColor(this.ceBenefits[ceBenefit].benefit.color);
            setDescription(this.ceBenefits[ceBenefit].benefit.description);
            setRationale(this.ceBenefits[ceBenefit].benefit.rationale);
            setPartner(this.ceBenefits[ceBenefit].benefit.partner);
          }
      }

    }

    hasId() {
        return this.ppp3CeBenefits.hasIds;
    }

    getImage() {
      return this.ppp3CeBenefits.benefit.image;
    }

    getName() {
      return this.ppp3CeBenefits.benefit.name;
    }
    getColor() {
      return this.ppp3CeBenefits.benefit.color;
    }
    getDescription() {
      return this.ppp3CeBenefits.benefit.description;
    }

    getRationale() {
      return this.ppp3CeBenefits.benefit.rationale;
    }

    getPartner() {
      return this.ppp3CeBenefits.partner;
    }

}

export default new PPP3BenefitDetails();
