<template>
  <div class="container-bg-white">
    <div class="row p-3">
      <div class="col-12 text-center">
        <h2 class="ml-0" v-html="contactTitle ? contactTitle : $t('flexben.general.contact_us')"></h2>
      </div>
      <div class="col-12 col-md-4 mt-2 d-flex">
        <font-awesome-icon icon="phone" class="mr-1 contact-ico"/>
        <a :href="'tel:+'+phoneNr.replace(' ','')" target="_blank" class="text-themed">{{phoneNr}}</a>
      </div>
      <div class="col-12 col-md-4 mt-2 d-flex">
        <font-awesome-icon icon="envelope" class="mr-1 contact-ico ml-md-auto"/>
        <a :href="'mailto:'+email" target="_blank" class="text-themed mr-md-auto">{{email}}</a>
      </div>
      <div class="col-12 col-md-4 mt-2 d-flex align-items-right">
        <font-awesome-icon icon="link" class="mr-1 contact-ico ml-md-auto"/>
        <a :href="'https://'+website" target="_blank" class="text-themed">{{ website }}</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContactUs",
  props: {
    contactTitle: {
      type: String,
      default: null,
      required: false
    },
    phoneNr: {
      type: String,
      default: '037 466 22 73',
      required: false
    },
    email: {
      type: String,
      default: 'info@upromania.ro',
      required: false
    },
    website: {
      type: String,
      default: 'www.upromania.ro',
      required: false
    },
  }
}
</script>
