export default {
  title: "Solicitari noi",
  title_history: "Solicitari anterioare",
  title_specific_info: "Informatii specifice",
  error_message: "Error message",
  title_placed_at: "Detalii solicitare {number} plasata pe",
  new_requests: "Solicitari noi",
  detail_requests: "Detalii solicitare",
  no_benefit_partner: "Beneficiul solicitarii nu este atribuit unui partener! Contacteaza echipa de supoprt pentru a continua.",
  no_specific_fields: "Beneficiul nu are campuri specifice",
  bou_error: {
    general_message : "Va rugam sa contactati echipa AS400",
    bou_id : "Bou id"
  },
  await_callback: {
    message : "Statusul pentru aceasta solicitare este 'await_callback' - asteptam un raspuns de la UP Online"
  },
  filter: {
    title: "Filtrele solicitari noi",
    field: {
      client: "Client",
      beneficiary: "Beneficiar",
      request_type: "Tip solicitare",
      start_date: "Data de inceput",
      end_date: "Data de sfarsit",
      benefit: "Beneficiu",
      benefit_sub_type: "Subtip beneficiu",
      status: "Stare",
      delivery_address: 'Cod adresa livrare carduri',
      benefit_group: 'Grup de beneficii',
      department: "Departament",
      job_title: "Functie",
      cost_center: "Centru de cost",
      working_point: "Punct lucru",
      partner: "Partener",
      platform_source: 'Sursa platforma'
    },
  },
  types: {
    top_up: 'Alimentare card',
    top_up_live:'Alimentare card - garantie',
    card_request: 'Emitere card',
    voucher: 'Vouchere cadou',
    subscription: 'Abonamente',
    not_legislated: 'Partner',
  },
  table: {
    header: {
      number: 'Nr solicitare',
      placed_on: 'Plasat la data de',
      beneficiary: 'Beneficiar',
      beneficiary_cnp: 'CNP beneficiar',
      client: 'Client',
      request_type: 'Tip solicitare',
      benefit: 'Beneficiu',
      amount: 'Valoare beneficiu',
      status: 'Stare',
      benefit_sub_type: 'Subtip beneficiu',
      delivery_address: 'Adresa de livrare',
      partner: 'Partener',
      taxes: 'Taxe salariu',
      contract_number: 'Nr. Polita',
      contract_verified: 'Polita Verificata',
      platform_source: 'Sursa platforma',
      ticket_count: 'Nr. Tichete',
      ticket_value: 'Valoare tichet'
    },
  },
  summary: {
    title: "Sumar",
    count_total_request: "Total solicitari",
    error_placing: "Eroare la plasare",
    count_top_up_request: "Solicitari de alimentare",
    count_request: "Solicitari de emitere a cardului",
    total_values: "Valoare totala",
    action: "Actiuni",
    order_placed: 'Comanda plasata',
    order_invalid: 'Erori de validare',
    status: {
      invited: "Invitat",
      new: "Nou",
      canceled: "Anulat",
      rejected: "Respins",
      ordered: "Comandat",
      valid: 'Valid',
      invalid: 'Invalid',
      await_top_up: 'In asteptare',
      await_callback: 'Asteapta validare',
      validation_failed: 'Invalid',
      bou_error: 'Eroare BOU',
      await_voucher_upload: 'Asteapta voucher'
    },
  },
  modal: {
    title: "Confirmare",
    confirm_to_reject_request:'Urmează să anulezi solicitarea {benefitName} în valoare de {totalValue} lei',
    confirmed_rejected_request:'Solicitarea a fost anulata!',
    please_confirm_this_action: "Te rugam sa confirmi aceasta actiune.",
    this_will_show_one_cart_type : 'Esti sigur ca iti doreste sa respingi solicitarea ?',
    confirm_mass_place_order: "Esti sigur ca vrei sa plasezi comanda?",
    confirm_mass_place_order_status_new: "Esti sigur ca vrei sa marchezi comanda in status nou?",
    confirm_mark_pending: "Esti sigur ca vrei sa marchezi in asteptare?",
    info_non_legislated_recurring: 'Se vor plasa comenazi doar pentru solicitarile de tip "Pensie privata pilon 3" si "Asigurare medicala".',
    field: {
      reject_reason: "Motivul refuzului",
      confirm_disable_auto_top_ups: "Ati dori sa opriti auto alimentarile pentru beneficiile de tipul {ppp3} si {medical_insurance}?",
    }
  },
  fields_modal: {
    title: "Campuri aditionale",
    no_results: "Nu exista campuri aditionale"
  },
  response: {
    success: "Succes",
    error: "Eroare",
  },
  action: {
    confirm: "Confirma",
    cancel: "Anuleaza",
    refuse_requests: "Refuza solicitarile",
    place_order: "Plaseaza comanda",
  },
  auto_statuses: {
    dejun_on: 'Up Dejun Auto Comanda - ON',
    dejun_off: 'Up Dejun Auto Comanda - OFF',
    gift_on: 'Up Cadou Auto Comanda - ON',
    gift_off: 'Up Cadou Auto Comanda - OFF',
    vacation_on: 'Up Vacanta Auto Comanda - ON',
    vacation_off: 'Up Vacanta Auto Comanda - OFF',
    culture_on: 'Up Cultura Auto Comanda - ON',
    culture_off: 'Up Cultura Auto Comanda - OFF',
  },
  btn: {
    request_type_top_up: 'Alimentare carduri UP',
    request_type_card: 'Emitere carduri UP',
    request_type_partners: 'Alte beneficii flexibile',
    approve_request: 'Aproba solicitarea',
    mark_pending: 'Marcare in asteptare',
    mark_new: 'Marcare in status nou',
    reject_request: 'Respinge solicitarea',
    back_to_list: 'Inapoi la lista de soliciari',
    place_order: "Plaseaza comanda",
    set_delivery_address_to: 'Selecteaza adresa de livrare',
    back_to_requests: 'Inapoi la toate solicitarile',
    export_requests: 'Exporta solicitari',
  },
  fields: {
    value: 'Valoare (Lei)',
    selected_period: 'Perioada abonament',
    voucher_file: 'Fisier voucher',
    voucher_code: 'Cod voucher',
    physical_label: 'Voucher cu livrare fizica',
    physical_text: 'Ia legatura cu beneficiarul pentru a stabili informatiile legate de livrarea fizica a voucherului',
    card_series: 'Serie card existent',
    card_request: 'Solicita emitere card',
    consume_voucher_stock: 'Consumati voucher din stoc?',
    personal_use: 'Uz personal',
  },
  success: {
    line_1: 'Au fost plasate <strong>{count}</strong> comenzi de {scope}',
    line_2: 'Vei regasi comenzile plasate in sectiunea Comenzi.',
    line_2_billing: 'Pentru a urmari statusul comenzii si pentru a putea descarca si plati proforma, te rugam sa mergi la pagina Comenzi accesand butonul de mai jos.',
    scope_top_up: 'alimentare carduri.',
    scope_card: 'emitere carduri.',
  },
  errors: {
    no_voucher_stocks: 'Nu mai exista stocuri pentru acest beneficiu'
  },
  delivering_auto: 'Sistemul proceseaza livrarea voucherului din stoc'
}
