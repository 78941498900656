import Api from "@/multiben-module/src/services/api/api";
import ApiHelper from "@/multiben-module/src/services/helper/apiHelper";

class ApiBenefit {

    prefix = 'beneficiary/';

    api = Api.create();

    token = ApiHelper.getToken();

    baseUrl = ApiHelper.getBaseUrl();

    budgetConsumptionType = 'general'

    setBudgetConsumptionType(type) {
      if (!['general', 'telework'].includes(type)) {
        throw 'Not supported budget type';
      }
      this.budgetConsumptionType = type;
    }

    getBenefitDetailsPPP3(beneficiaryId, benefitId, params) {
        if (!this.token) {
          throw 'Token is not set!'
        }

        return this.api.get(this.baseUrl + this.prefix + beneficiaryId + '/benefit/' + benefitId + '/ppp3Type', {
        headers: {
          Authorization: 'Barier '+ this.token,
          'budget-consumption-type': this.budgetConsumptionType
        },
        params: params
      });
    }

  getBenefitDetails(beneficiaryId, benefitId, params) {
    if (!this.token) {
      throw 'Token is not set!'
    }

    return this.api.get(this.baseUrl + this.prefix + beneficiaryId + '/benefit/' + benefitId, {
      headers: {
        Authorization: 'Barier '+ this.token,
        'budget-consumption-type': this.budgetConsumptionType
      },
      params: params
    });
  }

}


export default new ApiBenefit();
