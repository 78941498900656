<template>
  <div>
    <div class="container-bg-white mt-3">
      <div class="row p-3">
        <div class="col-12 col-md-8 order-2 order-md-1" v-if="partner.contact.site || partner.contact.phone || partner.contact.address">
          <div class="row">
            <div class="col-12">
              <h2 class="mt-0 pt-0 px-0 mb-1">{{ $t('flexben.offers.fields.contact')}}</h2>
            </div>
            <div class="col-12 col-xl-4 mt-2 d-flex"
                 v-if="partner.contact.address">
              <font-awesome-icon icon="map-marker-alt" class="mr-1 contact-ico"/>
              <span class="links-container" v-html="partner.contact.address"></span>
            </div>
            <div class="col-12 col-xl-4 mt-2 d-flex justify-content-center"
                 v-if="partner.contact.phone">
              <font-awesome-icon icon="phone" class="mr-1 contact-ico"/>
              <a :href="'tel:+'+partner.contact.phone" target="_blank" class="text-themed">{{partner.contact.phone}}</a>
            </div>
            <div class="col-12 col-xl-4 mt-2 d-flex justify-content-center"
                 v-if="partner.contact.site">
              <font-awesome-icon icon="link" class="mr-1 contact-ico"/>
              <a :href="partner.contact.site" target="_blank" class="text-themed">{{partner.contact.site}}</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 order-1 order-md-2">
          <div class="d-inline-flex text-left mx-auto container-partner">
            <img :src="partner.image_url"
                 :alt="partner.name" class="offer-partner-image mr-2"
                 width="100">
            <h6 class="my-auto p-0 pt-1">{{ partner.name}}</h6>
          </div>
        </div>

      </div>
    </div>
    <div class="container-bg-white mt-3" v-if="partner.description[locale].length>0">
      <div class="row p-3">
        <div class="col-12">
          <h2 class="mt-0 pt-0 px-0 mb-1">{{ $t('flexben.offers.fields.partner_description')}}</h2>
        </div>
        <div class="col-12 mt-1" v-html="partner.description[locale]">
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "BenefitPartner",
  props: {
    partner: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      default: 'ro',
      required: true,
    }
  }
}
</script>
