import {BCarousel, BCarouselSlide, BTooltip} from "bootstrap-vue";
import httpFlexbenService from "@/http/http-flexben.js";
import About from "../partials/About";
import ContactUs from "../partials/ContactUs";
import moment from "moment";
import ConfirmCancelRequestModal from "../modals/ConfirmCancelRequestModal";
import i18n from "@/plugins/language/i18n";
import RequestsTable from "../partials/RequestsTable";
import AutoTopUpModal from "@/components/flexben/beneficiary_pages/modals/AutoTopUpModal";
import flexbenUtils from "../../../../plugins/flexben_utils";
import BenefitsHorizontalList from "../partials/BenefitsHorizontalList";

export default {
  name: 'BeneficiaryDashboard',
  components: {
    "b-carousel": BCarousel,
    "b-carousel-slide": BCarouselSlide,
    "b-tooltip": BTooltip,
    'flx-request-table': RequestsTable,
    'flx-about': About,
    'flx-contact-us': ContactUs,
    "confirm-cancel-request-modal": ConfirmCancelRequestModal,
    'auto-top-up-modal': AutoTopUpModal,
    BenefitsHorizontalList
  },
  data() {
    return {
      httpService: httpFlexbenService.beneficiary,
      flexbenUtils: flexbenUtils,
      locale: 'ro',
      currentCompanyEmploymentIdentifier: null,
      initialLoading: true,
      loading: true,
      loaderError: false,
      requestStatus: null,
      cancelRequestObj: {id: null, benefit_sub_type: null},
      cancelRequestIndex: null,
      autoTopUp: {
        toggle: false,
        original: false,
        value: 0,
        ticket_count: 0,
        min: 0,
        max: 0,
        benefit_name: '',
        sliderHasFixedValues: false,
        rawSliderFixedValues: [],
        top_up_set_ticket_nr: {
          enable: false,
          ticket_value: null
        }
      },
      budgets: {
        available: {
          total: 0,
          date: null
        },
        year_budget: {
          total: 0,
          year: 2020
        },
        month_allocations: {
          total: 0,
          month: null,
          year: null
        },
        month_taxes: {
          total: 0,
          month: null,
          year: null
        },
        last_budget_credit: {
          total: 0,
          date: null
        },
        telework: {
          total: 0,
          date: null
        }
      },
      benefits: [],
      autoTopupsBenefits: [],
      requests: {
        data: []
      },
      companyOptions: {
        cam_budget_taxation: false,
        gross_budget_taxation: false,
        budget_telework_transfer_to_general: false,
        banner_image_url: null,
      }
    };
  },
  watch: {
    beneficiaryId: {
      handler: function (val) {
        this.loadData(val);
      },
      immediate: true
    }
  },
  computed: {
    beneficiaryId: function () {
      this.loading = false;
      return this.$store.getters.flx_beneficiaryActiveEmploymentId;
    }
  },
  beforeUpdate() {
    this.locale = i18n.locale;
  },
  methods: {
    topUpFromOther(benefitId) {
      sessionStorage.setItem('flexbenFastTopUpBenefitId', benefitId);
      window.location.href = '/beneficiary/multibeneficii/benefits';
    },
    loadData(val) {
      if (val !== null) {
        this.loading = true;
        this.httpService.overview(this.beneficiaryId)
          .then(function (response) {
            this.requests = response.data.latest_request;
            this.benefits = response.data.benefits;
            // this.autoTopupsBenefits = response.data.auto_topups_benefits;
            this.budgets = response.data.budgets;
            this.companyOptions = response.data.company_options;
            this._setAutoDejunTopUpData(this.benefits);

            this.initialLoading = false;
            this.loading = false;
          }.bind(this))
          .catch(function (error) {
            if (error.response.status >= 500 && error.response.status < 600) {
              this.loaderError = true;
            } else {
              this.loading = false
            }
          }.bind(this));
      }
    },
    _setAutoDejunTopUpData(benefits) {
      let benefit;

      for (benefit of benefits) {
        if (benefit.crm_code === 'up_dejun') {
          this.autoTopUp.toggle = benefit.enable_auto_topup;
          this.autoTopUp.original = benefit.enable_auto_topup;
          this.autoTopUp.value = benefit.auto_topup_ticket_value;
          this.autoTopUp.ticket_count = benefit.auto_topup_ticket_count;
          this.autoTopUp.min = benefit.data.values.daily_ticket_min_value.value;
          this.autoTopUp.max = benefit.data.values.daily_ticket_max_value.value;
          this.autoTopUp.sliderHasFixedValues = benefit.top_up_fixed_values_enable;
          this.autoTopUp.rawSliderFixedValues = benefit.top_up_fixed_values_list;
          this.autoTopUp.top_up_set_ticket_nr = benefit.top_up_set_ticket_nr;
          this.autoTopUp.benefit_name = benefit.name;
          break;
        }
      }
    },
    moment(date, format) {
      return moment(date).format(format);
    },
    confirmCancel(index) {
      this.cancelRequestObj = this.requests.data[index];
      this.cancelRequestIndex = index;
      this.$refs['cancel-request-modal'].openModal();
    },
    _resetCancelObj() {
      this.cancelRequestObj = {id: null, benefit_sub_type: null};
      this.cancelRequestIndex = null;
    },
    cancelRequest(toggleDisableAutoTopUp = null) {
      if (this.cancelRequestObj !== null) {
        this.loading = true;
        let params = {};
        if (toggleDisableAutoTopUp) {
          params.toggleDisableAutoTopUp = toggleDisableAutoTopUp;
        }
        this.httpService.orderCancel(this.beneficiaryId, this.cancelRequestObj.id, params)
          .then(function (response) {
            this.requests.data[this.cancelRequestIndex].status = 'canceled';
            this._resetCancelObj();
            this.loading = false;
          }.bind(this))
          .catch(function (error) {
            this.loaderError = true;
          }.bind(this))
      }
    },
    getBudgetExpirationDate(date) {
      if (date === null) {
        return {
          day: '',
          month: '',
          year: '',
        };
      }
      let momentDate = moment(date);
      return {
        day: momentDate.format('DD'),
        month: this.$t('flexben.general.months.' + momentDate.format('M')),
        year: momentDate.year(),
      };
    },
    getLastCreditDate() {
      if (this.budgets.last_budget_credit.date === null) {
        return '';
      }
      let date = moment(this.budgets.last_budget_credit.date);
      return date.format('DD') + ' ' + this.$t('flexben.general.months.' + date.format('M')) + ' ' + date.year();
    },
    openAutoTopUpModal() {
      this.$refs['auto_top_up_modal_component'].openModal();
    },
    toggleAutoTopUp(confirm, ticketValue, ticketCount) {
      if (confirm) {
        this._toggleAutoTopUp(ticketValue, ticketCount);
      } else {
        this._setAutoDejunTopUpData(this.benefits);
      }
    },
    _toggleAutoTopUp(ticketValue, ticketCount) {
      this.loading = true;
      let payload = {
        daily_ticket_value: ticketValue,
        auto_number_of_tickets: ticketCount,
        status: this.autoTopUp.original
      };
      this.httpService.toggleAutoTopup(this.beneficiaryId, payload)
        .then(function (response) {
          this.requestStatus = 'success';
          this.requestMessage = this.$t('flexben.beneficiary_pages.benefits.success_toggle_auto_topup');
          this.autoTopUp.original = response.data.enable_auto_topup;
          this.autoTopUp.value = ticketValue;
          this.loading = false;
        }.bind(this))
        .catch(function (error) {
          if (error.response.status >= 500 && error.response.status < 600) {
            this.loaderError = true;
            this._setAutoDejunTopUpData(this.benefits);
          } else {
            if (error.response.status === 400 && typeof error.response.data.error_fields !== 'undefined') {
              this.errorBag = error.response.data.error_fields;
              this._setAutoDejunTopUpData(this.benefits);
            }
            this.initialLoading = false;
            this.loading = false;
          }
        }.bind(this));
    },
    setBudgetFontClass() {
      if (3 >= this.budgets.available.total.toString().length) {
        return 'budget';
      } else if (5 >= this.budgets.available.total.toString().length) {
        return 'budget-1';
      } else {
        return 'budget-2';
      }
    },
    findOutMore(link) {
      if (link === null) {
        link = 'https://upromania.ro/'
      }
      window.open(link, '_blank');

    }
  },
}
