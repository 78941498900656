export default {
  titles: {
    list: 'Stocuri vouchere',
    import: 'Import',
    details: 'Detalii',
    import_summary: 'Rezumat import',
    import_items: 'Intrari import',
    import_item_details: 'Detalii voucher',
    import_complete: 'Import finalizat cu success!',
  },
  fields: {
    benefit: 'Beneficiu',
    partner: 'Partener',
    benefit_crm_code: 'Cod CRM',
    code: 'Cod voucher',
    start_date: 'Valabilitate start',
    end_date: 'Data expirare',
    expiration_date: 'Data expirare',
    terms: 'Termeni si conditii',
    instructions: 'Instructiuni utilizare',
    campaign_terms: 'Termeni & conditii campanie',
    pin_code: 'Cod pin',
    subscription_period: 'Perioada abonament',
    status: 'Stare',
    comments: 'Mentiuni',
    created_at: 'Data adaugarii',
    placed_at: 'Data comenzii',
  },
  inputLabels: {
    startCreatedAt: 'Start data creare',
    endCreatedAt: 'End data creare',
    startPlacedAt: 'Start data plasare comanda',
    endPlacedAt: 'End data plasare comanda',
  },
  btns: {
    import_example: 'Exemplu import',
    import_start: 'Incepe import',
    import_restart: 'Incepe alt import',
    back_to_list: 'Lista de vouchere',
  },
  summary: {
    in_stock: 'In stoc',
    will_expiring: 'Expira',
    reserved: 'Rezervat',
    used: 'Folosit',
    expired: 'Expirat',
  },
  statuses: {
    0: 'Toate',
    1: 'In stoc',
    2: 'Rezervat',
    3: 'Folosit',
    4: 'Expirat',
    5: 'Va expira',
  },
  import: {
    invalid_reasons: {
      malformed_import_file: 'Fisierul incarcat nu respecta structura',
      import_empty: 'Importul este gol',
    },
    statuses: {
      0: 'Toate',
      1: 'Valid',
      2: 'Invalid',
      3: 'Modificare',
    },
    summary: {
      total: 'Total vouchere',
      new_count: 'Numar intrari noi',
      error_count: '',
      total_update: 'Numar intrari modificate',
    },
    messages: {
      has_invalid_items: 'Importul are vouchere invalide!'
    }
  }
}
