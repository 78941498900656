<!-- TODO :: adjust loading due to slight issue on initial load of property from external var  -->
<template>
  <div :class="{show: this.loaderVisible }" class="modal fade">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-white">
        <div class="modal-body bg-white d-flex flex-column" v-if="loaderError">
          <h4 class="text-danger" v-html="$t('flexben.general.server_error')"></h4>
          <button @click="close()" class="btn btn-themed mx-auto">{{ $t('flexben.general.btn.close') }}
          </button>
        </div>
        <div class="modal-body bg-white" v-else>
          <img src="@/assets/images/loading.gif" class="w-100"/>
          <p class="w-100 text-center">{{ $t('loadingPopupMessage') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Loader',
  props: {
    loaderVisible: {type: Boolean, default: true},
    loaderError: {type: Boolean, default: false},
  },

  methods: {
    close() {
      if (typeof this.$parent.loading !== undefined) {
        this.$parent.loading = false;
        this.$parent.loaderError = false;
      } else {
        this.loaderVisible = false;
        this.loaderError = false;
      }
    }
  }
};
</script>
<style scoped src="../../../node_modules/bootstrap/dist/css/bootstrap.min.css"></style>
<style scoped lang="scss">
.modal {
  z-index: 31000;
  background: rgba(78, 91, 89, 0.9);
}
</style>
