<template>
  <b-modal ref="contract-preview"
           header-class="border-0 text-center"
           :body-class="{'no-border background-white text-center':true,'p-0':true}"
           footer-class="modal-footer border-top-0"
           :no-close-on-esc="true"
           :no-close-on-backdrop="true"
           size="xl"
           centered>
    <template v-slot:default>

      <div class="modal-height">
        <iframe width="100%" height="100%" type="application/pdf"
                :src="'data:application/pdf;base64,'+ imageBase64">
        </iframe>
      </div>
    </template>

    <template v-slot:modal-footer>
      <button type="button"
              @click="closePreview"
              class="btn save-btn mx-auto my-0">
        {{$t("flexben.general.btn.close")}}
      </button>
      <button :disabled="disabledRegister"
        class="btn-themed-green px-3 border-0 btn-page" @click="register">
        {{$t('flexben.affiliate_channels.partner.register.btns.send_contract')}}
      </button>
    </template>
    <br/>
  </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";

export default {
  name: "ContractPreviewModal",
  components: {
    'b-modal': BModal
  },
  props: {
    imageBase64: {
      type: String,
      required: false
    }
  },
  data: function () {
    return {
      disabledRegister: true
    }
  },
  methods: {
    closePreview() {
      this.$refs['contract-preview'].hide();
    },
    showPreview(disabledRegister) {
      this.disabledRegister = disabledRegister;
      this.$refs['contract-preview'].show();
    },
    register() {
      this.closePreview();
      this.$emit('register');
    }
  }
}
</script>

<style scoped>
.modal-height {
  height: 100vh;
}
</style>
