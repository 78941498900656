<template>
  <div class="" :key="this.$store.state.flx_forceRefreshComponent">
    <div v-show="channelExists" class="mbo-body">
      <div class="mbo-orangebg"></div>
      <header class="mbo-hero">
        <div class="mbo-container">
          <nav class="mbo-nav">
            <a href="#" class="mbo-logolink"><img
              src="@/assets/images/flexben/create_affiliate_partner/Logo-Up-Romania.svg" loading="lazy" alt=""
              class="mbo-logo"></a>
            <div class="mbo-langmenu">
              <LanguageBar></LanguageBar>
            </div>
          </nav>
          <div class="mbo-hero-content">
            <div class="mbo-herotext"><img
              src="@/assets/images/flexben/create_affiliate_partner/Logo-Up-MultiBeneficii.svg" loading="lazy" alt=""
              class="mbo-logomultib">
              <h1 class="mbo-h1 bold">{{$t('flexben.affiliate_channels.partner.register.landing_page.text_1')}}</h1>
              <div class="mbo-grouptext">
                <p class="mbo-paragraph">{{$t('flexben.affiliate_channels.partner.register.landing_page.text_17')}}</p>
                <p class="mbo-paragraph">{{$t('flexben.affiliate_channels.partner.register.landing_page.text_18')}}</p>
                <p class="mbo-paragraph">{{$t('flexben.affiliate_channels.partner.register.landing_page.text_19')}}</p>
                <p class="mbo-paragraph">{{$t('flexben.affiliate_channels.partner.register.landing_page.text_20')}}</p>
                <p class="mbo-paragraph">{{$t('flexben.affiliate_channels.partner.register.landing_page.text_21')}}</p>
                <h3 class="mbo-h3 pt-4">{{$t('flexben.affiliate_channels.partner.register.landing_page.text_2')}}</h3>
                <div class="mbo-textwithicon"><img src="@/assets/images/flexben/create_affiliate_partner/CheckIcon.svg"
                                                   loading="lazy" alt="" class="mbo-icon">
                  <p class="mbo-paragraph">{{$t('flexben.affiliate_channels.partner.register.landing_page.text_3')}}</p>
                </div>
                <div class="mbo-textwithicon"><img src="@/assets/images/flexben/create_affiliate_partner/CheckIcon.svg"
                                                   loading="lazy" alt="" class="mbo-icon">
                  <p class="mbo-paragraph">{{$t('flexben.affiliate_channels.partner.register.landing_page.text_4')}}</p>
                </div>
              </div>
              <button v-if="!showForgotAccount" type="button" @click="toggleForgotAccount" class="mbo-btn">
                {{$t('flexben.affiliate_channels.partner.register.forgot_account.active_contract')}}
              </button>
              <button v-else type="button" @click="toggleForgotAccount" class="mbo-btn">
                {{$t('flexben.affiliate_channels.btns.not_partner')}}
              </button>
            </div>
            <div class="mbo-heroform" v-if="!registered && !showForgotAccount">
              <div class="mbo-form">
                <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form">
                  <div class="form-step">
                    <div class="fields-group">
                      <div class="text-field-wrapper half">
                        <input v-model="formData.contract.companyName" type="text" class="text-field w-input"
                               maxlength="256"
                               :class="{'border-danger':(Object.keys(errorsBag).includes('contract.companyName')), 'active' :formData.contract.companyName.length}"
                               name="First-mane" data-name="First mane"
                               placeholder="" id="First-mane" required>
                        <label for="name" class="field-label" :class="{'active': formData.contract.companyName.length}">
                          <p class="text-danger" v-if="Object.keys(errorsBag).includes('contract.companyName')">
                            {{errorsBag['contract.companyName'][0]}} </p>
                          {{ $t('flexben.affiliate_channels.partner.register.form_fields.company_name') }} <span
                          class="mbo-spanreq">*</span></label>
                        <div class="text-field-done" :class="{'active': formData.contract.companyName.length}"></div>
                      </div>
                      <div class="text-field-wrapper half">
                        <input v-model="formData.contract.name" type="text" class="text-field w-input"
                               maxlength="256"
                               :class="{'border-danger':(Object.keys(errorsBag).includes('contract.name')), 'active' :formData.contract.name.length}"
                               name="Last-name" data-name="Last name"
                               placeholder=""
                               id="Last-name" required>
                        <p class="text-danger" v-if="Object.keys(errorsBag).includes('contract.name')">
                          {{errorsBag['contract.name'][0]}} </p>
                        <label for="name-2" class="field-label" :class="{'active': formData.contract.name.length}">
                          {{ $t('flexben.affiliate_channels.partner.register.form_fields.name') }}
                          <span class="mbo-spanreq">*</span></label>
                        <div class="text-field-done" :class="{'active': formData.contract.name.length}"></div>
                      </div>
                      <div class="text-field-wrapper half"><input type="text" class="text-field w-input"
                                                                  maxlength="256"
                                                                  v-model="formData.contract.function"
                                                                  :class="{'border-danger':(Object.keys(errorsBag).includes('contract.function')), 'active' :formData.contract.function.length}"
                                                                  name="First-mane-3" data-name="First Mane 3"
                                                                  placeholder="" id="First-mane-3" required>
                        <label for="name" class="field-label" :class="{'active': formData.contract.function.length}">
                          <p class="text-danger" v-if="Object.keys(errorsBag).includes('contract.function')">
                            {{errorsBag['contract.function'][0]}} </p>
                          {{ $t('flexben.affiliate_channels.partner.register.form_fields.function') }}
                          <span class="mbo-spanreq">*</span></label>
                        <div class="text-field-done" :class="{'active': formData.contract.function.length}"></div>
                      </div>
                      <div class="text-field-wrapper half">
                        <input v-model="formData.contact.email" type="email" class="text-field w-input"
                               maxlength="256" name="Last-name-3"
                               :class="{'border-danger':(Object.keys(errorsBag).includes('contract.email')), 'active' :formData.contact.email.length}"
                               data-name="Last Name 3" placeholder=""
                               id="Last-name-3" required="">
                        <p class="text-danger" v-if="Object.keys(errorsBag).includes('contract.email')">
                          {{errorsBag['contract.email'][0]}} </p>

                        <label for="name" class="field-label" :class="{'active': formData.contact.email.length}">
                          {{ $t('flexben.affiliate_channels.partner.register.form_fields.email') }}
                          <span class="mbo-spanreq">*</span></label>
                        <div class="text-field-done" :class="{'active': formData.contact.email.length}"></div>
                      </div>
                      <div class="text-field-wrapper half">
                        <input v-model="formData.contact.site" type="text" class="text-field w-input"
                               :class="{'border-danger':(Object.keys(errorsBag).includes('contact.site')), 'active' :formData.contact.site.length}"
                               maxlength="256"
                               name="First-mane-3" data-name="First Mane 3"
                               placeholder="" id="First-mane-3">
                        <p class="text-danger" v-if="Object.keys(errorsBag).includes('contact.site')">
                          {{errorsBag['contract.site'][0]}} </p>

                        <label for="name" class="field-label" :class="{'active': formData.contact.site.length}">
                          {{ $t('flexben.affiliate_channels.partner.register.form_fields.site') }}</label>
                        <div class="text-field-done" :class="{'active': formData.contact.site.length}"></div>
                      </div>
                      <div class="text-field-wrapper half"><input v-model="formData.contact.phone" type="tel"
                                                                  class="text-field w-input"
                                                                  maxlength="256"
                                                                  :class="{'border-danger':(Object.keys(errorsBag).includes('contract.phone')), 'active' :formData.contact.phone.length}"
                                                                  name="Last-name-3" data-name="Last Name 3"
                                                                  placeholder="" id="Last-name-3" required>
                        <p class="text-danger" v-if="Object.keys(errorsBag).includes('contract.phone')">
                          {{errorsBag['contract.phone'][0]}} </p>

                        <label for="name" class="field-label" :class="{'active': formData.contact.phone.length}">
                          {{ $t('flexben.affiliate_channels.partner.register.form_fields.phone') }} <span
                          class="mbo-spanreq">*</span></label>
                        <div class="text-field-done" :class="{'active': formData.contact.phone.length}"></div>
                      </div>
                      <div class="text-field-wrapper half"><input v-model="formData.contract.fiscalCode" type="text"
                                                                  class="text-field w-input"
                                                                  maxlength="256"
                                                                  :class="{'border-danger':(Object.keys(errorsBag).includes('contract.fiscalCode')), 'active' :formData.contract.fiscalCode.length}"
                                                                  name="First-mane-3" data-name="First Mane 3"
                                                                  placeholder="" id="First-mane-3" required>
                        <p class="text-danger" v-if="Object.keys(errorsBag).includes('contract.fiscalCode')">
                          {{errorsBag['contract.fiscalCode'][0]}} </p>

                        <label for="name" class="field-label" :class="{'active': formData.contract.fiscalCode.length}">
                          {{ $t('flexben.affiliate_channels.partner.register.form_fields.fiscal_code') }}<span
                          class="mbo-spanreq">*</span></label>
                        <div class="text-field-done" :class="{'active': formData.contract.fiscalCode.length}"></div>
                      </div>
                      <div class="text-field-wrapper half"><input v-model="formData.contract.onrc" type="text"
                                                                  class="text-field w-input"
                                                                  maxlength="256"
                                                                  :class="{'border-danger':(Object.keys(errorsBag).includes('contract.onrc')), 'active' :formData.contract.onrc.length}"
                                                                  name="Last-name-3" data-name="Last Name 3"
                                                                  placeholder="" id="Last-name-3" required="">
                        <p class="text-danger" v-if="Object.keys(errorsBag).includes('contract.onrc')">
                          {{errorsBag['contract.onrc'][0]}} </p>

                        <label for="name" class="field-label" :class="{'active': formData.contract.onrc.length}">
                          {{ $t('flexben.affiliate_channels.partner.register.form_fields.onrc') }} <span
                          class="mbo-spanreq">*</span></label>
                        <div class="text-field-done" :class="{'active': formData.contract.onrc.length}"></div>
                      </div>
                      <div class="text-field-wrapper"><input v-model="formData.contact.iban" type="text"
                                                             class="text-field w-input" maxlength="256"
                                                             name="First-mane-3" data-name="First Mane 3"
                                                             :class="{'border-danger':(Object.keys(errorsBag).includes('contact.iban')), 'active' :formData.contact.iban.length}"
                                                             placeholder=""
                                                             id="First-mane-3" required="">
                        <p class="text-danger" v-if="Object.keys(errorsBag).includes('contact.iban')">
                          {{errorsBag['contact.iban'][0]}} </p>

                        <label for="name" class="field-label" :class="{'active': formData.contact.iban.length}">{{
                          $t('flexben.affiliate_channels.partner.register.form_fields.iban') }}
                          <span class="mbo-spanreq">*</span></label>
                        <div class="text-field-done" :class="{'active': formData.contact.iban.length}"></div>
                      </div>
                      <div class="text-field-wrapper"><input v-model="formData.contact.address" type="text"
                                                             class="text-field w-input" maxlength="256"
                                                             name="First-mane-3" data-name="First Mane 3"
                                                             :class="{'border-danger':(Object.keys(errorsBag).includes('contract.address')), 'active' :formData.contact.address.length}"
                                                             placeholder=""
                                                             id="First-mane-3" required="">
                        <p class="text-danger" v-if="Object.keys(errorsBag).includes('contract.address')">
                          {{errorsBag['contract.address'][0]}} </p>

                        <label for="name" class="field-label" :class="{'active': formData.contact.address.length}">
                          {{ $t('flexben.affiliate_channels.partner.register.form_fields.address')}}
                          <span class="mbo-spanreq">*</span></label>
                        <div class="text-field-done" :class="{'active': formData.contract.address.length}"></div>
                      </div>
                      <div class="text-field-wrapper half">
                        <input v-model="formData.contract.city" type="text" class="text-field w-input"
                               maxlength="256"
                               :class="{'border-danger':(Object.keys(errorsBag).includes('contract.city')), 'active' :formData.contract.city.length}"
                               name="First-mane-3" data-name="First Mane 3"
                               placeholder="" id="First-mane-3" required>
                        <p class="text-danger" v-if="Object.keys(errorsBag).includes('contract.city')">
                          {{errorsBag['contract.city'][0]}} </p>
                        <label for="name" class="field-label" :class="{'active': formData.contract.city.length}">
                          {{ $t('flexben.affiliate_channels.partner.register.form_fields.city') }} <span
                          class="mbo-spanreq">*</span></label>
                        <div class="text-field-done" :class="{'active': formData.contract.city.length}"></div>
                        <div class="text-field-fail"></div>
                      </div>
                      <div class="text-field-wrapper half">
                        <select v-model="formData.contract.county" id="field" name="field" data-name="" required
                                class="select-field w-select">
                          <option value="" selected
                                  :class="{'border-danger':(Object.keys(errorsBag).includes('contract.county')), 'active' :formData.contract.county.length}">
                            {{ $t('flexben.affiliate_channels.partner.register.form_fields.county') }} *
                          </option>
                          <option
                            v-for="(county, index) in counties"
                            :key="index"

                            :value="county.name"
                          >{{ county.name }}
                          </option>
                          <span class="mbo-spanreq">*</span>
                        </select>
                        <p class="text-danger" v-if="Object.keys(errorsBag).includes('contract.county')">
                          {{errorsBag['contract.county'][0]}} </p>
                      </div>
                      <div class="fields-group">
                        <label class="w-checkbox mbo-checkbox">
                          <input type="checkbox"
                                 id="checkbox"
                                 v-model="accept.legalAgreement"
                                 name="checkbox"
                                 data-name="Checkbox"
                                 required=""
                                 class="w-checkbox-input checkbox-2">
                          <span class="mbo-checkboxlabel w-form-label" for="checkbox">
                          {{ $t('flexben.affiliate_channels.partner.register.landing_page.text_13') }} <a href="#"
                                                                                                          @click="showModalPopup('t_c')"
                                                                                                          class="link"><span
                            class="text-span"><strong>{{ $t('flexben.affiliate_channels.t_and_c') }}</strong></span></a>
                          {{$t('flexben.general.and') }} <a href="#" @click="showModalPopup('gdpr')" class="link"><span
                            class="text-span-2"><strong>{{ $t('flexben.affiliate_channels.gdpr') }}</strong></span></a><span>
                        </span> {{ $t('flexben.affiliate_channels.partner.register.landing_page.text_14') }}</span></label>
                        <div class="g-recaptcha">
                          <vue-recaptcha
                            ref="recaptcha"
                            :sitekey="recaptchaKey"
                            @verify="getRecaptchaRP"
                            @expired="onCaptchaExpired"
                            :loadRecaptchaScript="true"
                            badge="bottomleft"
                            language="ro"
                          ></vue-recaptcha>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="mbo-formbtns">
                <button :disabled="registerDisabled" class="mbo-btn form-submit" @click="register">
                  {{$t('flexben.affiliate_channels.partner.register.btns.send_contract')}}
                </button>
                <button :disabled="previewDisabled" class="mbo-btn" @click="showContractPreview">
                  {{$t('flexben.affiliate_channels.partner.register.btns.show_preview')}}
                </button>
              </div>
            </div>

            <div class="mbo-heroform" v-if="registered && !showForgotAccount">
              <div class="row">
                <div class="col-12 col-xl-4 d-flex justify-content-center align-items-start mb-3 mb-xl-0 mx-auto">
                  <div class="ml-2 image-container d-flex align-items-center mx-auto">
                    <img class="ml-5 align-self-center" src="../../../../assets/images/congrats.png"/>
                  </div>
                </div>
              </div>
              <h2 class="text-center">{{ $t("successMessageTitle") }}</h2>
              <p class="text-center lightGrey">
                {{ $t("flexben.affiliate_channels.partner.register.forgot_account.email_sent_with_contract") }}
              </p>
              <p v-if="affiliatedPartnerUrl" class="text-center lightGrey">
                {{ $t("flexben.affiliate_channels.partner.register.forgot_account.invitation_url") }}
              </p>

              <div class="row mt-md-3">
                <div class="col-12 text-center mt-1">
                  <a :href="affiliatedPartnerUrl" class="btn-themed px-3 border-0" target="_blank">
                    {{ $t("flexben.affiliate_channels.partner.register.forgot_account.manage_offers") }}</a>
                </div>
              </div>
            </div>
            <div class="mbo-heroform" v-if="showForgotAccount">
              <forgot-account :landingPageId="formData.contract.landingPageId"
                              @backToRegister="toggleForgotAccount"></forgot-account>
            </div>
          </div>
        </div>
      </header>
      <section class="mbo-benefits">
        <div class="mbo-container">
          <h1 class="mbo-h1 half bold">{{$t('flexben.affiliate_channels.partner.register.landing_page.text_6')}}</h1>
          <div class="mbo-benefitscards">
            <div class="mbo-card">
              <p class="mbo-paragraph">{{$t('flexben.affiliate_channels.partner.register.landing_page.text_7')}}</p>
              <img src="@/assets/images/flexben/create_affiliate_partner/CheckIcon.svg" loading="lazy" alt=""
                   class="mbo-icon icon-fixedup">
            </div>
            <div class="mbo-card">
              <p class="mbo-paragraph">{{$t('flexben.affiliate_channels.partner.register.landing_page.text_8')}}</p><img
              src="@/assets/images/flexben/create_affiliate_partner/CheckIcon.svg" loading="lazy" alt=""
              class="mbo-icon icon-fixedup">
            </div>
            <div class="mbo-card">
              <p class="mbo-paragraph">{{$t('flexben.affiliate_channels.partner.register.landing_page.text_9')}}</p><img
              src="@/assets/images/flexben/create_affiliate_partner/CheckIcon.svg" loading="lazy"
              alt="" class="mbo-icon icon-fixedup">
            </div>
            <div class="mbo-card">
              <p class="mbo-paragraph">{{$t('flexben.affiliate_channels.partner.register.landing_page.text_10')}}</p>
              <img
                src="@/assets/images/flexben/create_affiliate_partner/CheckIcon.svg"
                loading="lazy" alt=""
                class="mbo-icon icon-fixedup">
            </div>
            <div class="mbo-card">
              <p class="mbo-paragraph">{{$t('flexben.affiliate_channels.partner.register.landing_page.text_11')}}</p>
              <img
                src="@/assets/images/flexben/create_affiliate_partner/CheckIcon.svg" loading="lazy" alt=""
                class="mbo-icon icon-fixedup">
            </div>
            <div class="mbo-card">
              <p class="mbo-paragraph">{{$t('flexben.affiliate_channels.partner.register.landing_page.text_12')}}</p>
              <img
                src="@/assets/images/flexben/create_affiliate_partner/CheckIcon.svg" loading="lazy"
                alt="" class="mbo-icon icon-fixedup">
            </div>
          </div>
        </div>
      </section>
      <footer class="mbo-footer">
        <div class="mbo-container">
          <div class="mbo-footernav">
            <div class="mbo-footerlink"><img
              src="@/assets/images/flexben/create_affiliate_partner/iconmonstr-phone-1.svg" loading="lazy" alt=""
              class="mbo-icon footer-icon">
              <a href="tel:0374662273" class="mbo-link">037 466 22 73</a>
            </div>
            <div class="mbo-footerlink"><img
              src="@/assets/images/flexben/create_affiliate_partner/iconmonstr-email-3.svg" loading="lazy" alt=""
              class="mbo-icon footer-icon">
              <a href="mailto:info@upromania.ro?subject=Oferte%20Multibeneficii"
                 class="mbo-link">info@upromania.ro</a>
            </div>
            <div class="mbo-footerlink"><img
              src="@/assets/images/flexben/create_affiliate_partner/iconmonstr-link-7.svg" loading="lazy" alt=""
              class="mbo-icon footer-icon">
              <a href="http://www.upromania.ro" class="mbo-link">www.upromania.ro</a>
            </div>
          </div>
        </div>
      </footer>
    </div>

    <div class="row mt-3" v-show="!channelExists">
      <div class="col-12">
        <div class="card">
          <div class="card-body text-danger text-center">
            {{$t('flexben.affiliate_channels.partner.register.landing_page.text_16')}}
          </div>
        </div>
      </div>
    </div>
    <contract-preview-modal ref="contract-preview-modal"
                            :image-base64="contractPreview"
                            @register="register"></contract-preview-modal>
    <legal-agreement-modal @acceptAgreement="acceptAgreement"
                           ref="legal-agreement-modal"
                           :url-address="legalAgreementUrl"
                           :agreement-type="legalAgreementType"></legal-agreement-modal>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<style src="@/assets/styles/flexben_create_affiliate_partner.css"></style>

<script src="https://unpkg.com/vue-recaptcha@^1/dist/vue-recaptcha.min.js"></script>

<script>
import LanguageBar from "@/components/LanguageBar.vue";
import httpFlexben from "@/http/http-flexben";
import ForgotAccount from "@/components/flexben/affiliate_partners/create/ForgotAccount";
import i18n from "@/plugins/language/i18n";
import ContractPreviewModal from "@/components/flexben/affiliate_partners/create/ContractPreviewModal";
import VueRecaptcha from "vue-recaptcha";
import LegalAgreementModal from "@/components/flexben/affiliate_partners/create/LegalAgreementModal";
import AffiliationConsts from "@/constants/affiliate_partner_consts";

export default {
  name: "CreateAffiliatePartner",
  components: {
    'contract-preview-modal': ContractPreviewModal,
    'legal-agreement-modal': LegalAgreementModal,
    LanguageBar,
    ForgotAccount,
    'vue-recaptcha': VueRecaptcha,
  },
  data: function () {
    return {
      loading: false,
      loaderError: false,
      httpServiceChannels: httpFlexben.affiliationChannels,
      httpServicePartner: httpFlexben.affiliationChannels.partner,
      channelExists: false,
      config: JSON.parse(sessionStorage.getItem("client_jsonFile")),
      showForgotAccount: false,
      errorsBag: {},
      imageChanged: false,
      locale: i18n.locale,
      contractPreview: null,
      legalAgreementUrl: '',
      legalAgreementType: '',
      registered: false,
      affiliatedPartnerUrl: null,
      affiliatedSuccessPageUrl: null,
      affiliatedPartnerUrlId: null,
      partner: null,
      counties: [],
      formData: {
        contact: {
          site: '',
          phone: '',
          address: '',
          email: '',
          iban: '',
        },
        contract: {
          fiscalCode: '',
          onrc: '',
          companyName: '',
          county: '',
          city: '',
          address: '',
          name: '',
          email: '',
          phone: '',
          function: '',
          landingPageId: '',
        }
      },
      accept: {
        t_and_c: false,
        gdpr: false,
        legalAgreement: false,
        recaptcha: false,
      },
      recaptchaKey: null,
      disabled_preview: true,
      disabled_register: true
    };
  },
  created() {
    if (this.$route.fullPath.includes('registration/success')) {
        if (!this.affiliatedPartnerUrlId) {
          this.getSuccessPageDetails();
        }

      this.registered = true;
      this.channelExists = true;
    } else {
      this.formData.contract.landingPageId = this.$route.params.af_id;
      this._checkChannelsExists();
      this.getCounties();
      this.recaptchaKey = process.env.VUE_APP_GOOGLE_RECAPCHA_SITE_KEY;
    }

  },
  computed: {
    previewDisabled: function () {
      return this.disabled_preview;
    },
    registerDisabled: function () {
      return !(this.accept.legalAgreement && this.accept.recaptcha && !this.disabled_register);
    }
  },
  watch: {
    formData: {
      handler: function (val) {
        this.disabled_preview = !(val.contact.phone.length && val.contact.address.length && val.contact.email.length
          && val.contract.fiscalCode && val.contract.onrc.length && val.contract.companyName.length
          && val.contract.county.length && val.contract.city.length
          && val.contract.name.length && val.contract.function.length);

        if (!this.disabled_preview) {
          this.disabled_register = !val.contact.iban.length;
        }
      },
      deep: true
    }
  },
  methods: {
    async _checkChannelsExists() {
      this.loading = true;
      try {
        await this.httpServiceChannels.exists(this.formData.contract.landingPageId).then((response) => {
          if (response.data) {
            this.channelExists = true;
          }
          this.loading = false;
          this.initialLoading = false;
        })
      } catch (error) {
        this.loading = false;
        this.loaderError = false;
      }
    },
    getSuccessPageDetails() {
      this.affiliatedPartnerUrlId = this.$route.params.url_id;
      this.httpServicePartner.getDetails(this.affiliatedPartnerUrlId).then((response) => {
        if (response.data) {
          this.affiliatedPartnerUrl = response.data.partner.affiliated_partner_url;
          this.partner = response.data.partner;
        }
      }).catch((error) => {
        if (error.isAxiosError && error.response.status == 400) {
          this.errorsBag = error.response.data.error_fields;
          this.loading = false;
        } else {
          this.loading = true;
          if (error.response.status !== 401) {
            this.loaderError = true;
          }
        }
      });
    },
    showContractPreview() {
      this.loading = true;
      let payload = this._prepareFormData(true);
      this.httpServicePartner.getContractPreview(payload).then((response) => {
        if (response.data) {
          this.loading = false;

          this.contractPreview = response.data.data;
          this.openPreview();
        }
      }).catch((error) => {
        if (error.isAxiosError && error.response.status == 400) {
          this.errorsBag = error.response.data.error_fields;
          this.loading = false;
        } else {
          this.loading = true;
          if (error.response.status !== 401) {
            this.loaderError = true;
          }
        }
      });
    },
    register() {
      if (!this.accept.legalAgreement || !this.accept.recaptcha) {
        return;
      }

      this.loading = true;
      let payload = this._prepareFormData();
      this.httpServicePartner.register(payload).then((response) => {
        if (response.data) {
          this.loading = false;
          this.initialLoading = false;
          this.registered = true;


          this.affiliatedPartnerUrlId = response.data.partner.url_id;
          this.affiliatedPartnerUrl = response.data.partner.affiliated_partner_url;
        }
        this.redirectSuccessPage();
      }).catch((error) => {
        if (error.isAxiosError && error.response.status == 400) {
          this.errorsBag = error.response.data.error_fields;
          this.loading = false;
        } else {
          this.loading = true;
          this.loaderError = true;
        }
      });
    },
    redirectSuccessPage() {
      this.$gtm.enable(true);
      this.$ga.enable();

      this.$router.push({
        name: 'flexben_registration_success_affiliate_partner',
        params: { url_id: this.affiliatedPartnerUrlId }
      });

      this.$gtm.trackView(
        "Affiliates Landing Page",
        this.$route.fullPath
      );
      this.$ga.event({
        eventCategory: 'Affiliates Landing Page Register',
        eventAction: 'Click',
        eventLabel: 'Register',
      });
    },
    toggleForgotAccount() {
      this.showForgotAccount = !this.showForgotAccount;
    },
    _prepareFormData(preview) {
      let formData = new FormData();
      let isPreview = true;

      if (!preview) {
        isPreview = false;
        formData.append('lang', this.locale);
        formData.append('contact', JSON.stringify(this.formData.contact));
      } else {
        formData.append('isPreview', preview);
      }

      this.formData.contract.phone = this.formData.contact.phone;
      this.formData.contract.address = this.formData.contact.address;
      this.formData.contract.email = this.formData.contact.email;

      formData.append('contract', JSON.stringify(this.formData.contract));
      formData.append('landingPageId', this.formData.contract.landingPageId);

      return formData;
    },
    openPreview() {
      this.$refs['contract-preview-modal'].showPreview(this.disabled_register);
    },
    closePreview() {
      this.$refs['contract-preview-modal'].closePreview();
    },
    scrollIntoView() {
      document.getElementById('register_form').scrollIntoView({behavior: 'smooth', block: 'start'});
    },
    onCaptchaExpired(recaptcha) {
      this.$refs.recaptcha.reset();
      this.accept.recaptcha = false;
    },
    getRecaptchaRP(recaptchaToken) {
      this.accept.recaptcha = true;
    },
    getCounties() {
      this.httpServicePartner.getCounties().then((response) => {
        if (response.data) {
          this.loading = false;
          this.counties = response.data;
        }
      }).catch((error) => {
        this.loading = false;
        this.loaderError = false;
      });
    },
    showModalPopup(type) {
      if (type === 'gdpr') {
        this.legalAgreementUrl = AffiliationConsts.links.gdpr_file_url;
      } else if (type === 't_c') {
        this.legalAgreementUrl = AffiliationConsts.links.t_c_file_url;
      }

      this.legalAgreementType = type;
      this.$refs['legal-agreement-modal'].openModal();
    },
    acceptAgreement(type) {
      if (type === 'gdpr') {
        this.accept.gdpr_read = true;
      }
      if (type === 't_c') {
        this.accept.t_and_c_read = true;
      }

      if (this.accept.t_and_c_read && this.accept.gdpr_read) {
        this.accept.legalAgreement = true;
      }
    }
  }
}
</script>
