export default [
    {
        fileName: "beneficiary/campanie_TAZZ_discount_1032x166.png",
        link:
            "https://tazz.ro/",
        category: "Banner",
        action: "Click",
        label: "Utilizatori_campanie_TAZZ_discount",
    },
    {
        fileName: "beneficiary/banner_blenche.png",
        link:
          "https://www.youtube.com/watch?v=vWEVbyAk7rQ",
        category: "Banner",
        action: "Click",
        label: "Utilizatori_blenche",
    },
    {
        fileName: "beneficiary/banner_sezamo.png",
        link:
            "",
        category: "Banner",
        action: "Click",
        label: "Utilizatori_sezamo",
    },
    {
        fileName: "beneficiary/Cultura_oct_2023.png",
        link:
            "https://upromania.ro/companii/card-cultural/",
        category: "Banner",
        action: "Click",
        label: "Utilizatori_upcultura_vn_210",
    },
    {
        fileName: "beneficiary/banner-TazzTME.jpg",
        link:
            "https://companie.upromania.ro/media/ultimele-noutati/up-permite-plata-tazz/",
        category: "Banner",
        action: "Click",
        label: "Utilizatori_TazzTME",
    },
    {
        fileName: "beneficiary/banner_bonapp_1018x166_.png",
        link:
            "https://bonapp.eco/?utm_source=uponline.ro&utm_medium=afisari&utm_campaign=UPorg&utm_content=bannerHP",
        category: "Banner",
        action: "Click",
        label: "UtilizatoriBonApp",
    },
    {
        fileName: "beneficiary/banner_recommend.jpg",
        link: "https://upromania.ro/recomanda/",
        category: "Banner",
        action: "Click",
        label: "Utilizator_RecomandaCastiga",
    },
    {
        fileName: "beneficiary/banner_gpay.png",
        link: "https://upromania.ro/utilizatori/google-pay/",
        category: "Banner",
        action: "Click",
        label: "Utilizator_GPay",
    },
    {
        fileName: "beneficiary/banner_pay.png",
        link: "https://upromania.ro/utilizatori/up-mobil-pay/",
        category: "Banner",
        action: "Click",
        label: "Utilizator_UpMobilPay",
    },
    {
        fileName: "beneficiary/bannerUVV.png",
        link: "https://catalogulcuvacante.ro/b2c/#/up/catalogs",
        category: "Banner",
        action: "Click",
        label: "Utilizator_ConfigVacante",
    },
];
