<template>
  <div class="container-bg-white mt-3">
    <div class="row p-3">
      <div class="col-12">
        <h3 class="mx-0 my-auto">{{ $t('flexben.general.categories') }}</h3>
      </div>
      <div class="col-12">
        <div class="offer-list-container">
          <div v-for="(item, index) in categories"
               class="offer-card d-flex flex-column ml-0 mx-auto mx-lg-2 mb-3"
               :key="index">
            <img class="offer-card-img cursor-pointer" :src="item.image_url" :alt="item.name[locale]"
                 @click="showCategoryOffers(index)">
            <div class="offer-card-body d-flex flex-column">
              <div class="offer-card-name mt-1 text-capitalize">{{item.name[locale]}}</div>
              <div class="offer-card-actions d-inline-flex mt-2">
                <button class="btn btn-themed" @click="showCategoryOffers(index)">
                  {{ $t('flexben.general.details') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="categories.length===0"
           class="col-12 text-center text-20 text-bold" :key="'list_flexible_benefits'">
        {{ $t('flexben.offers.beneficiary.no_offers')}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoriesList",
  props: {
    categories: {
      type: Array,
      required: true,
      default: function () {
        return [];
      }
    },
    locale: {
      type: String,
      default: 'ro'
    }
  },
  methods: {
    showCategoryOffers(index) {
      this.$emit('showCategoryOffers', index);
    }
  }
}
</script>
