export default {
  app_name: 'Multibenefits',
  admin_dashboard: 'Dashboard',
  affiliation_channels: 'Affiliation channels',
  client_dashboard: 'My company',
  beneficiary_dashboard: 'Overview',
  benefit_groups: 'Benefit groups',
  overview: 'Overview',
  configuration: 'Configuration',
  categories: 'Benefit categories',
  benefits: 'Benefits',
  flexible_benefits: 'Flexible benefits',
  fixed_benefits: 'Fixed Benefits',
  clients: 'Clients',
  beneficiaries: 'Beneficiaries',
  requests: 'Requests',
  new_requests: 'New requests',
  requests_history: 'Requests history',
  orders: 'Orders history',
  import: 'Import',
  import_history: 'Import history',
  offer_category: 'Offer categories',
  offers_administration: 'Adm - Special offers',
  offers: 'Special offers',
  auto_top_ups: 'Auto top-up benefits',
  partners: 'Partners',
  brands: 'Brands',
  reports: 'Reports',
  terms: 'Terms and conditions',
  flexible_voucher_stocks: 'Voucher stocks',
  gdpr: 'Personal data policy',
  telework: 'Telework benefits',
  company_settings: 'Company settings',
  benefit_administration: 'Adm - Benefits',
  request_voucher_history: 'Voucher history',
}
