<template>
  <b-modal id="modal-1"
           ref="auto-topup-modal"
           :hide-header-close="true"
           headerClass="border-0 text-center"
           bodyClass="modal-body no-border background-white text-center"
           footerClass="modal-footer border-top-0"
           centered>
    <template v-slot:modal-header>
      <slot name="header">
        <h2 class="mb-0 mx-auto text-capitalize">{{ $t('flexben.general.confirmation') }}</h2>
      </slot>
    </template>
    <template v-slot:default>
      <div class="text-center"
           v-html="$t('flexben.beneficiary_pages.requests.modal.confirm_message', {request_id:request.id})"></div>
      <div v-if="(request.benefit_sub_type==='ppp3'||request.benefit_sub_type==='medical_insurance')"
           class="text-center mt-3">
        {{ $t('flexben.beneficiary_pages.requests.modal.cancel_auto_topup') }}
        <div class="d-flex mt-2">
          <span class="ml-auto">{{ $t('flexben.general.no') }}</span>
          <b-form-checkbox switch class="mx-3"
                           v-model="toggleDisableAutoTopUp"></b-form-checkbox>
          <span class="mr-auto">{{ $t('flexben.general.yes') }}</span>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="row w-100">
        <div class="col-12 text-center">
          <button class="btn btn-themed mx-auto" @click="confirmAction()">{{ $t('flexben.general.btn.confirm') }}
          </button>
        </div>
        <div class="col-12 text-center mt-2">
          <button class="btn" @click="closeModal()">{{ $t('flexben.general.btn.cancel') }}</button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {BFormCheckbox, BModal} from "bootstrap-vue";

export default {
  name: "ConfirmCancelRequestModal",
  components: {
    'b-modal': BModal,
    'b-form-checkbox': BFormCheckbox,
  },
  props: {
    request: {
      type: Object,
      default: () => {
        return {id: null};
      },
      required: true
    },
  },
  data: () => {
    return {
      toggleDisableAutoTopUp: false
    };
  },
  methods: {
    openModal() {
      this.toggleDisableAutoTopUp = false;
      this.$refs['auto-topup-modal'].show();
    },
    closeModal() {
      this.$refs['auto-topup-modal'].hide();
    },
    confirmAction() {
      this.$emit('confirmAction', this.toggleDisableAutoTopUp);
      this.$refs['auto-topup-modal'].hide();
    }
  },
}
</script>
