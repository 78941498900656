export default {
  new: 'Categorie noua',
  title: "Categorii de beneficii",
  description: "Grupeaza beneficiile pe categorii",
  no_category_message: "",
  save: "Salveaza",
  cancel_changes: "Modificarile curente vor fi pierdute. Esti sigur ca vrei sa continui?",
  delete: "Sterge categorie",
  edit: "Modifica categorie",
  cat_name: "Nume categorie",
  option_default: "Aloca beneficiul",
  benefits: "Beneficii",
  filters: {
    client_scoped: 'Specific clienti',
    general: 'General',
    company: 'Client',
    type: 'Tip categorie'
  },
  table: {
    title: "Categorii",
    new_category: "Categorie noua",
    description: "Descriere",
    benefits: "Beneficii",
    option_default: "Alege unul din beneficii",
    assign: "Aloca",
    benefit: "Beneficiu",
    date_created: "Data creare",
    date_categories: "Categorii",
    date_status: "Stare",
  },
  modal: {
    confirmation: "Confirmare",
    this_will_delete_the_selected_item: "Aceasta actiune va sterge categoria.",
    this_will_save_the_selected_item: "Aceasta actiune va salva categoria.",
    please_confirm_this_action: "Te rugam sa confirmi aceasta actiune",
    continue: "Continua",
    cancel: "Renunta",
    success_message_create: "<div class=\"container\"><div class=\"col-12 mt-2 mb-5\"><div class=\"text-center\"><h3>Succes</h3></div></div><div class=\"col-12 my-2 text-center\">Categoria a fost adaugata.</div></div>",
    success_message_edit: "<div class=\"container\"><div class=\"col-12 mt-2 mb-5\"><div class=\"text-center\"><h3>Succes</h3></div></div><div class=\"col-12 my-2 text-center\">Categoria a fost modificata.</div></div>",
  },
}
