<template>
  <div class="container-fluid">
    <div class="row container-gray py-3">
      <div :class="{
        'col-12 col-md-6':true,
        'col-xl-2':companyOptions.cam_budget_taxation,
      }">
        <span class="flx-type-badge mr-1 ">{{ $t("flexben.benefit.sub_types."+ceBenefit.benefit.sub_type) }}</span>
        <span v-if="thresholds.gym_membership.status && thresholds.gym_membership.value>0" id="threshold-gym-info" class="d-inline-block flexben-tooltip-icon mr-2">i</span>
        <b-tooltip v-if="thresholds.gym_membership.status && thresholds.gym_membership.value>0" :target="'threshold-gym-info'" variant="flexben" placement="topright">
          <div class="text-left">
            {{ $t('flexben.beneficiary_pages.benefits.tooltips.thresholds.' + thresholds.gym_membership.code, {value: thresholds.gym_membership.value}) }}
          </div>
        </b-tooltip>
      </div>
      <div :class="{
      'col-12 col-md-6':true,
      'col-xl-5':companyOptions.cam_budget_taxation,
      }">
        <div :class="companyOptions.cam_budget_taxation ? '' :'float-right'">
          <span class="mr-1" v-html="(ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale] === null) ?
            $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
            : ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale]">
          </span>
          <span id="tooltip_up_dejun_deductibility" class="d-inline-block flexben-tooltip-icon mr-2">i</span>

          <b-tooltip :target="'tooltip_up_dejun_deductibility'" variant="flexben" placement="topleft">
            <div class="text-left">
              {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
            </div>
          </b-tooltip>
          <span class="text-danger"><strong>{{ salaryDeductibility }} Lei</strong></span>
        </div>
      </div>

      <cam-value-info v-if="companyOptions.cam_budget_taxation" :valueCam="valueCam"></cam-value-info>

      <div class="col-12 mt-2 text-danger text-bold text-center"
           v-if="(dynamicFields.billing_value>remainingBudget)">
        {{ $t('flexben.beneficiary_pages.benefits.insufficient_budget') }}
      </div>
      <div class="col-12 text-danger text-center text-bold"
           v-html="$t('flexben.beneficiary_pages.benefits.last_day_to_order_for_next_month', {
        day:dynamicFields.last_day_to_order_for_next_month,start_date: calculateStartDate() })">
      </div>
      <div class="col-12 mt-3">
        <div class="row">
          <div class="col-12 col-md-6 col-xl-4">
            <div class="form-group mb-0">
              <label class="col-form-label pt-0 mr-1 form-fields-required">{{
                $t('flexben.general.chose_county') }}</label>
              <select name="" id="" class="form-control" v-model="selectedCounty"
                      :class="{'border-danger' : typeof requiredFields.location !='undefined'}">
                <option :value="null">-</option>
                <option v-for="(countyGroup, index) of dynamicFields.locations" :key="index"
                        :value="countyGroup">
                  {{ countyGroup.county_name }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="selectedCounty!=null" class="col-12 col-md-6 col-xl-4">
            <div class="form-group mb-0">
              <label class="col-form-label pt-0 mr-1 form-fields-required">{{
                $t('flexben.benefit.world_class_fields.chose_club') }}</label>
              <select name="" id="" class="form-control" v-model="selectedLocationKey"
                      :class="{'border-danger' : typeof requiredFields.location !='undefined'}">
                <option :value="null">-</option>
                <template v-for="(itemObj, index1) of selectedCounty.levels">
                  <optgroup v-if="itemObj.list.length"
                            :label="itemObj['group_by'].toUpperCase()"
                            :key="index1">
                    <option v-for="(location, index2) of itemObj.list" :key="index2"
                            :value="index1+'_'+index2">
                      {{ location.name }}
                    </option>
                  </optgroup>
                </template>
              </select>
            </div>
          </div>
          <div v-if="selectedLocation!=null"
               class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label pt-0 mr-1">
              {{ $t('flexben.benefit.world_class_fields.subscription_type') }}
            </label>
            <div class="d-flex mt-1 align-items-center">
              <span>{{ $t('flexben.benefit.world_class_types.network') }}</span>
              <b-form-checkbox v-if="selectedLocation.local_prices.length"
                               class="ml-2 mr-3" switch
                               :disabled="selectedLocation.local_prices.length ===0"
                               v-model="localPrice">
              </b-form-checkbox>
              <span
                v-if="selectedLocation.local_prices.length">{{ $t('flexben.benefit.world_class_types.local') }}</span>
              <span id="top_up_dejun_information"
                    class="d-inline-block flexben-tooltip-icon ml-2 text-bold">i</span>
              <b-tooltip :target="'top_up_dejun_information'" variant="flexben" placement="topleft">
                <div class="text-left">
                  <span class="text-bold">{{ $t('flexben.benefit.world_class_types.network') }}</span> - {{
                  $t('flexben.benefit.world_class_notices.network_price') }}
                  <template v-if="selectedLocation.local_prices.length">
                    <br>
                    <span class="text-bold">{{ $t('flexben.benefit.world_class_types.local') }}</span> - {{
                    $t('flexben.benefit.world_class_notices.local_price') }}
                  </template>
                </div>
              </b-tooltip>
            </div>
          </div>

        </div>
        <div v-if="selectedLocation!=null" class="row mt-2">
          <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="col-form-label pt-0 mr-1 form-fields-required">{{
              $t('flexben.benefit.world_class_fields.subscription_periods') }}</label>
            <select v-if="localPrice" class="form-control" v-model="subscriptionPeriodIndex"
                    :class="{'border-danger' : typeof requiredFields.subscription_period !='undefined'}">
              <option :value="null">-</option>
              <option v-for="(period, index) of selectedLocation.local_prices" :key="index"
                      :value="index">
                {{ $t('flexben.benefit.subscription_periods.'+period.period_code)}} ({{ period.price.toFixed(2) }}
                Lei)
              </option>
            </select>
            <select v-if="!localPrice" class="form-control" v-model="subscriptionPeriodIndex">
              <option :value="null">-</option>
              <option v-for="(period, index) of selectedLocation.normal_prices" :key="index"
                      :value="index">
                {{ $t('flexben.benefit.subscription_periods.'+period.period_code)}} ({{ period.price.toFixed(2) }}
                Lei)
              </option>
            </select>
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group mb-1">
            <label class="col-form-label form-fields-required pt-0 mr-1">{{ $t('flexben.flexbenFormFields.email')
              }}</label>
            <input type="text" class="form-control" v-model="formData.email"
                   :class="{'border-danger' : typeof requiredFields.email !='undefined'}">
          </div>

          <div class="col-12 col-md-6 col-xl-4 form-group mb-1">
            <label class="col-form-label form-fields-required pt-0 mr-1">{{ $t('flexben.flexbenFormFields.first_name')
              }}</label>
            <input type="text" class="form-control" v-model="formData.first_name"
                   :class="{'border-danger' : typeof requiredFields.first_name !='undefined'}">
          </div>

          <div class="col-12 col-md-6 col-xl-4 form-group mb-1">
            <label class="col-form-label form-fields-required pt-0 mr-1">{{ $t('flexben.flexbenFormFields.last_name')
              }}</label>
            <input type="text" class="form-control" v-model="formData.last_name"
                   :class="{'border-danger' : typeof requiredFields.last_name !='undefined'}">
          </div>

          <div class="col-12 col-md-6 col-xl-4 form-group mb-1">
            <label class="col-form-label form-fields-required pt-0 mr-1">{{ $t('flexben.flexbenFormFields.phone_number')
              }}</label>
            <input type="text" class="form-control" v-model="formData.phone_number"
                   :class="{'border-danger' : typeof requiredFields.phone_number !='undefined'}">
          </div>
          <div class="col-12 col-md-6 col-xl-4 form-group mb-1">
            <label class="col-form-label form-fields-required pt-0 mr-1">{{ $t('flexben.flexbenFormFields.birth_date')
              }}</label>
            <span id="top_up_dejun_birth_date_tooltip"
                  class="d-inline-block flexben-tooltip-icon ml-2 text-bold">i</span>
            <b-tooltip :target="'top_up_dejun_birth_date_tooltip'" variant="flexben" placement="topleft">
              <div class="text-left">
                {{$t('flexben.benefit.world_class_notices.birth_date_tooltip')}}
              </div>
            </b-tooltip>
            <div class="d-inline-flex w-100">
              <font-awesome-icon icon="calendar-alt" class="my-auto mr-2 text-24 flx-calendar-icon"/>
              <datepicker format="dd/MM/yyyy" :inputClass="'form-control-d m-0 w-100 background-white'"
                          v-model="formData.birth_date"
                          :disabledDates="disabledDates"
                          :class="{'w-100':true}"
                          id="budget_expiration_date"></datepicker>
            </div>
          </div>
          <div class="col-12 text-danger text-bold text-center mt-2">
            {{ $t('flexben.benefit.world_class_notices.personal_info') }}
          </div>

          <total-value-pay v-if="companyOptions.gross_budget_taxation || companyOptions.cam_budget_taxation"
                           :totalPayValue="totalPayValue"></total-value-pay>
          <div class="col-12">
            <hr>
          </div>
          <div class="col-12 col-md-6 col-xl-4">
            <h2>{{ $t('flexben.benefit.world_class_fields.facilities') }}</h2>
            <ul class="mb-0">
              <li v-for="(facility, index) of selectedLocation.available_services" :key="index">
                {{ $t('flexben.benefit.world_class_fields.'+facility) }}
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-6 col-xl-8">
            <h2>{{ $t('flexben.benefit.world_class_fields.time_schedule') }}</h2>
            <ul class="mb-0">
              <li>{{ $t('flexben.general.week_days.monday') }}: <b>{{ selectedLocation.time_schedule.monday ?
                selectedLocation.time_schedule.monday : $t('flexben.general.closed') }}</b></li>
              <li>{{ $t('flexben.general.week_days.tuesday') }}: <b>{{ selectedLocation.time_schedule.tuesday ?
                selectedLocation.time_schedule.tuesday : $t('flexben.general.closed') }}</b></li>
              <li>{{ $t('flexben.general.week_days.wednesday') }}: <b>{{ selectedLocation.time_schedule.wednesday ?
                selectedLocation.time_schedule.wednesday : $t('flexben.general.closed') }}</b></li>
              <li>{{ $t('flexben.general.week_days.thursday') }}: <b>{{ selectedLocation.time_schedule.thursday ?
                selectedLocation.time_schedule.thursday : $t('flexben.general.closed') }}</b></li>
              <li>{{ $t('flexben.general.week_days.friday') }}: <b>{{ selectedLocation.time_schedule.friday ?
                selectedLocation.time_schedule.friday : $t('flexben.general.closed') }}</b></li>
              <li>{{ $t('flexben.general.week_days.saturday') }}: <b>{{ selectedLocation.time_schedule.saturday ?
                selectedLocation.time_schedule.saturday : $t('flexben.general.closed') }}</b></li>
              <li>{{ $t('flexben.general.week_days.sunday') }}: <b>{{ selectedLocation.time_schedule.sunday ?
                selectedLocation.time_schedule.sunday : $t('flexben.general.closed') }}</b></li>
            </ul>
          </div>
          <div class="col-12 col-md-6 col-xl-4">
            <h2>{{ $t('flexben.benefit.world_class_fields.phone_number') }}</h2>
            <div class="pl-4">
              {{ selectedLocation.phone_number ? selectedLocation.phone_number : '-' }}
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-8">
            <h2>{{ $t('flexben.benefit.world_class_fields.address') }}</h2>
            <div class="pl-4">
              {{ selectedLocation.address ? selectedLocation.address : '-' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <V1FormBuilder v-if="checkNotEmptyObj(dynamicFormFields)"
                   :dynamic-fields="dynamicFormFields"
                   :dynamicFormModel="dynamicFormModel"
                   :requiredDynamicFields="requiredDynamicFields"
                   :ref="'v1-form-builder'"
                   :locale="locale"
                   @setDynamicFormModel="setDynamicFormModel"/>
    <div class="row" v-if="thresholds.gym_membership.status">
      <div class="col-6 col-md-4">
        <label for="personal_yes" class="form-fields-required col-form-label">
          <input type="radio"
                 name="personal"
                 class="radio-custom cursor-pointer"
                 id="personal_yes"
                 :value="true"
                 v-model="personalUse"/>
          {{$t("flexben.beneficiary_pages.benefits.personal_use_yes")}}</label>
      </div>
      <div class="col-6 col-md-4">
        <label for="personal_no" class="form-fields-required col-form-label">
          <input type="radio"
                 name="personal"
                 class="radio-custom cursor-pointer"
                 id="personal_no"
                 :value="false"
                 v-model="personalUse"/>
          {{$t("flexben.beneficiary_pages.benefits.personal_use_no")}}</label>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 px-0 pr-3 text-center text-md-left">
        <button class="btn btn-themed-green px-2 mr-2 px-md-4" @click="orderNow()"
                :disabled="(thresholds.gym_membership.status && personalUse == null) || !canOrder">
          {{ $t('flexben.beneficiary_pages.order_now') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {BFormCheckbox, BTooltip} from "bootstrap-vue";
import TotalValuePay from "@/components/flexben/beneficiary_pages/benefits/partials/TotalValuePay";
import CamValueInfo from "@/components/flexben/beneficiary_pages/benefits/partials/CamValueInfo";
import moment from "moment";
import V1FormBuilder from "@/components/flexben/beneficiary_pages/benefits/partials/V1FormBuilder";
import Datepicker from "vuejs-datepicker";
import flexbenUtils from "@/plugins/flexben_utils";

export default {
  name: "WorldClassForm",
  components: {
    'b-tooltip': BTooltip,
    V1FormBuilder,
    'total-value-pay': TotalValuePay,
    'cam-value-info': CamValueInfo,
    'b-form-checkbox': BFormCheckbox,
    'datepicker': Datepicker
  },
  props: {
    ceBenefit: {
      type: Object,
      required: true
    },
    budgets: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },
    payload: {
      type: Object,
      required: true,
      default: function () {
        return {
          value: 0,
          benefit_id: this.benefitId,
          child_benefit_id: null,
          event_id: null,
          auto_top_up: false,
          ticket_count: 0,
          ticket_value: 0,
          extra: {},
        };
      }
    },
    errorsBag: {
      type: [Object, Array],
      default: function () {
        return {};
      }
    },
    companyOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          cam_budget_taxation: false,
          gross_budget_taxation: false,
          legislated_gross_calculation: false,
          last_monthly_order_day: null,
          budget_telework_transfer_to_general: false,
          cash_out_last_order_day: null,
          non_legislated_net_taxes_calculate: false,
          banner_image_url: null,
        };
      }
    },
    remainingBudget: {
      type: Number,
      default: 0,
    }
  },
  data: function () {
    return {
      formData: {
        first_name: null,
        last_name: null,
        email: null,
        location: null,
        is_local: false,
        subscription_period: null,
        phone_number: null,
        value: null,
        birth_date: null,
      },
      personalUse: null,
      thresholds:{gym_membership: {status:false, value: 0, code: ''}},
      requestCard: true,
      salaryDeductibility: 0,
      valueCam: 0,
      totalPayValue: 0,
      dynamicFormFields: {},
      dynamicFormModel: {},
      requiredDynamicFields: {},
      requiredFields: {},
      isValidForm: true,
      canOrderForNextMonth: false,
      selectedCounty: null,
      selectedLocationKey: null,
      selectedLocation: null,
      subscriptionPeriodIndex: null,
      localPrice: false,
      obligatoryFields: [
        'first_name',
        'last_name',
        'email',
        'location',
        'subscription_period',
        'phone_number',
        'value',
        'birth_date',
      ],
      disabledDates: {from: moment().toDate()},
    };
  },
  computed: {
    dynamicFields: function () {
      return this.ceBenefit.benefit.dynamic_form.fields;
    },
    canOrder: function () {
      let returnValue = true;
      for (let field of this.obligatoryFields) {
        if (this.formData[field] == null || this.formData[field].length == 0) {
          returnValue = false;
        }
      }

      if (!this._testEmail(this.formData.email)) {
        // returnValue = false;
      }

      if (this.totalPayValue > this.remainingBudget) {
        returnValue = false;
      }
      return returnValue;
    }
  },
  watch: {
    selectedCounty: {
      deep: true,
      handler: function (v) {
        this.selectedLocation = this.selectedLocationKey = null;
      }
    },
    selectedLocationKey: {
      deep: true,
      handler: function (v) {
        this.localPrice = false;
        if (v === null) {
          this.selectedLocation = this.subscriptionPeriodIndex = this.formData.location = null;
          this.formData.subscription_period = this.formData.birth_date = this.formData.email = null;
        } else {
          let keySplit = v.split('_');
          this.selectedLocation = this.selectedCounty.levels[keySplit[0]].list[keySplit[1]];
          this.subscriptionPeriodIndex = null;
          this.formData.location = this.selectedLocation.id;
        }
      }
    },
    subscriptionPeriodIndex: {
      handler: function (v) {
        if (v != null) {
          let obj;
          if (this.localPrice) {
            obj = this.selectedLocation.local_prices[v];
          } else {
            obj = this.selectedLocation.normal_prices[v];
          }
          this.formData.value = obj.price;
          this.formData.subscription_period = obj.id;
          this._setSalaryDeductibility(this.formData.value);
        } else {
          this.formData.value = this.formData.subscription_period = this.formData.birth_date = this.formData.email = null;
        }
      }
    },
    localPrice: {
      handler: function (v) {
        this.formData.is_local = v;
      }
    },
    personalUse: {
      handler: function () {
        this._setSalaryDeductibility(this.formData.value);
      }
    }
  },
  created() {
    this.setThresholds();
    if (typeof this.dynamicFields.dynamic_fields !== "string") {
      this.dynamicFormFields = this.dynamicFields.dynamic_fields;
    }
    this.canOrderForNextMonth = this.dynamicFields.last_day_to_order_for_next_month < parseInt(moment().format('DD'));
    this.formData.first_name = this.ceBenefit.employee_data.first_name;
    this.formData.last_name = this.ceBenefit.employee_data.last_name;
    this.formData.phone_number = this.ceBenefit.employee_data.phone_number;
    this.formData.email = this.ceBenefit.employee_data.email;
  },
  methods: {
    orderNow() {
      this._validateDynamicFields();
      if (this.isValidForm) {
        this._setPayload();
        this.$emit('orderNow');
      }
    },
    requestCardAct() {
      this.requestCard = !this.requestCard;
      if (!this.requestCard && this.ceBenefit.properties.card_series !== null) {
        this.cardData.card_series = this.ceBenefit.properties.card_series;
      }
    },
    setDynamicFormModel(modelObj) {
      this.dynamicFormModel = modelObj;
    },
    setThresholds(){
      if (this.ceBenefit.benefit.thresholds && Object.values(this.ceBenefit.benefit.thresholds).length) {
        if (this.ceBenefit.benefit.thresholds.gym_membership) {
          this.thresholds.gym_membership.value = this.ceBenefit.benefit.remaining_legal_limit;
          this.thresholds.gym_membership.status = true;
          this.thresholds.gym_membership.code = this.ceBenefit.benefit.thresholds.gym_membership.code;
        }
      }
    },
    _validateDynamicFields() {
      this.isValidForm = true;
      let requiredDynamicFields = {}, requiredFields = {}, index, fieldObj;

      for (let field of this.obligatoryFields) {
        if (this.formData[field] == null || this.formData[field].length == 0) {
          requiredFields[field] = 'required';
        }
      }

      if (!this._testEmail(this.formData.email)) {
        requiredFields.email = 'invalid';
      }

      if (this.checkNotEmptyObj(requiredFields)) {
        this.isValidForm = false;
        this.requiredFields = requiredFields;
      }

      for (index in this.dynamicFormFields) {
        fieldObj = {...this.dynamicFormFields[index]};
        if (typeof fieldObj.required !== 'undefined' && fieldObj.required) {
          if (
            ((fieldObj.type === 'checklist' || fieldObj.type === 'vueMultiSelect') && this.dynamicFormModel[fieldObj.model].length === 0) ||
            this.dynamicFormModel[fieldObj.model] === null || this.dynamicFormModel[fieldObj.model].length === 0
          ) {
            requiredDynamicFields[fieldObj.model] = 'required';
          }
        }
      }
      if (this.checkNotEmptyObj(requiredDynamicFields)) {
        this.isValidForm = false;
        this.requiredDynamicFields = requiredDynamicFields;

        // wait for arguments to be passed to child
        setTimeout(function () {
          this._reRenderFormBuilder();
          this._toggleFieldsToComplete(true);
        }.bind(this), 100);
      }
    },
    _toggleFieldsToComplete(on) {
      this.$emit('setErrorInAlertModal', [this.$t('flexben.beneficiary_pages.benefits.fields_to_complete')]);
      this.scrollToTop();
    },
    _reRenderFormBuilder() {
      this.$refs['v1-form-builder'].createDynamicModel();
    },
    _setSalaryDeductibility(value) {
      if (this.thresholds.gym_membership.status) {
        if (this.personalUse && this._calculateValueWithThreshold(value)) {
          return;
        }
      }

      let valueToTax = flexbenUtils.calculateGross(value, this.ceBenefit.benefit.global_variables, this.companyOptions.non_legislated_net_taxes_calculate);
      this.salaryDeductibility = (valueToTax - value).toFixed(2);

      if (this.companyOptions.non_legislated_net_taxes_calculate) {
        this.valueCam = value * this.ceBenefit.benefit.global_variables['cam'] / 100;
      } else {
        this.valueCam = valueToTax * this.ceBenefit.benefit.global_variables['cam'] / 100;
      }

      this._calculateTotalByCompanyOptions(value);
    },
    _calculateValueWithThreshold(value) {
      let thresholdRemainingValue = 0;
      let benefit = this.ceBenefit.benefit;

      if (Object.values(benefit.thresholds).length) {
        if (benefit.remaining_legal_limit > 0 && benefit.remaining_legal_limit < value) {
          thresholdRemainingValue = value - benefit.remaining_legal_limit;

          let valueToTax = flexbenUtils.calculateGross(thresholdRemainingValue, benefit.global_variables, this.companyOptions.non_legislated_net_taxes_calculate);
          this.salaryDeductibility = (valueToTax - thresholdRemainingValue).toFixed(2);
          this.valueCam = valueToTax * benefit.global_variables['cam'] / 100;

          this._calculateTotalByCompanyOptions(value);
          return true;
        } else if (benefit.remaining_legal_limit >= value) {
          this.salaryDeductibility = 0;
          this.valueCam = 0;
          this._calculateTotalByCompanyOptions(value);
          return true;
        }
      }
      return false;
    },
    _setPayload() {
      this.payload.value = this.formData.value;
      this.payload.ticket_count = 1;
      this.payload.ticket_value = this.formData.value;
      this.payload.benefit_id = this.ceBenefit.benefit_id;
      this.payload.extra = this.formData;
      this.payload.extra = {
        first_name: this.formData.first_name,
        last_name: this.formData.last_name,
        email: this.formData.email,
        location: this.formData.location,
        is_local: this.formData.is_local,
        personal_use: this.personalUse,
        subscription_period: this.formData.subscription_period,
        phone_number: this.formData.phone_number,
        value: this.formData.value,
        birth_date: (this.formData.birth_date == null) ? null : moment(this.formData.birth_date).format('YYYY-MM-DD'),
      };

    },
    _calculateTotalByCompanyOptions(rawValue) {
      let value = parseFloat(rawValue);
      if (this.companyOptions.gross_budget_taxation && value > 0) {
        value += parseFloat(this.salaryDeductibility);
      }
      if (this.companyOptions.cam_budget_taxation && value > 0) {
        value += parseFloat(this.valueCam);
      }
      this.totalPayValue = value;
    },
    calculateStartDate() {
      return moment().add(2, 'M').startOf('month').format('DD.MM.YYYY');
    },
    _testEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    }
  }
}
</script>
