<template>
  <b-modal id="modal-1"
           ref="offer-modal"
           :hide-header-close="true"
           headerClass="border-0 text-center"
           bodyClass="modal-body no-border background-white text-center"
           footerClass="modal-footer border-top-0"
           centered>
    <template v-slot:modal-header>
      <h2 class="mb-0 mx-auto text-capitalize">{{ offer.name[locale] }}</h2>
    </template>
    <template v-slot:default>
      {{ $t('flexben.offers.beneficiary.code_is')}} <br><span
      class="text-16 text-bold">{{ offer.code_value }}</span>
    </template>
    <template v-slot:modal-footer>
      <button class="btn text-themed mx-auto" @click="closeModal()">{{ $t('flexben.general.btn.close') }}</button>
    </template>
  </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";

export default {
  name: "OfferCodeModal",
  components: {
    'b-modal': BModal
  },
  props: {
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },
    offer: {
      type: Object,
      required: true,
      default: function () {
        return {
          name: {},
          code_value: ''
        };
      }
    }
  },
  methods: {
    openModal() {
      this.$refs['offer-modal'].show();
    },
    closeModal() {
      this.$refs['offer-modal'].hide();
    }
  },
}
</script>
