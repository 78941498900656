<template>
  <div class="category-list-container mt-3">
    <div
      v-for="(brand,index) in brands"
      :key="index"
      class="category-card d-flex flex-column ml-0 mx-auto mx-lg-2 mb-3"
    >
      <img class="benefit-card-img cursor-pointer" :src="brand.image_url" :alt="brand.name"
           @click="setFilter(brand.id)">
      <div class="category-card-body d-flex flex-column">
        <div class="category-card-name mt-1 text-capitalize">{{ brand.name }}</div>
        <div class="category-card-actions mt-1">
          <button class="btn btn-themed" @click="setFilter(brand.id)">
            {{ $t('flexben.general.details') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlexibleBrandsListing",
  props: {
    brands: [],
  },
  methods: {
    setFilter(id) {
      this.$emit('setFilter', 'brand', id);
    }
  },
}
</script>
