<template>
  <div class="float-left d-inline-flex flexben-top-bar align-items-center"
       :class="showClass && 'flexben-employment-dropdown'"
       v-if="activeCompany !==null">
    <div class="employment-dropdown">
      <b-dropdown variant="white"
                  :no-caret="activeEmployments.length === 1"
                  :disabled="activeEmployments.length === 1"
                  :toggle-class="{ 'text-left p-0 m-0 d-flex  flex-row align-items-center': true, 'cursor-default':(activeEmployments.length === 1) }"
                  :key="'flexben_active_employments'">
        <template v-slot:button-content>
          <div class="employment-item-icon">
            <img src="@/assets/images/flexben/utils/icons8-company-64.png" alt="Companie"/>
          </div>
          <div class="employment-item">
            <span class="name">{{ activeCompany.company_name }}</span>
            <span class="text-ellipsis-40" :class="{hide:!showClass, 'address': showClass}">{{ activeCompany.company_address }}</span>
          </div>
        </template>
        <template v-for="(item, index) in activeEmployments">
        <b-dropdown-item class="dropdown-switcher-company"
                           v-if="index!=activeCompanyIndex"
                         :key="item.employment_id"
                         @click="changeActivateEmployment(index)"
                         link-class="w-100 py-0">
          <div class="employment-item-icon">
            <img src="@/assets/images/flexben/utils/icons8-company-64.png" alt="Companie"/>
          </div>
          <div class="employment-item">
            <span class="name">{{ item.company_name }}</span>
            <span class="address text-ellipsis-40">{{ item.company_address }}</span>
          </div>
        </b-dropdown-item>
        </template>
      </b-dropdown>
    </div>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import {BDropdown, BDropdownItem} from "bootstrap-vue";
import httpFlexbenService from "@/http/http-flexben.js";

export default {
  name: 'FlexbenCompanySwitcher',
  components: {
    'b-dropdown': BDropdown,
    'b-dropdown-item': BDropdownItem,
  },
  props: {
    showClass: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
  },
  computed: {
    activeCompany() {
      return this._getActiveEmployment();
    }
  },
  watch: {},
  data() {
    return {
      loading: false,
      loaderError: false,
      showList: false,
      isVisible: false,
      // activeCompany: {id: null, name: null, address: null},
      activeCompanyIndex: 0,
      activeEmployments: [],
      syncData: {
        auth_token: this.$parent.token,
        link: this.$parent.beneficiaryLink,
        role: this.flxUserLevel().userLevel
      },
      const_activeEmploymentCookie: 'flexbenActiveEmployments',
      canRerenderMainComponent: false
    };
  },
  created() {
    this._loadActiveEmployments();
  },
  methods: {
    changeActivateEmployment(index) {
      //remove selected company employment
      this.activeCompanyIndex = index;
      const activeCompany = JSON.parse(JSON.stringify(this.activeEmployments[index]));
      this._setActiveEmployment(activeCompany);
      if (this.isFlexbenRoute &&
        activeCompany &&
        !this.checkFlxModulePermission(activeCompany.module_permissions, this.$router.currentRoute.meta.module_permission_code || null)) {
        window.location = "/beneficiary/multibeneficii";
      }
      this.$emit('flexbenRerenderComponent', true);
    },
    _loadActiveEmployments() {
      if (this._checkInStorage()) {
        this.activeEmployments = this._getActiveEmployments();
        this._setPreviousCompanyIndex();
        this._setParentFlexbenNavigation();
      } else {
        httpFlexbenService.userService.syncUserData(this.syncData)
          .then((result) => {
            this.activeEmployments = result.data.employments;
            if (this.activeEmployments.length > 0) {
              this._setActiveEmployments(this.activeEmployments);
              this.changeActivateEmployment(this.activeCompanyIndex);
              this._setParentFlexbenNavigation();
            } else {
              this.activeEmployments = [];
            }
          })
          .catch(function (error) {
          });
      }
    },
    _setPreviousCompanyIndex() {
      const obj = this._getActiveEmployment();
      if (obj) {
        for (let index in this.activeEmployments) {
          if (parseInt(this.activeEmployments[index].employment_id) === obj.employment_id) {
            this.activeCompanyIndex = index;
            break;
      }
        }
        this._setParentFlexbenNavigation();
      }
    },
    _checkInStorage() {
      return sessionStorage.hasOwnProperty('flx_active_employments');
    },

    _getActiveEmployments() {
      return JSON.parse(sessionStorage.getItem('flx_active_employments'));
    },
    _setActiveEmployments(employments) {
      sessionStorage.setItem('flx_active_employments', JSON.stringify(employments));
      this.$store.state.flx_beneficiaryActiveEmployments = employments;
    },

    _setActiveEmployment(obj) {
      this.$store.commit('setActiveEmployment', obj);
      sessionStorage.setItem('flx_active_employment', JSON.stringify(obj));
    },
    _getActiveEmployment() {
      if (this.$store.state.flx_beneficiaryActiveEmployment) {
        return this.$store.state.flx_beneficiaryActiveEmployment;
      }
      if (sessionStorage.hasOwnProperty('flx_active_employment')) {
        return JSON.parse(sessionStorage.getItem('flx_active_employment'));
      }
      return null;
    },

    _setParentFlexbenNavigation() {
      this.$emit('showFlexbenComponents')
    }
  },
}
</script>

<style lang="scss">

.flexben-top-bar {
  width: 100%;
  max-width: 30%;
  height: 100%;

  .cursor-default {
    cursor: default !important;
  }

  .employment-dropdown {
    display: inline-block;
    max-width: 100%;

    [disabled="disabled"] {
      background-color: #fff !important;
      opacity: 1;
    }

    .employment-item {
      padding: 10px;
      box-sizing: border-box;
      font-size: 16px;
      height: inherit;

      .address {
        position: relative;
        display: block;
        font-size: 13px;
      }

      .name {
        width: 100%;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .employment-item-icon {
      width: 50px;
      height: inherit;
      position: relative;
      left: 19px;
    }
  }

  @media (max-width: 1004px) {
    .dropdown-toggle::after {
      border-width: 5px;
    }
  }
}
</style>
