export default {
  title: "Import History",
  new_import: "New import",
  data_interval: "Date interval",
  list_of_imported_files: "List of imported files",
  import_on: "Import on",
  import_type: "Tip Import type",
  total_number_of_items: "Total number of items",
  new_items: "New items",
  updated_items: "Updated items",
  import_summary: "Import summary",
  imported_on: "Imported on",
  imported_status: "Imported status",
  imported_type: "Imported type",
  timesheet_imported_for_month: "Timesheet imported for month",
  start_date: "Start date",
  end_date: "End date",
  import_filers: "Import Filters",
  client: "Client",
  titles: {
    beneficiary: "Beneficiary imported on {date}",
    time_sheet: "Timesheet imported on {date}",
    budget: "Budgets imported on {date}",
    update_beneficiary: "Updated beneficiary imported on {date}"
  },
}
