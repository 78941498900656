import $ from "jquery";

function showInfo(id) {
	$("#" + id).toggle("fast");
}

function setFunctionalTxt(b) {
	if (b) {
		$("#txtFunctional").html("Permise");
		$("#txtFunctional").removeClass("txtFade");
	} else {
		$("#txtFunctional").html("Oprite");
		$("#txtFunctional").addClass("txtFade");
	}
}

function setAnalisysTxt(b) {
	if (b) {
		$("#txtAnalisys").html("Permise");
		$("#txtAnalisys").removeClass("txtFade");
	} else {
		$("#txtAnalisys").html("Oprite");
		$("#txtAnalisys").addClass("txtFade");
	}
}

function setMarketingTxt(b) {
	if (b) {
		$("#txtMarketing").html("Permise");
		$("#txtMarketing").removeClass("txtFade");
	} else {
		$("#txtMarketing").html("Oprite");
		$("#txtMarketing").addClass("txtFade");
	}
}

$(document).ready(function() {
	$("#scrollable").on("scroll", function() {
		if (
			$(this).scrollTop() + $(this).innerHeight() >=
			$(this)[0].scrollHeight - 10
		) {
			$("#termeni").prop("disabled", false);
			$("#txtRed").removeClass("red");
			$("#greyLabel").removeClass("lightGrey");
			$("#greyLabel").addClass("darkGrey");
		}
	});

	$("#scrollable2").on("scroll", function() {
		if (
			$(this).scrollTop() + $(this).innerHeight() >=
			$(this)[0].scrollHeight - 10
		) {
			$("#termeni2").prop("disabled", false);
			$("#txtRed").removeClass("red");
			$("#greyLabel2").removeClass("lightGrey");
			$("#greyLabel2").addClass("darkGrey");
		}
	});

	if (document.getElementById("functional") !== null)
		setFunctionalTxt(document.getElementById("functional").checked);

	if (document.getElementById("analisys") !== null)
		setAnalisysTxt(document.getElementById("analisys").checked);

	if (document.getElementById("marketing") !== null)
		setMarketingTxt(document.getElementById("marketing").checked);

	$("#functional").on("change.bootstrapSwitch", function(e) {
		setFunctionalTxt(e.target.checked);
	});

	$("#analisys").on("change.bootstrapSwitch", function(e) {
		setAnalisysTxt(e.target.checked);
	});

	$("#marketing").on("change.bootstrapSwitch", function(e) {
		setMarketingTxt(e.target.checked);
	});

	var hash = window.location.hash;
	hash && $('ul.nav a[href="' + hash + '"]').addClass("show");
	if (hash.length !== 0 && hash !== "#") {
		window.location.hash = "";
	}

	$("#cookieTxt").css("top", $(".nav-bar").outerHeight() + "px");

	$("#accountTxt").css("top", $(".nav-bar").outerHeight() + "px");

	showInfo("cookieInfo");
});
