<template>
  <div>
    <AffiliatePartnerMenu @changeMenu="changeMenu"/>
    <div class="container-right">
      <div class="language-div">
        <LanguageBar/>
      </div>
      <div class="p-3">
        <component :is="activeSubComponent"
                   :affiliatePartnerId="affiliatePartnerId"
                   :urlAddress="this.urlAddress"
                   :accessToken="token"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import AffiliatePartnerMenu from "@/components/layout/partials/AffiliatePartnerMenu.vue";
import OfferList from "@/components/flexben/offers/OfferList.vue";
import httpFlexbenService from "@/http/http-flexben";
import LanguageBar from "@/components/LanguageBar.vue";
import LinkFrame from "@/components/beneficiary/LinkFrame";
import Cookies from "js-cookie";
import UpdateAffiliatePartner from "@/components/flexben/affiliate_partners/create/UpdateAffiliatePartner";
import AffiliationConsts from "@/constants/affiliate_partner_consts";

export default {
  name: "FlexbenAffiliatePartner",
  components: {
    AffiliatePartnerMenu,
    'offer-list':OfferList,
    LanguageBar,
    'link-frame': LinkFrame,
    'update-partner': UpdateAffiliatePartner
  },
  data: function () {
    return {
      config: JSON.parse(sessionStorage.getItem("client_jsonFile")),
      affiliateId: '',
      httpService: httpFlexbenService.affiliationChannels.partner,
      activeSubComponent: 'offer-list',
      showMenu: false,
      urlAddress: '',
      affiliatePartnerId: null,
      token: ''
    };
  },
  created() {
    this.token = Cookies.get("token");

    this.affiliatePartnerId = this.$router.currentRoute.params.af_id;
    if (!this.affiliatePartnerId) {
      // window.location = '/';
      // return;/
    }

    let exists = this._checkPartnerExists()
    if (!this.$router.currentRoute.meta ||
      !exists) {
      window.location = '/';
      return;
    }
    this.showMenu = true;
  },
  methods: {
    async _checkPartnerExists() {
      try {
        const response = await this.httpService.exists(this.affiliatePartnerId);
        return response.data.exists;
      } catch (err) {
        // Handle Error Here
        console.error(err);
        return false;
      }
    },
    changeMenu(selected) {
      switch (selected) {
        case 'editPartner':
          this.activeSubComponent = 'update-partner';
          break;
        case 'list':
          this.activeSubComponent = 'offer-list';
          break;
        case 'gdpr':
          this.urlAddress = this.config.gdpr;
          this.activeSubComponent = 'link-frame';
          break;
        case 'terms':
          this.urlAddress = this.config.gdpr;
          this.activeSubComponent = 'link-frame';
          break;
        case 'exit':
          this.$router.push({
            name: "login",
            params: { loginUserSelected: false }
          });
          break;
        default:
          this.activeSubComponent = 'offer-list';
      }
    }
  }
}
</script>

<style scoped src="@/assets/styles/beneficiary.css"></style>
