<template>
  <div class="content">
    <b-container fluid class="border-box">
      <b-row class="border-box">
        <b-col xl="12" md="12" class="border-box mb-4">
          <h3>
            <span>{{ $t("notificationPageTitle") }}</span>
          </h3>

          <!-- <p>{{ $t("noNotifications") }}</p> -->

          <div
            v-for="(notification, index) in notifications"
            :key="index"
            class="notification-block"
          >
            <div class="notification-title">
              <img v-if="notification.read" src="@/assets/images/happy.png" alt />
              <img v-else src="@/assets/images/sceptic.png" alt />
              <h6>{{ notification.dateIssued | fromNow }}</h6>
            </div>
            <div class="notification-content">
              <div class="d-block w-100">
                <h6>{{ notification.title }}</h6>
                <p>
                  <read-more
                    class="offer-description"
                    :moreStr="$t('readMore')"
                    :text="notification.message"
                    link="#"
                    :lessStr="$t('readLess')"
                    :max-chars="100"
                  ></read-more>
                </p>
              </div>
            </div>
            <div class="notification-footer">
              <p>
                <label class="cursor-pointer custom-checkbox" :class="(selectedNotifications.includes(notification)) && 'culoare-11'">
                  <font-awesome-icon icon="check-circle" size="3x"/>
                  <input 
                    type="checkbox" 
                    :id="'checkbox' + index"
                    @change="notificationChecked($event.target, notification)"
                  />
                </label>
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="selectedNotifications.length > 0" id="actionSelectedElement">
        <b-col xl="12" md="12" class="border-box">
          <h3>
            <span>{{ $t("beneficiariesTableAction") }}</span>
          </h3>
          <div class="container-alb pl-4 pr-4">
            <p class="mb-4">
              {{ $t("actionsNotificationText", { count: this.selectedNotifications.length }) }}
            </p>
            <b-row>
              <b-col cols="3" sm="2" md="2" class="border-box">
                <span>{{ $t("notificationActionSelect") }}:</span>
              </b-col>
              <b-col cols="9" sm="6" md="6" class="border-box">
                <select @change="actionSelected" class="filtrare notification-action-select">
                  <option
                    v-for="(entry, index) in notificationActions"
                    :key="index"
                    :value="entry"
                    :selected="selectedAction === entry"
                  >
                    {{ $t("notificationAction_" + entry) }}
                  </option>
                </select>
              </b-col>
            </b-row>
            <a
              class="status-background background-active mt-4"
              href="#"
              @click="validateSelection()"
              >{{ $t("confirmButtonLabel") }}</a
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import { BContainer, BRow, BCol } from "bootstrap-vue";

export default {
  name: "beneficiary_notifications",
  props: {
    notifications: Array
  },
  components: {
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol
  },
  data() {
    return {
      selectedNotifications: [],
      isSelected: false,
      notificationSelected: null,
      selectedAction: "READ",
      notificationActions: [
        "DELETE",
        "READ",
      ],
    };
  },
  filters: {
    fromNow(date) {
      return moment(date).fromNow();
    }
  },
  methods: {
    isNotificationChecked(entry) {
      for (let index in this.selectedNotifications) {
        if (entry.id === this.selectedNotifications[index].id) {
          return index;
        }
      }
      return -1;
    },
    notificationChecked(element, entry) {
      if (element.checked) {
        for (let index in this.selectedNotifications) {
          if (entry.id === this.selectedNotifications[index].id) {
            return;
          }
        }
        this.notificationSelected = entry;
        this.selectedNotifications.push(entry);
      } else {
        for (let index in this.selectedNotifications) {
          if (entry.id === this.selectedNotifications[index].id) {
            this.selectedNotifications.splice(index, 1);
            this.notificationSelected = null
          }
        }
      }
    },
    actionSelected(event) {
      this.selectedAction = event.target.value;
      switch (this.selectedAction) {
        case "DELETE":
        case "READ":
          document.getElementById("actionSelectedElement").style.visibility =
            "visible";
          break;
        default:
          document.getElementById("actionSelectedElement").style.visibility =
            "hidden";
      }
    },
     validateSelection() {
      if (this.selectedAction === "DELETE") {
        
      }
      else if (this.selectedAction === "READ") {
        
      } else {}
    }
  },
  mounted() {},
  computed: {},
  created() {}
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
.notification-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 20px solid #f1f1f1;
  background: #fff;
  border-radius: 5px;
}

.notification-block .notification-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  text-align: center;
}

.notification-block .notification-title img {
  position: relative;
  width: 40px;
}  

.notification-block .notification-title h6 {
	margin: 10px 0 0;
  padding: 0;
  display: block;
}

.notification-block .notification-content {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  padding: 0 15px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.notification-block .notification-content::before {
  content: '';
  position: absolute;
  top: -15px;
  height: calc(100% + 30px);
  width: 4px;
  background: #f1f1f1;
  left: 0;
}

.notification-block .notification-content::after {
  content: '';
  position: absolute;
  top: -15px;
  height: calc(100% + 30px);
  width: 4px;
  background: #f1f1f1;
  right: 0;
}

.notification-block .notification-content p {
  margin: 0;
}

.notification-block .notification-footer {
  padding: 0 10px;
  display: flex;
  align-items: center;
}
.custom-checkbox {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
</style>
