export default {
  title: "Configurare",
  description: "Gestioneaza setari generale",
  fixed_value: "valoare fixa",
  no_future_values: "Nu exista valori viitoare",
  current_value: "Valoare noua",
  new_value: "Valori viitoare",
  parameter_name: "Nume parametru",
  percent: "procent",
  table: {
    new_config: "Adauga configurare",
    edit_config: "Modifica configurarea",
    current_value: "Valoare curenta",
    future_value: "Valoare viitoare",
  }
}
