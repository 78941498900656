import axios from "axios";
import i18n from "@/plugins/language/i18n";

class Api {

  constructor() {
  }

  create() {
    return axios.create(
      {
        headers: {
          "Content-Type": 'application/json;charset=utf-8',
          Lang: i18n.locale,
        }
      });
  }
}

export default new Api();
