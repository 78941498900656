<template>
  <div class="menu">
    <a href="/company" class="flx-logo">
      <img src="@/assets/images/flexben/flexben-logo.svg"/>
    </a>
    <div class="menu-items d-flex flex-column text-white mt-3">

      <a :class="{'active-menu-link':this.menuSelected.editPartner, 'menu-item':true}" @click="setActive('editPartner')" href="#">{{$t('flexben.affiliate_channels.edit_partner') }}</a>
      <a :class="{'active-menu-link':this.menuSelected.listOffers,  'menu-item':true}" @click="setActive('list')" href="#">{{$t('flexben.offers.title') }}</a>
      <a :class="{'active-menu-link':this.menuSelected.terms, 'menu-item':true}" @click="setActive('terms')" href="#">{{ $t('flexben.affiliate_channels.t_and_c') }}</a>
      <a :class="{'active-menu-link':this.menuSelected.gdpr, 'menu-item':true}" @click="setActive('gdpr')" href="#">{{ $t('flexben.affiliate_channels.gdpr') }}</a>
      <a :class="{'menu-item':true}" @click="$emit('changeMenu', 'exit')" href="#">{{$t('logoutName')}}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "AffiliatePartnerMenu",
  data: () => {
    return {
      menuSelected: {
        editPartner: false,
        listOffers: true,
        terms: false,
        gdpr: false,
        exit: false
      }
    }
  },
  methods: {
    setActive(item) {
      this.resetMenu();
      this.menuSelected[item] = true;
      this.$emit('changeMenu', item);
    },
    resetMenu() {
      this.menuSelected.editPartner = false;
      this.menuSelected.listOffers = false;
      this.menuSelected.terms = false;
      this.menuSelected.gdpr = false;
    }
  }
}
</script>

<style scoped lang="scss">
.active-menu-link {
  color: #f79000!important;
}
.menu {
  .menu-items {
    .menu-item {
      display: block;
      color: #fff;
      padding-left: 30px;
      text-decoration: none;
      margin-top: 25px;
      font-size: 16px;
      text-align: left;

      &:hover {
        color: #f49400;
      }
    }
  }
}
</style>
