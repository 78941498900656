export default {
  title: 'Affiliation channels',
  description: 'Configure invitation links',
  edit_partner: 'Edit profile',
  btns: {
    new: 'Generate link',
    send_again: 'Resend email',
    not_partner: 'Not a partner'
  },
  fields: {
    name: 'Name',
    social_platform: 'Social platform',
    specific_identifier: 'Specific identifier',
    landing_page_id: 'Generated id',
    commercial_identifier: 'Commercial identifier',
    invitation_url: 'Invitation url',
  },
  modal: {
    name: 'Channel name',
    social_platform: 'Social platform',
    specific_identifier: 'Specific identifier',
    commercial_identifier: 'Commercial identifier',
    channel_type: 'Affiliation channel type',
    channel_types: {
      commercial: 'Commercial',
      platform: 'Platform',
    },
  },
  partner: {
    register: {
      title: 'Become Up MultiBeneficii partner',
      forgot_account: {
        title: 'Forgot your invitation link?',
        description: 'Enter your fiscal code and you will receive your invitation link on your email',
        email_sent: 'Your invitation link will be sent on your email address ',
        email_sent_with_contract: 'Your affiliation contract and invitation link will be sent on your email address ',
        invitation_url: 'Now you can create your customized offer!',
        manage_offers: 'Manage offers',
        active_contract: 'Already a partner?',
        fields: {
          cif: 'Fiscal code'
        }
      },
      form_fields: {
        image: 'Image',
        description: 'Tell us something about you',
        site: 'Website',
        phone: 'Phone Nr.',
        address: 'Address',
        email: 'Email',
        fiscal_code: 'Fiscal code',
        onrc: 'Trade register code',
        company_name: 'Company name',
        county: 'County',
        city: 'Locality',
        name: 'Contact name',
        capital: 'Capital',
        iban: 'IBAN',
        signature: 'Signature',
        contract_nr: 'Nr Contract',
        function: 'Function',
      },
      btns: {
        send_contract: 'Send',
        show_preview: 'Preview'
      },
      tool_tip: {
        iban: 'International bank account number',
        fiscal_code: 'Fiscal code number (CIF)',
        onrc: 'National trade register number ',
        capital: 'Financial social capital'
      },
      landing_page: {
        text_1: "Upload your offers on Up MultiBeneficii platform & increase your sales!",
        text_2: "Become a partner in 2 easy steps",
        text_3: "in the registration form (your contract will be automatically sent through email)",
        text_4: "Add your offers in your personalized Offers section",
        text_5: "OR",
        text_6: "Advantages of joining Up MultiBeneficii platform:",
        text_7: "You get free promotion of your services on Up MultiBeneficii platform",
        text_8: " We give you the opportunity to make your products visible, through an effective promotional mix",
        text_9: "You have Up MultiBeneficii platform users interacting with your offers 24/7",
        text_10: "You have access to a premium community",
        text_11: "We provide free marketing consultation in creating and improving your offers",
        text_12: "You get a new channel to promote your products and services",
        text_13: "I have read",
        text_14: "and I accept",
        text_15: "I have read and I accept ",
        text_16: "Sorry, we couldn't find this page. Check your invitation link or contact us.",
        text_17: "Up MultiBeneficii is an online platform, allowing employees in Romania to choose additional benefits based on a budget allocated by their employer.",
        text_18: "Thousands of employees in Romania use the Up MultiBeneficii platform to purchase products and services from a varied range.",
        text_19: "The platform is similar to an e-shop, and the employees can select various services or products.",
        text_20: "By filling out the form, you have access to the dedicated Special Offers page.",
        text_21:"You can add your offers, to be viewed by thousands of employees with access to the platform."
      }
    }
  },
  t_and_c: "Terms and conditions",
  gdpr: "Personal data policy",
}
