export default {
  titles: {
    list: 'Voucher Stocks',
    import: 'Import',
    details: 'Details',
    import_summary: 'Import summary',
    import_items: 'Import items',
    import_item_details: 'Voucher details',
    import_complete: 'Import finished successfully!',
  },
  fields: {
    benefit: 'Benefit',
    partner: 'Partner',
    code: 'Voucher code',
    benefit_crm_code: 'CRM Code',
    start_date: 'Valability start',
    end_date: 'Expiration date',
    terms: 'Term and conditions',
    instructions: 'Usage instructions',
    pin_code: 'Pin code',
    campaign_terms: 'Campaign Tem & conditions',
    subscription_period: 'Subscription period',
    status: 'Status',
    comments: 'Comments',
    created_at: 'Created at',
    placed_at: 'Order placed at',
  },
  inputLabels: {
    startCreatedAt: 'Start created at',
    endCreatedAt: 'End created at',
    startPlacedAt: 'Start placed order at',
    endPlacedAt: 'End placed order at',
  },
  summary: {
    in_stock: 'In stock',
    will_expiring: 'Expiring',
    reserved: 'Reserved',
    used: 'Used',
    expired: 'Expired',
  },
  btns: {
    import_example: 'Import example',
    import_start: 'Start import',
    import_restart: 'Start another import',
    back_to_list: 'Vouchers list',
  },
  statuses: {
    0: 'All',
    1: 'In stock',
    2: 'Reserved',
    3: 'Used',
    4: 'Expired',
    5: 'Will expire',
  },
  import: {
    invalid_reasons: {
      malformed_import_file: "Import file doesn't respect the structure",
      import_empty: 'Import is empty',
    },
    statuses: {
      0: 'All',
      1: 'Valid',
      2: 'Invalid',
      3: 'Updating',
    },
    summary: {
      total: 'Total vouchers',
      new_count: 'Number of updated items',
      error_count: '',
      total_update: 'Number of modified entries',
    },
    messages: {
      has_invalid_items: 'Import has invalid items!'
    }
  }
}
