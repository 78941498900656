export default {
  title: "| Istoric comenzi | Comenzi pentru {requestType}",
  description: '{count} comenzi in asteptare',
  order_history: 'Istoric comenzi',
  place_orders: 'Comanda noua',
  count: 'comenzi in asteptare',
  filter_orders: 'Filtrare comenzi',
  client: 'Client',
  order_type: 'Tipul comenzii',
  order_amount: 'Totalul comenzii',
  benefit: 'Beneficiu',
  date_between: 'Data de inceput',
  and: 'Data de sfarsit',
  order_fragment: 'Comanda',
  place_on: 'plasata la',
  orders: 'Comenzi',
  order_number: 'Numarul comenzii',
  type: 'Tip',
  types: {
    top_up: 'Alimentare card',
    top_up_live: 'Alimentare card - garantie',
    card_request: 'Solicitare card',
    not_legislated: 'Parteneri',
  },
  benefits: 'Beneficii',
  summary: 'Detalii comanda',
  placed_at: 'Plasata la',
  amount: 'Total',
  total_requests_value: 'Total valoare beneficii comandate',
  request_amount: 'Valoare beneficiu',
  status: 'Stare',
  steps: {
    add_new_card_or_top_up_order: "Adauga un card nou sau o comanda de alimentare",
    greetings_question: 'Ce tip de comanda doresti sa plasezi?',
    add_new_top_up_order: "Adauga o comanda noua de alimentare",
    add_new_card_order: "Adauga o comada noua de alimentare card",
    please_select_the_requests_you_want_to_include_in_the_order: "Selecteaza solicitarile pe care doresti sa le incluzi in comanda",
    please_review_the_selected_requests: 'Examineaza solicitarile selectate',
    please_select_the_benefit_you_want_to_order: "Selecteaza beneficiul pe care doresti sa il comanzi",
    department: "Departament",
    job_title: "Functie",
    cost_center: 'Centru de cost',
    working_point: 'Punct lucru',
    group: 'Grup',
    beneficiary_status: 'Stare beneficiar',
    beneficiary: 'Beneficiar',
    request_number: 'Numar solicitare',
    cnp: 'CNP',
    no_items_found: 'Nu s-au gasit solicitari',
    actions: 'Actiuni',
    number_of_selected_requests: 'Numarul de solicitari selectate:',
    valid_entries: 'Solicitari valide:',
    invalid_entries: 'Solicitari invalide:',
    delivery_address: 'Adresa de livrare',
    new_requests: "Solicitari noi",
    your_card_request_order_is_now_placed: "Comanda a fost plasata.<br /><br /><strong>{countPlaceOrders}</strong> noi carduri vor fi emise. Poti gasi comenzile plasete pentru companie in sectiunea Comenzi",
    your_top_up_order_is_now_placed: "Comanda a fost plasata. <strong>{countPlaceOrders}</strong> noi alimentari vor fi procesate.<br /><br />Suma totala de alimentare: <strong>{totalAmount} Lei</strong><br /><br />Comenzile plasate pentru compania, pot fi gasite in sectiunea Comenzi",
    for_selected: 'Pentru {count} inregistrari selectat(e)',
    buttons: {
      card_request: 'Comanda card',
      top_up: 'Alimentare card',
      reject_selected_order_request: 'Refuza {countOrderRequest} solicitare(i)',
      set_delivery_address_to: 'Selecteaza adresa de livrare',
      remove_selected_request_from_the_order: 'Elimina {countOrderRequest} solicitare(i) din comanda',
      retry: 'Reincearca',
      place_order: 'Plaseaza comanda',
      go_to_requests: 'Mergi la solicitari',
      go_to_orders: 'Mergi la comenzi',
    }
  },
  modal: {
    header_title: 'Comanda {orderNumber} plasata la {placedAt}',
    next_step: 'Pasul urmator',
    select_client_company_to_add_new_order: 'Selecteaza clientul pentru a adauga o comanda noua',
  },
  error: {
    beneficiary_does_not_have_card: 'Beneficiarul nu detine un card active pentru beneficiul selectat.',
    card_error_empty_list: "Comanda trimisa nu contine nici un beneficiar cu numar de tichete mai mare ca 0.",
    card_error_customer: "Codul din excel este diferit de codul de client pentru care se face comanda.",
    card_error_mandatory: "Campul {field} pentru angajatul {name} este obligatoriu.",
    card_error_already: "Cardul pentru {name} a fost deja emis.",
    card_error_already_deleted: "Angajatul {name} a fost sters, dar are card activ. Daca doresti reactivarea te rugam sa contactezi UpRomania.",
    card_error_length: "Campul {field} pentru angajatul {name} are completate prea multe caractere.",
    card_error_duplicate: "Campul {field} pentru angajatul {name} este duplicat.",
    card_error_invalid: "Campul {field} pentru angajatul {name} are o valoare invalida.",
    card_error_load_month: "Nr. tichete pentru angajatul {name} este invalid.",
    card_error_load_value: "Campul {field} pentru angajatul {name} este obligatoriu.",
    card_error_load_value_invalid: "Valoarea nominala {field} pentru angajatul {name} nu este valida pentru produsul selectat.",
    card_error_load_value_min: "Valoarea nominala pentru angajatul {name} nu poate fi mai mica decat {field} lei.",
    card_error_load_value_max: "Valoarea nominala pentru angajatul {name} nu poate fi mai mare decat {field} lei.",
    card_error_address_not_found: "Codul de adresa pentru angajatul {name} nu este valid.",
    card_error_not_validrenew: "Cardul pentru {name} nu este eligibil pentru reinnoire, deci nu este necesara anularea reinnoirii automate.",
    card_error_not_ivr_mobile: "Beneficiarul {name} nu are numar de telefon valid introdus si de aceea nu se poate face comanda.",
    card_error_not_validstatus: "Statusul cardului pentru {name} nu este valid pentru actiunea selectata.",
    card_error_not_valid_reissue: "Cardul pentru {name} nu este valid pentru reemitere. Este necesara comanda de card nou.",
    card_error_not_found: "Nu există card pentru acest CNP.",
    card_error_not_active: "Cardul pentru {name} nu este încă activ.",
    card_error_not_validtoday: "Statusul cardului pentru {name} nu este valid pentru actiunea selectata.",
    card_error_renew_invalid: "Cardul {name} nu este valid pentru a fi reinnoit.",
    card_error_renew_notfound: "Nu există card pentru acest CNP",
    card_error_already_repl: "Mai exista o cerere efectuata astazi pentru {name}.",
    this_order_status_has_been_changed: 'Starea solicitarii a fost schimbata.',
  }
}
