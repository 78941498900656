export default {

  druid_getToken() {
    var match = document.cookie.match(new RegExp("(^| )druidConversationToken=([^;]+)"));
    return match ? match[2] : "";
  },

  druid_setToken(token) {
    document.cookie = 'druidConversationToken=' + token + "; path=/;";
  },

  druid_deleteToken(tokenProp) {
    document.cookie = 'druidConversationToken=null; path=/;';
    localStorage[tokenProp] = null;
  },

  druid_deleteTokens() {
    document.cookie = 'druidConversationToken=null; path=/;';
    for (var prop in localStorage) {
      if (prop.indexOf("druid_token") !== -1) localStorage[prop] = null;
    }
  },

  druid_getExpDate() {
    return localStorage.druid_expDate ? parseInt(localStorage.druid_expDate) : null;
  },

  druid_setExpDate(days) {
    days = days || 1;
    // save in milliseconds
    localStorage.druid_expDate = Date.now() + days * 24 * 3600 * 1000;
  },

  druid_setActiveBot(name) {
    localStorage.druid_activeBot = name;
  },

  druid_getActiveBot() {
    return localStorage.druid_activeBot ? localStorage.druid_activeBot : null
  },

  druid_getBotByName(options, name) {
    for (var bot in options) {
      if (options[bot].name === name) return options[bot];
    }
    throw new Error("no bot with desired name")
  }
}
