export default {
  name: "Name",
  invoice_name: 'Invoice name',
  website: "Website",
  partner: 'Partner',
  crm_code: "Crm code",
  company: "Company",
  rationale: "Rationale",
  description: "Description",
  start_use_date: "Start use date",
  end_use_day_month: "End use day month",
  categories: "Categories",
  image: "Image (file)",
  future_values: "Future values",
  value: 'Value',
  type: 'Type',
  future_value: 'Future value',
  future_start_date: 'Valid from date',
  day: 'Day',
  month: 'Month',
  year: 'Year',
  working_days: 'Working days',
  budget_expiration_date: 'Budget expiration date',
  total_budget: 'Total budget',
  first_name: 'First name',
  last_name: 'Last name',
  email: 'Email',
  phone_number: 'Phone number',
  benefit_group: 'Benefit group',
  event: 'Event',
  event_date: 'Event date',
  file: 'File',
  group_name: 'Group name',
  event_start_date: 'Event start date',
  event_end_date: 'Event end date',
  ticket_value: 'Ticket value',
  minimum_value_per_order: 'Minimum value per order',
  maximum_legal_value_per_month: 'Maximum value per month',
  maximum_legal_value_per_event: 'Maximum value per event',
  maximum_legal_value_per_year: 'Maximum value per year',
  not_in_range: 'no in rage',
  benefits: 'Benefits',
  expiration_date: 'Expiration date',
  maximum_topup_limit: 'Maximum top-up limit',
  maximum_topup_limit_per_minor: 'Maximum top-up limit per minor',
  cnp: 'Personal numeric code (CNP)',
  underage_children_nr: 'Number of underages children',
  benefit_category_code: 'Benefit group',
  up_ro_address_id: 'Card delivery address code',
  budget: 'Budget',
  benefit: 'Benefit',
  occasion: 'Occasion',
  require: 'You must',
  requests: 'Requests',
  date: 'Data',
  address: 'Address',
  code_value: 'Code value',
  selected_counties: 'Selected counties',
  selected_companies : 'Clients',
  offer_category: 'Offer category',
  usage_instructions: 'Usage instruction',
  end_use_date: 'End use date',
  partner_name: 'Partner name',
  partner_image: 'Partner image (file)',
  contact_phone: 'Phone',
  contact_site: 'Website',
  iban: 'IBAN bank',
  voucher_digital_code: 'Voucher digital (code)',
  voucher_digital_file: 'Voucher digital (file)',
  voucher_billing_value: 'Voucher value',
  sub_type: 'Sub type',
  end_use_day_month_day: 'Month of latest annual day for placing orders',
  end_use_day_month_month: 'Latest annual day for placing orders',
  selected_period_code: 'Subscription period',
  delivery_type: 'Delivery type',
  dynamic_fields: 'Dynamic fields',
  partner_description: 'Partner description',
  up_dejun_enable_auto_topup: 'Auto topup Up Dejun',
  up_dejun_ticket_value: 'Auto topup ticket value Up Dejun',
  income_tax_percent: 'Income tax percent',
  cas_percent: 'CAS percent',
  cass_percent: 'CASS percent',
  fixed_benefit_group_code: 'Fixed benefits group',
  client: 'Fixed benefits group',
  contract_number: 'Contract number',
  recurring_value: 'Monthly topup value',
  name_mix: "Last name+First name",
  birth_date: "Birth date",
  points_pago: "Pago points equivalent",
  gym_membership_threshold_limit : 'Gym membership threshold limit incorrect',
  auto_topup_not_legislated: 'Top Up Non Legislated',
  order_exists: 'Order request',
  company_employee: 'Company employee',
  company_employee_benefit: 'Company employee benefit',
  vat: "VAT",
  scope_availability: "Disponibilitate",
  cif: 'CUI',
}
