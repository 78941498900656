import reports from './flexben/ro/reports.js';
import offers from './flexben/ro/offers';
import affiliate_channels from './flexben/ro/affiliate_channels';
import categories from './flexben/ro/categories';
import benefit from './flexben/ro/benefit';
import clients from './flexben/ro/clients';
import imports from './flexben/ro/import';
import beneficiaries from './flexben/ro/beneficiaries';
import order from './flexben/ro/order';
import requests from './flexben/ro/requests';
import beneficiary_pages from './flexben/ro/beneficiary_pages';
import benefit_groups from './flexben/ro/benefit_groups';
import partner from './flexben/ro/partner';
import brand from './flexben/ro/brand';
import voucher_stocks from './flexben/ro/voucher_stocks';
import general from './flexben/ro/general';
import form_fields from './flexben/ro/form_fields';
import form_error from './flexben/ro/form_error';
import menu from './flexben/ro/menu';
import dashboard from './flexben/ro/dashboard';
import import_history from './flexben/ro/import_history';
import offer_category from './flexben/ro/offer_category';
import fixed_benefits from './flexben/ro/fixed_benefits';
import configuration from './flexben/ro/configuration';
import up_ro_validation_messages from './flexben/ro/up_ro_validation_messages';

export default {
  about: {
    text_1: 'Platforma Up Multibeneficii iti ofera libertatea de alegere in privinta tipului de beneficii extrasalariale pe care ti le doresti, in baza bugetului alocat de catre angajator.',
    text_2: 'Poti alege dintr-o gama variata de beneficii cum ar fi de carduri de masa, carduri și vouchere cadou, carduri culturale, vouchere de vacanta, abonamente medicale, pensii private sau abonamente la sali de sport.',
  },
  admin_client_header: {
    id: 'ID',
    cui: 'CUI',
    iban: 'IBAN',
    address: 'Adresa'
  },
  affiliate_channels: affiliate_channels,
  benefit: benefit,
  beneficiaries: beneficiaries,
  beneficiary_pages: beneficiary_pages,
  benefit_groups: benefit_groups,
  configuration: configuration,
  categories: categories,
  clients: clients,
  dashboard: dashboard,
  formFieldError: "{field} - {reason}.",
  formVarFieldError: "{field} - {var} {reason}.",
  formErrorReasons: form_error,
  flexbenFormFields: form_fields,
  fixed_benefits: fixed_benefits,
  general: general,
  import: imports,
  import_history: import_history,
  managed: {
    multiple_companies: {
      title: "Administrare companii",
      description: "Gestioneaza companiile",
    },
  },
  menu: menu,
  order: order,
  offer_category: offer_category,
  offers: offers,
  partner: partner,
  brand: brand,
  pagination: {
    title: "Afisarea elementelor de la {from} pana la {to} din {of}",
    first: "Prima",
    last: "Ultima",
  },
  progress_bar: {
    chose_import: "Alege actiunea",
    chose_file: "Alege fisierul",
    review: "Revizuire",
    confirmation: "Confirmare",
    order_type: 'Tip comanda',
    benefit: 'Beneficiu',
    requests: 'Solicitari',
    order_placed: 'Comanda plasata',
  },
  reports: reports,
  requests: requests,
  voucher_stocks: voucher_stocks,
  up_ro_validation_messages: up_ro_validation_messages
};
