<template>
  <div class="container-fluid">
    <div class="row container-gray py-3">
      <div :class="{
        'col-12 col-md-6':true,
        'col-xl-2':companyOptions.cam_budget_taxation,
      }">
        <span class="flx-type-badge mr-1 ">{{ $t("flexben.benefit.sub_types."+ceBenefit.benefit.sub_type) }}</span>
      </div>
      <div v-if="selectedProduct && (selectedProduct.value <= remainingBudget)" :class="{
      'col-12 col-md-6':true,
      'col-xl-5':companyOptions.cam_budget_taxation,
      }">
        <div :class="companyOptions.cam_budget_taxation ? '' :'float-right'">
          <span class="mr-1" v-html="(ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale] === null) ?
            $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
            : ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale]">
          </span>
          <span id="tooltip_up_dejun_deductibility" class="d-inline-block flexben-tooltip-icon mr-2">i</span>

          <b-tooltip :target="'tooltip_up_dejun_deductibility'" variant="flexben" placement="topleft">
            <div class="text-left">
              {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
            </div>
          </b-tooltip>
          <span class="text-danger"><strong>{{ salaryDeductibility }} Lei</strong></span>
        </div>
      </div>

      <cam-value-info v-if="companyOptions.cam_budget_taxation" :valueCam="valueCam"></cam-value-info>

      <div class="col-12 mt-2 text-danger text-bold text-center"
           v-if="selectedProduct && (selectedProduct.value>remainingBudget)">
        {{ $t('flexben.beneficiary_pages.benefits.insufficient_budget') }}
      </div>

      <div class="col-12 mt-3">
        <div class="row">
          <div class="col-12 text-danger text-bold text-center">
            {{ $t('flexben.benefit.transport_24_pay.no_account_info') }}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label class="mt-2 col-form-label pt-0 mr-1 form-fields-required">{{ $t('flexben.benefit.transport_24_pay.labels.phone_number') }}</label>
            <input v-model="phoneNumber" type="text"
                   class="form-control w-input"
                   maxlength="256"
                   name="phone-number" data-name="phone-number"
                   placeholder="" id="Last-name-3" required>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-xl-4">
            <div class="form-group mb-0">
              <label class="mt-2 col-form-label pt-0 mr-1 form-fields-required">{{ $t('flexben.benefit.transport_24_pay.labels.provider') }}</label>
              <select name="" id="" class="form-control" v-model="selectedProvider">
                <option :value="null">-</option>
                <option v-for="(provider, index) of transportProducts" :key="index"
                        :value="provider">
                  {{ provider.county }} - {{provider.name}}
                </option>
              </select>
            </div>
          </div>
          <div v-if="selectedProvider!=null" class="col-12 col-md-6 col-xl-4">
            <div class="form-group mb-0">
              <label class="mt-2 col-form-label pt-0 mr-1 form-fields-required">{{ $t('flexben.benefit.transport_24_pay.period_name') }}</label>
              <select name="" id="" class="form-control" v-model="selectedPeriod">
                <option :value="null">-</option>
                <option v-for="(product, index) of selectedProvider.subscriptionProducts" :key="index"
                        :value="product">
                  {{ index }}
                </option>
              </select>
            </div>
          </div>

        </div>
        <div class="row">
          <div v-if="selectedPeriod" class="col-12 col-md-6 col-xl-4">
            <div class="form-group mb-0">
              <label class="mt-2 col-form-label pt-0 mr-1 form-fields-required">{{ $t('flexben.benefit.transport_24_pay.labels.subscription') }}</label>
              <select name="" id="" class="form-control" v-model="selectedProduct">
                <option :value="null">-</option>
                <option v-for="(product, index) of selectedPeriod" :key="index"
                        :value="product">
                  {{ product.name }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="selectedProduct" class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <label for="periods" class="mt-2 form-fields-required col-form-label pt-0">{{
              $t('flexben.beneficiary_pages.benefits.fields.subscription_value') }}</label>
            <input class="form-control"
                   type="number"
                   disabled
                   :value="selectedProduct.value"/>
          </div>
        </div>
        <div class="col-12">
          <hr>
        </div>
        <div class="row">
          <div v-if="selectedProduct" class="col-12 col-md-6 col-xl-4 form-group">
            <label class="mt-2 col-form-label form-fields-required pt-0 mr-1">{{ $t('flexben.benefit.transport_24_pay.labels.start_date') }}</label>
            <span id="start-date"
                  class="d-inline-block flexben-tooltip-icon ml-2 text-bold">i</span>
            <b-tooltip :target="'start-date'" variant="flexben" placement="topleft">
              <div class="text-left">{{ $t('flexben.benefit.transport_24_pay.start_date_info') }}
              </div>
            </b-tooltip>
            <div class="d-inline-flex w-100">
              <font-awesome-icon icon="calendar-alt" class="my-auto mr-2 text-24 flx-calendar-icon"/>
              <input class="form-control"
                     type="text"
                     disabled
                     :value="start_date"/>
            </div>
          </div>

          <div v-if="subscriptions.length" class="col-12 col-md-6 col-xl-4 form-group mb-0">
            <ul>
            <li v-for="subscription in subscriptions" :key="subscription.value">
              {{ $t('flexben.benefit.transport_24_pay.active_subscription') }}
              {{ subscription.expiration_date }}
            </li>
          </ul>
          </div>

        </div>
        <div v-if="selectedProduct" class="row mt-2">
          <div class="col-12">
            <hr>
          </div>

          <total-value-pay
            v-if="(companyOptions.gross_budget_taxation || companyOptions.cam_budget_taxation)"
            :totalPayValue="totalPayValue"></total-value-pay>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 px-0 pr-3 text-center text-md-left">
        <button class="btn btn-themed-green px-2 mr-2 px-md-4" @click="orderNow()"
                :disabled="!canOrder">
          {{ $t('flexben.beneficiary_pages.order_now') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {BTooltip} from "bootstrap-vue";
import TotalValuePay from "@/components/flexben/beneficiary_pages/benefits/partials/TotalValuePay";
import CamValueInfo from "@/components/flexben/beneficiary_pages/benefits/partials/CamValueInfo";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import flexbenUtils from "@/plugins/flexben_utils";
import httpFlexbenService from "@/http/http-flexben"

export default {
  name: "TransportForm",
  components: {
    'b-tooltip': BTooltip,
    'total-value-pay': TotalValuePay,
    'cam-value-info': CamValueInfo
  },
  props: {
    beneficiaryId: {
      type: Number,
      required: true
    },
    ceBenefit: {
      type: Object,
      required: true
    },
    budgets: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },
    payload: {
      type: Object,
      required: true,
      default: function () {
        return {
          value: 0,
          benefit_id: this.benefitId,
          child_benefit_id: null,
          event_id: null,
          auto_top_up: false,
          ticket_count: 0,
          ticket_value: 0,
          extra: {},
        };
      }
    },
    errorsBag: {
      type: [Object, Array],
      default: function () {
        return {};
      }
    },
    companyOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          cam_budget_taxation: false,
          gross_budget_taxation: false,
          legislated_gross_calculation: false,
          last_monthly_order_day: null,
          budget_telework_transfer_to_general: false,
          cash_out_last_order_day: null,
          non_legislated_net_taxes_calculate: false,
          banner_image_url: null,
        };
      }
    },
    remainingBudget: {
      type: Number,
      default: 0,
    },
    transportProducts: {
      type: Array,
      required: true
    },
    activeTransportSubscriptions: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      start_date: moment().format('DD-MM-YYYY'),
      formData: {
        start_date: moment().toDate(),
        type: this.ceBenefit.benefit.sub_type
      },
      httpService: httpFlexbenService.beneficiary,
      thresholds:{gym_membership: {status:false, value: 0, code: ''}},
      salaryDeductibility: 0,
      valueCam: 0,
      totalPayValue: 0,
      selectedProvider: null,
      selectedPeriod: null,
      selectedProduct: null,
      subscriptions: [],
      phoneNumber: null
    };
  },
  computed: {
    canOrder: function () {
      return !!(!this.subscriptions.length && this.selectedProduct && this.phoneNumber && (this.totalPayValue <= this.remainingBudget));
    }
  },
  watch: {
    selectedProduct: {
      handler: function (product) {
        this.subscriptions = [];
        if (!product) {
          return;
        }

        this._setSalaryDeductibility(product.value);
        for (const item of this.activeTransportSubscriptions) {
          if (product.id === item.value && this.phoneNumber === item.phone_number) {
            this.subscriptions.push(item);
          }
        }
      }
    },
    selectedProvider: {
      handler: function (v) {
          this.selectedPeriod = null;
          this.selectedProduct = null;
      }
    },
    selectedPeriod: {
      handler: function (v) {
        this.selectedProduct = null;
      }
    },
    phoneNumber: {
      handler: function (number) {
        this.subscriptions = [];

        if (!this.selectedProduct) {
          return;
        }

        for (const item of this.activeTransportSubscriptions) {
          if (this.selectedProduct.id === item.value && number === item.phone_number) {
            this.subscriptions.push(item);
          }
        }
      }
    }
  },
  methods: {
    orderNow() {
      this._setPayload();
      this.$emit('orderNow', this.ceBenefit.benefit.sub_type);
      //TODO:use this on next mobile release
      // this.$emit('validate24PayOrder');
    },
    _setSalaryDeductibility(value) {
      let valueToTax = flexbenUtils.calculateGross(value, this.ceBenefit.benefit.global_variables, this.companyOptions.non_legislated_net_taxes_calculate);
      this.salaryDeductibility = (valueToTax - value).toFixed(2);
      this.valueCam = valueToTax * this.ceBenefit.benefit.global_variables['cam'] / 100;

      this._calculateTotalByCompanyOptions(value);
    },
    _setPayload() {
      this.payload.value = this.selectedProduct.value;
      this.payload.ticket_count = 1;
      this.payload.ticket_value = this.selectedProduct.value;
      this.payload.benefit_id = this.ceBenefit.benefit_id;
      this.payload.extra = this.formData;
      this.payload.sub_type = this.formData.type;
      this.payload.extra = {
          start_date: moment(this.formData.start_date).format('YYYY-MM-DD'),
          product_code: this.selectedProduct.id,
          phone_number: this.phoneNumber,
          provider_code: this.selectedProvider.code
      };
    },
    _calculateTotalByCompanyOptions(rawValue) {
      let value = parseFloat(rawValue);
      if (this.companyOptions.gross_budget_taxation && value > 0) {
        value += parseFloat(this.salaryDeductibility);
      }
      if (this.companyOptions.cam_budget_taxation && value > 0) {
        value += parseFloat(this.valueCam);
      }
      this.totalPayValue = value;
    }
  }
}
</script>
