<template>
  <div class="benefit-list-container mt-3">
    <div class="benefit-card d-flex flex-column ml-0 mx-auto mx-lg-2 mb-3"
         v-for="(benefit,index) in benefits"
         :key="index">
      <img class="benefit-card-img cursor-pointer" :src="benefit.image_url" :alt="benefit.name[locale]"
           @click="showDetails(benefit.id)">
      <div class="benefit-card-body d-flex flex-column">
        <div class="benefit-card-name mt-1 text-capitalize">{{benefit.name}}</div>
        <div class="benefit-card-description mt-1 mb-auto">
          <span class="text-bold">{{ (benefit.partner_id!==null) ? benefit.partner.name :'-' }}</span><br>
          <div v-html="benefit.description[locale]" class="links-container"></div>
        </div>
        <div class="benefit-card-actions mt-1">
          <button class="btn btn-themed" @click="showDetails(benefit.id)">
            {{ $t('flexben.general.details') }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="benefits.length===0" class="mx-auto text-bold">
      {{ $t("flexben.general.no_results_found") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FlexibleBenefitsListing",
  props: {
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },
    benefits: {
      type: Array,
      required: true,
      default: function () {
        return [];
      }
    }
  },
  methods: {
    showDetails(benefitId) {
      this.$emit('showDetails', benefitId);
    }
  }
}
</script>
<style lang="scss">
.benefit-list-container {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;

  .benefit-card {
    border-radius: 5px;
    width: 277px;
    flex: 0 0 auto;
    padding: 10px;
    border: 1px solid #D7D7D7;
    transition: transform .5s;

    &:hover {
      transform: scale(1.05);
    }
    .benefit-card-img {
      width: 255px;
      height: 255px;
      border-radius: 5px;
    }

    .benefit-card-body {
      width: 100%;
      height: 100%;

      .benefit-card-name {
        font-size: 16px;
        font-weight: 700;
      }

      .benefit-card-description {
        //word-wrap: break-word;
        max-width: 250px;
        max-height: 90px;
        height: 90px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        font-size: 12px;
        color: #9b9a9a;
      }
    }
  }
}
</style>
