<template>
  <div class="container-fluid flexben-container flexben-beneficiary-offers">
    <div v-show="!loading && !details.show" class="mb-4">
      <div class="row mt-2">
        <div class="col-12 flexben-page-title">
          <h1>{{ $t('flexben.offers.beneficiary.title')}}</h1>
        </div>
      </div>
      <div v-if="selectedCategory!==null" class="container-bg-white">
        <div class="row p-3">
          <div class="col-12 col-xl-3 mb-2 mb-xl-0">
            <img src="@/assets/images/filter.svg"/>
            <span class="ml-3">{{ $t("flexben.general.filter") }}</span>
          </div>
          <div class="col-12 col-xl-9">
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-0" for="category">{{ $t("flexben.offers.fields.category") }}</label>
                  <select id="category" class="form-control w-100"
                          v-model="filters.category">
                    <option :value="null">{{ $t('flexben.general.all') }}</option>
                    <option v-for="(item, index) in categories"
                            :key="index"
                            :value="item.id">{{ item.name[locale] }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-0" for="type">{{ $t("flexben.offers.fields.type") }}</label>
                  <select id="type" class="form-control w-100"
                          v-model="filters.type">
                    <option :value="null">{{ $t('flexben.general.all') }}</option>
                    <option v-for="(item, index) in filter_options.types"
                            :key="index"
                            :value="item">{{ $t("flexben.offers.types."+item) }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-0" for="select_location_availability">{{
                    $t("flexben.offers.fields.available_locations") }}</label>
                  <select id="select_location_availability" class="form-control w-100"
                          v-model="filters.location_availability">
                    <option v-for="(location) in filter_options.location_availability" :key="location"
                            :value="location">

                      {{$t("flexben.offers.location_availability." + location)}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-0" for="counties">{{ $t("flexben.offers.fields.county") }}</label>
                  <select id="counties" class="form-control w-100 text-capitalize"
                          v-model="filters.county">
                    <option :value="null">{{ $t('flexben.general.all') }}</option>
                    <option v-for="(item, index) in filter_options.counties"
                            :key="index"
                            :value="item.id">{{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a v-if="!details.show && selectedCategory!==null" class="btn text-themed mt-3 mb-0 py-0 go-back"
         @click="backToCategories()">{{ $t('flexben.benefit.btn.clear_category') }}</a>

      <categories-list v-if="selectedCategory==null"
                       :categories="categories"
                       :locale="locale"
                       @showCategoryOffers="showCategoryOffers"></categories-list>
      <offers-list v-if="selectedCategory !==null"
                   :category="selectedCategory"
                   :offers="offers"
                   :locale="locale"
                   @showOfferDetails="showOfferDetails"
                   @showTextCode="showTextCode"
                   @backToCategories="backToCategories"></offers-list>
    </div>
    <offer-code-modal :offer="offerCodeModal.offer"
                      :locale="locale"
                      ref="offer-code-modal"></offer-code-modal>
    <OfferDetails v-if="details.show" :offer="details.offer" :locale="locale" @backToList="backToList"></OfferDetails>
    <scroll-to-top-btn></scroll-to-top-btn>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";
import OfferCodeModal from "@/components/flexben/beneficiary_pages/offers/modals/OfferCodeModal";
import OfferDetails from "@/components/flexben/beneficiary_pages/offers/OfferDetails";
import OffersList from "@/components/flexben/beneficiary_pages/offers/partials/OffersList";
import CategoriesList from "@/components/flexben/beneficiary_pages/offers/partials/CategoriesList";

export default {
  name: "BeneficiaryOffers",
  components: {
    'offer-code-modal': OfferCodeModal,
    'offers-list': OffersList,
    'categories-list': CategoriesList,
    OfferDetails
  },
  data: function () {
    return {
      loading: true,
      loaderError: false,
      httpService: httpFlexbenService.beneficiary,
      categories: [],
      offers: [],
      selectedCategory: null,

      searchString: '',
      sortField: 'name',
      sortDirection: 'asc',
      reverseSort: true,
      filters: {
        county: null,
        type: null,
        category: null,
        search: null,
        location_availability: 0,
        company_id : null
      },
      filter_options: {
        types: [],
        counties: [],
        categories: [],
        location_availability: [],
      },
      with_filter_options: 1,
      offerCodeModal: {
        show: false,
        offer: {name: {}, code_value: '',}
      },
      details: {
        offerId: null,
        offer: {},
        show: false,
      }
    }
  },
  watch: {
    filters: {
      handler: function (val) {
        if (val.category == null) {
          this.selectedCategory = null;
        } else {
          this._loadOffersList();
          if (val.category !== this.selectedCategory.id) {
            this.selectedCategory = this.filter_options.categories.filter((item) => {
              return item.id == val.category;
            })[0];
          }
        }
      },
      deep: true
    }
  },
  computed: {
    locale: () => {
      return i18n.locale;
    },
    activeCompany() {
      return this._getActiveEmployment();
    }
  },
  created() {
    this._loadCategories();
  },
  methods: {
    _loadCategories() {
      this.loading = true;
      let params = {company_id: this.activeCompany.company_id};
      this.httpService.offerCategories(params)
        .then((response) => {
          this.categories = response.data;
          this.filter_options.categories = this.categories;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = true;
          this.loaderError = true;
        })
    },
    _loadOffersList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      let filters = {...this.filters};
      filters.with_filter_options = this.with_filter_options;
      filters.company_id = this.activeCompany.company_id;
      this.httpService.offerList(filters)
        .then((response) => {
          this.offers = response.data.list;
          if (this.with_filter_options) {
            this.filter_options.types = response.data.filter_options.types;
            this.filter_options.location_availability = response.data.filter_options.location_availability;
            this.filter_options.counties = response.data.filter_options.counties;
            this.with_filter_options = 0;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = true;
          this.loaderError = true;
        });
    },
    showOfferDetails(offerId) {
      this.loading = true;
      if (offerId !== this.details.offerId) {
        this.details.offerId = offerId;
        this.httpService.offerDetail(offerId)
          .then((response) => {
            this.details.offer = response.data;
            this.details.show = true;
            this.loading = false;
            this.flxTrackData({
              c: 'offer',
              cid: this.$store.getters.flx_activeEmployment.company_id,
              tid: offerId,
              a: 'details',
              l: 'list',
            });
          })
          .catch((error) => {
            this.details.offer = {};
            this.details.show = false;
            this.loading = false;
          });
      } else {
        this.details.show = true;
        this.loading = false;
      }
    },
    showTextCode(offerIndex) {
      this.offerCodeModal.offer = this.offers[offerIndex];
      this.flxTrackData({
        c: 'offer',
        cid: this.$store.getters.flx_activeEmployment.company_id,
        tid: this.offerCodeModal.offer.id,
        a: 'order',
        l: 'list',
      });
      this.$refs['offer-code-modal'].openModal();
    },
    backToList() {
      this.details.show = false;
    },
    showCategoryOffers(index) {
      this.selectedCategory = this.categories[index];
      this.filters.category = this.selectedCategory.id;
      this._loadOffersList();
    },
    backToCategories() {
      this.filters.category = null;
      this.selectedCategory = null;
    },
    _getActiveEmployment() {
      if (this.$store.state.flx_beneficiaryActiveEmployment) {
        return this.$store.state.flx_beneficiaryActiveEmployment;
      }
      if (sessionStorage.hasOwnProperty('flx_active_employment')) {
        return JSON.parse(sessionStorage.getItem('flx_active_employment'));
      }
      return null;
    },
  }

}
</script>

<style lang="scss">
.flexben-beneficiary-offers {
  .offer-category-container {
    .offer-category-img {
      width: 60px;
      border-radius: 5px;
    }
  }

  .offer-list-container {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-direction: row;
    flex-wrap: wrap;

    .offer-card {
      border-radius: 5px;
      width: 277px;
      flex: 0 0 auto;
      padding: 10px;
      border: 1px solid #D7D7D7;
      transition: transform .5s;

      &:hover {
        transform: scale(1.05);
      }

      .offer-card-img {
        padding: 3em;
      }

      .offer-card-body {
        width: 100%;
        height: 100%;

        .offer-card-name {
          font-size: 16px;
          font-weight: 700;
        }

        .offer-card-description {
          //word-wrap: break-word;
          max-width: 250px;
          max-height: 90px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          font-size: 12px;
          color: #9b9a9a;
        }
      }
    }
  }
}
</style>
