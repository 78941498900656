<template>
  <b-modal id="modal-1"
           ref="alert-modal"
           :hide-header-close="true"
           headerClass="border-0 text-center"
           bodyClass="modal-body no-border background-white text-center "
           footerClass="modal-footer border-top-0"
           centered>
    <template v-slot:default>
      <div v-if ="company_id === 55" class="text-18" :class="textClass">
        <p>{{ $t('flexben.beneficiary_pages.benefits.action_message.are_you_sure') }}</p>
        <p style="color:red">{{ $t('flexben.beneficiary_pages.benefits.action_message.are_you_sure_top_up_live') }}</p>
      </div>
      <div  v-else class="text-18" :class="textClass" v-html="(message) ? message : $t('flexben.general.are_you_sure')"></div>
    </template>
    <template v-slot:modal-footer>
      <button class="btn text-themed mr-auto" @click="closeModal()">{{ $t('flexben.general.btn.close') }}</button>
      <button class="btn btn-themed " @click="confirm()">{{ $t('flexben.general.btn.confirm') }}</button>
    </template>
  </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";

export default {
  name: "AlertModal",
  components: {
    'b-modal': BModal
  },
  props: {
    message: {
      required: false,
      default: 'Success!'
    },
    textClass: {
      default: ''
    }
  },
  data: function () {
    return {
     company_id:null
    }
  },
  methods: {
    openModal() {
      this.company_id = this._getActiveEmployment().company_id;
        this.$refs['alert-modal'].show();
    },
    closeModal() {
      this.$emit('clearActionSuccess');
      this.$refs['alert-modal'].hide();
    },
    confirm() {
      this.$emit('confirmAction');
      this.$refs['alert-modal'].hide();
    },
    _getActiveEmployment() {
      if (this.$store.state.flx_beneficiaryActiveEmployment) {
        return this.$store.state.flx_beneficiaryActiveEmployment;
      }
      if (sessionStorage.hasOwnProperty('flx_active_employment')) {
        return JSON.parse(sessionStorage.getItem('flx_active_employment'));
      }
      return null;
    },
  },
}
</script>
