
<template>
  <div class="modal fade" id="mentenanceModal" tabindex="-1" role="dialog" aria-labelledby="mentenanceModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="mentenanceModalLabel">
            <svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 512 512"><path fill="#F59100" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
             Up MultiBeneficii</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Dragi utilizatori,<br/><br/>
          Va informam ca accesul in platforma <strong>Up MultiBeneficii </strong>va fi indisponibil temporar, in intervalul 1-3 ianuarie, pentru lucrari de mentenanta. Accesul va fi reluat pe <strong>3 ianuarie, dupa ora 15:00 </strong>. <br/><br/>
          Multumim pentru intelegere si va dorim un an productiv si plin de satisfactii! <br/><br/>
          Cu prietenie,<br/>
          <strong>Echipa Up MultiBeneficii</strong>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "ModalMentenance",
  props: {
    showModal: Boolean,
  },
};
</script>
