export default {
  title: "Categorii oferte",
  subtitle: "Gestioneaza categorii oferte",
  new: "Categorie noua",
  edit: "Gestioneaza categorie oferta",
  list: "Lista categorii oferte",
  assigned_offers: 'Oferte atribuite',
  no_assigned_offers: 'Nici o oferta atribuita',
  modal: {
    confirm_save: 'Esti sigur ca vrei sa salvezi modificarile?',
    confirm_leave: 'Exista informatii modificate care nu au fost salvate. Confirmi parasirea paginii?',
    confirm_delete: 'Esti sigur ca vrei sa stergi categoria?',
    extra_delete: 'Categoria curenta este atribuita la {count_offer} oferte. <br>Inlatura atribuirea inainte de a sterge categoria.'
  },
  table: {
    image: "image",
    name: "Name",
  }
}
