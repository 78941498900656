
export default {
	getUrlParam(name) {
		var query = window.location.search.substring(1); // Remove question mark
		var parameters = query.split("&");

		for (var i = 0; i < parameters.length; i++) {
			var pair = parameters[i].split("=");

			if (pair[0] == name) {
				return pair[1];
			}
		}

		return null;
	},
	openWindowWithPost(url, params) {
		var form = document.createElement("form");
		form.setAttribute("method", "post");
		form.setAttribute("action", url);
		if (url.includes("mobilpay")) {
			form.setAttribute("target", "_blank");
		}

		for (var i in params) {
			if (params.hasOwnProperty(i)) {
				var input = document.createElement("input");
				input.type = "hidden";
				input.name = i;
				input.value = params[i];
				form.appendChild(input);
			}
		}

		document.body.appendChild(form);

		//note I am using a post.htm page since I did not want to make double request to
		//the page it might have some Page_Load call which might screw things up.
		// window.open("post.htm");

		form.submit();

		document.body.removeChild(form);
	},
	getProductName(productId) {
		var cards = JSON.parse(sessionStorage.getItem("mobile_jsonFile"))
			.cards;
		for (var index in cards) {
			var product = cards[index];
			if (product.product === productId) {
				return product.name;
			}
		}
		return "";
	},
	getCompanyProductName(productId, productList) {
		let companyProduct = this.getProductById(productId, productList);
		let configProducts = null;
		if (sessionStorage.getItem("client_jsonFile")) {
			configProducts = JSON.parse(sessionStorage.getItem("client_jsonFile"))
				.products;
		} else if (sessionStorage.getItem("affiliate_jsonFile")) {
			configProducts = JSON.parse(sessionStorage.getItem("affiliate_jsonFile"))
				.products;
		}
		for (var index in configProducts) {
			var product = configProducts[index];
			if (!productId.toString().startsWith("15")) {
				productId = parseInt(productId.toString().substring(0, 2));
			}
			if (
				productId.toString().length > 2 &&
				productId === parseInt(product.card + "" + product.card_profile)
			) {
				return product.name + (companyProduct.card > 0 && !companyProduct.active ? " (inactiv)": "" );
			} else if (
				productId.toString().length <= 2 &&
				(product.card === companyProduct.card + "" ||
					// && product.card_profile === companyProduct.card_profile + ""
					(product.paper === companyProduct.paper + "" &&
						companyProduct.paper + "" !== "0"))
			) {
				return product.name + (companyProduct.card > 0 && !companyProduct.active ? " (inactiv)": "" );
			}
		}
		return "";
	},
	getProductById(productId, productList) {
		for (var index in productList) {
			var product = productList[index];
			if (
				productId.toString().length > 2 &&
				productId === parseInt(product.card + "" + product.card_profile)
			) {
				return product;
			} else if (
				product.card + "" === productId + "" ||
				product.paper + "" === productId + ""
			) {
				return product;
			}
		}
		return "";
	},
	formatPAN(pan) {
		return (
			pan.substring(0, 4) +
			" " +
			pan.substring(4, 8) +
			" " +
			pan.substring(8, 12) +
			" " +
			pan.substring(12, 16)
		);
	},
	formatAmount(value) {
		let val = (value / 1).toFixed(2).replace(".", ",");
		return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	},
	selectColorStatus(status) {
		switch (status) {
			case "A":
			case "Z":
				return "background-blocked";
			case "V":
			case "L":
				return "background-active";
			case "S":
			case "N":
			case "P":
			case "R":
			case "PR":
			case "PV":
				return "background-will-expire";
			case "X":
				return "background-expired";
			default:
				return "background-will-expire";
		}
	},
	base64ToArrayBuffer(base64) {
		var binaryString = window.atob(base64);
		var binaryLen = binaryString.length;
		var bytes = new Uint8Array(binaryLen);
		for (var i = 0; i < binaryLen; i++) {
			var ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	},
	uploadFile(fileReceived) {
		var content = "maria";
		var reader = new FileReader();
		reader.onload = (e) => {
			if (!e) {
				content = btoa(reader.content);
			} else {
				content = btoa(e.target.result);
			}
		};
		//For Browsers other than IE.
		if (reader.readAsBinaryString) {
			reader.readAsBinaryString(fileReceived);
		} else {
			alert("This browser does not support HTML5.");
		}

		return content;
	},
	addChat() {
		if (document.getElementById("chat-script")) return; // was already loaded
		if (document.getElementsByClassName("mcic-chat-banner").length > 0) {
			document.getElementsByClassName("mcic-chat-banner")[0].style.display =
				"block";
		}
		window._mdtCIC_configs = {
			ClientName: "",
			CampaignID: "9d1ba720-8c2d-46ba-ab88-3a6307b05372",
			ProxyPath: "https://chat.uponline.ro//WFClient",
		};
		let scriptChat = document.createElement("script");
		scriptChat.src = "https://chat.uponline.ro//chat/mcic_chat.js";
		scriptChat.id = "chat-script";
		document.getElementsByTagName("head")[0].appendChild(scriptChat);
		if (document.getElementsByClassName("mcic-chat-banner-image").length > 0) {
			document.getElementsByClassName("mcic-chat-banner")[0].style.width =
				"auto";
			document.getElementsByClassName("mcic-chat-banner")[0].style.padding =
				"0";
			document.getElementsByClassName("mcic-chat-banner-image")[0].style.width =
				"auto";
			document.getElementsByClassName(
				"mcic-chat-banner-image"
			)[0].src = require("@/assets/images/chat.png");
		} else {
			setTimeout(function() {
				let domElems = document.getElementsByClassName("mcic-chat-banner");
				if (domElems.length > 0) {
					domElems[0].style.width = "auto";
					domElems[0].style.padding = "0";
					domElems = document.getElementsByClassName(
						"mcic-chat-banner-image"
					)[0];
					domElems.style.width = "auto";
					domElems.src = require("@/assets/images/chat.png");
				}
			}, 100);
		}
	},
	removeChat() {
		let scriptChat = document.getElementById("chat-script");
		let divChat = document.getElementsByClassName("mcic-chat-banner");
		if (scriptChat) {
			scriptChat.parentNode.removeChild(scriptChat);
		}
		if (divChat.length > 0) {
			divChat[0].style.display = "none";
		}
	},
	getNomenclatureFor(
		typeItem,
		countyId = null,
		shopProductId = null,
		shopProfileId = null
	) {
		return url;
	},
	generateDownloadFromReponse(response) {
		const filename = response.headers['content-disposition'].split('=')[1].replace(/^"+|"+$/g, '');
		// Service that handles ajax call
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", filename);
		document.body.appendChild(link);
		link.click();
		link.remove();
	}
};
