import Api from "@/multiben-module/src/services/api/api";
import ApiHelper from "@/multiben-module/src/services/helper/apiHelper";

class ApiTaxes {
  prefix = '';

  api = Api.create();

  token = ApiHelper.getToken();

  baseUrl = ApiHelper.getBaseUrl('v2');

  getPPP3Taxes(params) {

    if (!this.token) {
      throw 'Token is not set!'
    }

    return this.api.get(this.baseUrl + this.prefix + 'ppp3-taxes-calculator', {
      headers: {
        Authorization: 'Barier '+ this.token,
      },
      params: params
    });
  }
}

export default new ApiTaxes();
