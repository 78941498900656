<template>
  <div class="d-flex align-items-center">
    <span>{{schema.leftText}}</span>
    <b-form-checkbox class="d-block field-bs-switch mt-1  mr-3"
                     :class="{'ml-2':(schema.leftText),'mr-3':(schema.rightText)}"
                           switch
                           v-model="value"></b-form-checkbox>
    <span>{{schema.rightText}}</span>
  </div>
</template>

<script>
import {BFormCheckbox} from "bootstrap-vue";
import {abstractField} from "vue-form-generator";

export default {
  name: "fieldBsSwitch",
  components: {
    'b-form-checkbox': BFormCheckbox,
  },
  mixins: [abstractField],
  methods:{

  }
}
</script>
