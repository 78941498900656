<template>
  <div>
    <div v-if="this.dataLoaded">
      <div v-if="!isMobile" class="menu">
        <a @click="pushRoute('beneficiary')" class="curosr-pointer" :class="{ logo: !flexbenHasAccess, 'flx-logo': flexbenHasAccess }">
          <img v-if="!flexbenHasAccess" src="@/assets/images/logo-up.png" />
          <img v-if="flexbenHasAccess" src="@/assets/images/flexben/flexben-logo.svg" />
        </a>
        <div class="menu-items">
          <a @click="pushRoute('beneficiary_dashboard')" class="dashboard cursor-pointer" :class="this.menuSelected.dashboard && 'dashboard-active'">{{
            $t("dashboardName") }}</a>

          <FlexbenMenu ref="flexben_side_menu" :menu-selected="this.menuSelected"
            v-if="flexbenShowComponents && !isMobile" />
          <a v-if="beneficiary !== null" @click="pushRoute('beneficiary_cards')" class="cards cursor-pointer"
            :class="this.menuSelected.cards && 'cards-active'">{{ $t("cardsName") }}</a>
          <a @click="pushRoute('beneficiary_services')" class="services cursor-pointer" :class="this.menuSelected.services && 'services-active'">{{
            $t("servicesName") }}</a>
          <a @click="pushRoute('beneficiary_faq')" class="faq cursor-pointer" :class="this.menuSelected.faq && 'faq-active'">{{ $t("faqName") }}</a>
          <a @click="pushRoute('beneficiary_account')" class="account cursor-pointer" :class="this.menuSelected.account && 'account-active'">{{
            $t("settingsName") }}</a>
          <a @click="pushRoute('beneficiary_contact')" class="contact cursor-pointer" :class="this.menuSelected.contact && 'contact-active'">{{
            $t("contactName") }}</a>
          <a @click="pushRoute('beneficiary_terms')" class="term cursor-pointer" :class="this.menuSelected.terms && 'term-active'">{{
            $t("termsAndConditionsName") }}</a>
          <a @click="pushRoute('beneficiary_gdpr')" class="policy cursor-pointer" :class="this.menuSelected.gdpr && 'policy-active'">{{
            $t("personalDataPolicyName") }}</a>
          <a href="#" class="logout" @click="logout()">{{ $t("logoutName") }}</a>
        </div>
        <div class="socials">
          <a href="https://www.facebook.com/UpRomania" class="facebook"></a>
          <a href="https://www.linkedin.com/company/up-romania" class="linkedin"></a>
          <a href="https://www.youtube.com/c/UpRomaniaBucuresti" class="youtube"></a>
        </div>
      </div>

      <div v-if="isMobile" class="menu-mobile p-0 w-100" :class="{ 'open-menu': showMobileMenu }">
        <div class="user-mobile px-3 w-100 d-flex m-0 box-sizing-bb">
          <div class="openNotifications" @click="showModalPopup('modal_popup_mobileNotifications', true)">
            <div v-if="this.readMessagesCount > 0" class="unread-notifications">
              <span>{{ this.readMessagesCount }}</span>
            </div>
            <img src="../../assets/images/clopotel-alb.svg" />
          </div>
          <img src="../../assets/images/linie.png" />
          <div>{{ (beneficiary.first_name + ' ' + beneficiary.last_name) | titleCase }}</div>
          <div class="ml-auto close-menu" @click="openMenu(!showMobileMenu)">
            <img src="../../assets/images/x.svg" />
          </div>
        </div>
        <div class="menu-items box-sizing-bb px-3">
          <a @click="pushRoute('beneficiary_dashboard')" class="dashboard" :class="this.menuSelected.dashboard && 'dashboard-active'">{{
            $t("dashboardName") }}</a>
          <a v-if="beneficiary !== null" @click="pushRoute('beneficiary_cards')" class="cards"
            :class="this.menuSelected.cards && 'cards-active'">{{ $t("cardsName") }}</a>
          <FlexbenMenu v-if="isMobile && beneficiary !== null" :key="flexbenComponentKey" ref="flexben_side_menu"
            :menu-selected="this.menuSelected"></FlexbenMenu>
          <a @click="pushRoute('beneficiary_services')" class="services" :class="this.menuSelected.services && 'services-active'">{{
            $t("servicesName") }}</a>
          <a @click="pushRoute('beneficiary_faq')" class="faq" :class="this.menuSelected.faq && 'faq-active'">{{ $t("faqName") }}</a>
          <a @click="pushRoute('beneficiary_account')" class="account" :class="this.menuSelected.account && 'account-active'">{{
            $t("settingsName") }}</a>
          <a @click="pushRoute('beneficiary_contact')" class="contact" :class="this.menuSelected.contact && 'contact-active'">{{
            $t("contactName") }}</a>
          <a @click="pushRoute('beneficiary_terms')" class="term" :class="this.menuSelected.terms && 'term-active'">{{
            $t("termsAndConditionsName") }}</a>
          <a @click="pushRoute('beneficiary_gdpr')" class="policy" :class="this.menuSelected.gdpr && 'policy-active'">{{
            $t("personalDataPolicyName") }}</a>
          <a href="#" class="logout" @click="logout()">{{ $t("logoutName") }}</a>
        </div>
        <div class="socials text-center box-sizing-bb ">
          <a href="https://www.facebook.com/UpRomania" class="facebook mx-3"><span class=""></span></a>
          <a href="https://www.linkedin.com/company/up-romania" class="linkedin mx-3"><span class=""></span></a>
          <a href="https://www.youtube.com/c/UpRomaniaBucuresti" class="youtube mx-3"><span class=""></span></a>
        </div>

        <div id="modal_popup_mobileNotifications" class="modal-popup">
          <div class="modal-popup-content">
            <a @click="showModalPopup('modal_popup_mobileNotifications', false)" class="close-button">
              <img src="../../assets/images/close-button.svg" />
            </a>
            <div class="notifications">
              <NotificationsBox :notifications="this.beneficiaryMessages" />
            </div>
          </div>
        </div>
      </div>

      <div class="container-right">
        <div class="barTop" :class="menuSelected.dashboard ? 'view' : 'bar-top-white'">
          <div class="logo-mobile" v-if="menuSelected.dashboard">
            <img src="../../assets/images/logo-up.png" />
          </div>
          <FlexbenCompanySwitcher v-if="flexbenHasAccess" @flexbenRerenderComponent="flexbenRerenderComponent"
            @showFlexbenComponents="flexbenToggleShowComponents" :showClass="true" />
          <div class="menu-white" v-if="menuSelected.dashboard" @click="openMenu(true)">
            <img src="../../assets/images/menu-burger-alb.svg" />
          </div>

          <div class="mobile-nav d-flex border-bottom" v-if="!menuSelected.dashboard && isMobile">
            <div class="page-title mx-auto">
              <h3>{{ this.getPageTitle() }}</h3>
            </div>
            <div class="menu-white" @click="openMenu(true)">
              <img src="../../assets/images/menu-burger-gri.svg" />
            </div>
          </div>
          <div class="mobile-nav bg-white mb-1 pt-1 d-flex" v-if="flexbenHasAccess && isMobile && flexbenShowComponents">
            <FlexbenCompanySwitcher v-if="flexbenHasAccess" @flexbenRerenderComponent="flexbenRerenderComponent"
              @showFlexbenComponents="flexbenToggleShowComponents" :showClass="false" />
            <div class="page-title"></div>
          </div>

          <div class="user float-right bg-white ml-auto">
            <div class="openNotifications" v-click-outside="hideNotification" @click="openNotifications">
              <div v-if="this.readMessagesCount > 0" class="unread-notifications">
                <span>{{ this.readMessagesCount }}</span>
              </div>
              <img src="@/assets/images/clopotel-notificari.svg" />
              <div class="notification-box" v-show="isOpenNotification">
                <div v-if="this.notificationON &&
                  this.beneficiaryMessages &&
                  this.beneficiaryMessages.length > 0
                  ">
                  <NotificationsBox :notifications="this.beneficiaryMessages" />
                  <a class="notification-view-all cursor-pointer" @click="pushRoute('beneficiary_notifications')">{{ $t("showAllNotifications") }}</a>
                </div>
                <div v-else-if="this.notificationON === false" class="noNotifications">
                  <p>{{ $t("notificationIsOFF") }}</p>
                  <p>
                    <a :href="'/beneficiary/account?tabSettings=1'">{{
                      $t("goToSetting")
                    }}</a>
                  </p>
                </div>
                <div v-else class="noNotifications">
                  <p>{{ $t("notificationMessage") }}</p>
                </div>
              </div>
            </div>
            <div>
              <img src="@/assets/images/linie.png" />
            </div>
            <div class="openUserMenu" v-click-outside="hide" @click="openUserMenu">
              <img src="@/assets/images/settings.png" />
              <a href="#">{{ this.beneficiary.first_name | titleCase }}
                {{ this.beneficiary.last_name | titleCase }}</a>
              <img src="@/assets/images/arrow-down.svg" />
              <div class="userMenu" v-show="isOpen">
                <a @click="pushRoute('beneficiary_account')" class="account cursor-pointer" :class="this.menuSelected.account && 'account-active'">{{
                  $t("settingsName") }}</a>
                <a @click="pushRoute('beneficiary_faq')" class="faq" :class="this.menuSelected.faq && 'faq-active'">{{ $t("faqName")
                }}</a>
                <a href="#" class="logout" @click="logout()">{{
                  $t("logoutName")
                }}</a>
              </div>
            </div>
            <div>
              <img src="@/assets/images/linie.png" />
            </div>
            <LanguageBar />
          </div>
        </div>

        <!-- <div class="content"> -->
        <Dashboard :accounts="this.beneficiary.accounts" :surname="this.beneficiary.first_name" :accessToken="this.token"
          v-if="this.menuSelected.dashboard" />

        <FlexbenBeneficiaryDashboard v-if="hasFlexBenAccess && this.menuSelected.beneficiaryFlexbenDashboard"
          :key="flexbenComponentKey" ref="flexben-beneficiary-dashboard" />

        <FlexbenBeneficiaryBenefits v-if="hasFlexBenAccess && this.menuSelected.beneficiaryFlexbenBenefits"
          budgetConsumptionType="general" :key="flexbenComponentKey" ref="flexben-beneficiary-benefits" />

        <FlexbenBeneficiaryBenefits v-if="hasFlexBenAccess && this.menuSelected.beneficiaryFlexbenTelework"
          budgetConsumptionType="telework" :key="flexbenComponentKey" ref="flexben-beneficiary-benefits" />

        <FlexbenBeneficiaryRequests v-if="hasFlexBenAccess && this.menuSelected.beneficiaryFlexbenRequests"
          :key="flexbenComponentKey" />

        <BeneficiaryRequestsVoucherHistory
          v-if="hasFlexBenAccess && this.menuSelected.beneficiaryFlexbenRequestVoucherHistory"
          :key="flexbenComponentKey" />

        <FlexbenBeneficiaryFixedBenefits v-if="hasFlexBenAccess && this.menuSelected.beneficiaryFlexbenFixedBenefits"
          :key="flexbenComponentKey" />

        <FlexbenBeneficiaryAutoTopups v-if="hasFlexBenAccess && this.menuSelected.beneficiaryFlexbenAutoTopups"
          :key="flexbenComponentKey" />

        <FlexbenBeneficiaryOffers v-if="hasFlexBenAccess && this.menuSelected.beneficiaryFlexbenOffers"
          :key="flexbenComponentKey" />
        <LinkFrame :urlAddress="this.config.faq_multiben" :accessToken="this.token"
          v-if="this.menuSelected.beneficiaryFlexbenFaq" />
        <LinkFrame :urlAddress="this.config.terms_multiben" :accessToken="this.token"
          v-if="this.menuSelected.beneficiaryFlexbenTerms" />
        <LinkFrame :urlAddress="this.config.gdpr_multiben" :accessToken="this.token"
          v-if="this.menuSelected.beneficiaryFlexbenGdpr" />

        <Cards :accounts="this.beneficiary.accounts" :accessToken="this.token" v-if="this.menuSelected.cards" />
        <Services :accessToken="this.token" :beneficiaryCardData="this.beneficiary"
          :recommandPartnerLink="this.config.suggest" :accounts="this.beneficiary.accounts"
          v-if="this.menuSelected.services" />
        <LinkFrame :urlAddress="this.config.web_faq" :accessToken="this.token" v-if="this.menuSelected.faq" />
        <MyAccount :beneficiaryCardData="this.beneficiary" :accessToken="this.token" v-if="this.menuSelected.account" />
        <LinkFrame :urlAddress="this.config.contact" v-if="this.menuSelected.contact" />
        <LinkFrame :urlAddress="this.config.web_terms" v-if="this.menuSelected.terms" />
        <LinkFrame :urlAddress="this.config.web_gdpr" v-if="this.menuSelected.gdpr" />
        <NotificationsPage :notifications="this.beneficiaryMessages" v-if="this.menuSelected.notifications" />
      </div>
      <content-loader :loaderVisible="flexbenLoading"></content-loader>
      <ModalPopupNPS :type="'nps_beneficiary'" :notification="this.npsNotification" :userId="this.beneficiary.id" />
    </div>
  </div>
</template>

<style scoped src="@/assets/styles/beneficiary.css"></style>
<style>
.box-sizing-bb {
  box-sizing: border-box !important;
}
</style>

<script>
import moment from "moment";
import LanguageBar from "@/components/LanguageBar.vue";
import Dashboard from "@/components/beneficiary/Dashboard.vue";
import Cards from "@/components/beneficiary/Cards.vue";
import Services from "@/components/beneficiary/Services.vue";
import MyAccount from "@/components/beneficiary/MyAccount.vue";
import LinkFrame from "@/components/beneficiary/LinkFrame.vue";
import NotificationsPage from "@/components/beneficiary/Notifications.vue";
import httpServiceBeneficiary from "../../http/http-beneficiary.js";
import httpService from "../../http/http-common.js";
import Cookies from "js-cookie";
import ClickOutside from "vue-click-outside";
import FlexbenMenu from "@/components/fragments/FlexbenMenu";
import FlexbenBeneficiaryDashboard from "../../components/flexben/beneficiary_pages/dashboard/BeneficiaryDashboard";
import FlexbenBeneficiaryBenefits from "../../components/flexben/beneficiary_pages/benefits/BeneficiaryBenefitList";
import FlexbenBeneficiaryRequests from "../../components/flexben/beneficiary_pages/requests/BeneficiaryRequests";
import FlexbenBeneficiaryFixedBenefits
  from "../../components/flexben/beneficiary_pages/fixed_benefits/BeneficiaryFixedBenefits";
import FlexbenBeneficiaryOffers from "../../components/flexben/beneficiary_pages/offers/BeneficiaryOffers";
import FlexbenBeneficiaryAutoTopups from "../../components/flexben/beneficiary_pages/dashboard/BeneficiaryAutoTopups";
import FlexbenCompanySwitcher from "../../components/fragments/FlexbenCompanySwitcher";
import NotificationsBox from "@/components/NotificationsBox.vue";
import BeneficiaryRequestsVoucherHistory
  from "@/components/flexben/beneficiary_pages/requests/BeneficiaryRequestsVoucherHistory";
import ModalPopupNPS from '@/components/fragments/ModalNps.vue';

export default {
  name: "Beneficiary",
  components: {
    FlexbenMenu,
    FlexbenCompanySwitcher,
    FlexbenBeneficiaryDashboard,
    FlexbenBeneficiaryBenefits,
    FlexbenBeneficiaryRequests,
    BeneficiaryRequestsVoucherHistory,
    FlexbenBeneficiaryFixedBenefits,
    FlexbenBeneficiaryOffers,
    FlexbenBeneficiaryAutoTopups,
    Dashboard,
    Cards,
    Services,
    MyAccount,
    LinkFrame,
    LanguageBar,
    NotificationsBox,
    NotificationsPage,
    ModalPopupNPS
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      beneficiaryLink: "",
      token: "",
      beneficiary: null,
      beneficiaryMessages: null,
      dataLoaded: false,
      menuSelected: {
        dashboard: false,
        cards: false,
        services: false,
        faq: false,
        account: false,
        contact: false,
        terms: false,
        gdpr: false,
        notifications: false,
        beneficiaryFlexbenDashboard: false,
        beneficiaryFlexbenBenefits: false,
        beneficiaryFlexbenRequests: false,
        beneficiaryFlexbenRequestVoucherHistory: false,
        beneficiaryFlexbenFixedBenefits: false,
        beneficiaryFlexbenAutoTopups: false,
        beneficiaryFlexbenOffers: false,
        beneficiaryFlexbenFaq: false,
        beneficiaryFlexbenTerms: false,
        beneficiaryFlexbenGdpr: false,
        beneficiaryFlexbenTelework: false
      },
      config: JSON.parse(sessionStorage.getItem("mobile_jsonFile")),
      isOpen: false,
      isOpenNotification: false,
      readMessagesCount: 0,
      flexbenHasAccess: false,
      flexbenLoading: false,
      flexbenComponentKey: 0,
      flexbenShowComponents: false,
      isMobile: (document.body.clientWidth < 1004),
      marketingMessage: true,
      notificationON: false,
      showMobileMenu: false,
      npsNotification: {}
    };
  },
  filters: {
    fromNow(date) {
      return moment(date).fromNow();
    }
  },
  computed: {
    isFlexBenRoute() {
      return (
        this.menuSelected.beneficiaryFlexbenDashboard ||
        this.menuSelected.beneficiaryFlexbenBenefits ||
        this.menuSelected.beneficiaryFlexbenFixedBenefits ||
        this.menuSelected.beneficiaryFlexbenRequestVoucherHistory ||
        this.menuSelected.beneficiaryFlexbenOffers ||
        this.menuSelected.beneficiaryFlexbenAutoTopups ||
        this.menuSelected.beneficiaryFlexbenRequests ||
        this.menuSelected.beneficiaryFlexbenFaq ||
        this.menuSelected.beneficiaryFlexbenGdpr ||
        this.menuSelected.beneficiaryFlexbenTelework ||
        this.menuSelected.beneficiaryFlexbenTerms
      );
    },
    hasFlexBenAccess() {
      if (this.isFlexBenRoute) {
        if (this.flxUserLevel().hasAccess) {
          return true;
        } else {
          window.location = "/beneficiary";
        }
      }
      return false;
    }
  },
  methods: {
    loadBeneficiaryData() {

      if (Cookies.get("beneficiaryLink") === null) {
        console.error('No beneficiary Link set');
        return this.logout();
      }

      let beneficiaryLink = Cookies.get("beneficiaryLink");
      let token = Cookies.get("token");

      if (beneficiaryLink !== null && token !== null) {
        this.beneficiaryLink = beneficiaryLink;
        this.token = token;
      } else {
        console.error('Beneficiary link and token is null')
        return this.logout();
      }

      if (!this.beneficiaryLink || !this.token) {
        console.error('Beneficiary link and token is undefined')
        return this.logout();
      }

      httpServiceBeneficiary.beneficiaryService
        .getBeneficiaryData(this.beneficiaryLink, this.token)
        .then(function (response) {
          // handle success
          return response.data;
        })
        .then(data => {
          this.beneficiary = data;
          this.loadMessages();
		  // this.loadMessageNPS();
          this.dataLoaded = true;
          this.$emit(
            "sendUserRole",
            "BEN",
            ""
          );

          this.flexbenHasAccess = this.beneficiary != null && this.flxUserLevel().hasAccess;
        })
        .catch(function (error) {
          console.log(error);
          this.logout();
        });
    },
    loadMessages() {
      let formData =
        "?user_id=" + this.beneficiary.id + "&lang=" + localStorage.language;
        
        httpServiceBeneficiary.beneficiaryService
        .getMessages(formData, this.token)
        .then(function (response) {
          // handle success
          return response;
        })
        .then(response => {
          // total no of messages X-Up-Count
          // no of read messages
          this.readMessagesCount = response.headers["x-up-count1"];
          // no of unread messages X-Up-Count2
          this.beneficiaryMessages = response.data;
        })
        .catch(function (error) {
          // handle error
        });
    },
    loadMessageNPS() {
      let formData =
        "?user_id=" + this.beneficiary.id + "&lang=" + localStorage.language + "&type=nps";
      httpServiceBeneficiary.beneficiaryService
        .getMessages(formData, this.token)
        .then(function (response) {
          // handle success
          return response;
        })
        .then(response => {
          // total no of messages X-Up-Count
          let hasMessage = response.headers["x-up-count"];

          if (hasMessage > 0) {
            let notification = response.data[0];
            let formDataNotification = "?id=" + notification.id + "&lang=" + localStorage.language;
            httpServiceBeneficiary.beneficiaryService
              .getNotification(this.beneficiary.id, notification.id, formDataNotification, this.token)
              .then(function (response) {
                // handle success
                return response;
              })
              .then(response => {
                this.npsNotification = response.data;
                this.npsNotification.content = this.fromBinaryStr(atob(this.npsNotification.content));
                // this.showModalPopup("modal_popup_nps", true);
              })
              .catch(function (error) {
                // handle error
              });
          }
        })
        .catch(function (error) {
          // handle error
        });
    },
    openMenu(open) {
      this.showMobileMenu = open;
    },
    openUserMenu() {
      this.isOpen = !this.isOpen;
    },
    openNotifications() {
      this.isNotificationON();
      this.loadMessages();
      this.isOpenNotification = true;
    },
    isReadNotification(message) {
      message.read = true;
      httpServiceBeneficiary.beneficiaryService
        .markMessageAsRead(message, this.token)
        .catch(function (error) {
          // handle error
        });
    },
    hide() {
      this.isOpen = false;
    },
    hideNotification() {
      this.isOpenNotification = false;
    },
    getPageTitle() {
      if (this.menuSelected.cards) {
        return "Cards";
      } else if (this.menuSelected.services) {
        return "Services";
      } else if (this.menuSelected.faq) {
        return "FAQ";
      } else if (this.menuSelected.account) {
        return "My Account";
      } else if (this.menuSelected.contact) {
        return "Contact";
      } else if (this.menuSelected.terms) {
        return "Terms and conditions";
      } else if (this.menuSelected.gdpr) {
        return "Personal data policy";
      } else if (this.menuSelected.notifications) {
        return "Notifications";
      } else {
        return "Dashboard";
      }
    },
    logout() {
      Cookies.remove("beneficiaryLink");
      Cookies.remove("token");
      let hasFxlAccess = this.flxUserLevel().hasAccess;
      this._flexbenLogout();
      sessionStorage.removeItem("chatOpened");
      if (hasFxlAccess) {
        this.$router.push({
          name: "loginMultibenefits",
          params: { loginUserSelected: true }
        });
      } else {
        this.$router.push({
          name: "login",
          params: { loginUserSelected: true }
        });
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    getExpireAmountDateFormat(date) {
      return moment(new Date(date), "YYYY-MM-DD").format("DD MMM YYYY");
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
        console.log(elementId, show);
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    redirectFlexbenNoAccess() {
      window.location = "/beneficiary";
    },
    flexbenRerenderComponent(completeRerender = false) {
      if (this.isFlexBenRoute && completeRerender) {
        this.flexbenComponentKey++;
      }
      if (this.$refs['flexben-beneficiary-benefits']) {
        this.$refs['flexben-beneficiary-benefits'].rerenderForCartChange()
      }
      if (this.$refs['flexben_side_menu']) {
        this.$refs['flexben_side_menu'].setActiveEmployment()
      }
    },
    isNotificationON() {
      let notificationTypeNotification = this.beneficiary.notifications.filter(
        notification => {
          return notification.type === "GDPR_PSH";
        }
      );
      this.notificationON = notificationTypeNotification[0].active;
    },
    _flexbenLogout() {
      this.$store.commit('logout');
      Cookies.remove("flexbenActiveEmployments");
      Cookies.remove("flxCookie");
      localStorage.removeItem("flexbenActiveEmploymentId");
      sessionStorage.removeItem("flexbenFastTopUpBenefitId");
      sessionStorage.removeItem("flx_active_employments");
      sessionStorage.removeItem("flx_active_employment");
    },
    flexbenToggleShowComponents() {
      this.flexbenShowComponents = this.beneficiary !== null;
    }
  },
  mounted() {
    // prevent click outside event with popupItem.
    // if (this.checkNpsSurvey("nps_beneficiary") < 4) {
    //   this.showModalPopup("modal_popup_nps", true);
    // }
  },
  created() {
    switch (this.$route.name) {
      case "beneficiary_cards":
        this.menuSelected.cards = true;
        break;
      case "beneficiary_services":
        this.menuSelected.services = true;
        break;
      case "beneficiary_faq":
        this.menuSelected.faq = true;
        break;
      case "beneficiary_account":
        this.menuSelected.account = true;
        break;
      case "beneficiary_contact":
        this.menuSelected.contact = true;
        break;
      case "beneficiary_terms":
        this.menuSelected.terms = true;
        break;
      case "beneficiary_gdpr":
        this.menuSelected.gdpr = true;
        break;
      case "beneficiary_notifications":
        this.menuSelected.notifications = true;
        break;
      case "beneficiary_flexben_dashboard":
        this.menuSelected.beneficiaryFlexbenDashboard = true;
        break;
      case "beneficiary_flexben_benefits":
        this.menuSelected.beneficiaryFlexbenBenefits = true;
        break;
      case "beneficiary_flexben_requests":
        this.menuSelected.beneficiaryFlexbenRequests = true;
        break;
      case "beneficiary_flexben_request_voucher_history":
        this.menuSelected.beneficiaryFlexbenRequestVoucherHistory = true;
        break;
      case "beneficiary_flexben_fixed_benefits":
        this.menuSelected.beneficiaryFlexbenFixedBenefits = true;
        break;
      case "beneficiary_flexben_offers":
        this.menuSelected.beneficiaryFlexbenOffers = true;
        break;
      case "beneficiary_flexben_auto_topups":
        this.menuSelected.beneficiaryFlexbenAutoTopups = true;
        break;
      case "beneficiary_flexben_terms":
        this.menuSelected.beneficiaryFlexbenTerms = true;
        break;
      case "beneficiary_flexben_faq":
        this.menuSelected.beneficiaryFlexbenFaq = true;
        break;
      case "beneficiary_flexben_gdpr":
        this.menuSelected.beneficiaryFlexbenGdpr = true;
        break;
      case "beneficiary_flexben_telework_benefits":
        this.menuSelected.beneficiaryFlexbenTelework = true;
        break;
      default:
        this.menuSelected.dashboard = true;
    }
    if (!sessionStorage.getItem("mobile_jsonFile")) {
      httpService.commonsService.getConfigFile(
        localStorage.language,
        "mobile"
      );
    }
    if (!Cookies.get("beneficiaryLink")) {
      this.$router.push({
        name: "login",
        params: { loginUserSelected: true }
      });
    }

    if (this.beneficiary == null) {
      this.loadBeneficiaryData();
    }

    //show flexben navigation if beneficiary has any active employments in flexben
    if (this.$store.state.flx_beneficiaryActiveEmploymentId) {
      this.flexbenShowComponents = true;
    }

  }
};
</script>

<style scoped >
.modal-popup-content[data-v-6f547fa0] {
  width: 550px !important;
  padding: 0px !important;
  max-width: 550px !important;
}
</style>
