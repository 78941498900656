export default {
  title: 'Brands',
  description: 'Administrate benefit brands',
  new: 'New brand',
  edit: 'Change brand',
  fields: {
    name: 'Name',
    description_placeholder: 'Brand description text. Minimum 50 characters. Maximum 1000 characters',
    website: 'Website',
    scope_availability: 'Availability',
  },
  modal: {
    confirm_save: 'Are you sure you want to save the changes?',
    confirm_leave: 'The information has been changed, are you sure you want to leave?',
    confirm_delete: 'Are you sure you want to delete the brand?',
    extra_delete: 'Current brand assigned to {count_benefits} benefits.'
  },
  tooltips: {
    description: '(min 50, max 1000)'
  },
  scope_availability: {
    benefit: 'Benefits',
    offer: 'Offers',
  },
}
