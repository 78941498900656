import Cookies from "js-cookie";

class ApiHelper {

  getBaseUrl(version = 'v1') {
    if (version !== 'v2') {
      return process.env.VUE_APP_FLEXBEN_BASE;
    }
    return process.env.VUE_APP_FLEXBEN_BASE_V2;
  }

  getToken() {
    let cookieToken = Cookies.get('token');

    if (cookieToken) {
      return btoa(cookieToken);
    }

    return null;
  }
}

export default new ApiHelper();
