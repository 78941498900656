<template>
  <div v-if="hasCeBenefitByContributionType">
    <div class="container-fluid">
        <div class="row" v-if="isActivePersonalContributionType">
          <button
            class="btn btn-themed-subselect mx-md-1 mb-2 col-12 col-md d-inline-flex align-items-center justify-content-center border-themed"
            v-for="(contributionType, index) in this.contributionTypesList"
            :class="{ active: contributionType.value === contributionTypeSelected }"
            :disabled="globalCeBenefitData.data.benefit.current_active_recurring_benefit !== null"
            @click="changeContributionTypeSelected(contributionType.value)"
            :key="index">
            {{$t("flexben.beneficiaries.fields.contribution_type")}}:  {{ $t(contributionType.translationKey) }}
          </button>
        </div>
        <div v-for="contributionType in this.ceBenefitByContributionType" class="container-fluid" :key="contributionType.id">
          <div class="mb-5" v-if="contributionType.isShow">
            <div class="row container-gray py-3 text-left" >
            <div :class="{
          'col-12 ':true
          }">
              <div  class="d-flex justify-content-between">
                <span class="flx-type-badge mr-1 h-25">{{ $t("flexben.benefit.sub_types."+ contributionType.ceBenefit.benefit.sub_type) }}</span>
                <div :class="companyOptions.cam_budget_taxation ? 'text-right text-xl-left' : 'text-right'" class="w-100" v-if="contributionType.contributionType === 'mixt'">
                  <div class="d-block text-center" >
                    <div class="row mb-3">
                      <div class="col-sm-10 p-0" style="text-align: end">
                       <span class="mr-1 " v-html="(contributionType.ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale] === null) ?
                        $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
                        : contributionType.ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale]">
                        </span>
                      </div>
                      <div class="col-sm-2 p-0">
                        <span id="tooltip_up_dejun_deductibility" class="d-inline-block flexben-tooltip-icon ">i</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-10 p-0" style="text-align: end">
                        <span>{{$t('flexben.beneficiary_pages.benefits.tooltips.employee')}}</span>
                      </div>
                      <div class="col-sm-2 p-0">
                        <div v-if="taxesLoader" class="load d-flex"></div>
                        <b-tooltip :target="'tooltip_up_dejun_deductibility'" variant="flexben" placement="topleft">
                          <div class="text-left">
                            {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
                          </div>
                        </b-tooltip>
                        <span v-if="!taxesLoader" class="text-danger"><strong>{{ salaryDeductibilityTypeCompany }} Lei</strong></span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-10 p-0" style="text-align: end">
                        <span >{{$t('flexben.beneficiary_pages.benefits.tooltips.personal')}}</span>
                      </div>
                      <div class="col-sm-2 p-0">
                        <div v-if="taxesLoader" class="load d-flex"></div>
                        <b-tooltip :target="'tooltip_up_dejun_deductibility'" variant="flexben" placement="topleft">
                          <div class="text-left">
                            {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
                          </div>
                        </b-tooltip>
                        <span v-if="!taxesLoader" class="text-danger"><strong>{{ salaryDeductibilityTypePersonal }} Lei</strong></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="contributionType.contributionType === 'personal'" class="d-flex">
                  <div v-if="contributionType.ceBenefit.benefit.translations !== undefined">
                     <span class="mr-1" v-html="(contributionType.ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale] === null) ?
                        $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
                        : contributionType.ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale]">
                      </span>
                  </div>
                  <span id="tooltip_up_dejun_deductibility" class="d-inline-block flexben-tooltip-icon mr-2">i</span>
                  <div v-if="taxesLoader" class="load d-flex"></div>
                  <b-tooltip :target="'tooltip_up_dejun_deductibility'" variant="flexben" placement="topleft">
                    <div class="text-left">
                      {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
                    </div>
                  </b-tooltip>
                  <span v-if="!taxesLoader" class="text-danger"><strong>{{ salaryDeductibilityTypePersonal }} Lei</strong></span>
                </div>
                <div v-else-if="contributionType.contributionType === 'employer'" class="d-flex">
                  <div v-if="contributionType.ceBenefit.benefit.translations !== undefined">
                     <span class="mr-1" v-html="(contributionType.ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale] === null) ?
                        $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
                        : contributionType.ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale]">
                      </span>
                  </div>
                  <span id="tooltip_up_dejun_deductibility" class="d-inline-block flexben-tooltip-icon mr-2">i</span>
                  <div v-if="taxesLoader" class="load d-flex"></div>
                  <b-tooltip :target="'tooltip_up_dejun_deductibility'" variant="flexben" placement="topleft">
                    <div class="text-left">
                      {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
                    </div>
                  </b-tooltip>
                  <span v-if="!taxesLoader" class="text-danger"><strong>{{ salaryDeductibilityTypeCompany }} Lei</strong></span>
                </div>
              </div>
              </div>
              <div :class="companyOptions.cam_budget_taxation ? 'text-right text-xl-left mt-2' : 'text-right mt-2'"></div>
              <div v-if="globalCeBenefitData.data.benefit.current_active_recurring_benefit !== null"
                   class="col-12 text-danger text-bold text-center">
                {{ $t('flexben.beneficiary_pages.benefits.non_legislated_already_month_use',{
                type: $t("flexben.benefit.sub_types."+ contributionType.ceBenefit.benefit.sub_type),
                benefit: contributionType.ceBenefit.benefit.current_active_recurring_benefit.name
                }) }}.
              </div>

              <div v-if="globalCeBenefitData.data.benefit.current_active_recurring_benefit === null && globalCeBenefitData.data.benefit.recurring_has_ordered"
                   class="col-12 text-danger text-bold text-center"
                   v-html="$t('flexben.beneficiary_pages.benefits.non_legislated_recurring_ordered')">
              </div>
              <div class="col-12 col-md-6 col-xl-6 form-group">
                <label for="contract_nr" class="col-form-label ">{{
                  $t('flexben.beneficiary_pages.benefits.fields.contract_number') }}
                  <span class="text-danger">*</span></label>
                <input type="text" id="contract_nr" :disabled="globalCeBenefitData.data.benefit.current_active_recurring_benefit !== null"
                       :class="{'border-danger':invalidFields.contractNr}"
                       class="form-control" v-model.trim="contributionType.contractNr">
              </div>
              <!--Personal value-->
              <div
                v-if="contributionType.contributionType === 'mixt' || contributionType.contributionType === 'personal'"
                class="col-12 col-md-6 col-xl-6 form-group">
                <label for="recurring_value" class="col-form-label">
                  {{ $t('flexben.beneficiary_pages.benefits.fields.top_up_personal_recurring_value') }}
                  <span class="text-danger">*</span>
                </label>
                <input id="recurring_value"
                       :disabled="globalCeBenefitData.data.benefit.current_active_recurring_benefit !== null"
                       type="number"
                       class="form-control"
                       :class="{'border-danger':invalidFields.recurring_personal_value}"
                       v-model.number="recurringPersonalValue">
                <div :class="{
              'show' : errorRecurringPersonalValue
            }" class="invalid-feedback">
                  {{$t('flexben.beneficiary_pages.benefits.fields.error_input_val') }}
                </div>
              </div>
              <!-- Employer value -->
              <div
                v-if="contributionType.contributionType === 'mixt' || contributionType.contributionType === 'employer'"
                class="col-12 col-md-6 col-xl-6 form-group">
                <label for="contract_nr" class="col-form-label">{{
                  $t('flexben.beneficiary_pages.benefits.fields.top_up_employer_recurring_value') }}
                  <span class="text-danger">*</span></label>
                <input type="number" step="0.01" id="contract_nr"
                       :disabled="globalCeBenefitData.data.benefit.current_active_recurring_benefit !== null"
                       :class="{'border-danger':invalidFields.recurring_company_value}"
                       class="form-control"
                       v-model.number="recurringCompanyValue">
                <div :class="{
              'show' : errorRecurringCompanyValue
            }" class="invalid-feedback">
                  {{ $t('flexben.beneficiary_pages.benefits.fields.error_input_val') }}
                </div>
              </div>
              <!--   Total           -->

            <div class="col-12 text-bold width-100">
            <span v-html="$t('flexben.beneficiary_pages.benefits.non_legislated_recurring_not_taxed',{
            value:contributionType.monthlyNotTaxedAttribution, remainingYearMonths:contributionType.remainingYearMonths
            })"></span> {{
                globalCeBenefitData.data.benefit.recurring_has_ordered ?
                '' : $t('flexben.beneficiary_pages.benefits.non_legislated_recurring_not_taxed_extra')
                }}.
              </div>
              <div class="position-relative w-100">
                <div v-if="taxesLoader" class="load load-total position-absolute"></div>
                <total-value-pay
                  v-if="companyOptions.gross_budget_taxation || companyOptions.cam_budget_taxation"
                  :totalPayValue="totalPayValue"
                  :isLoading="taxesLoader"
                ></total-value-pay>
              </div>
            </div>
          </div>
        </div>
      <div class="row mt-3">
        <div class="col-12 px-0 text-md-left">
          <button class="btn px-2 px-md-4 mb-2 mr-md-2"
                  :disabled="globalCeBenefitData.data.benefit.current_active_recurring_benefit !== null || !canActivateAutoTopUp"
                  :class="{
                              'btn-themed-green':!enableAutoTopup,
                              'btn-themed-red':enableAutoTopup,
                            }"
                  @click="confirmToggleAutoTopUp('toggle')"
          >
            {{ toogleAutoTopUpButtonText(globalCeBenefitData.recurring_has_ordered) }}
          </button>

          <button v-if="enableAutoTopup"
                  class="btn btn-themed btn px-2 px-md-4 mb-2"
                  @click="confirmToggleAutoTopUp('update')">{{
            $t('flexben.beneficiary_pages.benefits.btn.update_auto_top_up') }}
          </button>
          <a v-if="globalCeBenefitData.data.benefit.product_link!==null && globalCeBenefitData.data.benefit.product_link.length>0"
             :href="globalCeBenefitData.data.benefit.product_link" target="_blank"
             class="btn btn-themed-blue float-md-right px-2 px-md-4 mb-2"><span
            class="d-flex">
              <font-awesome-icon icon="external-link-alt" class="mr-1 contact-ico text-14"/>
              <span class="text-14">{{ $t('flexben.beneficiary_pages.benefits.btn.sign_contract') }}</span>
            </span></a>
        </div>
      </div>
        </div>

        <auto-top-up-not-legislated-modal ref="auto_top_up_not_legislated_modal"
                                          :locale="locale"
                                          @confirmAction="toggleAutoTopUp">
          <template v-slot:body>
            <div class="mb-0 mx-auto">
              <div v-if="ceBenefitByContributionType[contributionTypeSelected].ceBenefit.benefit.sub_type === 'ppp3'">
                <template v-if="action==='toggle'">
                  <div class="text-dark" v-if="enableAutoTopup">
                    {{$t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_disable') }}
                  </div>
                  <div v-else>
                    {{$t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_enable') }} <br><br>
                    <div class="text-danger">{{$t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_enable_pp3')
                      }}
                    </div>
                  </div>
                </template>
                <template v-if="action==='update'">
                  {{$t('flexben.beneficiary_pages.benefits.modal.auto_topup.confirm_update') }} <br><br>
                  <div class="text-danger">{{ $t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_enable_pp3')
                    }}
                  </div>
                </template>
              </div>
              <div class="text-capitalize" v-else>
                <template v-if="action==='toggle'">
                  {{ enableAutoTopup ? $t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_disable') :
                  $t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_enable') }}
                </template>
                <template v-if="action==='update'">
                  {{ $t('flexben.beneficiary_pages.benefits.modal.auto_topup.confirm_update') }}
                </template>
              </div>
            </div>
          </template>
        </auto-top-up-not-legislated-modal>
    </div>
</template>
<style type="scss">

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.load {
  width: 20px;
  height: 20px;
  margin: 0px auto 0;
  border: solid 2px #82a;
  border-right-color: rgb(136, 34, 170);
  border-bottom-color: rgb(136, 34, 170);
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1.0s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1.0s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.load-total {
  right: 0;
  margin-right: 62px;
}

</style>
<script>
import {BTooltip} from "bootstrap-vue";
import AutoTopUpNotLegislatedModal from "../modals/AutoTopUpNotLegislatedModal";
import TotalValuePay from "./TotalValuePay";
import ApiTaxes from "@/multiben-module/src/services/api/apiTaxes";

export default {
  name: "PPP3Forms",
  components: {
    'b-tooltip': BTooltip,
    'auto-top-up-not-legislated-modal': AutoTopUpNotLegislatedModal,
    'total-value-pay': TotalValuePay,
  },
  props: {
    ceBenefitAdditionalData: {
      type: Object,
      required: true
    },
    ceBenefit: {
      required: true
    },
    budgets: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },

    errorsBag: {
      type: [Object, Array],
      default: function () {
        return {};
      }
    },
    isActivePersonalContributionType: {
      type: Boolean,
      required: true
    },
    companyOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          cam_budget_taxation: false,
          gross_budget_taxation: false,
          legislated_gross_calculation: false,
          last_monthly_order_day: null,
          budget_telework_transfer_to_general: false,
          cash_out_last_order_day: null,
          non_legislated_net_taxes_calculate: false,
          banner_image_url: null,
        };
      }
    },
  },
  data: function () {
    return {
      globalCeBenefitData: {
        data: null,
      },
      contributionTypeSelected: '',
      ceBenefitByContributionType: {
        mixt: {
          ceBenefit: null,
          remainingYearMonths: 0,
          monthlyNotTaxedAttribution: 0,
          contractNr: '',
          recurringEmployeeValue: 0,
          contributionType: null,
          isShow: false,
        },
        personal: {
          ceBenefit: null,
          remainingYearMonths: 0,
          monthlyNotTaxedAttribution: 0,
          contractNr: '',
          contributionType: null,
          isShow: false,
        },
        employer: {
          ceBenefit: null,
          remainingYearMonths: 0,
          monthlyNotTaxedAttribution: 0,
          contractNr: '',
          contributionType: null,
          isShow: false,
        },
      },
      hasCeBenefitByContributionType: false,
      taxesLoader: false,
      contributionType: 'personal',
      salaryDeductibilityTypePersonal: 0,
      salaryDeductibilityTypeCompany: 0,
      contractNr: '',
      invalidFields: {},
      action: 'toggle',
      recurringPersonalValue: 0,
      recurringCompanyValue: 0,
      recurringValue: 0,
      totalPayValue: 0,
      isCreatedLoad: false,
      errorRecurringPersonalValue: false,
      errorRecurringCompanyValue: false,
      contributionTypesList: [
        {
          translationKey: "flexben.benefit.contribution_type.personal",
          value: "personal"
        },
        {
          translationKey: "flexben.benefit.contribution_type.employer",
          value: "employer"
        },
        {
          translationKey: "flexben.benefit.contribution_type.mixt",
          value: "mixt"
        },
      ],
    }
  },
  computed: {
    enableAutoTopup() {
      return this.globalCeBenefitData.data.enable_auto_topup;
    },
    canActivateAutoTopUp() {

      const contractNumber = this.ceBenefitByContributionType[this.contributionTypeSelected].contractNr;

      let hasContractNumber = (typeof contractNumber === 'string') && contractNumber.length > 0;
      let hasPersonalContributionSet = true;
      let hasCompanyContributionSet = true;
      if (this.contributionTypeSelected === 'personal' || this.contributionTypeSelected === 'mixt') {
        hasPersonalContributionSet = (typeof this.recurringPersonalValue === 'number') && this.recurringPersonalValue > 0;
      }
      if (this.contributionTypeSelected === 'employer' || this.contributionTypeSelected === 'mixt') {
        hasCompanyContributionSet = (typeof this.recurringCompanyValue === 'number') && this.recurringCompanyValue > 0;
      }

      return (hasContractNumber && hasPersonalContributionSet && hasCompanyContributionSet);
    }
  },

  watch: {
    ceBenefit: {
      handler: function (val) {
        this.setBenefit();
      }
    },

    recurringPersonalValue: {
      handler: function (val) {

        if (val === 0) {
          return;
        }

        if (!val) {
          this.errorRecurringPersonalValue = true;
          return;
        } else {
          this.errorRecurringPersonalValue = false;
        }

        let companyValue = 0;

        if (this.contributionTypeSelected === 'mixt') {
          companyValue = (!this.recurringCompanyValue) ? 0 : this.recurringCompanyValue;
        }

        this._calculateTotalByCompanyOptions(val, companyValue, this.contributionTypeSelected);

      }
    },

    recurringCompanyValue: {
      handler: function (val) {

        let personalValue = 0;

        if (val === 0) {
          return;
        }

        if (!val) {
          this.errorRecurringCompanyValue = true;
          return;
        } else {
          this.errorRecurringCompanyValue = false;
        }

        if (this.contributionTypeSelected === 'mixt') {
          personalValue = (!this.recurringPersonalValue) ? 0 : this.recurringPersonalValue;
        }

        this._calculateTotalByCompanyOptions(personalValue, val, this.contributionTypeSelected);
      }
    },
  },

  created() {
    this.isCreatedLoad = true;
    this.setBenefit();
  },

  methods: {

    toogleAutoTopUpButtonText() {
      return this.enableAutoTopup ?
        this.$t('flexben.beneficiary_pages.benefits.btn.disable_auto_top_up') :
        this.$t('flexben.beneficiary_pages.benefits.btn.activate_auto_top_up')
    },


    changeContributionTypeSelected(value) {
      if (!this.ceBenefitByContributionType[value]) {
        return;
      }

      switch (value) {
        case 'mixt':
          this.ceBenefitByContributionType.personal.isShow = false;
          this.ceBenefitByContributionType.employer.isShow = false;
          this.ceBenefitByContributionType.mixt.isShow = true;
          break
        case 'personal':
          this.ceBenefitByContributionType.personal.isShow = true;
          this.ceBenefitByContributionType.employer.isShow = false;
          this.ceBenefitByContributionType.mixt.isShow = false;
        break
        case 'employer':
          this.ceBenefitByContributionType.personal.isShow = false;
          this.ceBenefitByContributionType.employer.isShow = true;
          this.ceBenefitByContributionType.mixt.isShow = false;
      }

      this.contributionTypeSelected = value;

      this._calculateTotalByCompanyOptions(this.recurringPersonalValue, this.recurringCompanyValue, this.contributionTypeSelected);

    },

    setBenefit() {



      console.log(this.isActivePersonalContributionType)


      if (Object.keys(this.ceBenefit).length !== 0) {
        this.hasCeBenefitByContributionType = true;
      }

      this.globalCeBenefitData.data = null;

      this.ceBenefit.forEach(ceBenefit => {

        if (!ceBenefit.benefit) {
          return;
        }

        if (ceBenefit.enable_auto_topup) {
          this.globalCeBenefitData.data = ceBenefit;
        } else if (!this.globalCeBenefitData.data) {
          this.globalCeBenefitData.data = ceBenefit;
        }

        let ceBenefitContributionType = ceBenefit.contribution_type;

        for (let contributionType in this.ceBenefitByContributionType) {
          if (ceBenefitContributionType !== contributionType) {
            continue;
          }

          this.ceBenefitByContributionType[contributionType].remainingYearMonths = (11 - (new Date()).getMonth()) + 1 - (ceBenefit.benefit.recurring_has_ordered ? 1 : 0);
          this.ceBenefitByContributionType[contributionType].ceBenefit = ceBenefit;

          if (ceBenefit.benefit.remaining_legal_limit > 0) {
            this.ceBenefitByContributionType[contributionType].monthlyNotTaxedAttribution = (ceBenefit.benefit.remaining_legal_limit / this.ceBenefitByContributionType[contributionType].remainingYearMonths).toFixed(2);
          }

          this.ceBenefitByContributionType[contributionType].contractNr = ceBenefit.contract_numbers ? ceBenefit.contract_numbers.number : '';
          this.ceBenefitByContributionType[contributionType].contributionType = ceBenefitContributionType;

          switch (contributionType) {
            case 'mixt':
              this.recurringPersonalValue = ceBenefit.recurring_personal_value ? ceBenefit.recurring_personal_value : '';
              this.recurringCompanyValue = ceBenefit.recurring_company_value ? ceBenefit.recurring_company_value : '';
              break;
            case 'personal':
              this.recurringPersonalValue = ceBenefit.recurring_personal_value ? ceBenefit.recurring_personal_value : '';
            break
            case 'employee':
              this.recurringCompanyValue = ceBenefit.recurring_company_value ? ceBenefit.recurring_company_value : '';
          }
          this.ceBenefitByContributionType[contributionType].contractNr = (ceBenefit.contract_number) ? ceBenefit.contract_number : '';
        }
      })

      console.log(this.ceBenefitByContributionType)

      this.setupActiveTab();
    },

    setupActiveTab() {

      if (!this.globalCeBenefitData.data) {
        return;
      }

      this.ceBenefitByContributionType.personal.isShow = false;
      this.ceBenefitByContributionType.mixt.isShow = false;
      this.ceBenefitByContributionType.employer.isShow = false;

      if (!this.isActivePersonalContributionType) {
        this.ceBenefitByContributionType.employer.isShow = true;
        this.contributionTypeSelected = 'employer';
        return;
      }

      if (this.globalCeBenefitData) {
        this.ceBenefitByContributionType.personal.isShow = false;
        this.ceBenefitByContributionType.mixt.isShow = false;
        this.ceBenefitByContributionType.employer.isShow = false;

        let enablePToPopUp = this.globalCeBenefitData.data.enable_auto_topup;
        if (enablePToPopUp) {
            this.contributionTypeSelected = this.globalCeBenefitData.data.contribution_type;
            this.ceBenefitByContributionType[this.globalCeBenefitData.data.contribution_type].isShow = true
        } else {
            this.ceBenefitByContributionType.personal.isShow = true;
            this.ceBenefitByContributionType.mixt.isShow = false;
            this.ceBenefitByContributionType.employer.isShow = false;
            this.contributionTypeSelected = 'personal';
        }

      }
    },

    confirmToggleAutoTopUp(action = 'toggle') {
      this.action = action;
      this.$refs.auto_top_up_not_legislated_modal.openModal();
    },
    toggleAutoTopUp() {
      const getPersonalRecurringValue = () => {
          if (typeof this.recurringPersonalValue == 'number') {
            if (['mixt', 'personal'].includes(this.contributionTypeSelected)) {
              return this.recurringPersonalValue;
            }
          }
          return 0;
      }

      const getCompanyRecurringValue = () => {
        if (typeof this.recurringCompanyValue == 'number') {
          if (['mixt', 'employer'].includes(this.contributionTypeSelected)) {
            return this.recurringCompanyValue;
          }
        }
        return 0;
      }

      const payload = {
        enable_auto_topup: this.action === 'toggle' ? !this.enableAutoTopup :this.enableAutoTopup,
        recurring_company_value: getCompanyRecurringValue(),
        recurring_personal_value: getPersonalRecurringValue(),
        contract_number: this.ceBenefitByContributionType[this.contributionTypeSelected].contractNr,
        benefit_id: this.ceBenefitByContributionType[this.contributionTypeSelected].ceBenefit.benefit.id,
        contribution_type: this.contributionTypeSelected,
        isPPP3: true

      };
      if (payload.enable_auto_topup && this._validatePayload(payload)) {
        return;
      }

      this.$emit('toggleAutoTopUpNotLegislated', payload);
    },
    _validatePayload(payload) {
      this.invalidFields = {};
      let hasError = false;
      var regEx = /^[0-9a-zA-Z]+$/;
      if (payload.contract_number == null || payload.contract_number.length === 0) {
        this.invalidFields.contractNr = 'required';
        hasError = true;
      } else if (!payload.contract_number.match(regEx)) {
        this.invalidFields.contractNr = 'non_alpha_num';
        hasError = true;
      } else if (payload.contract_number.length > 100) {
        this.invalidFields.contractNr = 'invalid';
        hasError = true;
      }

      return hasError;
    },

    financial(x) {
      return Number.parseFloat(x).toFixed(2);
    },

    _calculateTotalByCompanyOptions(personalValue, companyValue, type) {

      if (!personalValue) {
        personalValue = 0;
      }

      if (!companyValue) {
        companyValue = 0;
      }


      let params = {
        'company_employee_id' : this.ceBenefitByContributionType[type].ceBenefit.company_employee_id,
        'benefit_id' : this.ceBenefitByContributionType[type].ceBenefit.benefit.id,
        'recurring_company_value' : companyValue,
        'recurring_personal_value' : personalValue,
        'contribution_type' : type,
      };

      this.taxesLoader = true;

      ApiTaxes.getPPP3Taxes(params).then(response => {

        let total = response.data.displayInfo.totalPayment;

        if (this.salaryDeductibilityTypeCompany !== this.financial(response.data.displayInfo.companySalaryDeductible)) {
          this.salaryDeductibilityTypeCompany = this.financial(response.data.displayInfo.companySalaryDeductible);
        }

        if (this.salaryDeductibilityTypePersonal !== this.financial(response.data.displayInfo.personalSalaryDeductible)) {
          this.salaryDeductibilityTypePersonal = this.financial(response.data.displayInfo.personalSalaryDeductible);
        }

        switch (this.contributionTypeSelected) {
          case 'personal':
            total = total - response.data.displayInfo.companySalaryDeductible - companyValue;
          break
          case 'employer':
            total = total - response.data.displayInfo.personalSalaryDeductible - personalValue;
          break
        }

        this.totalPayValue = total;

        this.taxesLoader = false;
      }).catch((error) => {
        console.log(error)
        this.taxesLoader = false;
      });

    },
  }
}
</script>
