<template>
  <b-modal :ref="'voucher_modal'"
           :hide-header-close="true"
           headerClass="border-0 text-center pb-0"
           bodyClass="modal-body no-border background-white text-center "
           footerClass="modal-footer border-top-0"
           :no-close-on-backdrop="true"
           centered>
    <template v-slot:modal-header>
      <h2 class="text-center w-100"> {{ $t('flexben.beneficiary_pages.requests.modal.voucher_history.title') }}</h2>
    </template>
    <template v-slot:default>
      <div v-if="voucherData!=null" class="">
        {{ $t('flexben.beneficiary_pages.requests.modal.voucher_history.p1') }} {{ voucherData.voucher_code }}
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="w-100 text-center mt-2">
        <button class="btn btn-themed-o" @click="closeModal()">{{ $t('flexben.general.btn.close') }}</button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";

export default {
  name: "ViewVoucherCodeModal",
  components: {
    'b-modal': BModal,
  },
  data: function () {
    return {
      voucherData: null
    };
  },

  methods: {
    openModal(voucherData) {
      this.voucherData = voucherData;
      this.$refs['voucher_modal'].show();
    },
    closeModal() {
      this.voucherData = null;
      this.$refs['voucher_modal'].hide();
    },
  }
}
</script>
