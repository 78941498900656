<template>
  <div>
    <div v-show="!showForm && !initialLoading">
      <div class="row mb-md-4">
        <div class="col-12 col-md-6 flexben-page-title">
          <h1 class="m-0">{{$t("flexben.offers.sub_title")}}</h1>
        </div>
        <div class="col-12 col-md-6">

          <button @click="openForm('create')" class="btn btn-themed px-4 float-left float-md-right my-2">
            <font-awesome-icon class="mr-2" icon="plus"/> {{ $t("flexben.offers.new") }}
          </button>
        </div>
      </div>
      <div class="card container-bg-white">
        <div class="row p-3">
          <div class="col-12 col-xl-3 mb-2 mb-xl-0">
            <img src="@/assets/images/filter.svg"/>
            <span class="ml-3">{{ $t("flexben.general.filter") }}</span>
          </div>
          <div class="col-12 col-xl-9">
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-0" for="counties">{{ $t("flexben.offers.fields.county") }}</label>
                  <select id="counties" class="form-control w-100"
                          v-model="filters.county">
                    <option :value="null"></option>
                    <option value="any">{{ $t("flexben.offers.availability.all") }}</option>
                    <option v-for="(item, value) in filter_options.counties"
                            :key="value"
                            :value="item.id">{{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-0" for="type">{{ $t("flexben.offers.fields.type") }}</label>
                  <select id="type" class="form-control w-100"
                          v-model="filters.type">
                    <option :value="null"></option>
                    <option v-for="(item, index) in filter_options.types"
                            :key="index"
                            :value="item">{{ $t("flexben.offers.types."+item) }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label for="select_category_type" class="col-form-label pt-0">{{ $t("flexben.categories.filters.type") }}</label>
                <select class="form-control" v-model="category_type" id="select_category_type">
                  <option :value="null">-</option>
                  <option v-for="(category, index) in filter_options.category_types" :key="index" :value="category">
                    {{$t("flexben.categories.filters." + category)}}
                  </option>
                </select>
              </div>
              <div v-if="category_type" class="col-12 col-md-4">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-0" for="category">{{ $t("flexben.offers.fields.category") }}</label>
                  <select v-if="category_type === 'general'" id="category" class="form-control w-100"
                          v-model="filters.category">
                    <option :value="null"></option>
                    <option value="not_set">{{ $t("flexben.general.unspecified") }}</option>
                    <option v-for="(item, value) in filter_options.categories"
                            :key="value"
                            :value="item.id">{{ item.name[locale] }}
                    </option>
                  </select>
                  <select v-else class="form-control" v-model="filters.category" id="select_category">
                    <option :value="null"></option>
                    <option value="not_set">{{ $t("flexben.general.unspecified") }}</option>
                    <option  v-for="(category, index) in filter_options.categories_client" :key="category.id" :value="index">
                      {{ category[locale] }}
                    </option>

                  </select>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-0" for="status">{{ $t("flexben.offers.fields.status") }}</label>
                  <select id="status" class="form-control w-100"
                          v-model="filters.status">
                    <option :value="null"></option>
                    <option v-for="(status, index) in filter_options.statuses"
                            :key="index"
                            :value="status">{{ $t("flexben.offers.statuses."+status) }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-if="!affiliatePartnerId" class="col-12 col-md-4">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-0" for="code_type">{{ $t("flexben.offers.fields.code_type") }}</label>
                  <select id="code_type" class="form-control w-100"
                          v-model="filters.code_type">
                    <option :value="null"></option>
                    <option v-for="(type, index) in filter_options.code_types"
                            :key="'ct_'+index"
                            :value="type">{{ $t("flexben.offers.code_types."+type) }}
                    </option>
                  </select>
                </div>
              </div>

              <div v-if="!affiliatePartnerId"  class="col-12 col-md-4">
                <div class="form-group mb-1">
                  <label for="partner_types" class="col-form-label pt-0">
                    {{ $t("flexben.offers.fields.partner_types")}}</label>
                  <select id="partner_types" class="form-control"
                          v-model="filters.partner_type">
                    <option :value="null"></option>
                    <option v-for="value in filter_options.partner_types"
                            :key="'ct_'+value"
                            :value="value">{{$t('flexben.offers.partner_types.'+value)}}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-12 col-md-4">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-0" for="code_type">{{ $t("flexben.offers.fields.period_availability")
                    }}</label>
                  <select id="code_type" class="form-control w-100"
                          v-model="filters.period_availability">
                    <option :value="null"></option>
                    <option v-for="(type, index) in filter_options.period_availability"
                            :key="'ct_'+index"
                            :value="type">{{ $t("flexben.offers.period_availability."+type) }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-0" for="location_availability">{{
                    $t("flexben.offers.fields.available_locations") }}</label>
                  <select id="location_availability" class="form-control w-100"
                          v-model="filters.location_availability">
                    <option v-for="(type, index) in filter_options.location_availability"
                            :key="'ct_'+index"
                            :value="type">{{ $t("flexben.offers.location_availability."+type) }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-if="!affiliatePartnerId" class="col-12 col-md-4">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-0" for="partner">
                    {{ $t("flexben.offers.fields.partner_names") }}</label>
                  <select id="partner" class="form-control w-100"
                          v-model="filters.partner">
                    <option :value="null"></option>
                    <option v-for="(partner, index) in filter_options.partners"
                            :key="'ct_'+index"
                            :value="partner.id">{{ partner.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-if="!affiliatePartnerId" class="col-12 col-md-4">
                <div class="form-group mb-1">
                  <label class="col-form-label pt-0" for="partner">
                    {{ $t("flexben.offers.fields.affiliation_channel") }}</label>
                  <select id="partner" class="form-control w-100"
                          v-model="filters.affiliation_channel">
                    <option :value="null"></option>
                    <option v-for="(channel, index) in filter_options.affiliation_channels"
                            :key="'ct_'+index"
                            :value="channel.id">{{ channel.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-if="!affiliatePartnerId" class="col-12 mt-2">
                <a class="btn btn-themed float-right px-4" :class="{'disabled':offers.data.length == 0}"
                   :href="exportUrl + '?lang=' + locale + authorizationTokenString"
                   target="_blank"
                   download
                   @click="(event)=>{if(offers.data.length == 0) {event.preventDefault();}}">
                  {{$t("flexben.offers.btn.export")}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card container-bg-white mt-3">
        <div class="row p-3">
          <div class="col-6 mt-3 text-bold">
            <h3 class="mt-0 pt-1 pl-0">{{ $t("flexben.offers.list_title") }}</h3>
          </div>
          <div class="col-6 mt-3">
            <input type="text"
                   v-model="searchString"
                   v-on:keyup.enter="search()"
                   :placeholder="$t('flexben.general.search')"
                   class="formedituser search-input float-right"/>
          </div>
          <div class="col-12">
            <div class="table-responsive hover-table">
              <table class="table table-striped">
                <thead>
                <tr>
                  <td>{{ $t("flexben.general.image") }}</td>
                  <td>{{ $t("flexben.offers.fields.name") }}</td>
                  <td>{{ $t("flexben.offers.fields.partner_name") }}</td>
                  <td>{{ $t("flexben.offers.fields.type") }}</td>
                  <td>{{ $t("flexben.offers.fields.status") }}</td>
                  <td>{{ $t("flexben.offers.fields.code_type") }}</td>
                  <td>{{ $t("flexben.offers.fields.county_availability") }}</td>
                  <td>{{ $t("flexben.offers.fields.counties") }}</td>
                  <td>{{ $t("flexben.offers.fields.available_locations") }}</td>
                  <td>{{ $t("flexben.offers.fields.created_at") }}</td>
                  <td>{{ $t("flexben.offers.fields.end_date") }}</td>
                  <td>{{ $t("flexben.offers.fields.affiliation_channel") }}</td>
                  <td></td>
                </tr>
                </thead>


                <tbody v-if="offers.data.length >0">
                <tr v-for="(item, index) in offers.data" :key="index" class="trhover">
                  <td><img :src="item.image_url" alt="" width="50"></td>
                  <td>{{ item.name[locale] }}</td>
                  <td>{{ item.partner_id ? item.partner.name : '-' }}</td>
                  <td>{{ $t("flexben.offers.types."+item.type) }}</td>
                  <td><span class="offer-status" :class="'offer-status-'+item.status">{{ $t("flexben.offers.statuses."+item.status) }}</span>
                  </td>
                  <td>{{ $t("flexben.offers.code_types."+item.code_type) }}</td>
                  <td>{{ $t('flexben.offers.availability.'+item.county_availability) }}</td>
                  <td>
                    {{ (item.counties.length > 0) ? item.counties.map(x => x.name).join(', ') : '-' }}
                  </td>
                  <td>
                    {{$t("flexben.offers.location_availability." + item.location_availability)}}
                  </td>
                  <td>
                    {{getDateFormat(item.created_at)}}
                  </td>
                  <td>
                    {{getDateFormat(item.end_use_date)}}
                  </td>
                  <td>
                    {{item.affiliation_channel_name ? item.affiliation_channel_name : '-' }}
                  </td>
                  <td class="action-group text-right">
                    <button type="button" class="btn action-button"
                            @click="openForm('update', index)">{{$t("flexben.general.btn.see_more")}}
                    </button>
                  </td>

                </tr>
                </tbody>
                <tbody v-else>
                <tr class="trhover gri-cell">
                  <td :colspan="9" class="text-center">
                    {{ $t("flexben.general.no_results_found") }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 p-0">
            <listPagination
              :activePage="offers.current_page"
              :currentPage="offers.current_page"
              :totalItems="offers.total"
              :startItem="offers.from"
              :endItem="offers.to"
              :itemsPerPage="offers.perPage"
              @pageChanged="pageChanged"
              @changePerPage="changePerPage"></listPagination>
          </div>
        </div>
      </div>
    </div>
    <OfferDetails v-if="showForm"
                  :affiliatePartnerId="affiliatePartnerId"
                  :itemId="itemId"
                  :affiliatedPartner="affiliatedPartner"
                  :formAction="formAction"
                  @clearForm="clearForm"/>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import i18n from "@/plugins/language/i18n";
import Cookies from "js-cookie";
import httpFlexbenService from "@/http/http-flexben";
import OfferDetails from "@/components/flexben/offers/OfferDetails";
import moment from "moment";

export default {
  name: "OfferList",
  components: {
    OfferDetails
  },
  props: {
    affiliatePartnerId: {
      type: String,
      default: null,
      required: false
    },
  },
  data: function () {
    return {
      initialLoading: true,
      loading: false,
      loaderError: false,
      showForm: false,
      exportUrl: httpFlexbenService.exports.offers.url,
      httpService: httpFlexbenService.offerService,

      searchString: '',
      sortField: 'name',
      sortDirection: 'asc',
      reverseSort: true,
      category_type: [],
      filters: {
        county: null,
        type: null,
        status: null,
        category: null,
        category_type: null,
        code_type: null,
        partner_type: null,
        period_availability: null,
        location_availability: 0,
        affiliation_channel: null,
        page: 1,
        perPage: 20,
        search: null,
      },
      with_filter_options: true,
      filter_options: {
        types: [],
        counties: [],
        statuses: [],
        categories: [],
        categories_client: [],
        code_types: [],
        period_availability: [],
        location_availability: [],
        partner_types: [],
        affiliation_channels: [],
      },

      offers: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 1,
        lastPage: 1,
        total: 1,
      },
      affiliatedPartner: {},
      formAction: '',
      itemIndex: null,
      itemId: null,
    }
  },
  watch: {
    filters: {
      handler: function (val) {
        if (!this.initialLoading) {
          this._loadList();
        }
      },
      deep: true
    }
  },
  computed: {
    locale: () => {
      return i18n.locale;
    },
    authorizationTokenString: function () {
      return '&authorization_token=' + btoa(Cookies.get('token'))
    }
  },
  created() {
    let userRole = this.flxUserLevel(this.affiliatePartnerId).userLevel;
    if (userRole !== 'admin' && userRole !== 'affiliate_partner') {
      window.location.href = '/';
    } else {
      this._loadList();
    }
  },
  methods: {
    _loadList() {
      this.loading = true;
      let filters = {...this.filters};
      filters.with_filter_options = this.initialLoading;
      if (this.affiliatePartnerId) {
        filters.aff_id = this.affiliatePartnerId;
      }
      this.httpService.list(filters)
        .then((response) => {
          this.affiliatedPartner = response.data.affiliated_partner;
          this.offers.data = response.data.list.data;
          this.offers.current_page = response.data.list.current_page;
          this.offers.to = response.data.list.to;
          this.offers.perPage = response.data.list.perPage;
          this.offers.lastPage = response.data.list.lastPage;
          this.offers.total = response.data.list.total;

          if (this.initialLoading) {
            this.filter_options = response.data.filter_options;
            this.initialLoading = false;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = true;
          this.loaderError = true;
        })
    },
    openForm(action, index = null) {
      this.formAction = action;
      if (action === 'update') {
        this.itemIndex = index;
        this.itemId = this.offers.data[index].id;
        this.showForm = true;
      }

      if (action === 'create') {
        this.itemId = null;
        this.showForm = true;
      }
    },
    search() {
      this.filters.search = this.searchString;
      this._loadList();
    },
    pageChanged(page) {
      this.filters.page = page;
      this._loadList();
    },
    changePerPage(perPage) {
      this.filters.page = 1;
      this.filters.perPage = perPage;
      this._loadList();
    },
    resetFilters() {
      let perPage = this.filters.perPage;
      this.filters = {
        county: null,
        type: null,
        status: null,
        category: null,
        code_type: null,
        partner_type: null,
        page: 1,
        perPage: perPage,
        search: null,
      };
    },
    clearForm(reRender = false) {
      this.showForm = false;
      this.detailIndex = null;
      this.itemId = null;
      if (reRender) {
        this.resetFilters();
        this._loadList();
      }
    },
    getDateFormat(date) {
      return moment(date).format("DD/MM/yyyy");
    },
  }
}
</script>
