<template>
  <div class="col-12  text-16 mt-2 text-right text-13 ">
    <div class="d-flex">
      <span class="ml-auto">{{ $t("flexben.beneficiary_pages.benefits.total_value") }}</span>
      <span v-if="!isLoading" class="text-danger text-bold mx-1">{{ totalPayValue.toFixed(2) }} lei</span>
      <span v-if="isLoading" class="text-danger text-bold mx-1 invisible">{{ totalPayValue.toFixed(2) }} lei</span>
      <span id="tooltip_total_pay_value" class="d-inline-block flexben-tooltip-icon">i</span>
      <b-tooltip :target="'tooltip_total_pay_value'" variant="flexben" placement="topleft">
        <div class="text-left">
          {{ $t("flexben.beneficiary_pages.benefits.tooltips.total_pay_value") }}
        </div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import {BTooltip} from "bootstrap-vue";

export default {
  name: "TotalValuePay",
  components: {
    'b-tooltip': BTooltip
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    totalPayValue: {
      type: Number,
      required: true,
      default: 0
    },
  }
}
</script>
