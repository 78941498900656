<template>
  <b-modal id="modal-1"
           ref="fixed_benefit_modal"
           :hide-header-close="true"
           headerClass="border-0 text-center"
           bodyClass="modal-body no-border background-white text-center py-0"
           footerClass="modal-footer border-top-0"
           centered>
    <template v-slot:modal-header>
      <h2 class="mb-0 mx-auto">{{ benefit.name[locale] }}</h2>
    </template>
    <template v-slot:default>
      <div class="row">
        <div class="col-12">
          <img class="flx-card-img mb-3 w-50" :src="benefit.image_url" :alt="benefit.name[locale]">
        </div>
        <div class="col-12" v-html="benefit.description[locale]"></div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <button class="btn text-themed mx-auto" @click="closeModal()">{{ $t('flexben.general.btn.close') }}</button>
    </template>
  </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";

export default {
  name: "FixedBenefitModal",
  components: {
    'b-modal': BModal
  },
  props: {
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },
    benefit: {
      type: Object,
      required: true,
      default: function () {
        return {
          name: {},
          description: {},
          image_url: null,
        };
      }
    }
  },
  methods: {
    openModal() {
      this.$refs['fixed_benefit_modal'].show();
    },
    closeModal() {
      this.$refs['fixed_benefit_modal'].hide();
    }
  },
}
</script>
