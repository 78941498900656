<template>
  <div class="content">
    <iframe v-if="this.urlAddress || this.documentContent" id="iframe" class="content-iframe" :src="this.urlAddress" :srcDoc="this.documentContent" ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    urlAddress: String,
    documentContent: String,
    msg: String
  }
};
</script>
<style scoped>
.content-iframe {
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  height: calc(100vh - 154px);
}
</style>
