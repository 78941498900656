class PPP3FormService {
  ceBenefitsData = null;
  isActivePersonalContributionType = false;
  mappedCeBenefitsData = {};
  activeRecurringBenefit = null;
  contributionTypeSelected = null;
  companyEmployeeID = null;
  benefitID = null
  contributionTypes = [
    'mixt',
    'personal',
    'employer'
  ];
  isSetMixtContributionType = false;
  isSetPersonalEmployerType = false;

  init(data, isActivePersonalContributionType) {
    this.ceBenefitsData = data;
    this.isActivePersonalContributionType = isActivePersonalContributionType;
    return this;
  }

  bind() {
    let mapService = null;
    this.mappedCeBenefitsData = {};
    this.activeRecurringBenefit = null;
    this.isSetMixtContributionType = false;
    this.isSetPersonalEmployerType = false;
    this.ceBenefitsData.forEach(ceBenefit => {
        let ceBenefitContributionType = ceBenefit.contribution_type;
        mapService = new MapCeBenefitData(ceBenefit, ceBenefitContributionType).bind();
        Object.assign(this.mappedCeBenefitsData, {[ceBenefitContributionType]: mapService.getMapData()});
        this.activeRecurringBenefit = ceBenefit.benefit.current_active_recurring_benefit;
        this.companyEmployeeID = ceBenefit.company_employee_id;
        this.benefitID = ceBenefit.benefit.id;
    })

    if (this.activeRecurringBenefit) {
      if (this.activeRecurringBenefit.id === this.benefitID) {
        this.activeRecurringBenefit = null;
      }
    }

    // Get only employer benefit (old flow)
    if (!this.isActivePersonalContributionType) {
      delete this.mappedCeBenefitsData.personal;
      delete this.mappedCeBenefitsData.mixt;
      this.mappedCeBenefitsData.employer.isShow = true;
      this.mappedCeBenefitsData.employer.contributionType = 'employer';
      this.contributionTypeSelected = 'employer';
      return;
    }

    const mappedCeBenefitsDataKeys = Object.keys(this.mappedCeBenefitsData);

    if (mappedCeBenefitsDataKeys.length !== 3) {
      this.contributionTypes.forEach(item => {
        if (!mappedCeBenefitsDataKeys.includes(item)) {
          mapService = new MapCeBenefitData(null, item).bind();
          Object.assign(this.mappedCeBenefitsData, {[item]: mapService.getMapData()});
        }
      })
    }

    let isEnabledToPopUp = false;

    for (let type in this.mappedCeBenefitsData) {

      if (!this.mappedCeBenefitsData[type].ceBenefit) {
        continue;
      }

      if (type === 'mixt' && this.mappedCeBenefitsData[type].ceBenefit.enable_auto_topup) {
        this.isSetMixtContributionType = true;
        this.mappedCeBenefitsData[type].isShow = true;
        continue;
      }

      if (!isEnabledToPopUp && this.mappedCeBenefitsData[type].ceBenefit.enable_auto_topup) {
        this.isSetPersonalEmployerType = true;
        this.mappedCeBenefitsData[type].isShow = true;
        this.contributionTypeSelected = type;
        isEnabledToPopUp = true;
      }
    }

    if (this.isSetMixtContributionType) {
      this.mappedCeBenefitsData.personal.isShow = false;
      this.mappedCeBenefitsData.employer.isShow = false;
      this.contributionTypeSelected = 'mixt';
    }

    const isShowSetup = Object.keys(this.mappedCeBenefitsData).filter(type => {
        return this.mappedCeBenefitsData[type].isShow === true;
    });


    if (isShowSetup.length === 0) {
      this.mappedCeBenefitsData.personal.isShow = true;
      this.contributionTypeSelected = 'personal';
    }

  }

  getMappedData() {
    return this.mappedCeBenefitsData;
  }

  getContributionTypeList() {
    return [
      {
        translationKey: "flexben.benefit.contribution_type.personal",
        value: "personal"
      },
      {
        translationKey: "flexben.benefit.contribution_type.employer",
        value: "employer"
      },
      {
        translationKey: "flexben.benefit.contribution_type.mixt",
        value: "mixt"
      },
    ]
  }

  getActiveRecurringBenefit() {
    return this.activeRecurringBenefit;
  }

  getContributionTypeSelected() {
    return this.contributionTypeSelected;
  }

  getCompanyEmployeeId() {
    return this.companyEmployeeID;
  }

  getBenefitId() {
    return this.benefitID;
  }

}

export default new PPP3FormService();


class MapCeBenefitData {
  ceBenefitData = null;
  contributionType = null;
  contributionTypeMapData = {
    ceBenefit: null,
    remainingYearMonths: 0,
    monthlyNotTaxedAttribution: 0,
    contractNr: '',
    recurringEmployeeValue: 0,
    contributionType: null,
    isShow: false,
    enableAutoTopup: false,
    recurringHasOrdered: false,
    recurringPersonalValue: '',
    recurringCompanyValue: '',

  }

  constructor(ceBenefitData, contributionType) {
    if (!contributionType) {
      return null;
    }

    this.ceBenefitData = ceBenefitData;
    this.contributionType = contributionType;
    return this;
  }

  bind() {
    if (!this.ceBenefitData) {
      this.contributionTypeMapData.contributionType = this.contributionType;
      return this;
    }

    this.contributionTypeMapData.ceBenefit = this.ceBenefitData;
    this.contributionTypeMapData.enableAutoTopup = this.ceBenefitData.enable_auto_topup;
    this.contributionTypeMapData.recurringHasOrdered = this.ceBenefitData.benefit.recurring_has_ordered;
    this.contributionTypeMapData.remainingYearMonths = (11 - (new Date()).getMonth()) + 1 - (this.contributionTypeMapData.recurringHasOrdered ? 1 : 0);

    if (this.ceBenefitData.benefit.remaining_legal_limit > 0) {
      this.contributionTypeMapData.monthlyNotTaxedAttribution = (this.ceBenefitData.benefit.remaining_legal_limit / this.contributionTypeMapData.remainingYearMonths).toFixed(2);
    }

    this.contributionTypeMapData.contractNr = this.ceBenefitData.contract_numbers ? this.ceBenefitData.contract_numbers.number : '';
    this.contributionTypeMapData.contributionType = this.contributionType;
    this.contributionTypeMapData.recurringPersonalValue = this.ceBenefitData.recurring_personal_value ? this.ceBenefitData.recurring_personal_value : '';
    this.contributionTypeMapData.recurringCompanyValue = this.ceBenefitData.recurring_company_value ? this.ceBenefitData.recurring_company_value : '';

    return this;
  }


  getMapData() {
    return this.contributionTypeMapData;
  }

}
