<template>
  <div class="content">
    <div class="faq-placed-content">
      <div class="services-container">
        <div class="cards-listing">
          <h3>{{ $t("settingPageTitle") }}</h3>
          <div>
            <a v-for="(settingItem, index) in settingItems" :key="index"
              class="service-item border-left border-left-color-11" :class="[
                settingItem.id === currentSetting.id
                  ? 'card-active'
                  : 'card-inactive'
              ]" @click="selectSetting(index)">
              <div class="logo-card">
                <img :src="settingItem.imageURL" />
              </div>
              <div class="service-name">
                {{ $t("settingItem" + settingItem.id) }}
              </div>
            </a>
          </div>
        </div>

        <div class="services-details settings view">
          <div class="services-content" v-if="currentSetting.id === 1">
            <h3>{{ $t("settingItem1") }}</h3>
            <p class="loginError" v-if="this.beneficiaryDataError != null"
              v-html="$t('errorMessage_' + this.beneficiaryDataError.error_code)">
            </p>
            <div class="loginForm">
              <form @submit.prevent="submitBeneficiaryDetails">
                <input type="text" :placeholder="[[$t('surnameFieldLabel')]]" id="input-first-name"
                  v-model="beneficiaryCardData.first_name" required disabled />
                <input type="text" :placeholder="[[$t('lastNameField')]]" id="input-last-name"
                  v-model="beneficiaryCardData.last_name" required disabled />
                <input type="text" :placeholder="[[$t('emailField')]]" id="input-email"
                  v-model="beneficiaryCardData.email" :style="validateEmailClass" required disabled />
                <input type="text" :placeholder="[[$t('mobileField')]]" id="input-mobile"
                  v-model="beneficiaryCardData.mobile_phone" :style="validateMobileClass" required />
                <b-form-radio-group id="lang" v-model="beneficiaryCardData.lang" name="lang">
                  <b-form-radio value="ro">ro</b-form-radio>
                  <b-form-radio value="en">en</b-form-radio>
                </b-form-radio-group>
                <input type="submit" :value="[[$t('confirmButtonLabel')]]" variant="primary" class="submitCompanies" />
              </form>
            </div>
          </div>

          <div class="services-content" v-else-if="currentSetting.id === 2">
            <h3>{{ $t("settingItem2") }}</h3>
            <p class="loginError" v-if="this.beneficiaryDataError != null"
              v-html="$t('errorMessage_' + this.beneficiaryDataError.error_code)">
            </p>
            <form @submit.prevent="submitBeneficiaryDetails">
              <div class="notificare">
                <div>
                  <img src="../../assets/images/notificare-portocaliu.svg" />
                </div>
                <div class="notification-text">
                  {{ $t("notificationsLabel") }}
                </div>
                <div>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitchN"
                      v-model="beneficiaryCardData.notifications[2].active" />
                    <label class="custom-control-label" for="customSwitchN"></label>
                  </div>
                </div>
              </div>
              <div class="notificare">
                <div>
                  <img src="../../assets/images/notificare-portocaliu.svg" />
                </div>
                <div class="notification-text">
                  {{ $t("notificationsSMSLabel") }}
                </div>
                <div>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitchSN"
                      v-model="beneficiaryCardData.notifications[1].active" />
                    <label class="custom-control-label" for="customSwitchSN"></label>
                  </div>
                </div>
              </div>
              <div class="notificare">
                <div>
                  <img src="../../assets/images/notificare-portocaliu.svg" />
                </div>
                <div class="notification-text">
                  {{ $t("notificationsEmailLabel") }}
                </div>
                <div>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitchEN"
                      v-model="beneficiaryCardData.notifications[3].active" />
                    <label class="custom-control-label" for="customSwitchEN"></label>
                  </div>
                </div>
              </div>
              <br />
              <input type="submit" :value="[[$t('confirmButtonLabel')]]" variant="primary" class="submitCompanies" />
            </form>
          </div>

          <div class="services-content" v-else-if="currentSetting.id === 3">
            <h3>{{ $t("settingItem3") }}</h3>
            <p class="loginError" v-if="this.beneficiaryDataError != null"
              v-html="$t('errorMessage_' + this.beneficiaryDataError.error_code)">
            </p>
            <div class="loginForm" @submit.prevent="submitBeneficiaryPassword">
              <form>
                <div class="password">
                  <input   :placeholder="[[$t('placeholderOldPassword')]]" v-model="oldPassword" required
                    :type="showPassword ? 'text' : 'password'"
                      />
                    <i v-on:click="showPassword = !showPassword">
                      <font-awesome-icon :icon="showPassword ? 'eye-slash' : 'eye'"
                        />

                    </i>
                </div>
                <div class="password">
                  <input   :placeholder="[[$t('passwordField')]]" v-model="newPassword"
                  @input="validatePassword" required
                    :type="showNewPassword ? 'text' : 'password'"
                      />
                    <i v-on:click="showNewPassword = !showNewPassword">
                      <font-awesome-icon :icon="showNewPassword ? 'eye-slash' : 'eye'"
                        />

                    </i>
                </div>
                <div class="password">
                  <input    :placeholder="[[$t('confirmPasswordField')]]" required
                  :style="confirmPasswordStyle" @input="validatePassword" v-model="confirmPassword"
                    :type="showConfirmPassword ? 'text' : 'password'"
                      />
                    <i v-on:click="showConfirmPassword = !showConfirmPassword">
                      <font-awesome-icon :icon="showConfirmPassword ? 'eye-slash' : 'eye'"
                        />

                    </i>
                </div>
                <password v-model="newPassword" :strength-meter-only="true" />
                <div class="passwordConditions">
                  <p>
                    <img v-if="!isIE" :src="
                      this.validPassword.passwordLengthValid
                        ? require('../../assets/images/yes.png')
                        : require('../../assets/images/no.png')
                    " />
                    &nbsp; {{ $t("passwordCriteria1Text") }}
                  </p>
                  <p>
                    <img v-if="!isIE" :src="
                      this.validPassword.containsLetter
                        ? require('../../assets/images/yes.png')
                        : require('../../assets/images/no.png')
                    " />
                    &nbsp; {{ $t("passwordCriteria2Text") }}
                  </p>
                  <p>
                    <img v-if="!isIE" :src="
                      this.validPassword.containsNumber
                        ? require('../../assets/images/yes.png')
                        : require('../../assets/images/no.png')
                    " />
                    &nbsp; {{ $t("passwordCriteria3Text") }}
                  </p>
                </div>
                <br />
                <br />
                <input type="submit" :value="[[$t('confirmButtonLabel')]]" variant="primary" class="submitCompanies" />
              </form>
            </div>
          </div>

          <div class="services-content" v-else-if="currentSetting.id === 4">
            <h3>{{ $t("settingItem4") }}</h3>
            <form @submit.prevent="
              showModalPopup('modal_popup_deleteAccount', true)
            " class="delete-account">
              <p>{{ $t("deleteAccountText") }}</p>
              <input type="submit" :value="[[$t('confirmButtonLabel')]]" variant="primary" class="submitCompanies" />
            </form>
          </div>
        </div>

        <div id="modal_popup_deleteAccount" class="modal-popup">
          <div class="modal-popup-content">
            <a @click="showModalPopup('modal_popup_deleteAccount', false)" class="close-button">
              <img src="../../assets/images/close-button.svg" />
            </a>
            <p>{{ $t("deleteAccountText") }}</p>
            <div class="buttons">
              <a href="#" class="yesButton" @click="deleteAccount()">Yes</a>
              <a href="#" class="noButton" @click="showModalPopup('modal_popup_deleteAccount', false)">No</a>
            </div>
          </div>
        </div>

        <div :id="'modal_popup_info'" class="modal-popup">
          <div class="modal-popup-content">
            <a @click="showModalPopup('modal_popup_info', false)" class="close-button">
              <img src="@/assets/images/close-button.svg" />
            </a>
            <div>
              <p v-html="$t('changePasswordSuccessMessage')"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpServiceBeneficiary from "../../http/http-beneficiary.js";
import validator from "../../plugins/fieldValidation";
import Cookies from "js-cookie";
import Password from "vue-password-strength-meter";
import utils from "@/plugins/utils.js"
import {
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";

export default {
  components: {
    Password,
    "b-form-radio": BFormRadio,
    "b-form-radio-group": BFormRadioGroup,
  },
  props: {
    beneficiaryCardData: Object,
    msg: String
  },
  data() {
    return {
      settingItems: [
        {
          id: 1,
          imageURL: require("../../assets/images/user.svg")
        },
        {
          id: 2,
          imageURL: require("../../assets/images/clopotel-gri.svg")
        },
        {
          id: 3,
          imageURL: require("../../assets/images/padlock.svg")
        },
        {
          id: 4,
          imageURL: require("../../assets/images/user-delete.svg")
        }
      ],
      beneficiaryDataError: null,
      currentSetting: null,
      oldPassword: null,
      newPassword: null,
      confirmPassword: "",
      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      validPassword: {
        passwordValid: false,
        passwordLengthValid: false,
        containsLetter: false,
        containsNumber: false,
        confirmPasswordValid: false
      },
      url: null
    };
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0) + value.slice(1).toLowerCase();
    }
  },
  methods: {
    selectSetting(index) {
      this.currentSetting = this.settingItems[index];
      this.beneficiaryDataError = null;
    },
    submitBeneficiaryDetails() {
      httpServiceBeneficiary.beneficiaryService
        .confirmBeneficaryData(
          this.url,
          {
            id: this.beneficiaryCardData.id,
            notifications: this.beneficiaryCardData.notifications,
            mobile_phone: this.beneficiaryCardData.mobile_phone,
            lang: this.beneficiaryCardData.lang
          },
          this.token
        )
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          return error.response;
        })
        .then(response => {
          if (response.status == 200) {
            this.beneficiaryDataError = null;
            window.location.reload();
          } else if (response.status == 400) {
            this.beneficiaryDataError = response.data;
          } else {
            this.beneficiaryDataError = { error_code: response.code };
          }
        });
    },
    validatePassword() {
      this.validPassword = validator.validatePassword(
        this.newPassword,
        this.confirmPassword
      );
    },
    submitBeneficiaryPassword() {
      if (!this.validPassword.passwordValid) {
        this.beneficiaryDataError = {
          description: "Invalid password",
          error_code: "BS20"
        };
        return;
      } else {
        httpServiceBeneficiary.beneficiaryService
          .confirmBeneficaryData(
            this.url,
            {
              id: this.beneficiaryCardData.id,
              current_password: this.oldPassword,
              new_password: this.newPassword
            },
            this.token
          )
          .then(function (response) {
            return response;
          })
          .catch(function (error) {
            return error.response;
          })
          .then(response => {
            if (response.status == 200) {
              this.beneficiaryDataError = null;
              this.oldPassword = "";
              this.newPassword = "";
              this.confirmPassword = "";
              this.showModalPopup("modal_popup_info", true);
            } else if (response.status == 400) {
              this.beneficiaryDataError = response.data;
            } else {
              this.beneficiaryDataError = { error_code: response.code };
            }
          });
      }
    },
    deleteAccount() {
      httpServiceBeneficiary.beneficiaryService
        .deleteBeneficary(this.url, this.token)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          return error.response;
        })
        .then(response => {
          if (response.status == 200) {
            Cookies.remove("beneficiaryLink");
            Cookies.remove("token");
            this.beneficiaryDataError = null;
            this.$router.push({ name: "login" });
          } else if (response.status == 400) {
            this.beneficiaryDataError = response.data;
          } else {
            this.beneficiaryDataError = { error_code: response.code };
          }
        });
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    }
  },
  mounted() {
    this.url = Cookies.get("beneficiaryLink");
    this.token = Cookies.get("token");

    let tabSettingsSelected = utils.getUrlParam("tabSettings");
    if (tabSettingsSelected !== null) {
      this.currentSetting = this.settingItems[tabSettingsSelected];
    }
  },
  computed: {
    confirmPasswordStyle() {
      return this.confirmPassword === ""
        ? ""
        : this.newPassword === this.confirmPassword
          ? ""
          : "outline-color: #ff0000a6;";
    },
    validateEmailClass() {
      return this.beneficiaryCardData.email === ""
        ? ""
        : validator.validateEmail(this.beneficiaryCardData.email)
          ? ""
          : "outline-color: #ff0000a6; ";
    },
    validateMobileClass() {
      return this.beneficiaryCardData.mobile_phone === ""
        ? ""
        : validator.validateMobilePhone(this.beneficiaryCardData.mobile_phone)
          ? ""
          : "outline-color: #ff0000a6; ";
    }
  },
  created() {
    this.currentSetting = this.settingItems[0];
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
.delete-account input[type="submit"] {
  display: inline-block;
  width: auto;
  padding: 0;
  margin-top: 0;
}
</style>
