export default {
  title: "Offer categories",
  subtitle: "Manage offer categories",
  new: "New category",
  edit: "Edit offer category",
  list: "Offer categories list",
  assigned_offers: 'Assigned offers',
  no_assigned_offers: 'No offer assigned',
  modal: {
    confirm_save: 'Are you sure you want to save the changes?',
    confirm_leave: 'The information has been changed, are you sure you want to leave?',
    confirm_delete: 'Are you sure you want to delete the category?',
    extra_delete: 'Current category assigned to {count_offer} offers.<br>Un-assign the category before category delete.'
  },
  table: {
    image: "image",
    name: "Name",
  }
}
