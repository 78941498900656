import { render, staticRenderFns } from "./BeneficiaryBenefitDetails.vue?vue&type=template&id=d850ff00"
import script from "./benefit_details.js?vue&type=script&lang=js&external"
export * from "./benefit_details.js?vue&type=script&lang=js&external"
import style0 from "./BeneficiaryBenefitDetails.vue?vue&type=style&index=0&id=d850ff00&prod&lang=scss"
import style1 from "@/assets/styles/flexben_beneficiary.scss?vue&type=style&index=1&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports