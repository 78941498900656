export default {
  title: "Istoric import",
  new_import: "Import nou",
  data_interval: "Interval de date",
  list_of_imported_files: "Lista fisierelor importate",
  import_on: "Import",
  import_type: "Tip import",
  total_number_of_items: "Numarul total de inregistrari",
  new_items: "Inregistrari noi",
  updated_items: "Inregistrari modificate",
  import_summary: "Sumar import",
  imported_on: "Importat la",
  imported_status: "Stare import",
  imported_type: "Tipul de import",
  timesheet_imported_for_month: "Pontajul importat pentru luna",
  start_date: "Data de inceput",
  end_date: "Data de sfarsit",
  import_filers: "Filtrare import",
  client: "Client",
  titles: {
    beneficiary: "Beneficiari importati pe data de {date}",
    time_sheet: "Pontaj zile importat pe data de {date}",
    budget: "Bugete importate pe data de {date}",
    update_beneficiary: "Actualizari de beneficiari importati pe data de {date}"
  },
}
