export default {
  titles: {
    types: "Rapoarte",
    standard: 'Standard',
    custom: 'Personalizat',
    world_class: 'World Class',
    points_pago: 'Puncte Pago',
    cash_out: 'Cash Out',
    allocated_budget: 'Buget Alocat',
    consumed_budget: 'Buget Consumat',
    unconsumed_budget: 'Buget Neconsumat',
    data_tracking: 'Trending report',
    offers: 'Raport oferte',
    upBenefits: 'Payroll MB report',
  },
  buttons: {
    types: {
      standard: 'Raport Standard',
      custom: 'Raport Personalizat',
      world_class: 'Export World Class',
      points_pago: 'Puncte Pago',
      cash_out: 'Cash Out',
      back_to_types: 'Tipuri de rapoarte',
      consumed_budget: 'Buget Consumat',
      unconsumed_budget: 'Buget Neconsumat',
      allocated_budget: 'Buget Alocat',
      data_tracking: 'Raport tendinte',
      offers: 'Raport oferte',
      upBenefits: 'Payroll MB report',
    },
    show_report: "Vizualizeaza raport",
    refresh_report: "Actualizeaza raport",
    download_report: "Descarca raport",
  },
  info: {
    world_class: 'Indiferent de data la care se face exportul dupa 27 ale lunii pana la finalul lunii curente, in raport vor fi cuprinse solicitarile din perioada 1-27 ale lunii respective'
  },
  filters: {
    company: 'Companie',
    client: 'Client',
    period: "Perioada",
    employeeData: "Date salariati",
    ticketInfo: "Info tichete",
    benefitInfo: "Info alte beneficii",
    benefit: "Tip beneficiu",
    total_taxes: "Total taxe",
    partner_fields: "Campuri parteneri",
    groupType: "Grupare",
    taxedBy: "Taxe",
    date_groups: "Tip grupare data",
    campaign_track_levels: "Tip grupare campanie",
    categories: "Categorii",
    campaign_types: "Tip produs",
    start_month: "Luna inceput",
    end_month: "Luna sfarsit",
    start_year: "An start",
    end_year: "An sfarsit",
  },
  inputLabels: {
    from : 'De la',
    to: 'Pana la',
    startDate: 'Data de inceput:',
    endDate: 'Data de sfarsit:',
    startDateOffer: 'Interval activare oferte:',
    endDateOffer: 'Data expirare oferte:',
    ticket_count: 'Nr.',
    total_value: 'Valoare',
    total_value_cas_cass: 'CAS+CASS',
    total_value_tax: 'Impozit venit',
    value_cam: 'Taxa CAM',
    partner_name: 'Nume Partener',
    partner_iban: 'IBAN Partener',
    contract_number: 'Nr. Polita',
    world_class_month: 'Luna abonament',
    world_class_year: 'An abonament',
    totals: 'Total - ',
    group_by: {
      benefit: 'Beneficiu',
      request: 'Solicitare',
      sumRequest: 'Solicitari grupate',
    },
    taxed_by: {
      gross: 'Brut',
      net: 'Net'
    }
  },
  errors: {
    endDateBeforeStartDate: 'Data de sfarsit trebuie sa fie mai mare ca data de inceput',
    no_date_selected: 'Selectati data de inceput si sfarsit',
    no_start_date_selected: 'Selectati data de inceput 1 si data de inceput 2',
    no_end_date_selected: 'Selectati data de sfarsit 1 si data de sfarsit 2',
    no_date_selected_unconsumed_budget: 'Selectati data expirarii bugetului neutilizat',
    no_client_selected: 'Selectati un client',
    minimum_columns: 'Coloane minime neselectate (Nume si prenume, CNP)',
    year_required: 'Selectati an',
    month_required: 'Selectati luna',
  },
  standard: {
    fields: {
      employee_code: 'Marca',
      cnp: 'CNP',
      name: 'Nume',
      fist_name: 'Prenume',
      allocated_budget: 'Buget alocat de angajator',
      ppp3_budget: 'Buget PPP3',
      available_budget: 'Buget disponibil',
      budget_expiration_date: 'Data expirarii bugetului neutilizat',
      budget_type: 'Tip buget',
      contribution_type: 'Tip contributie',
      benefit: 'Tip Beneficiu',
      quantity: 'Cantitate',
      unit_value: 'Valoare unitara',
      total_value: 'Valoare totala',
      tax_value: 'Impozit',
      cass_value: 'CASS',
      cas_value: 'CAS',
      cam_value: 'CAM',
      net_value: 'Valoare Neta',
      placed_at: 'Plasat pe',
      status: 'Stare solicitare',
      company: 'Companie',
      partner_name: 'Nume Partener',
      partner_iban: 'IBAN Partener',
      contract_number: 'Nr. Contract',
      personal_use: 'Uz personal',
      debit_from: 'Debitare din',
      ce_deleted_at: 'Utilizator sters'
    }
  },
  allocated_budget: {
    fields: {
      employee_code: 'Marca',
      cnp: 'CNP',
      name: 'Nume',
      fist_name: 'Prenume',
      allocated_budget: 'Buget Alocat de angajator',
      available_budget: 'Buget Disponibil',
      budget_expiration_date: 'Data Expirarii Bugetului Neutilizat',
      allocated_telework_budget: 'Buget Telemunca Alocat de Angajator',
      available_telework_budget: 'Buget Telemunca Disponibil',
      budget_telework_expiration_date: 'Data Expirarii Bugetului Telemunca Neutilizat',
      allocated_ppp3_budget: 'Buget Ppp3 Alocat de Angajator',
      available_ppp3_budget: 'Buget Ppp3 Disponibil',
      budget_ppp3_expiration_date: 'Data Expirarii Bugetului Ppp3 Neutilizat',
    }
  },
  consumed_budget: {
    fields: {
      employee_code: 'Marca',
      cnp: 'CNP',
      name: 'Name',
      budget_expiration_date: 'Data Expirarii Bugetului Neutilizat',
      budget_type: 'Tip Buget',
      benefit: 'Beneficiu',
      ticket_quantity: 'Nr. Tichete',
      ticket_value: 'Valoare Tichet',
      base_budget_value: 'Buget',
      base_budget_consumed: 'Consumat',
      base_exceeded_budge_value: 'Buget Depasit',
      base_exceeded_budge_consumed: 'Buget Depasit Consumat',
      base_tax_value: 'Impozit',
      base_cass_value: 'CASS',
      base_cas_value: 'CAS',
      base_exceeded_tax_value: 'Impozit Depasit',
      base_exceeded_cass_value: 'CASS Depasit',
      base_exceeded_cas_value: 'CAS Depasit',
      gross_taxed: 'Taxat Brut',
      total_value: 'Valoare Totala',
      tax_value: 'Impozit Total',
      cass_value: 'CASS Total',
      cas_value: 'CAS Total',
      cam_value: 'CAM Total',
      net_value: 'Valoare Neta',
      placed_at: 'Data Plasarii',
      status: 'Stare Solicitare',
      company: 'Companie',
      partner_name: 'Nume Partener',
      partner_iban: 'IBAN Partener',
      contract_number: 'Nr. Contract',
      month_year :'Luna/Anul',
    }
  },
  offers: {
    fields: {
      offer_name : 'Titlu Oferta',
      offer_type : 'Tip Oferta',
      offer_status: 'Status Oferta',
      partner: 'Partener',
      partner_name: 'Nume partener',
      partner_type: 'Tip Partener',
      partner_fiscal_code: 'Cod Fiscal',
      county_availability: 'Judet Disponibilitate',
      all_county: 'Toate judetele',
      affiliate_platform: 'Platforma Afiliere',
      affiliate_channel: 'Tip Canal Afiliere',
      affiliate_channel_name: 'Nume canal afiliere',
      created_at: 'Data Crearii',
      end_use_date: 'Data Expirare',
      start_use_date: 'Data de inceput',
      offer_code: 'Cod Oferta',
      category: 'Categorie',
      offer_tracking_order: 'Nr click - Obtine Oferta',
      offer_tracking_details: 'Nr click - Detalii'
    }
  },
  unconsumed_budget: {
    fields: {
      cnp: 'CNP',
      name: 'Name',
      date: 'Budget valabil (neconsumat) la data',
    }
  },
  custom: {
    fields: {
      beneficiary: {
        company: 'Companie',
        name: 'Nume si prenume',
        cnp: 'CNP',
        cost_center: 'Centru de cost',
        employee_number: 'ID angajat',
        department: 'Departament',
        job_title: 'Functie',
        workstation: 'Punct de lucru',
        benefit_category_code: 'Grup de beneficiari',
        underage_children_nr: 'Nr. Copii',
      },
      legislated_benefits: {
        dejun: {
          timesheet_ticket_count: 'Nr. tichete de masa alocate',
          total_nr_tickets: 'Nr. tichete  de masa comandate',
          total_value_cas_cass: 'Valoare CAS+CASS tichete de masa',
          total_value_tax: 'Valoare impozit venit tichete de masa',
          total_value: 'Valoare totala tichete de masa',
        },
        vacation: {
          total_nr_tickets: 'Nr. Vouchere de vacanta electronice',
          total_value_cas_cass: 'Valoare CAS+CASS Vouchere de vacanta electronice',
          total_value_tax: 'Valoare impozit venit Vouchere de vacanta electronice',
          total_value: 'Valoare totala Vouchere de vacanta electronice',
        },
        culture: {
          // month
          total_month_nr_tickets: 'Nr. tichete TCultura lunare',
          total_month_value_cas_cass: 'Valoare totala CAS+CASS TCultura lunare',
          total_month_value_tax: 'Valoare totala impozit venit TCultura lunare',
          total_month_value: 'Valoare totala TCultura lunare',
          // occasions
          total_occasion_nr_tickets: 'Nr. tichete TCultura ocazie',
          total_occasion_value_cas_cass: 'Valoare totala impozit venit TCultura ocazie',
          total_occasion_value_tax: 'Valoare totala CAS+CASS TCultura ocazie',
          total_occasion_value: 'Valoare totala TCultura ocazie',
          // total
          total_nr_tickets: 'Nr. tichete TCultura',
          total_value_cas_cass: 'Valoare totala impozit venit TCultura',
          total_value_tax: 'Valoare totala CAS+CASS TCultura',
          total_value: 'Valoare totala TCultura',
        },
        gift: {
          // legal_events
          total_legal_events_nr_tickets: 'Nr. tichete Cadou - evenimente legiferate',
          total_legal_events_value_cas_cass: 'Valoare totala CAS+CASS Cadou - evenimente legiferate',
          total_legal_events_value_tax: 'Valoare totala impozit venit Cadou - evenimente legiferate',
          total_legal_events_value: 'Valoare totala Cadou - evenimente legiferate',
          // company events
          total_company_events_nr_tickets: 'Nr. tichete Cadou - evenimente companie',
          total_company_events_value_cas_cass: 'Valoare totala CAS+CASS Cadou - evenimente companie',
          total_company_events_value_tax: 'Valoare totala impozit venit Cadou - evenimente companie',
          total_company_events_value: 'Valoare totala Cadou - evenimente companie',
          // stim
          total_stim_nr_tickets: 'Nr. tichete Cadou - avantaj in natura',
          total_stim_value_cas_cass: 'Valoare totala CAS+CASS Cadou - avantaj in natura',
          total_stim_value_tax: 'Valoare totala impozit venit Cadou - avantaj in natura',
          total_stim_value: 'Valoare totala Cadou - avantaj in natura',
          // total
          total_nr_tickets: 'Nr. tichete Cadou - total',
          total_value_cas_cass: 'Valoare totala CAS+CASS Cadou - total',
          total_value_tax: 'Valoare totala impozit venit Cadou - total',
          total_value: 'Valoare totala Cadou - total',
        },
      },
      not_legislated_benefits: {
        ticket_count: 'Nr. tichete',
        total_value: 'Valoare',
        total_value_cas_cass: 'CAS+CASS',
        total_value_tax: 'Impozit venit',
      },
      benefit_totals: {
        sum_taxes: 'Total Taxe',
        tax: 'Total impozit',
        cas_cass: 'Total CAS+CASS',
        cam: 'Total CAM',
      }
    }
  },
  world_class: {
    fields: {
      subscription_start_day: 'Data incepere abonament',
      index: 'Nr',
      first_name: 'Prenume',
      last_name: 'Nume',
      email: 'E-mail',
      phone: 'Telefon',
      birth_day: 'Zi nastere (dd/mm/yyyy)',
      ci_number: 'Nr. serie',
      city: 'Oras',
      address: 'Adresa',
      network: 'Retea',
      club_name: 'Nume club',
      type: 'Tip abonament',
      subscription_duration: 'Durata tip abonament',
      order_request_id: 'Nr solicitare',
    }
  },
  points_pago: {
    fields: {
      index: 'Nr',
      first_name: 'Prenume',
      last_name: 'Nume',
      email: 'E-mail incarcare',
      cnp: 'CNP',
      order_request_id: 'Nr solicitare',
      request_value_total: 'Valoare solicitare(total)',
      request_value_pago: 'Valoare solicitare(Pago)',
      conversion_value: 'Valoare conversie',
      points: 'Puncte',
      processing_tax: 'Valoare taxa procesare',
      placed_at: 'Plasat pe',
    }
  },
  data_tracking: {
    date_groups: {
      day: 'Zile',
      month: 'Luna',
      year: 'An',
      week: 'Saptamana',
    },
    campaign_track_levels: {
      category: 'Categorii',
      product: 'Produs',
    },
    campaign_type: {
      offer: 'Oferte',
      benefit: 'Beneficii',
    }
  }
}
