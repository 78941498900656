<template>
  <div class="container-fluid">
    <div class="row container-gray py-3">
      <div :class="{
        'col-12 col-md-6':true,
        'col-xl-2':companyOptions.cam_budget_taxation,
      }">
        <span class="flx-type-badge mr-1">{{ $t("flexben.benefit.sub_types."+ceBenefit.benefit.sub_type) }}</span>
      </div>
      <div
           :class="{
            'col-12 col-md-6':true,
            'col-xl-5':companyOptions.cam_budget_taxation,
            }">
        <div :class="companyOptions.cam_budget_taxation ? '' :'float-right'">
          <span class="mr-1" v-html="(ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale] === null) ?
            $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
            : ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale]">
          </span>
          <span id="tooltip_up_dejun_deductibility" class="d-inline-block flexben-tooltip-icon mr-2">i</span>

          <b-tooltip :target="'tooltip_up_dejun_deductibility'" variant="flexben" placement="topleft">
            <div class="text-left">
              {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
            </div>
          </b-tooltip>
          <span class="text-danger"><strong>{{ salaryDeductibility }} Lei</strong></span>
        </div>
      </div>
      <div class="col-12 mt-2 text-danger text-bold text-center"
           v-if="(dynamicFields.billing_value>remainingBudget)">
        {{ $t('flexben.beneficiary_pages.benefits.insufficient_budget') }}
      </div>
      <div class="col-12 mt-2 text-danger text-bold text-center"
           v-if="ceBenefit.benefit.cash_out_last_order_day_exceeded">
        {{
        $t('flexben.beneficiary_pages.benefits.cash_out_last_order_day_exceeded',{day:ceBenefit.benefit.cash_out_last_order_day})
        }}
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-6 col-xl-4 form-group">
            <label class="col-form-label form-fields-required pt-0 mr-1">
              {{ $t('flexben.flexbenFormFields.value') }} (min: {{
              this.ceBenefit.benefit.data.values.min_order_value.value }}Lei)</label>
            <input type="text" class="form-control"
                   v-model="value"
                   :min="ceBenefit.benefit.data.values.min_order_value.value"
                   :max="budgets.available"
                   :class="{'border-danger' : typeof requiredFields.value !='undefined'}">
          </div>
        </div>

      </div>
      <div v-if="enableAutoTopup"
           class="col-12 text-danger">
        {{ $t('flexben.beneficiary_pages.benefits.auto_topup_cash_out') }}
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 px-0 pr-3 text-center text-md-left">
        <button v-if="!enableAutoTopup"
                class="btn btn-themed-green px-2 mr-2 px-md-4 mb-2" @click="orderNow()">
          {{ $t('flexben.beneficiary_pages.order_now') }}
        </button>
        <button class="btn px-2 px-md-4 mr-md-2 mb-2"
                :class="{
                          'btn-themed-green':!enableAutoTopup,
                          'btn-themed-red':enableAutoTopup,
                        }"
                @click="confirmToggleAutoTopUp('toggle')">
          {{ enableAutoTopup ? $t('flexben.beneficiary_pages.benefits.btn.disable_auto_top_up'):
          $t('flexben.beneficiary_pages.benefits.btn.activate_auto_top_up') }}
        </button>

        <button v-if="enableAutoTopup"
                class="btn btn-themed btn px-2 px-md-4 mb-2"
                @click="confirmToggleAutoTopUp('update')">{{
          $t('flexben.beneficiary_pages.benefits.btn.update_auto_top_up') }}
        </button>
      </div>
    </div>

    <auto-top-up-not-legislated-modal ref="auto_top_up_not_legislated_modal"
                                      :locale="locale"
                                      @confirmAction="toggleAutoTopUp">
      <template v-slot:body>
        <div class="mb-0 mx-auto text-capitalize">
          <template v-if="action==='toggle'">
            {{ enableAutoTopup ? $t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_disable') :
            $t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_enable') }}
          </template>
          <template v-if="action==='update'">
            {{ $t('flexben.beneficiary_pages.benefits.modal.auto_topup.confirm_update') }}
          </template>
        </div>
      </template>
    </auto-top-up-not-legislated-modal>
  </div>
</template>

<script>
import {BTooltip} from "bootstrap-vue";
import AutoTopUpNotLegislatedModal
  from "@/components/flexben/beneficiary_pages/benefits/modals/AutoTopUpNotLegislatedModal";
import flexbenUtils from "@/plugins/flexben_utils";

export default {
  name: "CashOutForm",
  components: {
    'auto-top-up-not-legislated-modal': AutoTopUpNotLegislatedModal,
    'b-tooltip': BTooltip,
  },
  props: {
    ceBenefit: {
      type: Object,
      required: true
    },
    budgets: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },
    payload: {
      type: Object,
      required: true,
      default: function () {
        return {
          value: 0,
          benefit_id: this.benefitId,
          child_benefit_id: null,
          event_id: null,
          auto_top_up: false,
          ticket_count: 0,
          ticket_value: 0,
          extra: {},
        };
      }
    },
    errorsBag: {
      type: [Object, Array],
      default: function () {
        return {};
      }
    },
    companyOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          cam_budget_taxation: false,
          gross_budget_taxation: false,
          legislated_gross_calculation: false,
          last_monthly_order_day: null,
          budget_telework_transfer_to_general: false,
          cash_out_last_order_day: null,
          non_legislated_net_taxes_calculate: false,
          banner_image_url: null,
        };
      }
    },
    remainingBudget: {
      type: Number,
      default: 0,
    }
  },
  data: function () {
    return {
      formData: {
        first_name: null,
        last_name: null,
        email: null,
        value: null,
        points_pago: null
      },
      valueCam: 0,
      acceptTax: false,
      dynamicFormFields: {},
      dynamicFormModel: {},
      requiredDynamicFields: {},
      requiredFields: {},
      invalidFields: {},
      isValidForm: true,
      value: 0,
      salaryDeductibility: 0,
      totalPayValue: 0,
      action: null
    };
  },
  computed: {
    dynamicFields: function () {
      return this.ceBenefit.benefit.dynamic_form.fields;
    },
    enableAutoTopup() {
      return this.ceBenefit.enable_auto_topup;
    },
  },
  watch: {
    value: {
      handler: function (value) {
        this.formData.value = value;
        this._setSalaryDeductibility(this.formData.value);
      }
    }
  },
  created() {
    if (typeof this.dynamicFields.dynamic_fields !== "string") {
      this.dynamicFormFields = this.dynamicFields.dynamic_fields;
    }
    this.value = this.ceBenefit.ticket_value ? this.ceBenefit.ticket_value : this.ceBenefit.benefit.data.values.min_order_value.value;
    this.formData.value = this.ceBenefit.ticket_value ? this.ceBenefit.ticket_value : this.ceBenefit.benefit.data.values.min_order_value.value;

    this._setSalaryDeductibility(this.formData.value);
  },
  methods: {
    orderNow() {
      this._validateDynamicFields();
      if (this.isValidForm) {
        this._setPayload();
        this.$emit('orderNow');
      }
    },
    setDynamicFormModel(modelObj) {
      this.dynamicFormModel = modelObj;
    },
    confirmToggleAutoTopUp(action = 'toggle') {
      this.action = action;
      this.$refs['auto_top_up_not_legislated_modal'].openModal();
    },
    _setSalaryDeductibility(value) {
      let valueToTax = flexbenUtils.calculateGross(value, this.ceBenefit.benefit.global_variables, this.companyOptions.non_legislated_net_taxes_calculate);
      this.salaryDeductibility = (valueToTax - value).toFixed(2);
      this.valueCam = valueToTax * this.ceBenefit.benefit.global_variables['cam'] / 100;

      this._calculateTotalByCompanyOptions(value);
    },
    _calculateTotalByCompanyOptions(sliderValue) {
      let value = parseFloat(sliderValue);
      if (this.companyOptions.gross_budget_taxation && value > 0) {
        value += parseFloat(this.salaryDeductibility);
      }
      if (this.companyOptions.cam_budget_taxation && value > 0) {
        value += parseFloat(this.valueCam);
      }
      this.totalPayValue = value;
    },
    toggleAutoTopUp() {
      const payload = {
        enable_auto_topup: this.action === 'toggle' ? !this.enableAutoTopup : this.enableAutoTopup,
        ticket_value: this.value,
        contract_number: null,
        benefit_id: this.ceBenefit.benefit_id,
      };
      if (payload.enable_auto_topup && this._validatePayload(payload)) {
        return;
      }
      this.$emit('toggleAutoTopUpNotLegislated', payload);
    },
    _validatePayload(payload) {
      this.invalidFields = {};
      let hasError = false;

      if (payload.ticket_value < this.ceBenefit.benefit.dynamic_form.fields.min_order_value) {
        this.invalidFields.recurringValue = 'invalid';
        hasError = true;
      }

      return hasError;
    },
    _validateDynamicFields() {
      this.isValidForm = true;
      let requiredFields = {};

      if (this.formData.value < this.ceBenefit.benefit.data.values.min_order_value.value) {
        requiredFields.value = 'invalid';
      } else if (this.formData.value > this.budgets.available) {
        requiredFields.value = 'invalid';
      }

      if (this.checkNotEmptyObj(requiredFields)) {
        this.isValidForm = false;
        this.requiredFields = requiredFields;
      }
    },
    _toggleFieldsToComplete(on) {
      this.$emit('setErrorInAlertModal', [this.$t('flexben.beneficiary_pages.benefits.fields_to_complete')]);
      this.scrollToTop();
    },

    _setPayload() {
      this.payload.value = this.formData.value;
      this.payload.ticket_count = 1;
      this.payload.ticket_value = this.formData.value;
      this.payload.benefit_id = this.ceBenefit.benefit_id;
      this.payload.extra = {};
    }
  }
}
</script>
