<template>
  <div class="container-fluid">
    <div class="row container-gray py-3">
      <div :class="{
      'col-12 col-md-6':true
      }">
        <span class="flx-type-badge mr-1">{{ $t("flexben.benefit.sub_types."+ceBenefit.benefit.sub_type) }}</span>
      </div>
      <div :class="{
      'col-12 col-md-6':true
      }">
        <div :class="companyOptions.cam_budget_taxation ? 'text-right text-xl-left' : 'text-right'">
         <span class="mr-1" v-html="(ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale] === null) ?
            $t('flexben.beneficiary_pages.benefits.' + (companyOptions.gross_budget_taxation ? 'salary_taxes' : 'salary_deductibility'))
            : ceBenefit.benefit.translations.beneficiary_benefit_taxation[locale]">
          </span>
          <span id="tooltip_up_dejun_deductibility" class="d-inline-block flexben-tooltip-icon mr-2">i</span>

          <b-tooltip :target="'tooltip_up_dejun_deductibility'" variant="flexben" placement="topleft">
            <div class="text-left">
              {{ $t('flexben.beneficiary_pages.benefits.tooltips.top_up_deduction') }}
            </div>
          </b-tooltip>
          <span class="text-danger"><strong>{{ salaryDeductibility }} Lei</strong></span>
        </div>
      </div>
      <div v-if="ceBenefit.benefit.current_active_recurring_benefit !== null"
           class="col-12 text-danger text-bold text-center">
        {{ $t('flexben.beneficiary_pages.benefits.non_legislated_already_month_use',{
        type: $t("flexben.benefit.sub_types."+ceBenefit.benefit.sub_type),
        benefit: ceBenefit.benefit.current_active_recurring_benefit.name
        }) }}.
      </div>
      <div v-if="ceBenefit.benefit.recurring_has_ordered"
           class="col-12 text-danger text-bold text-center"
           v-html="$t('flexben.beneficiary_pages.benefits.non_legislated_recurring_ordered')">
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group">
        <label for="recurring_value" class="col-form-label ">
          {{
          $t('flexben.beneficiary_pages.benefits.fields.top_up_recurring_value')
          }}
          <span class="text-danger">*</span>
        </label>
        <input type="number" id="recurring_value"
               class="form-control"
               :class="{'border-danger':invalidFields.recurringValue}"
               :min="ceBenefit.benefit.dynamic_form.fields.min_order_value"
               v-model.number="recurringValue">
        <span>
          {{$t('flexben.beneficiary_pages.benefits.fields.min_top_up_recurring_value',{min:ceBenefit.benefit.dynamic_form.fields.min_order_value}) }}
        </span>
      </div>
      <div class="col-12 col-md-6 col-xl-4 form-group">
        <label for="contract_nr" class="col-form-label ">{{
          $t('flexben.beneficiary_pages.benefits.fields.contract_number') }}
          <span class="text-danger">*</span></label>
        <input type="text" id="contract_nr"
               :class="{'border-danger':invalidFields.contractNr}"
               class="form-control" v-model.trim="contractNr">
      </div>
      <div class="col-12 text-bold">
        <span v-html="$t('flexben.beneficiary_pages.benefits.non_legislated_recurring_not_taxed',{
        value:monthlyNotTaxedAttribution, remainingYearMonths:remainingYearMonths
        })"></span> {{ ceBenefit.benefit.recurring_has_ordered ?
        '':$t('flexben.beneficiary_pages.benefits.non_legislated_recurring_not_taxed_extra') }}.
      </div>
      <total-value-pay v-if="companyOptions.gross_budget_taxation || companyOptions.cam_budget_taxation"
                       :totalPayValue="totalPayValue"></total-value-pay>
    </div>
    <div class="row mt-3">
      <div class="col-12 px-0 text-md-left">
        <button class="btn px-2 px-md-4 mb-2 mr-md-2"
                :class="{
                          'btn-themed-green':!enableAutoTopup,
                          'btn-themed-red':enableAutoTopup,
                        }"
                @click="confirmToggleAutoTopUp('toggle')">
          {{ enableAutoTopup ?$t('flexben.beneficiary_pages.benefits.btn.disable_auto_top_up'):
          $t('flexben.beneficiary_pages.benefits.btn.activate_auto_top_up') }}
        </button>

        <button v-if="enableAutoTopup"
                class="btn btn-themed btn px-2 px-md-4 mb-2"
                @click="confirmToggleAutoTopUp('update')">{{
          $t('flexben.beneficiary_pages.benefits.btn.update_auto_top_up') }}
        </button>
        <a v-if="ceBenefit.benefit.product_link!==null && ceBenefit.benefit.product_link.length>0"
           :href="ceBenefit.benefit.product_link" target="_blank"
           class="btn btn-themed-blue float-md-right px-2 px-md-4 mb-2"><span
          class="d-flex">
          <font-awesome-icon icon="external-link-alt" class="mr-1 contact-ico text-14"/>
          <span class="text-14">{{ $t('flexben.beneficiary_pages.benefits.btn.sign_contract') }}</span>
        </span></a>
      </div>
    </div>
    <auto-top-up-not-legislated-modal ref="auto_top_up_not_legislated_modal"
                                      :locale="locale"
                                      @confirmAction="toggleAutoTopUp">
      <template v-slot:body>
        <div class="mb-0 mx-auto">
          <div v-if="benefitSubType == 'ppp3' || benefitSubType == 'medical_insurance'">
            <template v-if="action==='toggle'">
              <div class="text-dark" v-if="enableAutoTopup"> {{$t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_disable') }} </div>
              <div v-else>
                {{$t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_enable') }} <br><br>
                <div class="text-danger">{{$t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_enable_pp3') }} </div>
              </div>
            </template>
            <template v-if="action==='update'">
              {{$t('flexben.beneficiary_pages.benefits.modal.auto_topup.confirm_update') }} <br><br>
               <div class="text-danger">{{ $t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_enable_pp3') }} </div>
            </template>
          </div>
          <div class="text-capitalize" v-else>
            <template v-if="action==='toggle'">
              {{ enableAutoTopup ? $t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_disable') :
              $t('flexben.beneficiary_pages.benefits.modal.auto_topup.button_enable') }}
            </template>
            <template v-if="action==='update'">
              {{ $t('flexben.beneficiary_pages.benefits.modal.auto_topup.confirm_update') }}
            </template>
          </div>
        </div>
      </template>
    </auto-top-up-not-legislated-modal>
  </div>
</template>

<script>
import {BTooltip} from "bootstrap-vue";
import AutoTopUpNotLegislatedModal from "../modals/AutoTopUpNotLegislatedModal";
import TotalValuePay from "./TotalValuePay";
import flexbenUtils from "@/plugins/flexben_utils";

export default {
  name: "RecurringNotLegislatedForm",
  components: {
    'b-tooltip': BTooltip,
    'auto-top-up-not-legislated-modal': AutoTopUpNotLegislatedModal,
    'total-value-pay': TotalValuePay,
  },
  props: {
    ceBenefit: {
      type: Object,
      required: true
    },
    budgets: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: true,
      default: 'ro'
    },
    errorsBag: {
      type: [Object, Array],
      default: function () {
        return {};
      }
    },
    companyOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          cam_budget_taxation: false,
          gross_budget_taxation: false,
          legislated_gross_calculation: false,
          last_monthly_order_day: null,
          budget_telework_transfer_to_general: false,
          cash_out_last_order_day: null,
          non_legislated_net_taxes_calculate: false,
          banner_image_url: null,
        };
      }
    },
  },
  data: function () {
    return {
      remainingYearMonths: 0,
      monthlyNotTaxedAttribution: 0,
      salaryDeductibility: 0,
      contractNr: '',
      invalidFields: {},
      action: 'toggle',
      recurringValue: 0,
      totalPayValue: 0
    }
  },
  watch: {
    recurringValue: {
      handler: function (val) {
        let exceededValue;
        if (val <= this.ceBenefit.benefit.remaining_legal_limit) {
          this.salaryDeductibility = 0;
          exceededValue = 0;
        } else {
          exceededValue = val - this.ceBenefit.benefit.remaining_legal_limit;
        }
        this._calculateTotalByCompanyOptions(val, exceededValue);
      }
    }
  },
  computed: {
    enableAutoTopup() {
      return this.ceBenefit.enable_auto_topup;
    },
    benefitSubType() {
      return this.ceBenefit.benefit.sub_type
    }
  },
  created() {
    this.remainingYearMonths = (11 - (new Date()).getMonth()) + 1 - (this.ceBenefit.benefit.recurring_has_ordered ? 1 : 0);
    if (this.ceBenefit.benefit.remaining_legal_limit > 0) {
      this.monthlyNotTaxedAttribution = (this.ceBenefit.benefit.remaining_legal_limit / this.remainingYearMonths).toFixed(2);
    }

    this.contractNr = this.ceBenefit.contract_numbers.number;
    this.recurringValue = this.ceBenefit.ticket_value ? this.ceBenefit.ticket_value : this.ceBenefit.benefit.dynamic_form.fields.min_order_value;
  },
  methods: {
    confirmToggleAutoTopUp(action = 'toggle') {
      this.action = action;
      this.$refs['auto_top_up_not_legislated_modal'].openModal();
    },
    toggleAutoTopUp() {
      const payload = {
        enable_auto_topup: this.action === 'toggle' ? !this.enableAutoTopup : this.enableAutoTopup,
        ticket_value: this.recurringValue,
        contract_number: this.contractNr,
        benefit_id: this.ceBenefit.benefit_id,
      };
      if (payload.enable_auto_topup && this._validatePayload(payload)) {
        return;
      }
      this.$emit('toggleAutoTopUpNotLegislated', payload);
    },
    _validatePayload(payload) {
      this.invalidFields = {};
      let hasError = false;
      var regEx = /^[0-9a-zA-Z]+$/;
      if (payload.contract_number == null || payload.contract_number.length === 0) {
        this.invalidFields.contractNr = 'required';
        hasError = true;
      } else if (!payload.contract_number.match(regEx)) {
        this.invalidFields.contractNr = 'non_alpha_num';
        hasError = true;
      } else if (payload.contract_number.length > 100) {
        this.invalidFields.contractNr = 'invalid';
        hasError = true;
      }

      if (payload.ticket_value < this.ceBenefit.benefit.dynamic_form.fields.min_order_value) {
        this.invalidFields.recurringValue = 'invalid';
        hasError = true;
      }

      return hasError;
    },
    _calculateTotalByCompanyOptions(inputValue, exceededValue) {
      let value = parseFloat(exceededValue);

      let valueToTax = flexbenUtils.calculateGross(value, this.ceBenefit.benefit.global_variables, this.companyOptions.non_legislated_net_taxes_calculate);
      this.salaryDeductibility = (valueToTax - value).toFixed(2);

      if (this.companyOptions.gross_budget_taxation) {
        inputValue += parseFloat(this.salaryDeductibility);
      }
      if (this.companyOptions.cam_budget_taxation) {
        this.valueCam = valueToTax * this.ceBenefit.benefit.global_variables['cam'] / 100;
        inputValue += parseFloat(this.valueCam);
      }
      this.totalPayValue = inputValue;
    },
  }
}
</script>
