<template>
  <b-modal ref="legal-agreement"
           :content-class="'custom-modal'"
           header-class="border-0"
           :body-class="{'no-border background-white text-center':true,'p-0':true}"
           footer-class="modal-footer border-top-0 p-0"
           :no-close-on-esc="true"
           :no-close-on-backdrop="true"
           :hide-header="true"
           size="xl"
           centered>
    <template v-slot:default>
      <div class="p-3 agreement">
        <link-frame :urlAddress="urlAddress"></link-frame>

      </div>
    </template>

    <template v-slot:modal-footer>
      <button type="button"
              @click="accept"
              class="mbo-btn form-submit mx-auto my-0">
        {{$t('flexben.affiliate_channels.partner.register.landing_page.text_15')}}
      </button>
    </template>
  </b-modal>
</template>

<style scoped>
.mbo-btn {
  color: #fff;
  text-decoration: none;
  max-width: 20rem;
  padding: 1rem 3.7rem;
  border-radius: 0.625rem;
  background-color: #2e1b00;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.mbo-btn.form-submit {
  max-width: none;
  background-color: #f59100;
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
  color: #2e1b00;
  font-weight: 600;
  border: 0;
}

.mbo-btn.form-submit:hover {
  background-color: rgba(245, 145, 0, 0.8);
}

@media screen and (max-width: 991px) {
  .mbo-btn.form-submit {
    padding: 1rem 1.5rem;
  }
}
</style>
<style>
.custom-modal {
  border-radius: 20px !important;
  padding: 2rem;
}

@media screen and (max-width: 991px) {
  .custom-modal {
    border-radius: 20px !important;
    padding: 0.2rem;
  }

}
</style>
<script>
import LinkFrame from "@/components/beneficiary/LinkFrame";
import {BModal} from "bootstrap-vue";

export default {
  name: "LegalAgreementModal",
  components: {
    'link-frame': LinkFrame,
    'b-modal': BModal
  },
  props: {
    urlAddress: {
      type: String,
      required: true
    },
    agreementType: {
      type: String,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$refs['legal-agreement'].hide();

    },
    openModal() {
      this.$refs['legal-agreement'].show();
    },
    accept() {
      this.$emit('acceptAgreement', this.agreementType);
      this.closeModal();
    }
  }
}
</script>
