<template>
  <div>
    <date-range-picker
      :dateRange="timeValue ? timeValue : time"
      @update="changeValue"
      :locale-data="locale"
      :opens="openDirection ? 'right' : 'left'"
      :ranges="noRanges ? false : ranges"
      @toggle="changeLanguage"
      :single-date-picker="single"
      :always-show-calendars="true"
      :minDate="minDateOrder ? minDateOrder : null"
      :maxDate="maxDateCourier ? maxDateCourier : null"
      :timePicker="timePicker ? timePicker : false"
      :timePicker24Hour="timePicker24Hour ? timePicker24Hour : false"
      :timePickerIncrement="1"
      ref="openDate"
    >
      <div slot="input">
        <span v-if="noRanges && single">{{ formatStartDate() }}</span>
        <span v-else>{{ formatStartDate() }} - {{ formatEndDate() }}</span>
        <span class="mobile-icon" style="margin-left: 5px">
          <font-awesome-icon icon="calendar-alt" />
        </span>
      </div>
    </date-range-picker>
  </div>
</template>

<script>
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    DateRangePicker
  },
  props: {
    msg: String,
    time: Array,
    single: Boolean,
    noRanges: Boolean,
    minDateOrder: Date,
    maxDateCourier: Date,
    timePicker: Boolean,
    timePicker24Hour: Boolean,
    format: String,
    openDirection: Boolean
  },
  data() {
    return {
      locale: {
        locale: moment.locale(localStorage.language),
        direction: "ltr", //direction of text
        format: "DD-MM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 1 //ISO first day of week - see moment documenations for details,
      },
      timeValue: this.time
        ? { startDate: this.time[0], endDate: this.time[1] }
        : { startDate: moment(), endDate: moment() }
    };
  },
  computed: {
    ranges: function() {
      if (localStorage.language === "ro") {
        return {
          "Luna curenta": [moment().startOf("month"), moment()],
          "Luna trecuta": [
            moment()
              .subtract(1, "month")
              .startOf("month"),
            moment()
              .subtract(1, "month")
              .endOf("month")
          ],
          "Ultimele 3 luni": [
            moment()
              .subtract(3, "month")
              .startOf(moment()),
            moment()
          ],
          "Anul curent": [moment().startOf("year"), moment().endOf("year")]
        };
      } else {
        return {
          "This month": [moment().startOf("month"), moment()],
          "Last month": [
            moment()
              .subtract(1, "month")
              .startOf("month"),
            moment()
              .subtract(1, "month")
              .endOf("month")
          ],
          "Last 3 months": [
            moment()
              .subtract(3, "month")
              .startOf(moment()),
            moment()
          ],
          "This year": [moment().startOf("year"), moment().endOf("year")]
        };
      }
    }
  },
  // created() {
  //   console.log(this.time);
  //   this.timeValue = [
  //     moment().format("DD-MM-YYYY HH:mm"),
  //     moment().format("DD-MM-YYYY HH:mm")
  //   ];

  // this.timeValue[0] = this.time[0].toDate();
  // this.timeValue[1] = this.time[1].toDate();
  // },
  // mounted() {
  //   this.timeValue[0] = this.time[0].toDate();
  //   this.timeValue[1] = this.time[1].toDate();
  // },
  watch: {
    time: function(val) {
      this.timeValue = val;
    }
  },
  methods: {
    changeValue(newValue) {
      this.timeValue = [newValue.startDate, newValue.endDate];

      this.$emit("close", this.timeValue);
    },
    changeLanguage() {
      this.locale.locale = moment.locale(localStorage.language);
      this.$emit("toggle", this.locale.locale);
    },
    formatStartDate() {
      if (this.format) {
        return moment(this.timeValue[0]).format(this.format);
      } else {
      return moment(this.timeValue[0]).format("DD-MM-YYYY");
      }
    },
    formatEndDate() {
      if (this.format) {
        return moment(this.timeValue[1]).format(this.format);
      } else {
      return moment(this.timeValue[1]).format("DD-MM-YYYY");
      }
    }
  }
};
</script>
