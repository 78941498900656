
import "vue-form-generator/dist/vfg-core.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/js/bootstrap.js";
import "./assets/styles/fonts.css";
import Vue from "vue";

import FlagIcon from "vue-flag-icon";
import VueGtm from "vue-gtm";
import VueAnalytics from "vue-analytics";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/plugins/language/i18n";
import Cookies from "js-cookie";
// import auth from "@/auth";
import { BootstrapVue, IconsPlugin, ToastPlugin } from "bootstrap-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faInfoCircle,
	faCalendarAlt,
	faChevronLeft,
	faPlusCircle,
	faMinusCircle,
	faCheckCircle,
	faUserPlus,
	faUserMinus,
	faArrowAltCircleRight,
	faTrashAlt,
	faTruck,
	faBoxOpen,
	faCoins,
	faEnvelope,
	faPrint,
	faPlus,
	faMinus,
	faCheck,
	faBan,
	faTrash,
	faDownload,
	faPhone,
	faLink,
	faMapMarkerAlt,
	faKeyboard,
	faChevronDown,
	faHome,
	faList,
	faExternalLinkAlt,
	faCaretSquareDown,
	faCommentAlt,
	faUniversity,
	faFileExcel,
	faAngleLeft,
	faAngleRight,
	faEyeSlash,
	faEye
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Loader from "./components/fragments/Loader";
import LanguageBar from "./components/LanguageBar";
import Pagination from "./components/fragments/Pagination";
import Multiselect from "vue-multiselect";
import ScrollToTopBtn from "@/components/fragments/ScrollToTopBtn";
import fieldBsSwitch from "@/components/fragments/dynamic_fields/fieldBsSwitch";
import moment from "moment";
import flxUtils from './plugins/flexben_utils';

library.add(faInfoCircle);
library.add(faCalendarAlt);
library.add(faChevronLeft);
library.add(faPlusCircle);
library.add(faMinusCircle);
library.add(faCheckCircle);
library.add(faUserPlus);
library.add(faUserMinus);
library.add(faArrowAltCircleRight);
library.add(faTrashAlt);
library.add(faTruck);
library.add(faBoxOpen);
library.add(faCoins);
library.add(faEnvelope);
library.add(faPrint);
library.add(faCheck);
library.add(faBan);
library.add(faTrash);
library.add(faDownload);
library.add(faPhone);
library.add(faLink);
library.add(faMapMarkerAlt);
library.add(faPlus);
library.add(faKeyboard);
library.add(faChevronDown);
library.add(faHome);
library.add(faMinus);
library.add(faList);
library.add(faExternalLinkAlt);
library.add(faCaretSquareDown);
library.add(faCommentAlt);
library.add(faUniversity);
library.add(faFileExcel);
library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faEyeSlash);
library.add(faEye);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("contentLoader", Loader);
Vue.component("listPagination", Pagination);
Vue.component("LanguageBar", LanguageBar);
Vue.component("multiselect", Multiselect);
Vue.component("scroll-to-top-btn", ScrollToTopBtn);
Vue.component("field-BsSwitch", fieldBsSwitch);

Vue.config.productionTip = false;

// require("./assets/styles/normalize.css");
require("./http/http-common.js");

Vue.filter("titleCase", function (value) {
	if (!value) return "";
	value = value
		.toLowerCase()
		.replace("-", " ")
		.split(" ");
	for (var i = 0; i < value.length; i++) {
		value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1);
	}
	return value.join(" ");
});
console.log("Test deploy pipeline");
// Vue.use(auth);
Vue.use(FlagIcon);
Vue.use(ToastPlugin);
Vue.use(IconsPlugin);
Vue.use(BootstrapVue);
Vue.config.productionTip = false;
Vue.mixin({
	methods: {
		flxUserLevel(affiliatePartnerId = null) {
			if (affiliatePartnerId) {
				return {
					hasAccess: true,
					userLevel: 'affiliate_partner',
				};
			}
			let flxCookie = Cookies.get("flxCookie");
			let hasAccess = false;
			let userLevel = "";

			if (typeof flxCookie != "undefined") {
				hasAccess = true;
				if (flxCookie === "admin") {
					userLevel = "admin";
				} else if (flxCookie === "client") {
					userLevel = "client";
				} else if (flxCookie === "beneficiary") {
					userLevel = "beneficiary";
				}
			}

			return {
				hasAccess: hasAccess,
				userLevel: userLevel,
			};
		},
		/**
		 * Method to check flexben route
		 * @returns {boolean}
		 */
		isFlexbenRoute() {
			return this.$route.meta.isFlxRoute || false;
		},
		checkNotEmptyObj(obj) {
			if (Array.isArray(obj) && obj.length > 0) {
				return true;
			}
			return (
				typeof obj !== "undefined" &&
				obj !== null &&
				!(
					(obj.constructor === Array || obj.constructor === Object) &&
					Object.keys(obj).length === 0
				)
			);
		},
		scrollToTop(pixels = 0) {
			window.scrollTo({ top: pixels, behavior: "smooth" });
		},
		moment(date, format, fromFormat) {
			return moment(date, fromFormat).format(format);
		},
		generateRange(startNr, endNr) {
			let returnData = [];
			for (let i = startNr; i <= endNr; i++) {
				returnData.push(i);
			}
			return returnData;
		},
		sendClickToGA(category, action, label) {
			if (this.$ga) {
				this.$ga.event({
					eventCategory: category,
					eventAction: action,
					eventLabel: label,
				});
			}
		},
		flxModuleCan(toCheck, checkModulePermissions = null) {
			if (checkModulePermissions == null) {
				const ae = this.$store.getters.flx_activeEmployment;
				checkModulePermissions = ae != null ? ae.module_permissions : [];
			}

			return this.checkFlxModulePermission(checkModulePermissions, toCheck);
		},
		checkFlxModulePermission(modulePermissions, toCheck) {
			return flxUtils.checkFlxModulePermission(modulePermissions, toCheck);
		},
		flxTrackData(data) {
			flxUtils.trackData(data);
		},
		checkNpsSurvey(type){
			let x = parseInt(
				Cookies.get(type + "_count")
				? Cookies.get(type)
				: 0
				);
			  return  Cookies.get(type) ? 5 : x;
		},
		fromBinaryStr(binary) {
			// 1: create an array of bytes
			const bytes = Uint8Array.from({ length: binary.length }, (_, index) =>
			  binary.charCodeAt(index)
			);

			// 2: decode the byte data into a string
			const decoder = new TextDecoder('utf-8');
			return decoder.decode(bytes);
		  },
		  pushRoute(routeName){
			this.$router.push({	name: routeName , replace: true});
			// this.$forceUpdate();
		  }
	},
});

if (!FileReader.prototype.readAsBinaryString) {
	FileReader.prototype.readAsBinaryString = function (fileData) {
		var binary = "";
		var pt = this;
		var reader = new FileReader();
		reader.onload = function () {
			var bytes = new Uint8Array(reader.result);
			var length = bytes.byteLength;
			for (var i = 0; i < length; i++) {
				binary += String.fromCharCode(bytes[i]);
			}
			//pt.result  - readonly so assign binary
			pt.content = binary;
			pt.onload();
		};
		reader.readAsArrayBuffer(fileData);
	};
}

new Vue({
	delimiters: ["[[", "]]"],
	router,
	store,
	i18n,
	render: (h) => h(App),
	mounted() {
		const isProd = process.env.NODE_ENV === "production";
		if (Cookies.get("cookies_analytics") !== null) {
			Vue.use(VueGtm, {
				id: "GTM-N2W59PJ", // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
				// queryParams: {
				// 	// Add url query string when load gtm.js with GTM ID (optional)
				// 	gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
				// 	gtm_preview: "env-4",
				// 	gtm_cookies_win: "x"
				// },
				enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
				debug: true, // Whether or not display console logs debugs (optional)
				loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
				vueRouter: router, // Pass the router instance to automatically sync with router (optional)
				// ignoredViews: ["homepage"] // If router, you can exclude some routes name (case insensitive) (optional)
			});
			// Vue.use(VueGtag, {
			// 	config: {id: "UA-59971354-2"},
			// 	router,
			// 	// debug: {
			// 	// 	enabled: true, // isProd;
			// 	// 	sendHitTask: true, // isProd;
			// 	// },
			// 	enabled: true,
			// });
			Vue.use(VueAnalytics, {
				id: "UA-59971354-2",
				router,
				checkDuplicatedScript: true,
				enabled: (typeof process.env.VUE_APP_ENABLE_GOOGLE_ANALITICS != 'undefined') ? parseInt(process.env.VUE_APP_ENABLE_GOOGLE_ANALITICS) : 1,
				debug: {
					enabled: false, // default value
					trace: false, // default value
					sendHitTask: true, // default value
				},
			});
		}
	},
}).$mount("#app");
