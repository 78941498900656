import { render, staticRenderFns } from "./OAuth.vue?vue&type=template&id=e4bb950a&scoped=true"
import script from "./OAuth.vue?vue&type=script&lang=js"
export * from "./OAuth.vue?vue&type=script&lang=js"
import style0 from "@/assets/styles/login.css?vue&type=style&index=0&id=e4bb950a&prod&scoped=true&lang=css&external"
import style1 from "./OAuth.vue?vue&type=style&index=1&id=e4bb950a&prod&scoped=true&lang=css"
import style2 from "./OAuth.vue?vue&type=style&index=2&id=e4bb950a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4bb950a",
  null
  
)

export default component.exports