<template>
  <div class="category-list-container mt-3">
    <div
      v-for="(category,index) in categories"
      :key="index"
      class="category-card d-flex flex-column ml-0 mx-auto mx-lg-2 mb-3"
    >
      <img class="category-card-img cursor-pointer" :src="category.image_url" :alt="category.name[locale]"
           @click="getBenefits(category.id)">
      <div class="category-card-body d-flex flex-column">
        <div class="category-card-name mt-1 text-capitalize">{{ category.name[locale] }}</div>
        <div class="category-card-actions mt-1">
          <button class="btn btn-themed" @click="setFilter(category.id)">
            {{ $t('flexben.general.details') }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="categories.length===0" class="mx-auto text-bold">
      {{ $t("flexben.general.no_results_found") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FlexibleCategoriesListing",
  props: {
    categories: {
      type: Array,
      required: true,
      default: function () {
        return [];
      }
    },
    locale: {
      type: String,
      default: 'ro'
    }
  },
  methods: {
    setFilter(id) {
      this.$emit('setFilter', 'category', id);
    }
  }
}
</script>

<style lang="scss">
.category-list-container {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;

  .category-card {
    border-radius: 5px;
    width: 277px;
    flex: 0 0 auto;
    padding: 10px;
    border: 1px solid #D7D7D7;
    transition: transform .5s;

    &:hover {
      transform: scale(1.05);
    }

    .category-card-img {
      padding: 3em;
    }

    .category-card-body {
      width: 100%;
      height: 100%;

      .category-card-name {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}
</style>
