<template>
<div class="d-flex w-100 text-center">
  <h1 class="m-auto">404</h1>
</div>
</template>

<script>
export default {
  name: "NotFoundComponent"
}
</script>
