export default {
  name: "Nume",
  invoice_name: 'Nume factura',
  partner: 'Partener',
  crm_code: "Code crm",
  company:"Compania",
  rationale: "Motivare",
  description: "Descriere",
  start_use_date: "Valabil de la",
  end_use_day_month: "Ultima zi din an pentru plasarea comenzilor",
  categories: "Categorii",
  image: "Imaginea (fisier)",
  future_values: "Valori viitoare",
  value: 'Valoare',
  type: 'Tip',
  future_value: 'Valoare viitoare',
  future_start_date: 'Data intrare in vigoare',
  day: 'Ziua',
  month: 'Luna',
  year: 'An',
  working_days: 'Zile lucrate',
  budget_expiration_date: 'Data expirare buget',
  total_budget: 'Buget total',
  first_name: 'Prenume',
  last_name: 'Nume',
  invitation_email: 'Email',
  email: 'Email',
  phone_number: 'Numar telefon',
  benefit_group: 'Grup beneficii',
  event: 'Eveniment',
  event_date: 'Data eveniment',
  file: 'Fisier',
  group_name: 'Denumire grup',
  event_start_date: 'Data de inceperea a evenimentului',
  event_end_date: 'Data de sfarsit a evenimentului',
  ticket_value: 'Valoarea tichetului',
  minimum_value_per_order: 'Valoare minima a comenzii',
  maximum_legal_value_per_month: 'Valoare maxima pe luna',
  maximum_legal_value_per_event: 'Valoare maxima pe eveniment',
  maximum_legal_value_per_year: 'Valoare maxima pe an',
  not_in_range: 'Nu este intre limite',
  benefits: 'Beneficii',
  expiration_date: 'Data expirare',
  maximum_topup_limit: 'Limita superioara pentru alimentare',
  maximum_topup_limit_per_minor: 'Limita superioara pentru alimentare per minor',
  cnp: 'Cod numeric personal (CNP)',
  underage_children_nr: 'Numar copii minori',
  benefit_category_code: 'Grup beneficii',
  up_ro_address_id: 'Cod adresa livrare carduri',
  budget: 'Buget',
  benefit: 'Beneficiu',
  occasion: 'Ocazie',
  require: 'Trebuie',
  requests: 'Solicitarile',
  date: 'Data',
  address: 'Adresa',
  code_value: 'Valoare cod',
  selected_counties: 'Judete',
  selected_companies : 'Clienti',
  offer_category: 'Categorie oferta',
  usage_instructions: 'Instructiuni utilizare',
  end_use_date: 'Valabil pana la',
  partner_name: 'Nume partener',
  partner_image: 'Imagine partener (fisier)',
  contact_phone: 'Nr telefon',
  contact_site: 'Website',
  iban: 'IBAN banca',
  voucher_digital_code: 'Voucher digital (cod)',
  voucher_digital_file: 'Voucher digital (fisier)',
  voucher_billing_value: 'Valoare voucher',
  sub_type: 'Sub tip',
  end_use_day_month_day: 'Luna ultimei zile din an pentru plasarea comenzilor',
  end_use_day_month_month: 'Ultima zi din an pentru plasarea comenzilor',
  selected_period_code: 'Perioada abonament',
  delivery_type: 'Tip livrare',
  dynamic_fields: 'Campuri dinamice',
  exists: 'deja folosit',
  partner_description: 'Descriere partener',
  up_dejun_enable_auto_topup: 'Auto alimentare Up Dejun',
  up_dejun_ticket_value: 'Valoare tichet auto alimentare Up Dejun',
  income_tax_percent: 'Procent impozit pe venit',
  cas_percent: 'Procent CAS',
  cass_percent: 'Procent CASS',
  fixed_benefit_group_code: 'Grup beneficii fixe',
  contract_number: 'Numar Polita',
  recurring_value: 'Valoare alimentare lunara',
  name_mix: "Nume+Prenume",
  birth_date: "Data de nastere",
  points_pago: "Echivalent puncte Pago",
  gym_membership_threshold_limit : 'Plafon abonament sportiv incorect. Limita depasita',
  auto_topup_not_legislated: 'Re�ncarcare nelegislata',
  order_exists: 'Cerere de comanda',
  company_employee: 'Angajat al companiei',
  company_employee_benefit: 'Beneficiu pentru angajat al companiei',
  vat: "TVA",
  cif: 'CUI',
}
