export default {
  title: "Import din fisiere",
  select: " Alege fisier import de {type}",
  select_update_beneficiaries:"Alege fisierul de import pentru actualizarea beneficiarilor.",
  descriptive_text: "Import beneficiari, bugete si pontaje.",
  greetings_question: "Ce ai vrea sa faci?",
  no_file_uploaded: "Incarca un fisier pentru a continua.",
  go_back_action: "Inapoi",
  go_back_file_upload: "Inapoi la incarcarea fisierului",
  entries: "Intrari",
  complete: {
    thank_you: 'Multumesc!',
    fail: 'Esuat!',
    fail_text: 'Importul a esuat! Te rugam sa reincerci.',
    beneficiary: {
      text_1: 'Importul a fost terminat. <strong>{total}</strong> beneficiari au fost adaugati si <strong>{updated}</strong> modificati.',
      text_1_no_update: 'Importul a fost terminat. <strong>{total}</strong> beneficiari au fost adaugati.',
    },
    //todo: de modificat daca este nevoie
    update_beneficiary: {
      text_1: 'Importul a fost terminat. <strong>{total}</strong> beneficiari au fost adaugati si <strong>{updated}</strong> modificati.',
      text_1_no_update: 'Importul a fost terminat. <strong>{total}</strong> beneficiari au fost adaugati.',
    },
    budget: {
      text_1: 'Importul a fost terminat. <strong>{total}</strong> bugete incarcate si <strong>{updated}</strong> modificate',
      text_1_no_update: 'Importul a fost terminat. <strong>{total}</strong> bugete incarcate.',
      text_2: 'Total budget amount <strong>{sum} Lei.</strong>',
    },
    time_sheet: {
      text_1: 'Importul a fost terminat. <strong>{total}</strong> pontaje au fost adaugate si <strong>{updated}</strong> modificate.',
      text_1_no_update: 'Importul a fost terminat. <strong>{total}</strong> pontaje au fost adaugate.',
    },
    review_text_2: 'In sectiunea Beneficiari poti regasi inregistrarile de {type} importate pentru fiecare angajat.',
    review_text_3: 'De asemenea, importurile precedente, pot fi gasite in sectiunea Istoric import.',
  },
  types: {
    beneficiaries: "beneficiari",
    update_beneficiary: 'actualizarii beneficiari',
    beneficiary: "beneficiari",
    time_sheet: "pontaje",
    time_sheets: "pontaje",
    budgets: "bugete",
    budget: "bugete",
    ppp3: "pensie privata",
    telework_budgets: "bugete telemunca",
    offer_beneficiaries: 'beneficiari cu access la oferte speciale'
  },
  statuses: {
    fail: "Esuat",
    complete: "Finalizat",
    pending: "Procesare",
    ready: "Pregatit",
  },
  item_statuses: {
    new: "Nou",
    valid: 'Valid',
    invalid: "Invalid",
    duplicate: "Duplicat",
    update: "Modificat",
  },
  summary: {
    total_items: "Numar total intrari:",
    total_valid: "Numar intrari valide:",
    total_invalid: "Numar intrari invalide:",
    total_selected: "Numar intrari selectate:",
    total_new: "Numar intrari noi:",
    total_duplicate: "Numar intrari duplicate:",
    total_update: "Numar intrari de modificare:",
    total_amount: "Total buget:",
    time_sheet_date: "Pontaj incarcat pentru luna:",
    notice_new_beneficiary: "Beneficiarii nou adaugati vor primi un email de invitatie pentru a se inregistra pe platforma.",
    filter_entries: "Filtre",
  },
  invalid_reasons: {
    empty_import: "Importul nu contine date, incearca un fisier diferit.",
    malformed_import_file: "Fisierul de import nu respecta macheta sau campurile nu sunt formatate corect.",
    invalid_year: "Anul importului este invalid.",
    invalid_month: "Luna importului este invalida.",
    miss_match_last_top_up_day: "Nu se poate incarca pontajul pentru luna selectata. Beneficiarii inca pot plasa solicitari pana pe {date} pentru luna in curs.",
    import_completion_failed: "Importul nu a putut fi terminat.",
  },
  table_headings: {
    first_name: "Prenume",
    last_name: "Nume",
    name_mix: "Nume+Prenume",
    name: "Beneficiar",
    cnp: "Cod numeric personal(CNP)",
    ppp3_budget: "Buget PPP3",
    ppp3_expiration_date: "Data expirare PPP3",
    action: "Actiune",
    contribution_type: "Tip contributie setata",
    personal_contribution_value: "Valoare contributie personala",
    company_contribution_value: "Valoare contributie companie",
    contract_number: "Numar contract",
    benefit_name:"Nume beneficiu",
    insurance_policy_value:"Valoare polita",
    invitation_email: "Email invitatie",
    email: "Email",
    invitation_channel: "Invitatie in platforma prin",
    benefit_group: "Grup de beneficii",
    phone_number: "Numar telefon",
    employee_number: "ID angajat",
    department: "Departament",
    job_title: "Functie",
    cost_center: "Centru de cost",
    working_point: "Punct lucru",
    underage_children_nr: "Numar copii minori",
    total_budget: "Buget disponibil",
    budget: "Buget",
    expiration_date: "Data expirare",
    updated_at: "Ultima actualizare",
    working_days: "Numar zile lucrate",
    recorded_for: "Inregistrat pentru",
    status_entry: "Status intrare",
    year: "An",
    month: "Luna",
    last_employment_date: "Sters la",
    group: "Grup",
    benefit_category_code: "Grup beneficii",
    up_ro_address_id: 'Cod adresa livrare carduri',
    address: 'Adresa',
    up_dejun_enable_auto_topup: 'Auto alimentare Up Dejun',
    up_dejun_ticket_value: 'Valoare (lei) tichet auto alimentare Up Dejun',
    up_dejun_ticket_count: 'Nr tichete auto alimentare Up Dejun',
    up_dejun_ticket_value_no_tips: 'Valoare tichet auto alimentare Up Dejun',
    income_tax_percent: 'Procent impozit pe venit',
    cas_percent: 'Procent CAS',
    cass_percent: 'Procent CASS',
    fixed_benefit_group: 'Grup beneficii fixe',
    fixed_benefit_group_code: 'Grup beneficii fixe',
    ppp3_deduction_limit: 'Plafon Deductibilitate PPP3',
    ppp3_personal_deduction_limit: 'Plafon Deductibilitate Personala PPP3',
    medical_insurance_deduction_limit: 'Plafon Deductibilitate Asigurare medicala',
    thresholds: {
      gym_membership: 'Plafon Abonamente Sportive',
      deleted_at: 'Beneficiar sters in data de:',
    }
  },
  buttons: {
    delete_button: "Sterge {count} intrare(i) selectate",
    chose_beneficiaries: "Import beneficiari",
    chose_update_beneficiaries: "Actualizeaza beneficiari",
    chose_budgets: "Import bugete",
    chose_time_sheets: "Import pontaje",
    chose_telework_budgets: "Import bugete telemunca",
    chose_ppp3: "Import ppp3",
    chose_offer_beneficiaries: 'Import beneficiari cu access la oferte speciale',
    upload_file: "Incarca fisier",
    download_template: "Descarca exemplu",
    next_step: "Urmatorul pas",
    go_back_action: "Inapoi la actiuni",
    go_back_file_upload: "Inapoi la incarcare fisier",
    confirm_complete: "Confirma import",
    go_to_beneficiary: "Catre beneficiari",
    go_to_import_history: "Catre istoric import",
    new_import: "Import nou",
  },
}
