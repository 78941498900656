<template>
  <div>
    <div v-if="!emailSent" class="col-12">
      <div class="row">
        <div class="col-12 text-center mbo-card bold">
          {{ $t('flexben.affiliate_channels.partner.register.forgot_account.title') }}
        </div>
        <div class="col-12 mbo-card text-center half bold">
            {{ $t('flexben.affiliate_channels.partner.register.forgot_account.description') }}
        </div>
        <div class="col-12 col-md-8 offset-md-2">
          <input type="text"
                 required
                 v-model="formData.cif"
                 placeholder=""
                 id="code"
                 class="mt-3 form-control"/>

          <label v-show="!formData.cif.length" for="code" class="field-label">
            {{ $t('flexben.affiliate_channels.partner.register.forgot_account.fields.cif') }}
          </label>

          <div class="text-danger" v-if="error">
            {{ error }}
          </div>
        </div>
        <div class="col-12 mt-2 text-center mt-3 d-flex flex-column">
          <button class="btn-themed mbo-btn form-submit border-0 mx-auto mt-3" @click="resendInvitation">
            {{$t('flexben.general.btn.confirm')}}
          </button>
        </div>
      </div>
    </div>
    <div v-if="emailSent" class="col-12">
      <div class="row">
        <div class="col-12 col-xl-4 d-flex justify-content-center align-items-start mb-3 mb-xl-0 mx-auto">
          <div class="ml-2 image-container d-flex align-items-center mx-auto">
            <img class="ml-5 align-self-center" src="../../../../assets/images/congrats.png"/></div>
        </div>
      </div>


      <h2 class="text-center">{{ $t("successMessageTitle") }}</h2>
      <p class="text-center lightGrey">
        {{ $t("flexben.affiliate_channels.partner.register.forgot_account.email_sent") }}
      </p>

      <div class="row mt-3">
        <div class="col-12 text-center">

          <button class="btn-themed" @click="resendInvitation">
            {{$t('flexben.affiliate_channels.btns.send_again')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpFlexben from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";

export default {
  name: "ForgotAccount",
  props: {
    landingPageId: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      locale: i18n.locale,
      httpService: httpFlexben.affiliationChannels.partner,
      emailSent: false,
      error: null,
      formData: {
        cif: '',
        lang: this.locale,
        landingPageId: this.landingPageId
      }
    }
  },
  beforeUpdate() {
    this.locale = i18n.locale;
  },
  methods: {
    resendInvitation() {
      this.httpService.sendInvite(this.formData).then((response) => {
        if (response.data.status === 'success') {
          this.emailSent = true;
        } else {
          this.error = response.data.message;
        }
      }).catch(function (error) {
        console.log(error)
      });
    }
  }
}
</script>
