export default {
  title: "Grupuri de beneficii",
  new: "Grup nou",
  edit: "Modifica grup",
  list: "Lista de grup",
  benefits: "Beneficii",
  benefit_type_legislated: "legiferate",
  benefit_type_flexible: "nelegiferate",
  no_benefits_available: "Nu ai beneficii {type} alocate",
  detail: {
    ben_name: 'Nume beneficiu',
    contribution: {
      client: 'Contributie angajator',
      personal: 'Contributie personala'
    },
    partner_name: 'Partener',
    description: 'Descriere',
    allocation_control: 'Controleaza alocare',
    allocated: 'Alocat',
    not_allocated: 'Nealocat',
  },
  modal: {
    confirm_save: 'Esti sigur ca vrei sa salvezi modificarile?',
    confirm_leave: 'Exista informatii modificate care nu au fost salvate. Confirmi parasirea paginii?',
    confirm_delete: 'Esti sigur ca vrei sa sa stergi grupul?',
    disclaimer_delete: 'Beneficiarii care apartin acestui grup vor fi alocati grupului implicit.'
  },
  types: {
    all: 'Toate',
    default: 'Implicit',
    not_default: 'Personalizat',
  },
  benefit_types: {
    all: 'Toate',
    1: 'Beneficii flexibile',
    2: 'Beneficii fixe',
  },
  fields: {
    title: "Benefit groups",
    company: "Client",
    group: "Grup",
    code: "Cod grup",
    benefits: 'Beneficii',
    type: 'Tipul grupului',
    benefit_type: "Tip beneficii",
  },
  btn: {
    add_all: 'Selecteaza toate',
    remove_all: 'Deselecteaza toate',
  }
}
