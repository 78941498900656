<template>
  <div class="container-bg-white mt-3">
    <div class="row p-3">
      <div class="col-12">
        <h2 class="ml-0 mb-3">{{ $t('flexben.general.about') }} {{$t('flexben.menu.app_name')}}</h2>
        <div class="mb-3">{{ $t('flexben.about.text_1') }}</div>
        <div>{{ $t('flexben.about.text_2') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "About"
  }
</script>
