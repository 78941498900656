<template>
  <div class="container-fluid flexben-container flexben-beneficiary-auto-topup-benefits">
    <div class="row mt-2">
      <div class="col-12 col-md-6 flexben-page-title">
        <h1>{{ $t('flexben.beneficiary_pages.auto_topup_benefits.title')}}</h1>
      </div>
    </div>
    <div class="benefit-list-container mt-3" v-show="!loading">
      <div class="benefit-card d-flex flex-column ml-0 mx-auto mx-lg-2 mb-3"
           v-for="(benefit,index) in benefits"
           :key="index">
        <img class="benefit-card-img cursor-pointer" :src="benefit.image_url" :alt="benefit.name[locale]"
             @click="showBenefit(benefit)">
        <div class="benefit-card-body d-flex flex-column">
          <div class="benefit-card-name mt-1 text-capitalize">{{benefit.name}}</div>
          <div class="benefit-card-description mt-1 mb-auto">
            <span class="text-bold text-capitalize">{{ (benefit.partner_id!==null) ? benefit.partner.name :'-' }}</span><br>
            <div v-html="benefit.description[locale]" class="links-container"></div>
          </div>
          <div class="benefit-card-actions mt-1">
            <button class="btn btn-themed" @click="showBenefit(benefit)">
              {{ $t('flexben.general.details') }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="benefits.length===0" class="mx-auto text-bold">
        {{ $t("flexben.general.no_results_found") }}
      </div>
    </div>
    <scroll-to-top-btn></scroll-to-top-btn>
    <content-loader :loaderVisible="loading" :loaderError="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";

export default {
  name: "BeneficiaryAutoTopups",
  data() {
    return {
      loading: true,
      loaderError: false,
      httpService: httpFlexbenService.beneficiary,
      benefits: []
    }
  },
  watch: {
    beneficiaryId: {
      handler: function () {
        this._loadBenefits();
      },
      immediate: true
    }
  },
  computed: {
    beneficiaryId: function () {
      return this.$store.getters.flx_beneficiaryActiveEmploymentId;
    },
    locale: function () {
      return i18n.locale;
    },
  },
  methods: {
    showBenefit(benefit) {

      let benefitId = null;
      let childBenefitId = null;
      let budgetType = benefit.budget_type;
      let url = '/beneficiary/multibeneficii';

      switch (benefit.sub_type) {
        case 'ppp3':
          benefitId = benefit.parent_id;
          childBenefitId = benefit.id;
          break;
        default:
          benefitId = benefit.id;
      }

      switch (budgetType) {
        case 'telework':
          url = '/beneficiary/multibeneficii/telework-benefits';
          break
        case 'general':
          url = '/beneficiary/multibeneficii/benefits';
      }

      sessionStorage.setItem('flexbenFastTopUpChildBenefitId', childBenefitId);
      sessionStorage.setItem('flexbenFastTopUpBenefitId', benefitId);

      window.location.href = url;
    },
    _loadBenefits() {
      this.loading = true;
      this.httpService.autoTopupsBenefits(this.beneficiaryId)
        .then((response) => {
          this.benefits = response.data;
          this.loading = false;
        })
        .catch(function (error) {
          if (500 <= error.response.status && error.response.status < 600) {
            this.loaderError = true;
            this.loading = false;
          } else {
            this.loading = false;
          }
        });
    }
  }
}
</script>

<style scoped lang="scss">
.fixed-benefit-list {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;

  .fixed-benefit {
    width: 300px;
    flex: 0 0 auto;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-direction: column;
    //align-items: top;
    //justify-content: center;
    //background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin: 0 auto 2rem auto;
    transition: 0.5s;

    .fixed-benefit-img {
      content: "";
      width: 300px;
      height: 300px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .fixed-benefit-text {
      margin-top: 0.2rem;
      font-size: 16px;
      text-align: center;
      line-height: 1.1;
      overflow: hidden;
      max-width: 300px;
      word-wrap: break-word;
      max-height: 50px;
      text-overflow: ellipsis;
    }
  }

  @media(min-width: 381px) and (max-width: 740px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .fixed-benefit {
      margin: 0 auto 1rem;

      .fixed-benefit-text {
        max-height: 45px;
        text-align: center;
        font-size: 14px;
      }
    }
  }
  @media (min-width: 300px) and (max-width: 380px) {
    .fixed-benefit {
      margin: 0 auto 0.3rem;
      width: 260px;

      .fixed-benefit-img {
        width: 260px;
        height: 260px;
      }

      .fixed-benefit-text {
        max-height: 45px;
        text-align: center;
        max-width: 260px;
        font-size: 14px;
      }
    }
  }
}

.flx-list-container {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;

  .flx-card {
    border-radius: 5px;
    width: 277px;
    flex: 0 0 auto;
    padding: 10px;
    border: 1px solid #D7D7D7;
    transition: transform .5s;

    &:hover {
      transform: scale(1.05);
    }

    .flx-card-img {
      width: 255px;
      height: 255px;
      border-radius: 5px;
    }

    .flx-card-body {
      width: 100%;

      .flx-card-name {
        font-size: 16px;
        line-height: 1.1;
        overflow: hidden;
        max-width: 277px;
        word-wrap: break-word;
        //max-height: 50px;
        text-overflow: ellipsis;
        font-weight: 700;
      }
    }
  }
}
</style>

