import httpFlexbenService from "@/http/http-flexben";
import i18n from "@/plugins/language/i18n";
import FixedBenefitModal from "./modals/FixedBenefitModal";

export default {
  name: "BeneficiaryFixedBenefits",
  components: {
    'fixed-benefit-modal': FixedBenefitModal
  },
  data() {
    return {
      loading: true,
      loaderError: false,
      httpService: httpFlexbenService.beneficiary,
      fixedBenefits: [],
      modalBenefit: {
        name: {},
        description: {},
        image_url: null,
      }
    }
  },
  watch: {
    beneficiaryId: {
      handler: function (val) {
        this.getFixedBenefits();
      },
      immediate: true
    }
  },
  computed: {
    beneficiaryId: function () {
      this.loading = false;
      return this.$store.getters.flx_beneficiaryActiveEmploymentId;
    },
    locale: function () {
      return i18n.locale;
    },
  },
  methods: {
    getFixedBenefits: function () {
      if (this.beneficiaryId !== null) {
        this.loading = true;
        this.httpService.fixedBenefits(this.beneficiaryId)
          .then(function (response) {
            this.fixedBenefits = response.data.list;
            this.loading = false;
          }.bind(this))
          .catch(function (error) {
            this.loading = false;
          }.bind(this));
      } else {

      }
    },
    showModal(offerIndex) {
      this.modalBenefit.name = this.fixedBenefits[offerIndex].name;
      this.modalBenefit.description = this.fixedBenefits[offerIndex].description;
      this.modalBenefit.image_url = this.fixedBenefits[offerIndex].image_url;
      this.$refs['modal_benefit'].openModal();
    }
  }
}
