export default {
  title: 'Canale parteneri afiliati',
  description: 'Administreaza link-uri de invitatie',
  edit_partner: 'Editare profil',
  btns: {
    new: 'Genereaza link',
    send_again: 'Retrimite email',
    not_partner: 'Nu sunt partener'
  },
  fields: {
    name: 'Nume',
    social_platform: 'Platforma sociala',
    specific_identifier: 'Identificator specific',
    landing_page_id: 'Id generat',
    commercial_identifier: 'Identificator comercial',
    invitation_url: 'Url invitatie',
  },
  modal: {
    name: 'Nume canal',
    social_platform: 'Platforma sociala',
    specific_identifier: 'Identificator specific',
    commercial_identifier: 'Identificator comercial',
    channel_type: 'Tip canal de afiliere',
    channel_types: {
      commercial: 'Comercial',
      platform: 'Platforma',
    },
  },
  partner: {
    register: {
      title: 'Devino partener Up MultiBeneficii',
      forgot_account: {
        title: 'Ai uitat link-ul de invitatie?',
        description: 'Te rugam sa introduci codul fiscal pentru a primi link-ul de invitatie pe mail',
        email_sent: 'A fost transmis un email ce contine contractul de afiliere si link-ul de invitatie',
        email_sent_with_contract: 'A fost transmis un email ce contine link-ul de invitatie',
        invitation_url: 'Acum poti crea oferta ta personalizata!',
        manage_offers: 'Gestioneaza ofertele',
        active_contract: 'Esti deja partener',
        fields: {
          cif: 'Codul fiscal'
        }
      },
      form_fields: {
        image: 'Imagine',
        description: 'Spune-ne ceva despre tine',
        site: 'Website',
        phone: 'Nr. Telefon',
        address: 'Adresa',
        email: 'Email',
        fiscal_code: 'Cod fiscal',
        onrc: 'Cod R comertului',
        company_name: 'Nume companie',
        county: 'Judet',
        city: 'Localitate',
        name: 'Persoana de contact',
        capital: 'Capital',
        iban: 'IBAN',
        signature: 'Semnatura',
        contract_nr: 'Nr Contract',
        function: 'Functie',
      },
      btns: {
        send_contract: 'Trimite',
        show_preview: 'Previzualizeaza'
      },
      tool_tip: {
        iban: 'Numar international de cont bancar',
        fiscal_code: 'Cod de identificare fiscala (CIF)',
        onrc: 'Cod de inregistrare la registrul comertului',
        capital: 'Capital social al companiei'
      },
      landing_page: {
        text_1: "Posteaza oferte in Up MultiBeneficii si creste-ti vanzarile!",
        text_2: "Devino partener in doi pasi simpli",
        text_3: "Completaza datele si genereaza contractul automat",
        text_4: "Listeaza ofertele in sectiunea preferata",
        text_5: "SAU",
        text_6: "Up MultiBeneficii iti aduce si tie beneficii:",
        text_7: "Iti promovam gratuit ofertele si serviciile in platforma Up MultiBeneficii",
        text_8: "Iti oferim vizibilitate printr-un mix de promovare atractiv",
        text_9: "Beneficiezi de interactiune cu o baza de utilizatori 24/7, intr-o platforma moderna",
        text_10: "Ai acces la o comunitate premium de clienti",
        text_11: "Ai consultanta gratuita de marketing in construirea ofertei tale",
        text_12: "Ai un canal nou de promovare",
        text_13: "Am citit",
        text_14: "si sunt de acord cu ele",
        text_15: "Am citit si sunt de acord cu ",
        text_16: "Pagina nu a fost gasita. Verifica link-ul de invitatie sau contacteaza-ne.",
        text_17: "Up MultiBeneficii este platforma online prin care angajatorii ofera angajatilor un buget, iar angajatii decid unde sa cheltuiasca banii.",
        text_18: "Mii de angajati din Romania folosesc platforma Up MultiBeneficii, pentru a achiziona produse si servicii dintr-o gama variata.",
        text_19: "Prin completarea formularului, ai acces la pagina de Oferte Speciale dedicata doar tie, din cadrul platformei Up MultiBeneficii.",
        text_20: "Ofertele tale pot fi vizualizate de mii de angajati cu acces in platforma.",
        text_21:""
      }
    }
  },
  t_and_c: "Termeni si conditii",
  gdpr: "Politica privind protectia datelor cu caracter personal",
}
